<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row" id="row">
          <div :class="'col-' + col" v-for="(inf, key) in iconInfo" :key="key">
            <RotateImg :info="inf" :id="'rot-' + key" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RotateImg from "@/components/RotateImg.vue";
export default {
  components: {
    RotateImg,
  },
  props: {
    iconInfo: Array,
    mobile: Boolean,
  },
  computed: {
    col() {
      var numCol = "";
      if (this.mobile) {
        numCol = "6";
      } else {
        if (this.iconInfo.length === 3) {
          numCol = "4";
        } else if (this.iconInfo.length === 4) {
          numCol = "3";
        }
      }
      return numCol;
    },
  },
};
</script>
