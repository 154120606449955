<template>
  <div class="container-fluid">
    <div class="row">
      <template v-if="imgLeft">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="p-1">
            <img class="img-fluid" :src="require('@/assets/Images/Home/' + infoSec.img)" alt="img" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text">
          <div class="p-1">
            <div class="title-sec">
              <h2 data-aos="fade-right">{{ infoSec.title }}</h2>
            </div>
            <div class="text-sec" v-html="infoSec.text"></div>
            <div v-if="haveButton" class="button-sec">
              <a class="btn" @click="goTo">{{ infoSec.button }}</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text">
          <div class="p-1">
            <div class="title-sec">
              <h2 data-aos="fade-right">{{ infoSec.title }}</h2>
            </div>
            <div class="text-sec" v-html="infoSec.text"></div>
            <div v-if="haveButton" class="button-sec">
              <a class="btn" @click="goTo">{{ infoSec.button }}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="p-1">
            <img class="img-fluid" :src="require('@/assets/Images/Home/' + infoSec.img)" alt="img" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoSec: Object,
    imgLeft: Boolean,
    haveButton: Boolean,
    modal: true,
    link: String,
    page: String,
    openMenu: String,
  },
  methods: {
    goTo() {
      if (this.openMenu && this.openMenu.trim() != "") {
        this.$store.state.showMenu = true;
        this.$store.state.menuOption = this.openMenu;
      } else if (this.page && this.page.trim() != "") {
        this.$router.push({ name: this.page });
      } else if (this.link && this.link.trim() != "") {
        window.open(this.link, "_blank");
      } else if (this.modal && this.lang != 'es') {
        window.$("#subscription-modal").modal("show");
      } else if(this.lang != 'es') {
        window.$("#contact-modal").modal("show");
      }
    },
  },
};
</script>

<style scoped>
.text {
  padding: 80px 40px 0px;
}

.title-sec {
  padding-right: 0px;
  margin-bottom: 30px;
}

.title-sec h2 {
  text-align: left;
  font: normal normal 800 23px/30px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.text-sec {
  padding: 25px 0px;
}

.text-sec >>> h4 {
  text-align: left;
  font: normal normal 600 28px/34px Montserrat;
  letter-spacing: 0px;
  color: #383838;
  opacity: 1;
  margin-bottom: 25px;
}

.btn {
  background-image: url("../../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  border-radius: 10px;
  /* border: 0.4px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  font: normal normal bold 22px/27px Montserrat;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 24px 55px;
  color: #0f1010;
  background-size: 95px 80px;
  height: 80px;
}

.btn:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

.img-fluid {
  height: 500px;
  width: auto;
  object-fit: cover;
}

@media (min-width: 1920px) {
  .img-fluid {
    height: 600px;
  }
}
</style>
