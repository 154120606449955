import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import store from "@/store";

const routesMap = require(`../routes/${process.env.VUE_APP_LOCALE}`).default;

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: routesMap["/coach-senior"],
    name: 'CoachSenior',
    component: () => import( /* webpackChunkName: "coach-senior" */ '../views/CoachSenior.vue')
  },
  {
    path: routesMap["/coach-jeunes"],
    name: 'CoachJeunes',
    component: () => import( /* webpackChunkName: "coach-jeunes" */ '../views/CoachJeunes.vue')
  },
  {
    path: '/faq.php',
    redirect: { name: 'Faq'}
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import( /* webpackChunkName: "faq" */ '../views/Faq.vue')
  },
  {
    path: routesMap["/espace-membres"] + '.php',
    redirect: { name: 'Members'}
  },
  {
    path: routesMap["/espace-membres"],
    name: 'Members',
    component: () => import( /* webpackChunkName: "espace-membres" */ '../views/Members.vue')
  },
  {
    path: '/blog.php',
    redirect: { name: 'Blog'}
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import( /* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
  {
    path: routesMap['/blog/category'] + '/:id',
    name: 'BlogCategory',
    component: () => import( /* webpackChunkName: "blog" */ '../views/BlogCategory.vue')
  }, 
  {
    path: routesMap['/guide-800-exercices'] + '.php',
    redirect: { name: 'Exercices'}
  },
  {
    path: routesMap['/guide-800-exercices'],
    name: 'Exercices',
    component: () => import( /* webpackChunkName: "800-exercices" */ '../views/Exercices.vue')
  },
  {
    path: routesMap['/300-exercices-app'],
    name: 'ExercicesApp',
    component: () => import( /* webpackChunkName: "300-exercices-app" */ '../views/ExercicesApp.vue')
  }, 
  {
    path: routesMap['/seances-coach-jeunes'] + '.php',
    redirect: { name: 'SaisonJeunes'}
  },
  {
    path: routesMap['/seances-coach-jeunes'],
    name: 'SaisonJeunes',
    component: () => import( /* webpackChunkName: "saison-complete-jeunes" */ '../views/SaisonJeunes.vue')
  }, 
  {
    path: routesMap['/seances-coach-seniors'] + '.php',
    redirect: { name: 'SaisonSenior'}
  },
  {
    path: routesMap['/seances-coach-seniors'],
    name: 'SaisonSenior',
    component: () => import( /* webpackChunkName: "saison-complete-senior" */ '../views/SaisonSenior.vue')
  },
  {
    path: routesMap['/coach-de-senior'],
    name: 'CoachS',
    component: () => import( /* webpackChunkName: "coach-de-senior" */ '../views/CoachSeniorPage.vue')
  },
  {
    path: routesMap['/coach-de-jeunes'],
    name: 'CoachJ',
    component: () => import( /* webpackChunkName: "coach-de-jeunes" */ '../views/CoachJeunesPage.vue')
  }, 
  {
    path: routesMap['/contact'] + '.php',
    redirect: { name: 'Contacto'}
  },
  {
    path: routesMap['/contact'],
    name: 'Contacto',
    component: () => import( /* webpackChunkName: "coach-de-jeunes-y-senior" */ '../views/Contacto.vue')
  },
  {
    path: routesMap['/preparation-physique-coach'] + '.php',
    redirect: { name: 'Formation' }
  },
  {
    path: routesMap['/preparation-physique-coach'],
    name: 'Formation',
    component: () => import( /* webpackChunkName: "formation" */ '../views/Formation.vue')
  },
  {
    path: routesMap['/programmes'],
    name: 'Programmes',
    component: () => import( /* webpackChunkName: "programmes" */ '../views/Programmes.vue')
  }, 
  {
    path: routesMap['/programmes/treve-hivernale'] + '.php',
    redirect: { name: 'TreveHivernale'}
  },
  {
    path: routesMap['/programmes/treve-hivernale'],
    name: 'TreveHivernale',
    component: () => import( /* webpackChunkName: "treve-hivernale" */ '../views/TrainingWinter.vue')
  }, 
  {
    path: routesMap['/programmes/treve-estivale-coach'] + '.php',
    redirect: { name: 'TreveEstivale'}
  },
  {
    path: routesMap['/programmes/treve-estivale-coach'],
    name: 'TreveEstivale',
    component: () => import( /* webpackChunkName: "treve-estivale" */ '../views/TrainingSaison.vue')
  }, 
  {
    path: routesMap['/guide/200-exercices-jeux-reduits'] + '.php',
    redirect: { name: 'Idees'}
  },
  {
    path: routesMap['/guide/200-exercices-jeux-reduits'],
    name: 'Idees',
    component: () => import( /* webpackChunkName: "programmes" */ '../views/Idees.vue')
  }, 
  {
    path: routesMap['/guide/management'] + '.php',
    redirect: { name: 'GuideManagement'}
  },
  {
    path: routesMap['/guide/management'],
    name: 'GuideManagement',
    component: () => import( /* webpackChunkName: "guide-management" */ '../views/CoachPractice.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/100-pour-100-ballon'] + '.php',
    redirect: { name: 'Avec'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/100-pour-100-ballon'],
    name: 'Avec',
    component: () => import( /* webpackChunkName: "avec" */ '../views/Avec.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse'] + '.php',
    redirect: { name: 'SpecialVitesse'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse'],
    name: 'SpecialVitesse',
    component: () => import( /* webpackChunkName: "special-vitesse" */ '../views/SpecialVitesse.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-gardien'] + '.php',
    redirect: { name: 'GardienVitesse'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-gardien'],
    name: 'GardienVitesse',
    component: () => import( /* webpackChunkName: "gardien-vitesse" */ '../views/GardienVitesse.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-gardien-U15-U18'] + '.php',
    redirect: { name: 'GardienVitesseU15'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-gardien-U15-U18'],
    name: 'GardienVitesseU15',
    component: () => import( /* webpackChunkName: "gardien-vitesse-u15" */ '../views/GardienVitesseU15.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance-futsal'] + '.php',
    redirect: { name: 'FutsalPuissance'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance-futsal'],
    name: 'FutsalPuissance',
    component: () => import( /* webpackChunkName: "futsal-puissance" */ '../views/FutsalPuissance.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18'] + '.php',
    redirect: { name: 'FutsalPuissanceU15'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18'],
    name: 'FutsalPuissanceU15',
    component: () => import( /* webpackChunkName: "futsal-puissance-U15" */ '../views/FutsalPuissanceU15.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-futsal'] + '.php',
    redirect: { name: 'FutsalVitesse'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-futsal'],
    name: 'FutsalVitesse',
    component: () => import( /* webpackChunkName: "futsal-vitesse" */ '../views/FutsalVitesse.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-futsal-U15-U18'] + '.php',
    redirect: { name: 'FutsalVitesseU15'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/vitesse-futsal-U15-U18'],
    name: 'FutsalVitesseU15',
    component: () => import( /* webpackChunkName: "futsal-vitesse-U15" */ '../views/FutsalVitesseU15.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance'] + '.php',
    redirect: { name: 'Puissance'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/endurance-puissance'],
    name: 'Puissance',
    component: () => import( /* webpackChunkName: "puissance" */ '../views/Puissance.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/jeunes/endurance-puissance-U15-U18'] + '.php',
    redirect: { name: 'PuissanceJeunes'}
  },
  {
    path: routesMap['/cycle-entrainement/jeunes/endurance-puissance-U15-U18'],
    name: 'PuissanceJeunes',
    component: () => import( /* webpackChunkName: "puissance-jeunes" */ '../views/PuissanceJeunes.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/jeunes/coordination-U8-U12'] + '.php',
    redirect: { name: 'Coordination'}
  },
  {
    path: routesMap['/cycle-entrainement/jeunes/coordination-U8-U12'],
    name: 'Coordination',
    component: () => import( /* webpackChunkName: "coordination" */ '../views/Coordination.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U8-U12'] + '.php',
    redirect: { name: 'VitesseJeunesU8'}
  },
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U8-U12'],
    name: 'VitesseJeunesU8',
    component: () => import( /* webpackChunkName: "vitesse-jeunesU8" */ '../views/VitesseJeunesU8.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U12-14'] + '.php',
    redirect: { name: 'VitesseJeunesU12'}
  },
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U12-14'],
    name: 'VitesseJeunesU12',
    component: () => import( /* webpackChunkName: "vitesse-jeunesU12" */ '../views/VitesseJeunesU12.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U14-18'] + '.php',
    redirect: { name: 'VitesseJeunesU14'}
  },
  {
    path: routesMap['/cycle-entrainement/jeunes/vitesse-U14-18'],
    name: 'VitesseJeunesU14',
    component: () => import( /* webpackChunkName: "vitesse-jeunesU14" */ '../views/VitesseJeunesU14.vue')
  }, 
  {
    path: routesMap['/cycle-entrainement/seniors/periodisation-tactique'] + '.php',
    redirect: { name: 'Periodisation'}
  },
  {
    path: routesMap['/cycle-entrainement/seniors/periodisation-tactique'],
    name: 'Periodisation',
    component: () => import( /* webpackChunkName: "periodisation-tactique" */ '../views/Periodisation.vue')
  },
  {
    path: '/question-:id',
    name: 'Question',
    component: () => import( /* webpackChunkName: "question" */ '../views/Question.vue')
  },
  {
    path: '/packs-:id',
    name: 'Packs',
    component: () => import( /* webpackChunkName: "packs" */ '../views/Packs.vue')
  },
  {
    path: routesMap['/les-emails'],
    name: 'Emails',
    component: () => import( /* webpackChunkName: "emails" */ '../views/Emails.vue')
  },
  {
    path: routesMap['/preparation-physique-des-jeunes-footballeurs'] + '.php',
    redirect: { name: 'GuideJeunes' },
  },
  {
    path: routesMap['/preparation-physique-des-jeunes-footballeurs'],
    name: 'GuideJeunes',
    component: () => import( /* webpackChunkName: "guide-preparation-jeunes" */ '../views/GuideJeunes.vue')
  },
  {
    path: routesMap['/boutique-en-ligne'],
    name: 'Shop',
    component: () => import( /* webpackChunkName: "boutique-en-ligne" */ '../views/Shop.vue')
  },
  {
    path: routesMap['/bientot-disponible'],
    name: 'Unavailable',
    component: () => import( /* webpackChunkName: "boutique-en-ligne" */ '../views/Unavailable.vue')
  },
  {
    path: routesMap['/mention-legal'],
    name: 'Mentions',
    component: () => import( /* webpackChunkName: "boutique-en-ligne" */ '../views/Mentions.vue')
  },
  {
    path: routesMap['/qui-sommes-nous'],
    name: 'APropos',
    component: () => import( /* webpackChunkName: "boutique-en-ligne" */ '../views/APropos.vue')
  },
  {
    path: '/:year/:id',
    name: 'Article',
    component: () => import( /* webpackChunkName: "article" */ '../views/BlogSolo.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, };
  }
});

const _history = [];

router.beforeEach((to, from, next) => {

  document.querySelector("#app").style.background = "#0f1010";

  setTimeout(() => {
    document.querySelector("#app").style.background = "#f7f7f7";
  }, 1000);

  if (_history[_history.length - 1] == to.fullPath) {
    _history.pop();
    store.commit("SET_TRANSITION", "prev");
  } else {
    _history.push(from.fullPath);
    store.commit("SET_TRANSITION", "next");
  }

  next();
});

router.afterEach((to, from) => {
  store.state.showMenu = false;
});

export default router