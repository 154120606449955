import "popper.js/dist/popper.min"
import "bootstrap/dist/js/bootstrap.min"
import "bootstrap/dist/css/bootstrap.min.css"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "aos/dist/aos.css";
import VueSocialSharing from 'vue-social-sharing'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
window.$ = window.jQuery = require('jquery')
import './assets/css/animations.css'

import VueSwal from "vue-swal";
Vue.use(VueSwal);

import localeMixin from "@/mixins/localeMixin";
Vue.mixin(localeMixin);

import Parse from 'parse'
Parse.initialize("BNPYTo7DklxcaqheAjyKkbdF6BHsfPkwEzjujNGk", "dxeGUhpfN7wM8TCUNGH7u2XxQPKGFZoW7KkcrEBX", "LeCynhMTPIGfRVL2fLUJqZiT06HYmGlujHo5Vjul")
Parse.serverURL = "https://parse.preparacionfisicafutbol.com/parse"
Parse.masterKey = "LeCynhMTPIGfRVL2fLUJqZiT06HYmGlujHo5Vjul"

Vue.use(VueSocialSharing);

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')