<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="titleTop" class="title-sec">
          <h2 data-aos="fade-right">{{ infoSec.title }}</h2>
        </div>
        <img
          :src="require('@/assets/Images/Home/' + infoSec.img)"
          alt="img"
          class="img-fluid"
        />
      </div>
    </div>
    <div class="col-12 text">
      <div class="p-1">
        <div v-if="!titleTop" class="title-sec">
          <h2 data-aos="fade-right">{{ infoSec.title }}</h2>
        </div>
        <div class="text-sec" v-html="infoSec.text"></div>
        <div v-if="haveButton" class="button-sec">
          <a class="btn btn-sc" @click="goTo">{{
            infoSec.button
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infoSec: Object,
    haveButton: Boolean,
    titleTop: Boolean,
    link: String,
    page: String,
    openMenu: String,
    modal: Boolean
  },
  methods: {
    goTo() {
      if (this.openMenu && this.openMenu.trim() != "") {
        this.$store.state.showMenu = true;
        this.$store.state.menuOption = this.openMenu;
      } else if (this.page && this.page.trim() != "") {
        this.$router.push({ name: this.page });
      } else if (this.link && this.link.trim() != "") {
        window.open(this.link, "_blank");
      } else if (this.modal && this.lang != 'es') {
        window.$("#subscription-modal").modal("show");
      } else if(this.lang != 'es') {
        window.$("#contact-modal").modal("show");
      }
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  font: normal normal 800 21px/25px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.text-sec >>> p,
.text-sec >>> h4 {
  text-align: center;
  font: normal normal 500 13px/21px Montserrat;
  letter-spacing: 0px;
  color: #383838;
  opacity: 1;
}

.text-sec >>> h4.first {
  font: normal normal 500 12px/18px Montserrat;
  color: #a2a2a2;
  margin-bottom: 60px;
}

.btn-sc {
  font: normal normal bold 14px/18px Montserrat;
  border-radius: 15px;
  padding: 35px 30px;
  background-image: url("../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* border: 0.4px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #0f1010;
  height: 90px;
  background-size: 95px 80px;
}

.img-fluid {
  max-width: 80%;
}

@media (max-width: 360px) {
  .btn-sc {
    padding: 25px 30px;
  }
}
</style>
