<template>
  <div class="card" v-if="artInfo">
    <img v-if="artInfo.picture" class="card-img-top" :src="artInfo.picture" alt="Img-card"/>
    <img v-else-if="artInfo.img" class="card-img-top" :src="require('@/assets/Images/' + artInfo.img)" alt="Img-card"/>
    <img v-else class="card-img-top" src="@/assets/Images/Home/Imagen 97.png" alt="Img-card"/>
    <div class="card-body">
      <div class="category">
        <p>{{ artInfo.category }}</p>
      </div>
      <h5 class="card-title">{{ artInfo.title }}</h5>
      <p class="card-sub">{{ artInfo.sub }}</p>
      <p class="card-text">{{ artInfo.text }}</p>
      <a class="read-more" @click="$router.push('/' + artInfo.year + '/' + artInfo.slug)">
        {{locale[lang].ARTICLE_LINK}}  
        <i class="fas fa-arrow-right" id="arrow"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    artInfo: Object,
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 30px;
  opacity: 1;
  margin-bottom: 40px;
}

img {
  height: 263px;
  object-fit: cover;
  border-radius: 30px 30px 0px 0px;
}

.card-body {
  position: relative;
  padding: 30px 60px 24px 32px;
}

.category {
  background: #fc202e 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  padding: 11px 30px 0px 29px;
  top: -14%;
}

.category p {
  text-align: left;
  font: normal normal 500 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.card-title {
  text-align: left;
  font: normal normal bold 21px/25px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.card-text {
  text-align: left;
  font: normal normal 500 18px/30px Montserrat;
  letter-spacing: 0px;
  color: #c2c2c2;
  opacity: 1;
}

.card-sub {
  text-align: left;
  font: normal normal 500 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.read-more {
  text-align: left;
  font: normal normal 600 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #ff0313;
  opacity: 1;
  display: block;
}

#arrow {
  margin-left: 10px;
  padding-top: 2px;
}
</style>
