<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row  justify-content-center">
          <div class="col-12 col-md-6 mt-4 title" data-aos="fade-right">
            <h3>{{locale[lang].HOME.CARDS.TITLE}}</h3>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row car">
          <Carousel :perPageCustom="[
              [0, 1],
              [720, 1],
              [1200, 3],
            ]">
            <Slide v-for="(card, key) in locale[lang].HOME.CARDS.CARDS" :key="key">
              <div class="px-2">
                <Card :cardInfo="card" />
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Home/Card.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Card,
    Carousel,
    Slide,
  },
};
</script>

<style scoped>
.VueCarousel,
.VueCarousel-inner {
  width: 100%;
}

.VueCarousel .card {
  margin: auto;
}

.car >>> .VueCarousel-dot--active {
  background-color: #ff0313 !important;
}

.title {
  margin-bottom: 45px;
}

.title h3 {
  font: normal normal 800 30px/40px Montserrat;
  letter-spacing: 0px;
  color: #1c1c1c;
  opacity: 1;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  right: 0;
  left: 0;
  margin: auto;
  bottom: -15px;
  opacity: 1;
}
</style>
