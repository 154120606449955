<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg" id="navbar_top">
      <div class="container-fluid px-md-5 d-flex align-items-center justify-content-between">
        <router-link to="/" class="text-decoration-none">
          <img :src="require('@/assets/Images/' + locale[lang].NAV_IMG)" id="logo" alt="Soccer-logo"
            class="menu_item__text pl-md-2"/>
        </router-link>
        <div class="text-decoration-none">
          <button class="button-container btn-dark" :class="{ open: showMenu }" @click="open">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </button>
        </div>
      </div>
    </nav>
    <main-menu v-on:close-menu="close" :class="{ open: showMenu }"></main-menu>
  </div>
</template>

<script>
import MainMenu from "@/views/MainMenu.vue";
export default {
  components: {
    MainMenu,
  },
  computed: {
    showMenu() {
      return this.$store.state.showMenu;
    },
  },
  methods: {
    open() {
      if (this.showMenu) {
        this.$store.state.showMenu = false;
      } else {
        this.$store.state.showMenu = true;
      }
    },
    close() {
      this.$store.state.showMenu = false;
    },
  },
};
</script>

<style scoped>
#logo {
  width: 415px;
  margin-left: 60px;
  margin-top: 12px;
}

.btn-dark {
  background: transparent;
  height: 75px;
  width: 85px;
  opacity: 1;
  border: none;
  border-radius: 20px;
  position: fixed;
  z-index: 104;
  top: 20px;
  right: 40px;
}

.btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark:target {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.btn-dark span {
  background: #ff0313;
  border: none;
  height: 4px;
  width: 33px;
  position: absolute;
  top: 0;
  left: 26px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.btn-dark span.top {
  top: 25px;
}

.btn-dark.open span.top {
  -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
  transform: translateY(10px) translateX(0) rotate(45deg);
}

.btn-dark span.middle {
  top: 35px;
}

.btn-dark.open span.middle {
  opacity: 0;
}

.btn-dark span.bottom {
  top: 45px;
}

.btn-dark.open span.bottom {
  -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
  transform: translateY(-10px) translateX(0) rotate(-45deg);
}

.img-fluid {
  height: 20px;
  object-fit: cover;
}

#navbar_top.fixed-top #logo {
  display: none !important;
}

@media (max-width: 1024px) {
  #logo {
    width: 270px;
    margin-left: 0;
    margin-top: 0;
  }
}

@media (max-width: 425px) {
  #logo {
    width: 225px;
    margin-left: 0;
    margin-top: 0;
  }

  .btn-dark {
    width: 54px;
    height: 47px;
    border-radius: 10px;
    top: 1%;
  }

  .btn-dark span.top {
    top: 12px;
  }

  .btn-dark span.middle {
    top: 22px;
  }

  .btn-dark span.bottom {
    top: 32px;
  }

  .btn-dark span {
    left: 12px;
  }
}
</style>
