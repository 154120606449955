export default {
  NAV_IMG: 'logo 1-01.png',
  HOME: {
    INTRO: {
      TITLE: "Amateur soccer coaches",
      SUBTITLE: "We help you create more effective physical sessions",
      BUTTON: "Choose your offered program",
      WORDS: ["Adult", "U8 to U17", "Futsal", "Goalkeeper"],
      INITIAL: "Adult",
    },
    CARDS: {
      TITLE: "You are a soccer coach, what would you like to improve?",
      CARDS: [{
          IMG: "Grupo 1028.png",
          TITLE: "Improve physical training of seniors in soccer.",
          TEXT: `<p>
                   You will find information on physical preparation in football for seniors.
                       </p>
                       <p>
                       But also, examples of pre-season physical preparation and examples of physical
                       preparation during the winter break.
                       </p>`,
          BUTTON: "Summer break program",
          LINK: "TreveEstivale"
        },
        {
          IMG: "Grupo 1029.png",
          TITLE: "Improve the physical training of U8s to U17s in Football",
          TEXT: `<p>
                    You will find examples of exercises to develop the skills of coordination, speed, endurance, flexibility or even strength.
                  </p>
                  <p>
                  You will also find sample sessions and planning over 4 weeks for each category
                  from U8 to U17.
                  </p>`,
          BUTTON: "Young footballers guide",
          LINK: "GuideJeunes"
        },
        {
          IMG: "Grupo 1031.png",
          TITLE: "Improve your knowledge of the physical preparation of young players.",
          TEXT: `<p>
          You will find a lot of information on young football players’ development. Coaching
          young players is complex and knowing their characteristics (which change with age)
          seems essential to us. You will find general characteristics sorted by age group.
                       </p>
                       <p>
                       But also, explanatory modules on each physical quality, as well as on injury
                       prevention, testing and even mental preparation.
                       </p>`,
          BUTTON: "Young footballers guide",
          LINK: "GuideJeunes"
        },
      ],
    },
    ICONINFO: [{
        img: "Grupo 665.png",
        text: `<h3>Over 10000</h3>
        <p>Coaches and players trust us</p>`,
      },
      {
        img: "Icon awesome-users.png",
        text: `<h3>Over 40000</h3>
        <p>Active members</p>`,
      },
      {
        img: "Icon awesome-facebook-square.png",
        text: `<h3>Over 100000</h3>
        <p>Followers on Facebook and Instagram</p>`,
      },
      {
        img: "Grupo 1034.png",
        text: `<h3>1 million</h3>
        <p>Visitors to the site from 172 countries</p>`,
      },
    ],
    FORMATION: `You may help yourself and receive inspiration from the offered programs to create
    your own plans.`,
    INFOSEC2: {
      img: "Gif-B.gif",
      text: `
              <h4 class="first">
              If you want to go further, we also offer:
              </h4>
              <h4 class="subtitle">
              Online training courses on physical preparation in amateur football
              </h4>
              <p>
              We have taken into account all the needs and requests of many coaches and we have created a training course to be able to answer them and help you as much as possible.
              </p>
              <p>The goal is to give you the tools to physical preparation for football, tools that you
              can quickly use on the field. The training was created by physical trainers who
              specialize in football.
             </p>`,
      button: "CLICK HERE TO FIND OUT MORE",
    },
    INFOSEC3: {
      img: "gif-a (1).gif",
      title: "Online physical training sessions",
      text: `<p>
              We offer physical training programs for young people (u8 to u17) and seniors.
              </p>
              <p>
              They include an athletic part, a warm-up and cool-down, but not the technical and tactical part.
              </p>
              <p>
              We offer a progressive course over 4-week cycles or over a full season (10 months) with a different theme each month (aerobic power, strength, speed, explosiveness, ...) depending on the time of year.
             </p>`,
      button: "CLICK HERE TO FIND OUT MORE",
    },
    INFOSEC4: {
      img: "Gif-A.gif",
      title: "Guide with more than 800 online physical exercises",
      text: `<p>
              Discover online guides with many examples of physical exercises for youth, seniors, goalkeepers and futsal.
              </p>
              <p>
              The exercises are presented in video format and 3D diagrams.
              </p>
              <p>
              All the guides also contain a theoretical part in order to understand how to propose the exercises in terms of workload (exercise time, recovery time, exercise intensity, number of repetitions, number of sets ..).
             </p>`,
      button: "CLICK HERE TO FIND OUT MORE",
    },
    INFOSEC5: {
      img: "Grupo 133.png",
      title: 'Become a member and receive "Private Emails from Thibaud"',
      text: `<p>
              Discover free exclusive information each week to improve your physical training 
              sessions and your coaching (exercise ideas, advice on coaching, management and 
              mental preparation).
              </p>
              <p>
              More than 40,000 coaches receive our emails every week!
             </p>`,
      button: "CLICK HERE TO FIND OUT MORE",
    },
    INFOSEC5_MOBILE: {
      img: "Grupo 133.png",
      title: 'Become a member and receive "Private Emails from Thibaud"',
      text: `<p>
              Discover free exclusive information each week to improve your physical training 
              sessions and your coaching (exercise ideas, advice on coaching, management and 
              mental preparation).
              </p>`,
      button: "CLICK HERE TO FIND OUT MORE",
    },
    APPSEC: {
      img: "Home/gif-g (6).gif",
      title: `Discover 300 exercise ideas for free for football
      coaches with our mobile application!`,
      title_mobile: 'Discover 300 exercise ideas for free with our mobile application!',
      button: "CLICK HERE TO FIND OUT MORE",
    },
    OPINION_TITLE: `Coaches trust us: more than 10,000 coaches have already subscribed to one of our programs.`,
    OPINION_MOBILE_TITLE: "Coaches and players trust us ...",
  },
  MENU: {
    PAGE_1: [
      'Home Page',
      'Blog',
      `Youth coaches,<br>
      start here`,
      `Adult coaches,<br> start here`,
      `Exercises<br />
      ideas`,
      'Programs',
      'E-learning',
      'See more',
    ],
    PAGE_2: [
      'Shop',
      'Private emails ',
      `Member's<br />area`,
      'Contact',
      'FAQ',
    ],
    PRINCIPAL: 'Main menu',
    PROGRAMMES: [
      `Youth coaches <br> Soccer programs`,
      `Adult coaches <br> Soccer programs`,
    ],
    IDEES: {
      TITLE: "Exercises ideas",
      EXERCICES: [
        {
          id: 0,
          title: "800 exercises guide",
        },
        {
          id: 1,
          title: "200 small sided games ideas",
        },
        {
          id: 2,
          title: "300 physical exercises ideas - application ",
        },
        {
          id: 3,
          title: "200 physical exercises ideas for the goalkeeper ",
        },
        {
          id: 4,
          title: "Blog",
        },
        {
          id: 5,
          title: "200 physical exercises ideas for futsal ",
        },
        {
          id: 6,
          title: "The packages",
        },]
    },
    FORMATION: {
      TITLE: 'E-learning',
      ITEMS: [
        {
          id: 0,
          title:
            "Complete courses on physical preparation in amateur soccer",
        },
        {
          id: 1,
          title: "How to plan a season of physical training in amateur soccer",
        },
        {
          id: 2,
          title:
            "How to work and develop endurance and speed in amateur soccer",
        },
        {
          id: 3,
          title:
            "How to work and develop motor skills in young soccer players",
        },
        {
          id: 4,
          title: "Management guide",
        },
        {
          id: 5,
          title:
            "Guide to physical preparation for young soccer players",
        },
        {
          id: 6,
          title: "E-learning packages",
        },
      ],
    },
  },
  FORMATION: {
    INFOSEC1: {
      img: "Gif-B.gif",
      title:
        "Online training courses on physical preparation in amateur soccer",
    },
    INFOSEC2: {
      img: "Grupo 1006.png",
      title: "Join the wait list",
      text: `<p>You will receive all the information on the physical preparation training, have priority
      to register and benefit from discounts.</p>`,
      button: "Join the wait list",
    },
    INFOSEC3: {
      img: "Grupo 1111.png",
      title: "We provide a complete satisfaction guarantee!",
      text: `<p>If you are not satisfied with the training for any reason, we will give you a full refund!
      You just need to send us an email informing us of your decision.</p>`,
    },
    CARD: {
      TITLE: `"I would like to have a group of players in the best physical shape, without injuries,
      who progress and who implement my game ideas, who have fun during training and
      matches (and who make my relatives and spectators pleased)"
      (Ludovic, advanced R3 coach)`,
      TEXT: `We recently did a survey to find out the needs or the difficulties experienced by
      coaches. We received hundreds of responses. We have taken into account all the
      needs and requests of coaches and we hope that this new version of the 'football
      physical preparation' online training guide meets your expectations and wishes (like
      Ludovic’s mentioned above). We will reveal all the information to you 
      very soon (to those who are pre-registered).`,
    },
    IMG_CARDS: {
      CARDS: {
        CARD_1: `INTEGRATED<br />PHYSICAL<br />PREPARATION`,
        CARD_2: `TACTICAL<br />PERIODIZATION`
      },
      CONTENT: {
        TITLE: `You will find for example:`,
        TEXT: `New methods and approaches to training and football, innovative sessions (in which
          players will enjoy taking part) and ideas possibly more in line with your methods and
          wishes.`,
      }
    },
    SAISON: {
      TEXT: `Plans over a full season (10 months of physical training with examples of exercises)
      for each category from u8 to adults (also goalkeeper training and futsal) to give you a
      guiding thread and examples on which you can rely to help you do the physical work
      well and make you achieve your goals.`,
      IMG: {
        TITLE: `A FULL SEASON`,
        SUB_1: `Futsal goalkeepers`,
        SUB_2: `U8 to U17 Adults`,
      }
    },
    VIDEO_SUB: `New fun and effective exercises (that players love)`,
    EXEMPLES: {
      TITLE: `You will find for example:`,
      CARDS: [
        {
          link:
            "https://www.preparationphysiquefootball.com/images/exercices-inedits.png",
          text: `      
          <h5 class="card-title">
            New exercises
          </h5>`,
        },
        {
          link:
            "https://www.preparationphysiquefootball.com/images/formation-fabien-richard.png",
          text: `
          <h5 class="card-title">
            A great bonus (for one of the participants) with the registration offered to a 3-day training course (live) offered by the physical trainer of R.Marhez, S.Mané, F. Thauvin, M.pjanic ... (more info about <a href="https://www.fabienrichardconsulting.com/individuel" target="_blank">this training HERE)</a>.
          </h5>`,
        },
      ],
      ATTENTION: {
        TITLE: `The rest will be revealed to you very soon.`,
        CONTENT: [
          `Attention, In order to be able to offer a personalized and high-quality follow-up, we
          must limit the number of registrations for the online training (hundreds of coaches
          obtained the first version and we receive many questions every day. To provide a
          quality follow-up , we must therefore limit the number of registrations).`,
          `If this training
          interests you, we recommend that you join the waiting list (you will receive all the
          information on the physical preparation training, have priority to register and benefit
          from discounts).`,
        ]
      }
    },
    QUESTIONS: {
      TITLE: `Frequently asked Questions`,
      ITEMS: [
        {
          id: 71,
          question:
            "Do we need to have knowledge in the field of physical preparation to do the E-Training?",
          description: `
            <p>
             No, it is accessible to everyone. We have tried to make it as understandable as possible.
            </p>`,
        },
        {
          id: 72,
          question:
            "I am an expert in physical preparation, is the E-Training suitable for my level?",
          description: `
            <p>
              We have neither the pretension nor the legitimacy to propose training for "experts"! 
              We therefore propose a training course rather adapted to those who are just starting to learn about 
              physical preparation!
            </p>`,
        },
        {
          id: 73,
          question: "Is it a 100% theoretical training?",
          description: `
          <p>
            No, the objective of this training is to give you the keys to physical preparation in football but above all to give you 
            but above all to give you keys that you can use quickly on the field!
          </p>
          <p>
            A theoretical-knowledge part is unavoidable but the practical part will be consistent!
          </p>
          <p>
            You will have access to many videos of exercises!
          </p>`,
        },
        {
          id: 74,
          question: "What happens after ordering?",
          description: `
          <p>
            1 to 2 minutes after ordering you will receive a confirmation email with 
            the access link to the online training. You must register (name, first name, ...) 
            and you have direct access to the training. That is to say a few minutes after your order.
          </p>`,
        },
        {
          id: 75,
          question: "I do not understand a point in the training?",
          description: `
          <p>
            Share your questions on the training forum, many people will certainly know how to answer you. 
            many people will certainly know how to answer you. It is normal 
            to experience difficulties when learning!
          </p>`,
        },
        {
          id: 76,
          question: "I do not agree on a point in the training?",
          description: `
          <p>
            We are here to exchange, let us know your disagreements! 
            That's what dynamic training is all about!
          </p>`,
        },
        {
          id: 77,
          question: "On which media is the training accessible?",
          description: `
          <p>
            Our training is accessible on all types of media (phone, tablet, computer).
          </p>`,
        },
        {
          id: 78,
          question: "How long do I have access to the training?",
          description: `
          <p>
            Once you have ordered, you have access to the training platform for 12
            months... which should give you plenty of time to get the hang of it! We
            renew for a year (or more) for free if you need to.
          </p>`,
        },
        {
          id: 79,
          question: "Is the payment secure?",
          description: `
          <p>
            We use very secure payment methods like Paypal, Apple Pay, and
            Stripe.
            Our goal is to provide you with easy access to all our online
            programs via this platform and to provide you with simple, fast, and
            highly secure payment methods.
          </p>`,
        },
        {
          id: 80,
          question: "If I don't like the program, can I get a refund?",
          description: `
          <p>
            Of course, you can!
            Either you are satisfied with what you ordered,
            and your investment pays off, or you are not; we immediately refund
            you, and you get your money back.
            So there is no risk!
          </p>`,
        },
        {
          id: 81,
          question: "Do you have any other questions or queries?",
          description: `
          <p>
            No problem, write us at : fitnesssoccertraining100@gmail.com and we will answer you promptly.
          </p>`,
        },
        {
          id: 82,
          question: "You have a total satisfaction guarantee!",
          description: `
          <p>
            If you are not satisfied with the training, for whatever reason, we will give you a full 
            we will give you a full refund! Just send us an email 
            (fitnesssoccertraining100@gmail.com) informing us of your decision.
          </p>`,
        },
      ],
    },
    MODULOS: {
      TITLE: `Content of your online course`,
      ITEMS: [
        {
          name: "Modules",
          count: "1-8",
          items: [
            {
              id: 1,
              title: "Module 1: Analyzing Modern Soccer.",
              description: `<p>
                You will discover:
                </p>
                <p>How to analyze match activities in soccer.</p>
                <p>What are the typical activities of the soccer player (they are not always what we think).</p>
                <p>How does this influence physical preparation?</p>
                <p>The different conceptions of physical preparation (the one that inspired, the new trends, ...)</p>`,
            },
            {
              id: 2,
              title:
                "Module 2: Various training schedules",
              description: `<p>
                  You will discover:
                </p>
                <p>
                  The particularities of soccer planning (quite different from the ''classic'' planning found in other sports).
                </p>
                <p>
                  How to evaluate training load in amateur soccer and know if your sessions are adapted (and know the state of form of the players).
                </p>
                <p>
                  Concrete planning samples for the entire amateur season and all the key periods of a season.
                </p>
                <p>
                  The best tracking software (free) in our opinion.
                </p>`,
            },
            {
              id: 3,
              title: "Module 3: Sport Physiology",
              description: `<p>
              Physiology provides an understanding of the effects of workout on the body and shows how the body adapts. We will cover the basic notions you need to know.
              </p>`,
            },
            {
              id: 4,
              title: "Module 4: Evaluation of the soccer player",
              description: `<p>
                You will discover for example:
                </p>
                <p>Why do tests. The best test for the different physical qualities.</p>
                <p>An application that allows you to do a simple test (and for less than 10 euros), and very useful (the most useful according to us for amateurs).</p>
                <p>Limitations of the tests.</p>`
            },
            {
              id: 5,
              title: "Module 5: Motor skills of the modern player",
              description: `<p>
                  You will discover for example:
                </p>
                <p>What motricity is, how to develop it in soccer.</p>
                <p>A new and very interesting concept to help you find the perfect position for your players, and also "to optimize their performance and avoid injuries”.</p>`,
            },
            {
              id: 6,
              title: "Module 6: Endurance",
              description: `<p>
                You will discover for example:
                </p>
                <p>The key concepts of endurance</p>
                <p>Methods of developing endurance in soccer</p>
                <p>Which method to use depending on the time of year</p>
                <p>How to hone your endurance over the year</p>
                <p>How to test it</p>
                <p>How to use it with young players</p>
                <p>How to work on it with a ball You will have access to many sample exercises</p>`,
            },
            {
              id: 7,
              title: "Module 7: Speed",
              description: `<p>
                You will discover for example:
                </p>
                <p>What is speed in soccer</p>
                <p>Methods for developing speed</p>
                <p>When to work on it during the year, during the week, during the session</p>
                <p>How to schedule it over the year</p>
                <p>How to test it</p>
                <p>Can it be worked on with a ball?</p>
                <p>You will have access to many sample exercises</p>`,
            },
            {
              id: 8,
              title: "Module 8: Strength",
              description: `<p>
                You will discover for example:
                </p>
                <p>Methods of developing strength</p>
                <p>When to work on it during the year, during the week, during the session</p>
                <p>How to schedule it over the year</p>
                <p>How to test it</p>
                <p>Can it be worked on with a ball?</p>
                <p>You will have access to many sample exercises</p>`,
            },
          ],
        },
        {
          name: "Modules",
          count: "9-16",
          items: [
            {
              id: 9,
              title: "Module 9: Coordination",
              description: `
              <p>
                You will discover for example:
              </p>
              <p>Methods of developing strength</p>
              <p>When to work on it during the year, during the week, during the session</p>
              <p>How to plan it over the year</p>
              <p>How to test it</p>
              <p>Can it be worked on with a with a ball?</p>
              <p>You will have access to many sample exercises</p>`,
            },
            {
              id: 10,
              title: "Module 10: Flexibility",
              description: `
              <p>
                You will discover for example:
              </p>
              <p>Is flexibility important in soccer?</p>
              <p>Should you stretch before a game, after a game?</p>
              <p>Is there a single method of stretching?</p>
              <p>How to improve your flexibility?</p>
              <p>At what age should I start stretching?</p>
              <p>How do you actually do it?</p>`,
            },
            {
              id: 11,
              title: "Module 11: Soccer warm-up",
              description: `
              <p>
              You will learn about the objectives and challenges of warm-up, where to start a warm-up (and where not to start), you will have examples of typical  warm-ups. You will also see the exercises to do in a warm-up to reduce injury rates by 50% (according to UEFA).
              </p>`,
            },
            {
              id: 12,
              title: "Module 12: Recovery",
              description: `
              <p>
                You will learn about all the means of recovery for the soccer player, the 3 pillars of recovery and a recovery protocol.
              </p>`,
            },
            {
              id: 13,
              title: "Module 13 : Session design",
              description: `
              <p>
              You will learn how to design your sessions according to different parameters (your philosophy, your objectives, the time, the instructions...) and how to lead a session.
              </p>`,
            },
            {
              id: 14,
              title: "Module 14: Young Players",
              description: `
              <p>
              You will see the differences between older and younger players, the notions you need to know to train younger players, how to work on the body for each age category, and we will answer many questions about physical work with younger players (can they do strength, power workout, flexibility workout, at what age should they work on speed, ...)
              </p>`,
            },
            {
              id: 15,
              title: "Module 15: Mental training",
              description: `
              <p>
              Mental training in soccer is not yet widely used. And yet its importance cannot be overemphasized! You will learn, for example, how to boost your self-confidence, how to manage your emotions, how to set goals, how to improve group cohesion...
              </p>`,
            },
            {
              id: 16,
              title: "Module 16: Injury prevention",
              description: `
              <p>
              Why do we get injured? How to strengthen hamstrings, quadriceps, adductors, calves, ... which strengthening methods to use for ankles and knees? Here you will find our answers and solutions. You will see which exercises to perform to reduce the injury rate by 50% (according to UEFA).
              </p>`,
            },
          ],
        },
        {
          name: "Modules",
          count: "17-24",
          items: [
            {
              id: 17,
              title: "Module 17: Equipment",
              description: `
              <p>
                How to use the various tools for your training in amateur soccer, what are their advantages and disadvantages.
              </p>`,
            },
            {
              id: 18,
              title: "Module 18: Nutrition of the soccer player",
              description: `
              <p>
              WWhy nutrition is so important in soccer, what are the basic rules, what to eat before a match, after a match, how to change eating habits, how to make your players lose weight?
              </p>`,
            },
            {
              id: 19,
              title: "Module 20: Physical training programs",
              description: `
              <p>
              You will have access, for each category (from U8 to seniors), to an example of physical work schedule over the year (with an average of 2 physical training sessions per week, i.e., more than 80 sessions for each category). You will also have access to 40 physical sessions for futsal and goalkeepers.
              </p>`,
            },
            {
              id: 20,
              title: "Module 20: Physical training programs",
              description: `
              <p>
              You will have access, for each category (from U8 to seniors), to an example of physical work schedule over the year (with an average of 2 physical training sessions per week, i.e., more than 80 sessions for each category). You will also have access to 40 physical sessions for futsal and goalkeepers.
              </p>`,
            },
            {
              id: 21,
              title: "Module 21: Integrated physical preparation",
              description: `
              <p>
              We will see if it is relevant to integrate physical workout in soccer. If so, can we work on all physical qualities with the ball? For each physical quality, we will propose the advantages and disadvantages of working with the ball. We will provide many samples of exercises.
              </p>`,
            },
            {
              id: 22,
              title: "Module 22: Tactical periodization",
              description: `
              <p>
                We will present this training method which is becoming more and more used.
              </p>
              <p>You will discover its philosophy, how to implement it, its advantages, its disadvantages, examples of exercises, how to design your own exercises, ...</p>`,
            },
            {
              id: 23,
              title: "Module 23: Bonus 1",
              description: `
              <p>
              Here you will have advice on coaching, management, physiology, biomechanics, ... as well as information on reathletization, on futsal, ...
              </p>`,
            },
            {
              id: 24,
              title: "Module 24: Bonus 2",
              description: `
              <p>
              In this last module, we give our bibliography, the books and the trainings we recommend, ... we propose you quizzes related to the previous modules.
              </p>`,
            },
          ],
        },
      ],
    },
    ICONINFO: [
      {
        img: "soccer.png",
        text: `<h3>SUITABLE</h3>
      <p>for amateurs</p>`,
      },
      {
        img: "uniform.png",
        text: `<h3>PRACTICAL</h3>
      <p>With many video examples and examples of programs</p>`,
      },
      {
        img: "ball.png",
        text: `<h3>DYNAMIC</h3>
      <p>Thanks to the forum and interactions with other coaches and trainers</p>`,
      },
      {
        img: "field.png",
        text: `<h3>REFLEXIVE</h3>
      <p>In order to better integrate and understand physical preparation</p>`,
      },
    ],
    OPINION_TITLE: `Coaches trust us: more than 10,000 coaches have already joined one of our
    programs.`,
    OPINION_TITLE_MOBILE: `Coaches and players trust us ...`,
    CONFIANCE: {
      TITLE: `WHY SHOULD YOU TRUST US TOO?`,
      CONTENT: [
        `We have developed our approach by integrating the best works of every great
        physical trainer and coaches that we have had the chance to work with, but also the
        best of our own readings, university educations and private trainings.`,
        `The creation of this E-Training is therefore based on this approach that we have tried
        to adapt as best as possible to your needs ...`,
        `A training designed by qualified physical trainers specialized in Football.`,
      ],
    },
    END: {
      TITLE: `Join the wait list`,
      TEXT: `You will receive all the information on the physical preparation training, have priority
      to register and benefit from discounts.`,
      BUTTON: `Join the wait list`,
    }
  },
  COACH_JEUNES: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
      If you land on this site, you may ask yourself: Why this blog about physical
      preparation in amateur football and how can it help me ?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      A few years ago, during training with various physical trainers and amateur coaches,
      everyone realized: There is a lack of information on physical preparation in amateur football.
             </p>
             <p>
             The idea of creating a blog to share and exchange knowledge about physical preparation was launched! 
             </p>
             <p>
             Quickly hundreds of coaches shared their gratitude and their needs (and asked whether it was possible to help them).
             </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      A few years ago, during training with various physical trainers and amateur coaches,
      everyone realized:
     </p>
     <p>There is a lack of information on physical preparation in amateur football.</p>
     <p>The idea of creating a blog to share and exchange knowledge about physical preparation was launched!</p>
     <p>
     Quickly hundreds of coaches shared their gratitude and their needs (and asked whether it was possible to help them).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "To do this, I have implemented several ways:",
      text: `<p>
              For example, you have access to a blog with completely free information on
              many topics related to physical preparation in football, but also on management,
              mental preparation or nutrition.
              </p>
              <p>
              There are a lot of articles to read, and it is not necessarily easy to navigate! 
              This is why we have created different categories in the blog.
             </p>`,
      button: "Go to the blog",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "To do this, I have implemented several ways:",
      text: `<p>
        For example, you have access to a blog with completely free information on
        many topics related to physical preparation in football, but also on management,
        mental preparation or nutrition.
        </p>
        <p>
        There are a lot of articles to read, and it is not necessarily easy to navigate! 
        This is why we have created different categories in the blog.
        </p>`,
      button: "Go to the blog",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `You can find 300 examples of physical exercises on our MOBILE APP link to the presentation page`,
      button: "CLICK HERE TO FIND OUT MORE",
      button_mobile: "More information",
    },
    LIST_1: {
      TITLE: `Here is a quick overview of the needs and requests that come up often:`,
      ITEMSL: [
        "I would like ideas for physical exercises to vary my trainings (to reduce monotony and motivate my players)",
        "I would like to know which physical skill to work on first with my u12s",
        "I would like to know how to develop endurance, strength and speed in my u15 players",
        "I would like to know how to plan the physical work in the season (to avoid mistakes and allow my u14 players to improve over the season)",
      ],
      ITEMSR: [
        "I would like exercise ideas suitable for my u10 group.",
        "I would like to know how to work on coordination with my u8",
        "I would like to know how to stretch with my young u13s (and be able to advise my players and offer them efficient training)",
        "I would like to have information on different methods of physical preparation in amateur football such as integrated physical preparation or tactical periodization (and to know if these methods are relevant to young players)",
      ],
      END: `We have received hundreds of requests like these!`
    },
    ROTATE: {
      TITLE: "We have received hundreds of requests like these!",
      ICONINFO: [
        `<h3>+ de 10000</h3>
        <p>Currently, more than 10,000 coaches have joined one of our guides,
        programs or training!</p>`,
        `<h3>+ de 40000</h3>
        <p>Over 40,000 read the daily advice newsletter.</p>`,
        `<h3>+ de 100000</h3>
        <p>Over 100,000 follow our posts on Facebook and Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>+ de 10000</h3>
        <p>Coaches and players trust us</p>`,
        `<h3>+ de 40000</h3>
        <p>Over 40,000 read the daily advice newsletter.</p>`,
        `<h3>+ de 100000</h3>
        <p>Active members</p>`,
      ],
      END: `To summarize briefly, I want to help you and try to meet everyone’s needs.`,
    },
    CARDS: [{
        img: "Grupo 1022.png",
        text: "You also have access to a small information guide on young people",
        buttons: [{
          text: "Youth guide",
          link: 'GuideJeunes',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "You will find examples of exercises on our Facebook and Instagram pages",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/Soccer-Fitness-Training-for-coaches-102255048793855/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/soccer_training_for_coaches/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `If you want to go further, you also have the option to access guides, programs and
      online training:`,
      CHECKSL: [{
          text: "A guide with more than 800 physical exercises",
          next: "Exercices",
        },
        {
          text: "Programs over 4-week cycles",
          next: "special-vitesse",
        },
      ],
      CHECKSR: [{
          text: "A Management guide",
          next: "GuideManagement",
        },
        {
          text: "Programs for a complete season",
          next: "SaisonJeunes",
        },
      ],
      CHECKM: {
        text: "Training with more than 20 modules on everything related to performance optimization",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'We hope to meet all your needs and help you:',
      ITEMSA: [
        `To be independent when implementing your physical preparation exercises and no
        longer have to ask for advice all the time (become the person who is asked for
        advice instead;))`,
        `To feel competent in the field of physical preparation and to see your work
        recognized by your players, other coaches and your relatives.`,
        `Or to make it easier for you to discuss physical preparation with other players and
        other coaches!`,
      ],
    }
  },
  COACH_SENIOR: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
      If you land on this site, you may ask yourself: Why this blog about physical
      preparation in amateur football and how can it help me ?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      A few years ago, during training with various physical trainers and amateur coaches,
      everyone realized: There is a lack of information on physical preparation in amateur football.
             </p>
             <p>
             The idea of creating a blog to share and exchange knowledge about physical preparation was launched! <br> Quickly hundreds of coaches shared their gratitude and their needs (and asked whether it was possible to help them).
             </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      A few years ago, during training with various physical trainers and amateur coaches,
      everyone realized:
     </p>
     <p>There is a lack of information on physical preparation in amateur football.</p>
     <p>The idea of creating a blog to share and exchange knowledge about physical preparation was launched!</p>
     <p>
     Quickly hundreds of coaches shared their gratitude and their needs (and asked whether it was possible to help them).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "To do this, I have implemented several ways:",
      text: `<p>
        For example, you have access to a blog with completely free information on
        many topics related to physical preparation in football, but also on management,
        mental preparation or nutrition</p>
        <p>
        There are a lot of articles to read, and it is not necessarily easy to navigate! 
        This is why we have created different categories in the blog.
        </p>`,
      button: "Go to the blog",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "To do this, I have implemented several ways:",
      text: `<p>
          For example, you have access to a blog with completely free information on
          many topics related to physical preparation in football, but also on management,
          mental preparation or nutrition.
        </p>
        <p>
        There are a lot of articles to read, and it is not necessarily easy to navigate! 
        This is why we have created different categories in the blog.
        </p>`,
      button: "Go to the blog",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `You can find 300 examples of physical exercises on our MOBILE APP link to the presentation page`,
      button: "CLICK HERE TO FIND OUT MORE",
      button_mobile: "More information",
    },
    LIST_1: {
      TITLE: `Here is a quick overview of the needs and requests that come up often:`,
      ITEMSL: [
        "I would like ideas for physical exercises to vary my trainings (to reduce monotony and motivate my players)",
        "I would like to know how to develop the endurance, strength and speed in my players",
        "I would like to know how to use stretching (and thus be able to advise my players and offer them effective work)",
        "I would like to have information on different methods of physical preparation in amateur football such as integrated physical preparation or tactical periodization for example",
      ],
      ITEMSR: [
        "I would like exercise ideas suitable for my senior group.",
        "I would like to know how to optimize player recovery (and thus be able to respond to them when they ask me for advice)",
        "I would like to know how to plan the physical work in the season (to avoid mistakes and allow my players to improve over the season)",
      ],
      END: `We have received hundreds of requests like these!`
    },
    ROTATE: {
      TITLE: "We have received hundreds of requests like these!",
      ICONINFO: [
        `<h3>+ de 10000</h3>
        <p>Currently, more than 10,000 coaches have joined one of our guides,
        programs or training!</p>`,
        `<h3>+ de 40000</h3>
        <p>Over 40,000 read the daily advice newsletter.</p>`,
        `<h3>+ de 100000</h3>
        <p>Over 100,000 follow our posts on Facebook and Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>+ de 10000</h3>
        <p>Coaches and players trust us</p>`,
        `<h3>+ de 40000</h3>
        <p>Over 40,000 read the daily advice newsletter.</p>`,
        `<h3>+ de 100000</h3>
        <p>Active members</p>`,
      ],
      END: `To summarize briefly, I want to help you and try to meet everyone’s needs.`,
    },
    CARDS: [{
        img: "player.svg",
        text: "You also have access to a sample program for pre-season physical work",
        buttons: [{
          text: "Pre-season training",
          link: 'TreveEstivale',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "You will find examples of exercises on our Facebook and Instagram pages",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/preparationphysiquefootball/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/preparationphysiquefootball/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `If you want to go further, you also have the option to access guides, programs and
      online training:`,
      CHECKSL: [{
          text: "A guide with more than 800 physical exercises",
          next: "Exercices",
        },
        {
          text: "Programs over 4-week cycles",
          next: "special-vitesse",
        },
      ],
      CHECKSR: [{
          text: "A Management guide",
          next: "GuideManagement",
        },
        {
          text: "Programs for a complete season",
          next: "SaisonSenior",
        },
      ],
      CHECKM: {
        text: "Training with more than 20 modules on everything related to performance optimization",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'We hope to meet all your needs and help you:',
      ITEMSA: [
        `To be independent when implementing your physical preparation exercises and no
        longer have to ask for advice all the time (become the person who is asked for
        advice instead;))`,
        `To feel competent in the field of physical preparation and to see your work
        recognized by your players, other coaches and your relatives.`,
        `Or to make it easier for you to discuss physical preparation with other players and
        other coaches!`,
      ],
    }
  },
  SAISON_SENIOR: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "Your club doesn’t have a fitness trainer?",
      text: `<p>
            Planning the physical training and creating effective sessions is quite complex.
           </p>
           <p>
            Many coaches have asked us for a little "help" in the creation of work cycles and
           physical sessions.
           </p>`,
      button: "See SENIOR sessions",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "Mais aussi les séances sont disponibles sur mobile, tablette et ordinateur.",
      text: `<p>
            Programmation progressive avec thème différent chaque mois (cycle de 4 semaines) pendant 10 mois. Les séances sont avec et sans ballon. <br> 1 séance par semaine pour les catégories FUTSAL et GARDIENS et 2 ou 3 séances par semaine pour les séniors (au choix).
           </p>
           <p>
            Tout est adapté au football amateur et vous avez le choix de modifier ou non les exercices proposés en fonction de votre groupe.
           </p>
           <p>
            Les thèmes sont variés et programmés en fonction de la période de l’année ("puissance aérobie", "force", "vitesse", "explosivité", ...) Les séances comprennent la partie athlétique, l’échauffement et le retour au calme mais pas la partie technico tactique.
           </p>`,
      button: "Voir les seánces JEUNES",
    },
    ROTATE: {
      TITLE: `Physical training sessions over a full season`,
      SUBTITLE: `We wanted to respond to this need and we now offer fitness training sessions (4
        week cycle) over a full season (10 months)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>You receive 10 months’ worth of physical preparation sessions!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>You save precious time! and you learn at the same time.</p>`,
        },
        {
          img: "mail.png",
          text: `<p>You can chat on the forum with other coaches and physical trainers</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>You can adjust your sessions and receive inspiration for them ...</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `After ordering, you will receive an email directly with an access link to the online
      platform and your sessions.`,
      TITLE: `SESSIONS of physical training over a full season`,
      TITLE_MOBILE: `SESSIONS OVER A SEASON`,
      BUTTON: 'All the information here (Youth and advanced sessions)',
      CARDS1: [{
          img: "Senior2.png",
          category: "2 sessions per week",
          body: {
            sessions: "8 sessions / month",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
        {
          img: "Senior3.png",
          category: "3 sessions per week",
          body: {
            sessions: "12 sessions / month",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        }
      ],
      CARDS2: [{
          img: "Senior4.png",
          category: "Goalkeeper",
          body: {
            sessions: "4 sessions / month",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
        {
          img: "Senior1.png",
          category: "Futsal",
          body: {
            sessions: "4 sessions / month",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        }
      ],
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of physical preparation exercises",
      SUBTITLE: `Here are some examples of physical exercises that you will find in the physical
      training sessions`,
      CARDS: [{
          id: 0,
          img: "Imagen 90.png",
          title: 'INTERMITTENT 30-30 AT 100% OF THE "INTERMITTENT" MAXIMAL AEROBIC SPEED (MAS) OF THE PLAYER (WITH PASS)',
          sub: "Example of endurance exercise",
          text: `
        <p>Footwork + at the end of the race the player must make a pass (the objective:
          precision and diligent pass despite fatigue and running speed).</p>
        <p>2 groups: when one group runs, the second receives and passes to the players who
        arrive at the end of the race.</p>`,
          hide: ` <p>You can vary the pass (on the ground, in the air, chest-pass, head, ...) Each distance is individualized according to the intermittent MAS of your players (MAS calculated by an intermittent test type 30-15 or 45-15 for example). 2 sets of 10 repetitions (10 races of 30 seconds per set) depending on the level and shape of your players. 3 minutes of recovery between the 2 sets. Passive recovery (walking) between runs.</p>`,
          open: false,
        },
        {
          id: 1,
          img: "Imagen 92.png",
          title: '"POWERFUL FOOTWORK + REACTION WORK"',
          sub: "Example of endurance exercise",
          text: `<p>The players perform a power activity (jump with hurdles, plyometrics) then once
          arrived at the yellow cones, one of the two players (here the player in blue on the
          diagram) chooses the direction of the sprint (to the right or the left).</p>`,
          hide: `<p>The other player must manage to touch it before it crosses the finish line (the 2 orange studs). You can change the work of strength by a work of coordination or support, or a work of frequency, or simply an acceleration with braking ... 5 or 6 passes for each player (recovery from 45 seconds to 1 minute)</p>`,
          open: false,
        },
        {
          id: 2,
          img: "Imagen 91.png",
          title: "INTERMITTENT POWER",
          sub: "Example of endurance exercise",
          text: `<p>15 seconds of plyometric-type effort (jumps) - 15 seconds of recovery</p>
        <p>15 seconds of running at maximal aerobic speed (MAS) - 15 seconds of recovery</p>`,
          hide: `<p>15 seconds of plyometric type effort (jumps) - 15 seconds of recovery 15 seconds of running at VMA - 15 seconds of recovery ... etc for 7 to 10 minutes (depending on the level and shape of the players) 2 series 1 . back and forth jumps above benches 2. supports in the rhythm scale 3. jumps with straight legs 4. leaps with bent legs 5. staggered supports</p>`,
          open: false,
        },
      ],
    },
    CHECK: {
      TITLE: 'Each session includes:',
      CONTENT: `A suggested warm-up with 2 to 4 exercises <br />
      The main session with 3 to 4 exercises working on a physical skill <br />
      The cool-down.`,
      CONTENT_MOBILE: [
        `A suggested warm-up with 2 to 4 exercises`,
        `The main session with 3 to 4 exercises working on a physical skill`,
        `The cool-down.`,
      ]
    },
    OPINION_TITLE: 'Coaches and players trust us ...',
    END: {
      TEXT: `Are you interested in a program?`,
      BUTTON: 'All information here',
    }
  },
  SAISON_JEUNES: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "Your club doesn’t have a fitness trainer?",
      text: `<p>
            Planning the physical training and creating effective sessions is quite complex.
           </p>
           <p>
            Many coaches have asked us for a little "help" in the creation of work cycles and
           physical sessions.
           </p>`,
      button: "See YOUTH sessions",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "The sessions are also available on mobile, tablet and computer.",
      text: `<p>
            A progressive program with a different theme each month (4 week cycle) for 10
            months. The sessions are with and without using balls.
            1 session per week for the FUTSAL and GOALKEEPER categories and 2 or 3
            sessions per week for advanced (your choice).
           </p>
           <p>
           Everything is suitable for amateur football and you have the choice to modify or not
           the proposed exercises according to your group’s level and abilities.
           </p>
           <p>
           The themes are varied and scheduled according to the time of year ("aerobic power",
           "strength", "speed", "explosiveness", ...)
           </p>
           <p>
           The sessions include the athletic part, the
           warm-up and the cool-down but not the technical or tactical part.
           </p>`,
      button: "See YOUTH sessions",
    },
    ROTATE: {
      TITLE: `Physical training sessions over a full season`,
      SUBTITLE: `We wanted to respond to this need and we now offer fitness training sessions (4
        week cycle) over a full season (10 months)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>You receive 10 months’ worth of physical preparation sessions!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>You save precious time! and you learn at the same time.</p>`,
        },
        {
          img: "mail.png",
          text: `<p>You can chat on the forum with other coaches and physical trainers</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>You can adjust your sessions and receive inspiration for them ...</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `After ordering, you will receive an email directly with an access link to the online
      platform and your sessions.`,
      TITLE: `SESSIONS of physical training over a full season`,
      TITLE_MOBILE: `SESSIONS OVER A SEASON`,
      BUTTON: 'All the information here (Youth and advanced sessions)',
      CARDS1: [{
          img: "Grupo 1051.png",
          category: "U8 - U9",
          body: {
            sessions: "2 sessions per week",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
        {
          img: "Grupo 157.png",
          category: "U10 - U11",
          body: {
            sessions: "2 sessions per week",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
        {
          img: "Grupo 1053.png",
          category: "U12 - U13",
          body: {
            sessions: "2 sessions per week",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
      ],
      CARDS2: [{
          img: "Grupo 1055.png",
          category: "U14 - U15",
          body: {
            sessions: "2 sessions per week",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
        {
          img: "Grupo 1057.png",
          category: "U16 - U17",
          body: {
            sessions: "2 sessions per week",
            interval: "Your sessions over the course of 10 months (from August to May)",
          },
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of physical preparation exercises",
      SUBTITLE: `Here are some examples of physical exercises that you will find in the physical
      training sessions`,
      CARDS: [{
          id: 0,
          img: "Imagen 90.png",
          title: 'INTERMITTENT 30-30 AT 100% OF THE "INTERMITTENT" MAXIMAL AEROBIC SPEED (MAS) OF THE PLAYER (WITH PASS)',
          sub: "Example of endurance exercise",
          text: `
        <p>Footwork + at the end of the race the player must make a pass (the objective:
          precision and diligent pass despite fatigue and running speed).</p>
        <p>2 groups: when one group runs, the second receives and passes to the players who
        arrive at the end of the race.</p>`,
          hide: ` <p>You can vary the pass (on the ground, in the air, chest-pass, head, ...) Each distance is individualized according to the intermittent MAS of your players (MAS calculated by an intermittent test type 30-15 or 45-15 for example). 2 sets of 10 repetitions (10 races of 30 seconds per set) depending on the level and shape of your players. 3 minutes of recovery between the 2 sets. Passive recovery (walking) between runs.</p>`,
          open: false,
        },
        {
          id: 1,
          img: "Imagen 92.png",
          title: '"POWERFUL FOOTWORK + REACTION WORK"',
          sub: "Example of endurance exercise",
          text: `<p>The players perform a power activity (jump with hurdles, plyometrics) then once
          arrived at the yellow cones, one of the two players (here the player in blue on the
          diagram) chooses the direction of the sprint (to the right or the left).</p>`,
          hide: `<p>The other player must manage to touch it before it crosses the finish line (the 2 orange studs). You can change the work of strength by a work of coordination or support, or a work of frequency, or simply an acceleration with braking ... 5 or 6 passes for each player (recovery from 45 seconds to 1 minute)</p>`,
          open: false,
        },
        {
          id: 2,
          img: "Imagen 91.png",
          title: "INTERMITTENT POWER",
          sub: "Example of endurance exercise",
          text: `<p>15 seconds of plyometric-type effort (jumps) - 15 seconds of recovery</p>
        <p>15 seconds of running at maximal aerobic speed (MAS) - 15 seconds of recovery</p>`,
          hide: `<p>15 seconds of plyometric type effort (jumps) - 15 seconds of recovery 15 seconds of running at VMA - 15 seconds of recovery ... etc for 7 to 10 minutes (depending on the level and shape of the players) 2 series 1 . back and forth jumps above benches 2. supports in the rhythm scale 3. jumps with straight legs 4. leaps with bent legs 5. staggered supports</p>`,
          open: false,
        },
      ],
    },
    CHECK: {
      TITLE: 'Each session includes:',
      CONTENT: `A suggested warm-up with 2 to 4 exercises <br />
      The main session with 3 to 4 exercises working on a physical skill <br />
      The cool-down.`,
      CONTENT_MOBILE: [
        `A suggested warm-up with 2 to 4 exercises`,
        `The main session with 3 to 4 exercises working on a physical skill`,
        `The cool-down.`,
      ]
    },
    OPINION_TITLE: 'Coaches and players trust us ...',
    END: {
      TEXT: `Are you interested in a program?`,
      BUTTON: 'All information here',
    }
  },
  EXERCICES: {
    QUESTIONS: {
      TITLE: '800 EXERCISES GUIDE',
      SUBTITLE: '3D DIAGRAMS AND VIDEOS',
      ITEMS: [
        "Are you sometimes lacking ideas to bring something new to your training (group or individual)?",
        "Would you like to save time in creating your sessions (and not search the internet for hours in search of new exercises)?",
        "Would you like to offer warm-ups and physical exercises that are more fun and more appreciated by your players?",
        "Would you like to see more motivation and progress in your players when they do training exercises?",
      ],
    },
    LE_GUIDE: {
      TITLE: 'The online guide is available on mobile, tablet and desktop.',
      CONTENT: {
        TEXT_1_1: `We offer an online “guide” with more than 800 exercises in video format and 3D
        diagrams.`,
        TEXT_1_2: `You have an “explanations” part for each physical skill and an “exercises” part.
        These exercises are without equipment (with and without ball) or with some
        equipment (which all clubs have, with and without ball).`,
        TEXT_2_1: `This guide is intended for coaches and players looking for exercise ideas for their
        physical training sessions,`,
        TEXT_2_2: `more than 700 players`,
        TEXT_2_3: `and coaches are already following this guide!`,
        TEXT_3_1: "Through",
        TEXT_3_2: "more than 800 exercises in video format and 3D diagrams",
        TEXT_3_3: ", you will discover the different possible training methods (and exercises) for:",
        TEXT_4: `The online guide is "satisfaction guarantee or your money back", so you won’t be
        taking a risk by ordering it. If you don't like, we will refund you directly.`,
        CHECKS: [
          'Speed',
          'Strength',
          'Endurance',
          'Coordination',
          'Flexibility',
          'Warm-ups',
          'Prevention of injuries',
        ],
      }
    },
    VOTRE: {
      TITLE: 'YOUR GUIDE',
      CONTENT: {
        TEXT_1: `You will find a "library" or a "directory" of exercises in order to vary your training
        (team or individual). To progress, it is sometimes (or often) necessary to add variety
        and novelty, in order to shock the bodies (source of progress) and to avoid monotony
        (loss of motivation, overtraining ...)`,
        TEXT_2: 'To do this, you may vary the intensities, the quantities, ... but also the exercises!',
        TEXT_3: "You can suggest (or do) the same exercises but you can especially get inspiration from them and create your own.",
        TEXT_4: "That is the main objective, to give you ideas and examples so that you have a basis on which you can rely to design your own exercises!",
        TEXT_5: 'Are you interested in the 800 exercises guide?',
        BUTTON: 'All information here',
        BUTTON_SHOP_LINK: 'https://soccer-fitness-training-shop.com/collections/exercises-ideas/products/guide-800-exercises',
      }
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXAMPLES: {
      TITLE: "Examples of exercises found in the guide",
      SUBTITLE: "Here are examples of physical exercises in 3D format that you will find in the online guide.",
      EXERCICES1: [{
          img: "Exercices/exercice1.png",
          category: "Warm-up",
          title: "PASSING AND COORDINATION EXERCISES",
          sub: "Example of warm-up exercise",
          text: `The player makes a pass then will do footwork.. then will take the place of the
          partner to whom he passed... a different exercise each time.`,
        },
        {
          img: "Exercices/exercice2.png",
          category: "Speed",
          title: "MOVEMENT FREQUENCY WORK",
          sub: "Example of a speed exercise",
          text: `Movement frequency work between the slats (2 steps between each slat, from the
            side), then accelerate and pass, run backwards then turn back around,
            Frequency work between the slats (2 steps between each slat, from the side), then
            acceleration, pass and run backwards.`,
        },
        {
          img: "Exercices/exercice3.png",
          category: "Endurance",
          title: "POSSESSION - PRESSING",
          sub: "Example of power endurance exercise",
          text: `4 teams of 3 players in 4 defined squares. To start, the coach announces a team that
          uses pressing to retrieve the ball. Each player of the selected team goes into one of
          the other squares (one player per square so it is 1 against 3) .. 30 seconds of the drill
          for instance then a new team will do the pressing ...`,
        },
        {
          img: "Exercices/exercice4.png",
          category: "Speed",
          title: "REACTION TIME AND DUEL",
          sub: "Example of speed exercise",
          text: `the players (facing front or back) begin at the signal (announcement of a colour) and
          go to touch the cone of the announced colour. Then they return to take control of the
          ball and attempt to make a goal.. the first to arrive at the ball kicks or starts a duel..`,
        },
      ],
      EXERCICES2: [{
          img: "Exercices/vivacite-force-1.png",
          category: "Warm up",
          title: "SUPPORT COORDINATION",
          sub: "Example of warm-up exercise",
          text: `Footwork, coordination exercises and jumping during the warm-up to prepare the
          players for the session and at the same time work on different physical skills…`,
        },
        {
          img: "Exercices/vivacite-force-2.png",
          category: "Vitesse",
          title: "STRENGTH AND SPEED",
          sub: "Example of speed exercise",
          text: `the players start with plyometric work then one of the two players chooses a side and
          accelerates ... the other player must manage to tag them before they reach the finish
          line.`,
        },
      ]
    },
    OPINION_TITLE: `WHAT THEY THINK ABOUT THE ONLINE GUIDE`,
    OPINION_MOBILE_TITLE: `Coaches and players trust us ...`,
    END_PART: {
      TEXT: 'ARE YOU INTERESTED IN THE 800 EXERCISES GUIDE?',
      BUTTON: 'All information here',
    }
  },
  EXERCICES_APP: {
    FIRST: {
      TITLE: '300 exercise ideas',
      SUB: 'Why are we offering you this application?',
      CONTENT_1: `The blog contains a lot of workout examples. <br />
      But they are spread out in all the articles (more than 100 articles).`,
      CONTENT_2: `This application helps us organize the drills better and find the <br />
      exercises you are looking for faster.`,
      CONTENT_3: {
        TEXT: `We have organized the exercises into 6 main themes:`,
        LIST: [
          `Warm-up`,
          `Speed`,
          `Endurance`,
          `Coordination`,
          `Strength`,
          `Young players`,
        ],
      },
      END: `This application is aimed at amateur soccer coaches who are looking for drill ideas for
      their physical training sessions.`,
    },
    CATEGORIES: [
      'Warm-up',
      'Endurance',
      'Speed',
      'Strength',
      'Coordination',
      'Young players',
    ],
    LAST: {
      TITLE: `What are the features of this mobile application:`,
      ITEMS: [
        `Completely free`,
        `Easy to use`,
        `New drills are routinely added`,
        `Contact section for any questions or suggestions`,
        `Add your favorite exercises`,
        `Share the application with your friends or other coaches`,
        `Search function to quickly find the drills you are interested in`,
      ]
    },
    TEXT_MOBILE: `Pour visualiser cette page, veuillez accéder depuis votre ordinateur`,
    BACK_HOME: `Back to home`,
  },
  IDEES: {
    TOP: {
      TITLE: '200 small games ideas to work on physical',
      SUBTITLE: 'While working on technique and tactics',
    },
    QUESTIONS: {
      TITLE: '200 SMALL GAMES IDEAS  ',
      SUBTITLE: 'Suitable for young and older',
      ITEMS: [
        "Are you sometimes short of ideas to bring something new to your workouts?",
        "Would you like to know more exercises to work on endurance while working on technique and tactics?",
        "Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?",
        "Would you like to offer physical exercises with a balloon that are more fun and more appreciated by your players?",
        "Would you like to see more motivation and progress in your players when they do physical exercises?",
      ],
    },
    GUIDE: {
      TITLE: `We propose an online "guide" with
      <span>200 ideas of games reduced in the form of 3D diagrams</span>
      to work the physical (while working the technique and tactics).`,
      CONTENT: {
        TEXT_1: `This guide is for coaches of youth and senior teams who are looking for exercise ideas to work on the physical in an integrated way.`,
        TEXT_2: `The reduced games are oppositions with ball and allow to work on technical and tactical aspects.`,
        TEXT_3: `They would also allow to work on some aspects of physical work (you will have more explanations in the guide on the effects of small games on physical qualities)!`,
        TEXT_4: `This is particularly interesting in amateur soccer where you have to work on physical skills but also on tactics, technique or mental skills with a very limited training time.`,
        CHECKS: [
          `You have an "explanations" part about the reduced games (advantages, how to design them yourself, ...)`,
          `And explanations on endurance work`,
          `These exercises are without equipment or with small equipment (that all clubs can have, and all with ball)`,
        ],
      },
      BUTTON: 'Receive the program by email for free'
    },
    COMMANDE: {
      TITLE: 'After ordering, you will receive an email with the link to access the online guide.',
      CONTENT: {
        TEXT_1: `You will find a "library" or "repertoire" of exercises in order to  vary your workouts. To make progress, it is sometimes (even often) necessary to bring variety, novelty, to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)`,
        TEXT_2: `For this, you can vary the intensity, the volume, ... but also the exercises!`,
        TEXT_3: `You can propose (or do) the same exercises, but you can also use them as inspiration and create your own exercises.`,
        TEXT_4: `For this, you can vary the intensity, the volume, ... but also the exercises!`,
      }
    },
    INFOSHOP: {
      title: 'We offer you this new "GUIDE 200 IDEAS OF REDUCED GAMES" online',
      img: "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/2_900x.png?v=1620481664",
      link: "https://soccer-fitness-training-shop.com/collections/exercises-ideas/products/200-small-sided-games-ideas",
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of exercises found in the programs",
      SUBTITLE: `Here are some examples of physical exercises in 3D format that you will find in the online guide.`,
      E_LEFT: [{
          img: "Idees/Left1.png",
          title: "1 VS 1 SMALL GAMES",
          text: `
        <p>In the form of a mini-tournament (uphill-downhill) with evolution of the games.</p>
        <p>Winners move up to ﬁnish on court 1. Losers move down, with the last court being court 4 (or 5 or 6 depending on the number of players present).</p>
        <p><i class="fas fa-check"></i> Field 4: Ball retention (the player who keeps the ball the longest wins).</p>
        <p><i class="fas fa-check"></i> Field 3: stop ball in the red zone.</p>
        <p><i class="fas fa-check"></i> Field 2: The player must pass between the opposing studs (red or blue).</p>
        <p><i class="fas fa-check"></i> Field 1: Field 1: score in the opponent's small goal. The intensity must be high.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "4 VS 1 SMALL GAMES",
          text: `
        <p>5 squares. <br /> 4 against 1 in each square. <br /> Of the 12 yellow players who must keep the ball, 4 players (white arrows) play in 2 squares at the same time. These 4 players must never receive 2 balls at the same time.4 teams of 3 players in 4 definite squares.</p>
        <p>You can vary the size of the squares, give specific instructions, propose other constraints, ....
        </p>
        `,
        },
      ],
      E_RIGHT: [{
          img: "Idees/Right1.png",
          title: "5 VS 2 SMALL GAMES",
          text: `
        <p>3 zones.</p>
        <p>The team in numerical superiority must pass the 3 "steps" to score a point.</p>
        <p>The team on the power play must complete 8 passes before they can move to the next zone.</p>
        <p>In each zone, 2 new players at the pressing.</p>
        <p>The team on the power play has for example 2 minutes to pass the 3 steps and score a point.</p>
        <p>Each time the ball is lost, it starts again from the starting zone.</p>
        `,
        },
        {
          img: "Idees/Right2.png",
          title: "4 VS 4 SMALL GAMES",
          text: `
        <p>3 teams.</p>
        <p>A team that attacks (in red) and chooses the team against which it will attack (against white or yellow).</p>
        <p>If they score, they attack the other team. If they lose the ball, they go on defense and the other team goes on offense.</p>
        `,
        },
      ],
    }
  },
  TREVE_ESTIVALE: {
    TOP: {
      TITLE: 'In order to help you in your lessons',
      SUBTITLE: `We offer you this program to give you a framework, ideas or even examples on
      which you can rely to design your program!`,
      BUTTON: 'Receive the program by email for free',
    },
    QUESTIONS: {
      TITLE: 'SUMMER BREAK FOOTBALL PROGRAM',
      SUBTITLE: 'EXERCISES SHOWN AS 3D DIAGRAMS',
      ITEMS: [
        "Would you like to have an example, a model on which you can rely to prepare for the pre-season?",
        "Would you like to save time in creating your sessions (and not search the internet for hours in search of new exercises)?",
        "Would you like to offer warm-ups and physical exercises that are more fun and more appreciated by your players?",
        "Would you like to see more motivation and progress in your players when they do training exercises?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'The online guide is available on mobile, tablet and desktop.',
      CONTENT: {
        TEXT_1: `We are offering a new online “summer break program” with many ball exercises
        presented in 3D diagram format.`,
        TEXT_2: `You have an “explanations” part for planning and for each physical skill and a
        “sessions and exercises” part.`,
        TEXT_3: `These exercises are without equipment or with some equipment (which all amateur
          clubs have).`,
        TEXT_4: `This program is primarily intended for coaches who are looking for example
        programs for the winter break in Football
        <br /><span>More than 2000</span> coaches already follow this program!`,
        TEXT_5: `<span>We offer you this new program.</span><br />
        Click on the button below to have it sent directly to your inbox.`,
      },
      BUTTON: 'Receive the program by email for free',
      EMAIL: `After registering, you will receive an email with the access link to the online program
      (it sometimes arrives into the spam folder).`
    },
    VOTRE: {
      TITLE: "Your pre-season program",
      CONTENT: {
        TEXT_1: `You will find a "program" planned over 6 weeks with 2, 3 or 4 training sessions per
        week.`,
        TEXT_2: `You can suggest (or do) the same exercises but you can especially get inspiration
        from them and create your own.`,
        TEXT_3: `That is the main objective, to give you ideas and examples so that you have a basis
        on which you can rely to design your own exercises!`,
      }
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of exercises found in the programs",
      SUBTITLE: `Here are examples of physical exercises in 3D format that you will find in the online
      guide.`,
      E_LEFT: [{
          img: "Training/ELeft1.png",
          title: "SPECIFIC SKILL AND AEROBIC POWER EXERCISE",
          sub: "Example of an exercise combining power and endurance",
          text: `
          <p>Striking exercise: The player makes a header aiming at a target (here blue and red)
          then returns back into starting position and starts again. As quickly as possible.</p>
          <p>In 10-20 for example<br /> Then: <br /> 2 against 2 <br /> 1 minute 30 of play, 45 seconds of recovery <br /> 3 sequences.</p>
          `,
        },
        {
          img: "Training/ELeft2.png",
          title: "POSSESSION - PRESSING",
          sub: "Example of power endurance exercise",
          text: `
          <p>4 teams of 3 players in 4 defined squares.</p>
          <p>To start, the coach announces a team that uses pressing to retrieve the ball. Each
          player of the selected team goes into one of the other squares (one player per
          square so it is 1 against 3).</p>
          <p>30 seconds of the drill for instance then a new team will do the pressing ...</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/ERight1.png",
          title: "WORK OF ENDURANCE CAPACITY AND ADDRESS",
          sub: "Example of an exercise combining endurance and skill",
          text: `
          <h5>"skill" route</h5>
          <p><i class="fas fa-check"></i> Exercise 1: make the cone fall</p>
          <p><i class="fas fa-check"></i> Exercise 2: get the ball as close as possible to the pole</p>
          <p><i class="fas fa-check"></i> Exercise 3: touch the crossbar</p>
          <p><i class="fas fa-check"></i> Exercise 4: steal the ball and get it inside a big tire (it can be a hoop or anything else)</p>
          <p><i class="fas fa-check"></i> Exercise 5: make the cone fall</p>
          <p><i class="fas fa-check"></i> Exercise 6: get the ball as close as possible to the pole</p>
          <p><i class="fas fa-check"></i> Exercise 7: kick the ball under the hurdle in the middle of the cage</p>
          <p class="last">No stopping. Average pace <br /> Possible instructions: weak footwork.<br /> For example: 15 minutes</p>
          `,
        },
        {
          img: "Training/ERight2.png",
          title: "REACTION TIME AND DUEL SPEED",
          sub: "Example of a power endurance exercise",
          text: `
          <p>The players (facing front or back) begin at the signal (announcement of a colour) and
          go to touch the cone of the announced colour. Then they return to take control of the
          ball and attempt to make a goal.</p>
          <p>The first to arrive at the ball kicks or starts a duel</p>
          `,
        },
      ],
    }
  },
  TREVE_HIVERNALE: {
    TOP: {
      TITLE: 'In order to help you in your lessons',
      SUBTITLE: `We offer you this program to give you a framework, ideas or even examples on
      which you can rely to design your program!`,
      BUTTON: 'Receive the program by email for free',
    },
    QUESTIONS: {
      TITLE: 'WINTER BREAK PROGRAM IN SOCCER',
      SUBTITLE: 'EXERCISES PRESENTED IN THE FORM OF 3D DIAGRAMS',
      ITEMS: [
        "Would you like to have an example, a model on which to base your preparation during the winter break?",
        "Would you like to save time in creating your sessions and program during the winter break (and not look for hours of new exercises on the internet)?",
        "Would you like to offer more fun warm-ups and physical exercises that your players will enjoy?",
        "Would you like to see more motivation and progress in your players when they do physical exercises?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'Online programs are available on mobile, tablet and computer.',
      CONTENT: {
        TEXT_1: `We offer a new “winter break program” online with many exercises with a ball presented in 3D format.`,
        TEXT_2: `You have an "explanations" part for the planiﬁcation and for each physical quality and a "sessions and exercises" part.`,
        TEXT_3: `These exercises are without equipment or with small equipment (which all amateur clubs can).`,
        TEXT_4: `This program is primarily for coaches looking for examples of winter break programs in soccer.
        <br /><span>More than 700 </span>coaches are already following this program! `,
        TEXT_5: `<span>We offer you this new program.</span><br />
        Click on the button below to receive it directly in your mailbox.`,
      },
      BUTTON: 'Receive the program by email for free',
      EMAIL: `After registering, you will receive an email with the link to access the online program (beware that the email sometimes arrives in spam).`
    },
    VOTRE: {
      TITLE: "Your special winter break program",
      CONTENT: {
        TEXT_1: `You will find a "program" over 6 weeks with 2 trainings per week.`,
        TEXT_2: `You can propose (or do) the same programming and exercises, but you can also draw inspiration from them and create your own programming.`,
        TEXT_3: `The goal is to give you ideas and examples so that you have a basis on which to design your programming!`,
      }
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of exercises found in the programs",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne.`,
      E_LEFT: [{
          img: "Training/HLeft1.png",
          title: "EXERCICE DE FORCE SPÉCIFIQUE ET DE PUISSANCE AÉROBIE",
          sub: "Exemple d'exercice mixant force et endurance",
          text: `
          <p>Circuit training pendant 3 minutes puis travail de course pendant 7 minutes (par exemple)</p>
          <p>Sous forme 15-15 (ou 10-20..)</p>
          `,
        },
        {
          img: "Training/left2.png",
          title: "VITESSE DE RÉACTION",
          sub: "Exemple d'exercice de vitesse de réaction",
          text: `
          <p>Le joueur doit aller toucher avec le pied le cône de couleur que lui annonce son partenaire.</p>
          <p>"vert" et le joueur va vers le cône vert puis se replace rapidement au centre. Dès que le joueur est replacé au centre le partenaire lui annonce la nouvelle couleur... ne pas lui laisser de temps de repos.</p>
          <p>Le joueur doit prendre les informations (les couleurs) avant de commencer pour pouvoir enchaîner très vite.</p>
          <p>Travail par deux (un qui fait l'exercice, l'autre qui annonce les couleurs).</p>
          <p>Effort court (5-6 secondes) et récupération longue ( 1 minute par exemple).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/HRight1.png",
          title: "TRAVAIL DE FORCE ET D'ENDURANCE",
          sub: "Exemple d'exercice mixant endurance et force",
          text: `
          <p><i class="fas fa-check"></i> Jeux réduits 1 vs 1 - 5 matchs</p>
          <p><i class="fas fa-check"></i> Ici, les joueurs vont faire des duels (1mn de jeu, 30 secondes de repos).</p>
          <p><i class="fas fa-check"></i> Objectif, aller déposer le ballon dans le camp de son adversaire (zone rouge).</p>
          <p><i class="fas fa-check"></i> L'intensité doit être grande.</p>
          <p><i class="fas fa-check"></i> Mettez des ballons autour des petits terrains.</p>
          <p><i class="fas fa-check"></i> Dimension des terrains : 5m x 5m environ.</p>
          <p><i class="fas fa-check"></i> Possibilité de faire sous forme de mini-tournoi (montée-descente).</p>
          `,
        },
        {
          img: "Training/right2.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher les cônes des couleurs annoncées.</p>
          <p>L'entraîneur annonce 2 ou 3 couleurs.</p>
          <p>Les joueurs doivent toucher les cônes le plus vite possible puis aller au duel et frapper.</p>
          `,
        },
      ],
    }
  },
  GUIDE_JEUNES: {
    TOP: {
      TITLE: 'In order to help you in your training',
      SUBTITLE: `We are offering you this guide which we hope will provide you with knowledge, ideas and examples on which you can base your scheduling!`,
      BUTTON: 'Receive the program by email for free',
    },
    CHECKS: {
      TITLE: '"PHYSICAL PREPARATION OF YOUNG FOOTBALLERS" GUIDE',
      ITEMS: [
        `It is not always easy to find information about young
        athletes and knowledge of how the child functions
        during training.`,
        `There are far fewer studies and research studies than for adults.`,
        `In order to help you, we have put together the information that we feel is essential to know in order to offer your young people content that will allow them to progress and develop harmoniously.`,
      ],
    },
    LE_GUIDE: {
      TITLE: 'The online guide is available on mobile, tablet and computer',
      SUBTITLE: `Many coaches have asked us if we could help them with the physical preparation of young soccer players.
      We tried to answer this need by creating this small guide for you.`,
      CONTENT: {
        TEXT_1: `We offer you an online "guide" on the physical preparation of young soccer players. <br />
        You have access to a general information module on young players and information modules by category with exercise examples to propose.`,
        TEXT_2: `This guide is mainly aimed at coaches who are looking for information about young players but also exercise ideas for their physical training sessions.`,
        TEXT_3: ``,
        TEXT_4_1: `+ More than 3000`,
        TEXT_4_2: `coaches are already following this guide!`,
        TEXT_5: `You will also discover the different methods (and exercises) possible workouts of:`,
        TEXT_6_1: "We are offering you this new program.",
        TEXT_6_2: `Click on the button below to receive it directly in your mailbox.`,
        TEXT_7: `After registering, you will receive an email with the link to access the online program (beware that the email sometimes arrives in spam).`,
        CHECKS: [
          'Speed',
          'Strength',
          'Endurance',
          'Coordination',
          'Flexibility',
          'Warm-upt',
          'Injury prevention',
        ],
        BUTTON: 'Receive the program by email for free',
      }
    },
    VOTRE: {
      TITLE: "YOUR GUIDE",
      CONTENT: {
        TEXT_1: `You will also find a small "library" or "repertoire" of exercises to vary your workouts. To progress, it is sometimes (if not often) necessary to bring variety, novelty, in order to surprise the body (source of progress) and to avoid monotony (loss of motivation, overtraining...)`,
        TEXT_2: `To this end, you can vary the intensity, volume, etc., as well as the exercises!`,
        TEXT_3: `You can propose (or do) the same exercises, but you can especially draw inspiration from them and create your own exercises.`,
        TEXT_4: `The objective is to give you ideas and examples so that you have a basis on which to design your own exercises!`
      }
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
    EXERCICES: {
      TITLE: "Examples of drills found in the guide",
      SUBTITLE: `Here are some examples of physical exercises in 3D format that you will find in the online guide to give you ideas.`,
      E_LEFT: [{
          img: "Guide/GuideLeft1.png",
          title: "PASSING AND COORDINATION DRILLS",
          sub: "Example of a warm-up drill",
          text: `
          <p>The player makes a pass and then goes to do a coordination strides. Then he will take the place of the partner to whom he made the pass. A different drill each time.</p>
          `,
        },
        {
          img: "Guide/GuideLeft2.png",
          title: "FREQUENCY + REACTION",
          sub: "Example of a speed drill",
          text: `
          <p>The players work on the frequency on the rhythm ladder (2 strides per ladder square, very fast) then when they reach the yellow markers, one of the two players (here the player in blue on the diagram) chooses the direction of the sprint (right or left). The other player has to touch him before he crosses the finish line (the 2 orange markers).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Guide/GuideRight1.png",
          title: "MOVEMENT FREQUENCY",
          sub: "Example of a speed drill",
          text: `
          <p>Players work on frequency on the rhythm ladder (2 strides per ladder square, very fast) then when they reach the yellow markers, one of the two players (here the player in blue on the diagram) chooses the direction of the sprint (right or left). The other player has to touch him before he crosses the finish line (the 2 orange cones).</p>
          `,
        },
        {
          img: "Guide/GuideRight2.png",
          title: "REACTION TIME AND DUEL",
          sub: "Example of a speed drill",
          text: `
          <p>The players (front or back) start at the signal (announcement of a color) and go to touch the cone of the announced color. Then return to pick up the ball and strike it on goal. The first one to touch the ball strikes or goes for a duel.</p>
          `,
        },
      ],
    }
  },
  GUIDE_M: {
    TOP: {
      TITLE: 'TO HELP YOU IN YOUR WORK AS A COACH',
      SUBTITLE: `We offer you this "MANAGEMENT" GUIDE <br />
      which includes 5 modules (and a bonus).`,
    },
    LINK_VIDEO: 'https://www.youtube.com/embed/f4h6OZyZG80',
    CHECKS: {
      TITLE: 'MANAGEMENT GUIDE',
      ITEMS: [
        `Are you a coach and would like to have the solutions to resolving conflict (between
          players, with your players, with parents ...)?`,
        `Would you like to know how to deal with "difficult" players, with strong personalities?`,
        `Would you like to develop your leadership skills or work on your personal
        development?`,
        `Would you like to improve your organization skills or even liveliness of your group?`,
      ],
    },
    LA_GUIDE: {
      TITLE: 'THE ONLINE GUIDE IS AVAILABLE ON MOBILE, TABLET AND COMPUTER.',
      CONTENT: {
        COL_1_1: {
          TITLE: 'Enable you to progress',
          TEXT: `The idea of this guide is to help you to progress as a coach in terms of group
          management, relations with players, organization and even liveliness.`
        },
        COL_1_2: {
          TITLE: 'Give you the tools',
          TEXT: `We are giving you the tools to the management of your group, to give you the seeds
          to create your own methodology.`,
        },
        TEXT_1: `The modules MANAGEMENT, MENTAL PREPARATION AND MANAGEMENT OF
        TRAINING SESSIONS aim to meet these objectives.`,
        COL_2_1: {
          TITLE: 'Develop your abilities',
          TEXT: `This guide should help you develop your capacities to manage, empower and
          organize your teams. For this, we believe that self-improvement is essential.`
        },
        COL_2_2: {
          TITLE: 'Provide advice',
          TEXT: `We therefore try to provide you with advice and methods to help you improve
          yourself.`,
        },
        TEXT_2: `The LEADERSHIP and PERSONAL DEVELOPMENT - MINDSET modules aim to
        meet these objectives.`,
      }
    },
    INFOSHOP: {
      title: 'We are offering this new online "MANAGEMENT GUIDE"',
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/83_2_900x.png?v=1620480451",
      link:
        "https://soccer-fitness-training-shop.com/collections/e-learning/products/management-guide",
    },
    MODULES: {
      TITLE: 'CONTENTS OF YOUR ONLINE GUIDE',
      SUBTITLE: "You will find 5 modules with more than 100 videos containing advice from management and coaching experts!",
      INITIAL: `<h4>MODULE 1: MANAGEMENT</h4>
      <p class="gray">You will find for example:</p>
      <p>
        <i class="fas fa-check-circle"></i> How to deal with criticism?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> How to manage conflicts with your players, between players, with parents?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> How to deal with egos and "strong personalities"? How to actively involve players in
        training?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> How to handle losses, how to manage substitutes, leaders, ...
      </p>
      <p class="gray">
      You will also discover a communication method that will allow you to solve many
      problems!
      </p>`,
      ITEMS: [{
          id: 1,
          content: `<h4>MODULE 1: MANAGEMENT</h4>
          <p class="gray">You will find for example:</p>
          <p>
            <i class="fas fa-check-circle"></i> How to deal with criticism?
          </p>
          <p>
            <i class="fas fa-check-circle"></i> How to manage conflicts with your players, between players, with parents?
          </p>
          <p>
            <i class="fas fa-check-circle"></i> How to deal with egos and "strong personalities"? How to actively involve players in
            training?
          </p>
          <p>
            <i class="fas fa-check-circle"></i> How to handle losses, how to manage substitutes, leaders, ...
          </p>
          <p class="gray">
          You will also discover a communication method that will allow you to solve many
          problems!
          </p>`,
        },
        {
          id: 2,
          content: `<h4>MODULE 2 LEADERSHIP</h4>
                  <p class="gray">You will discover for example:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to become a leader?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to express yourself in front of a group?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to make your pitsh?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to develop your charisma? or how to get respect?
                  </p>`,
        },
        {
          id: 3,
          content: `<h4>MODULE 3 MENTAL PREPARATION</h4>
                  <p class="gray">You will discover for example:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to deal with criticism?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Methods for managing emotions
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Stress management
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Self-confidence
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Group cohesion
                  </p><p>
                    <i class="fas fa-check-circle"></i> Motivation and concentration
                  </p>`,
        },
        {
          id: 4,
          content: `<h4>MODULE 4 PERSONAL DEVELOPMENT - MINDSET</h4>
                  <p class="gray">You will discover for example:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to build your success?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to develop self-discipline?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to increase your comfort zone?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to become an actor of your life, how to set goals or how to become
                    happier?
                  </p>`,
        },
        {
          id: 5,
          content: `<h4>MODULE 5 TRAINING SESSION MANAGEMENT</h4>
                  <p class="gray">You will discover for example:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to present the training sessions to the players?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to calibrate training sessions?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> How to lead training sessions?
                  </p>`,
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
  },
  OPINIONS: {
    FIRST_SLIDE: [{
        text: `
        ""800 Workout Guide" provides workout ideas, which saves time when
        doing your sessions. As someone who doesn't have a lot of free time, it
        really helps me, the players enjoy the drills and the results this year are
        very good! Thank you"`,
        person: 'Laurent D.'
      },
      {
        text: `
        I wanted to test the "une année d'entraînements physiques" program
        because I felt that I was always proposing the same thing during my
        training sessions. I discovered new workouts and now I can design new
        ones based on the ones presented. Otherwise it's well explained, and not
        expensive, so I recommend it to coaches (I already recommended it to a
        friend who is also a coach ;) )`,
        person: 'Chris T.'
      },
      {
        text: `
        I find this training to be very comprehensive for the price charged. It is
        very suitable and efficient for people who want to start out. It is a good
        foundation to start with. For me, the training allows me to bring me
        additional information and reinforce the content that I implement on a
        daily basis. Thank you.
        Sincerely.`,
        person: 'Rabah'
      }
    ],
    SECOND_SLIDE: [{
        text: `
      I am in the middle of the mental module of the training. I am not disappointed by the training
      that I am following, at my own pace of course because my free time is limited at the moment.
      But your training clarifies a lot of points for me and I think that as an amateur we will be able
      to approach physical preparation more serenely and with far fewer restrictions. Thank you
      for all that you put in place and all the support that you put at our disposal.`,
        person: 'Anne'
      },
      {
        text: `
      I have U9s this year and I didn't have many ideas on what coordination and motor skills
      exercises to offer. You recommended this guide and I am very satisfied. The speed
      exercises are also very good. The kids are happy, that's the main thing`,
        person: 'Yohan J.'
      },
      {
        text: `
      I started the sessions a little while ago but the players have really enjoyed it. It's a lot of ball
      and fun. The exercises are easy to understand. I didn't know how to plan the physical work,
      so now I just have to follow what you suggest. And I'll be learning at the same time, I think.
      At this price it's very good!`,
        person: 'Amine B.'
      }
    ],
    POSITION: 'Coach',
  },
  SUBMENU_JEUNES: {
    TITLE: 'Youth coaches programs',
    TITLE_MOBILE: 'Youth coaches',
    ITEMS_LEFT: [{
        text: "Full season program (U8 to U17)",
        next: "SaisonJeunes",
      },
      {
        text: "Customized program",
        next: "",
      },
      {
        text: "The packages",
        next: "Packs",
        param: "youth",
      },
    ],
    ITEMS_RIGHT: [{
        text: `2 physical training cycles focusing on "coordination" U8-U12`,
        next: "Coordination",
      },
      {
        text: `2 physical training cycles focusing on "speed" U8-U12`,
        next: "VitesseJeunesU8",
      },
      {
        text: `2 physical training cycles focusing on "speed" U12-U14`,
        next: "VitesseJeunesU12",
      },
      {
        text: `2 physical training cycles focusing on "speed" U14-U18`,
        next: "VitesseJeunesU14",
      },
      {
        text: `2 physical training cycles focusing on "power endurance" U15-U18`,
        next: "PuissanceJeunes",
      },
      {
        text: `A special 4-week "tactical periodization" cycle U15-U18`,
        next: "Periodisation",
      },
      {
        text: `2 cycles of physical training for goalkeepers special speed U15-U18`,
        next: "GardienVitesseU15",
      },
      {
        text: `2 cycles of physical training in futsal special speed U15-U18`,
        next: "FutsalVitesseU15",
      },
      {
        text: `2 cycles of physical training in futsal special power endurance U15-U18`,
        next: "FutsalPuissanceU15",
      },
    ],
  },
  SUBMENU_SENIOR: {
    TITLE: 'Adult coaches programs',
    TITLE_MOBILE: 'Adult coaches',
    ITEMS_LEFT: [{
        text: "Full season program - Adult",
        next: "SaisonSenior",
      },
      {
        text: "Pre-season program",
        next: "TreveEstivale",
      },
      // {
      //   text: "Trêve hivernale",
      //   next: "TreveHivernale",
      // },
      // {
      //   text: "Pré-saison Gardiens et futsal",
      //   next: "Programmes",
      // },
      {
        text: "Customized program",
        next: "",
      },
      {
        text: "The packages ",
        next: "Packs",
        param: "adult",
      }
    ],
    ITEMS_RIGHT: [{
        text: `2 cycles of special "speed" training`,
        next: "SpecialVitesse",
      },
      {
        text: `2 cycles of special "power endurance" training`,
        next: "Puissance",
      },
      {
        text: `2 physical training cycles "100% with ball"`,
        next: "Avec",
      },
      {
        text: `A special 4-week "tactical periodization" cycle`,
        next: "Periodisation",
      },
      {
        text: `2 cycles of physical training for goalkeepers special "speed"`,
        next: "GardienVitesse",
      },
      {
        text: `2 cycles of physical training in futsal special "speed"`,
        next: "FutsalVitesse",
      },
      {
        text: `2 cycles of physical training in futsal special "power endurance"`,
        next: "FutsalPuissance",
      }
    ],
  },
  CONTACTO: {
    TITLE: 'Do you have a question? ',
    SUBTITLE: "Do not hesitate to write to us",
    PLACEHOLDERS: {
      NAME: "First name",
      EMAIL: "Email ",
      MESSAGE: "Message",
    },
    BUTTON: "Send",
    ALERT: 'All fields are required',
  },
  MEMBERS: {
    INFOSEC1: {
      img: "Grupo 1012@2x.png",
      title: "Are you a member of one of our programs, guides or trainings?",
      text: `<h3>
                To connect to the "special member" platform, click on the link below:
              </h3>`,
      button: "Access to the online platform",
    },
    INFOSEC1_MOBILE: {
      img: "Grupo 1012@2x.png",
      title: "Are you a member of one of our programs, guides or trainings?",
      text: `<h3>
                To connect to the "special member" platform, click on the link below:
              </h3>`,
      button: "Access to the online platform",
    },
    INFOSEC2: {
      img: "Llave.png",
      title: "Forgotten your password?",
      text: `<h3>
              You can reset it by clicking on the link below
              </h3>`,
      button: "Recover Password",
    },
    INFOSEC2_MOBILE: {
      img: "Llave.png",
      text: `<h3>
              Forgotten your password? You can reset it by clicking on the link below
              </h3>`,
      button: "Recover Password",
    },
    LINKS: [
      'https://members.fitness-soccer-training.com/formation/',
      'https://members.fitness-soccer-training.com/formation/',
    ],
    PROBLEME: {
      TITLE: 'Have another problem?',
      CONTENT: {
        TEXT_1: 'Write to us on the chat or',
        TEXT_2: 'HERE',
        TEXT_3: ' we will get back to you as soon as possible.',
      },
      CONTENT_MOBILE: 'Write to us on the chat or HERE, we will get back to you as soon as possible.'
    }
  },
  EMAILS: {
    TITLE: "Thibaud's private emails",
    CONTENT: `Join more than <strong>40,000 amateur coaches</strong> and find weekly <strong>exercise ideas, information on</strong> amateur soccer <strong>physical preparation</strong> and <strong>special offers</strong>
    son online programs and guides.`,
    PLACEHOLDER: 'Your email address',
    BUTTON: 'I want to receive private emails',
    LOCK: 'The emails are free, your data will remain private and you can unsubscribe at any time.'
  },
  SHOP: {
    TITLE: `If you would like to order any of our programs, guides or online
    courses, you will be redirected to our online store.`,
    SUBTITLE: 'Why an online store?',
    CONTENT: {
      TEXT_1: `Our goal is to provide you with
      <strong>easy access</strong> to all online
      programs and to offer you simple, fast and <strong>highly secure</strong>
      payment methods.`,
      TEXT_2: `This also allows us to easily offer you
      <strong>packs,</strong>
      <strong>special offers</strong> or
      <strong>physical preparation material.</strong>`,
      TEXT_3: `We want your experience on our site to be as pleasant as
      possible and we hope you will be satisfied ;)`,
      TEXT_4: `<i>So, when you click on "order" on a program, you will be redirected to the online
      store. Once on the store, you can place your order and then, if you wish to
      return to the site, you will find a link in the home page of the store to return
      here.</i>`,
    },
    BUTTON: 'VISIT THE ONLINE STORE',
    LINK: 'https://soccer-fitness-training-shop.com',
  },
  PACKS: {
    INFOEXERCICES: {
      id: 'exercises',
      title: "Package - Physical exercises",
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/8_900x.png?v=1620590320",
      link:
        "https://soccer-fitness-training-shop.com/collections/exercises-ideas/products/pack-physical-exercises",
    },
    INFOJEUNES: {
      title: "PHYSICAL TRAINING PACKS FOR YOUTH COACHES",
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/8_479b0d00-3928-4b13-b9e8-a8e33c43098c_900x.png?v=1620605620",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/physical-training-packs-for-youth-coaches",
    },
    INFOSENIOR: {
      title: "PHYSICAL TRAINING PACKS FOR ADULT COACHES",
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/CopiadeCopiadeCopiadeCopiadeejer-1_3_900x.png?v=1625688315",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/physical-training-packs-for-adult-coaches",
    },
    INFOFORMATION: {
      id: 'e-learning',
      title: "E-LEARNING PACKS",
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/89_1_900x.png?v=1620480539",
      link:
        "https://soccer-fitness-training-shop.com/collections/e-learning/products/e-learning-packs",
    },
  },
  BLOG: {
    RESULT: {
      TEXT_1: '',
      TEXT_2: 'results',
    },
    BLOG_TOP: {
      TITLE: "“Knowledge is power”",
      AUTHOR: 'F.Bacon',
      PLACEHOLDER: 'Search...'
    },
    BLOG_ICONS: {
      TITLE: 'The categories',
      ICONS: [{
          img: "Trazado 2375.png",
          name: "Strength",
          category: "Strength",
        },
        {
          img: "noun_speed_1109796.png",
          name: "Speed",
          category: "Speed",
        },
        {
          img: "Grupo 819.png",
          name: "Endurance",
          category: "Endurance",
        },
        {
          img: "noun_run_562324.png",
          name: "Coordination and flexibility",
          category: "Coordination",
        },
        {
          img: "Grupo 862.png",
          name: "Injury prevention",
          category: "Prevention",
        },
        {
          img: "Grupo 820.png",
          name: "Other materials",
          category: "Others",
        },
        {
          img: "Grupo 816.png",
          name: "Planning",
          category: "Planning",
        },
        {
          img: "Grupo 817.png",
          name: "Mental preparation",
          category: "PM",
        },
        {
          img: "noun_Fruits_3480584.png",
          name: "Nutrition",
          category: "Nutrition",
        },
        {
          img: "Trazado 2397.png",
          name: "Management",
          category: "Management",
        },
        {
          img: "healthy-boy.png",
          name: "Youth",
          category: "Youth",
        },
        {
          img: "Grupo 861.png",
          name: "Futsal",
          category: "Futsal",
        },
        {
          img: "noun_glove_1827610.png",
          name: "Goalkeeper",
          category: "Goalkeeper",
        },
      ],
    },
    NEWS: {
      TITLE: 'EXERCISE IDEAS',
      VIDEOS: [{
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video1.mp4?alt=media&token=8b524077-f6f9-481a-9141-f2a1df25953d",
          caption: "Speed reaction"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video2.mp4?alt=media&token=b671a94a-8fdf-4f84-a272-2fbfd0685700",
          caption: "Vivacity"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video3.mp4?alt=media&token=1c1cfc43-bb13-46e8-b53d-3d5095dab465",
          caption: "Vivacity"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video4.mp4?alt=media&token=053828b4-2563-4031-a0f0-9cd145ed182d",
          caption: "Vivacity"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video5.mp4?alt=media&token=10731864-6e3e-48b6-bdd8-1d50480616f4",
          caption: "Warm up"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video6.mp4?alt=media&token=2bc8ab3f-d549-4a5f-9174-c25cd5ef8e41",
          caption: "Warm up"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video7.mp4?alt=media&token=6c8ea541-a081-4712-b5b0-b0bc21805e6a",
          caption: "Warm up"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video8.mp4?alt=media&token=a9dec042-247f-49fb-9905-9136b4da3097",
          caption: "Speed reaction"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video9.mp4?alt=media&token=945b3ca7-c123-490c-858c-519e0b3c764d",
          caption: "Warm up"
        },
      ]
    },
    BLOG_CATE: {
      RETURN: 'Back to blog',
      NOT_FOUND: "No articles were found in this category.",
    }
  },
  ARTICLES_TITLE: 'Discover our latest articles ...',
  ARTICLE_LINK: 'Read more',
  APPSEC_MSG: 'Coming soon',
  FAQ: {
    TITLE: 'Have a question?',
    SUBTITLE: 'We may have the answer!',
    PLACEHOLDER: 'Search...',
    ITEMS: [{
        id: 1,
        param: "firstQ",
        text: "Physical skills in football",
      },
      {
        id: 5,
        param: "fifthQ",
        text: "Management",
      },
      {
        id: 2,
        param: "secondQ",
        text: "Prevention of injuries",
      },
      {
        id: 6,
        param: "sixQ",
        text: "Other",
      },
      {
        id: 3,
        param: "thirdQ",
        text: "Youth",
      },
      {
        id: 7,
        param: "seventhQ",
        text: "What we offer",
      },
      {
        id: 4,
        param: "fourthQ",
        text: "Mental preparation",
      },
      {
        id: 8,
        param: "eighthQ",
        text: "What we don’t offer",
      },
    ]
  },
  APROPOS: {
    TITLE: 'About us',
    SUBTITLE: 'To this end, I have put in place several means:',
    CONTENT_1: [
      `A few years ago, during a training session with various physical trainers and
      amateur coaches, everyone realized that there was not enough information
      about physical training in amateur soccer.`,
      `The idea of creating a blog to share and exchange knowledge on physical
      preparation was born! <br />
      And quickly, hundreds of coaches expressed their gratitude and their needs (and
      if it was possible to help them).`,
      `My main objective is to meet your needs as much as I can.`,
    ],
    CONTENT_2: [
      `You have, for example, access to a blog with totally free information on many
      subjects related to physical preparation in soccer and management, mental
      preparation, or nutrition.`,
      `You have the possibility to receive weekly "private emails" on physical exercise
      ideas and information on physical preparation in amateur soccer.`,
      `You have access to free guides and programs on physical preparation for young
      footballers and during the pre-season.`,
      `Finally, you will find many guides, programs, and courses on physical preparation
      in soccer.`,
      `I try to continually educate myself so that I can meet all your needs. If you don't
      find what you are looking for on this website, you can contact me by mail, and I
      will try to help you ;)`,
      `Sportingly`,
    ],
    END: 'Physical trainer',
  },
  MENTIONS: {
    LINK: {
      TEXT: 'www.fitness-soccer-training.com',
      LINK: 'https://fitness-soccer-training.com',
    },
    CONTENT: [
      {
        TITLE: 'LEGAL NOTICES',
        TEXT: `Please carefully read the different terms of use of this site before browsing
        its pages. By connecting to this site, you accept without reservation the
        present terms and conditions. Also, per article n°6 of the Law n°2004-575
        of June 21, 2004, for trust in the digital economy, the persons in charge of
        the present website www.fitness-soccer-training.com are:`,
      },
      {
        TITLE: `SITE EDITOR`,
        TEXT: [
          `Conte Thibaud`,
          `SIRET : 80922462900034`,
          `95 chemin des combes – 07270 Le crestet`,
          `Email : prepaphysiquefootball@gmail.com`,
          `Website: https://fitness-soccer-training.com`,
        ]
      },
      {
        TITLE: `HOSTING`,
        TEXT: [
          `Hosting company: 1and1`,
          `7 place de la gare 57201 Sarreguemines`,
        ]
      },
      {
        TITLE: `DEVELOPMENT`,
      },
      {
        TITLE: `OBJECT OF THE SERVICE`,
        TEXT: [
          `www.fitness-soccer-training.com is an information and training site on physical
            preparation in football.`,
          `The reader must however be warned that the results obtained (whether positive or
            negative) depend solely on the use that will be made of the information and that the
            author cannot be held responsible for these results and declines all responsibility.
            with regard to the possible consequences of using the site. The reader should also
            be aware that the opinions, experience feedback or advice given are not
            recommendations.`
        ],
      },
      {
        TITLE: `TERMS OF USE`,
        TEXT: [
          `This site is offered in different web languages (HTML5, Javascript, CSS3,
          JQuery, PHP, etc...). For better use and more pleasant graphics, we
          recommend you use modern browsers such as Internet Explorer, Safari,
          Firefox, Google Chrome, etc... The legal notices were generated on the
          Legal Notice Generator website, offered by Welye. Preparation pysique
          football implements all the means at its disposal to guarantee reliable
          information and a reliable update of its websites. However, errors or
          omissions may occur. Therefore, the Internet user should ensure the
          accuracy of the information with ... and report any modifications of the site
          they deem helpful. ...is in no way responsible for the use made of this
          information, and any direct or indirect damage that may result.`,
          `Football Physical Preparation is committed to providing an honest opinion and
          sincere about all the products or services mentioned`
        ]
      },
      {
        TITLE: `COOKIES`,
        TEXT:
          `The www.fitness-soccer-training.com website may ask you to accept cookies for statistical and display purposes. A cookie is a piece of
          information deposited on your hard drive by the server of the site you are
          visiting. It contains several pieces of data stored on your computer in a
          simple text file that a server accesses to read and record information.
          Some parts of this site cannot be accessed without accepting cookies.`,

      },
      {
        TITLE: `HYPERTEXT LINKS`,
        TEXT: [
          `The websites may offer links to other websites or other resources
        available on the Internet. Fitness soccer training does not have any
        means to control the sites connected with its Internet sites, does not
        answer for the availability of such sites and external sources, nor
        guarantees it. It cannot be held responsible for any damage, of any nature
        whatsoever, resulting from the content of these external sites or sources,
        particularly from the information, products, or services they offer, or any
        use that may be made of these elements. The risks related to this use are
        entirely incumbent on the Internet user, who must comply with their
        conditions of use. `,
        `Users, subscribers, and visitors to websites cannot set up
        a hyperlink to this site without the prior express authorization of
        Fitness Soccer Training. Fitness Soccer Training reserves the
        right to accept or refuse a hyperlink without having to justify its decision.`
        ]
      },
      {
        TITLE: `SERVICES PROVIDED`,
        TEXT:
          `All of the company's activities and information are presented on our
          website www.fitness-soccer-training.com. 
          Fitness Soccer Training strives to provide the most accurate information possible. 
          The information contained in is not exhaustive, and the photos are not contractual. 
          They are subject to modifications having been made
          since they were put online. Furthermore, all information on the given as an illustration and is likely
          to change or evolve without notice.`
      },
      {
        TITLE: `CONTRACTUAL LIMITATION ON DATA`,
        TEXT: [
          `The information contained on this site is as accurate as possible, and the
        site is updated at various times of the year but may contain inaccuracies
        or omissions. If you notice a gap, error, or what seems to be a malfunction,
        please report it by email to fitnesssoccertraining100@gmail.com, describing
        the problem as clearly as possible (page causing the problem, type of
        computer and browser used, ...).`,
          `Any downloaded content is done at the
        user's own risk and under his sole responsibility. As a result, the company
        cannot be held responsible for any damage to the user's computer or any
        data loss resulting from the download. Moreover, the site user commits
        himself to reach the site by using recent hardware, not containing viruses,
        and with a new updated browser. The hypertext links set up within the
        framework of the present Internet site leading to other resources present
        on the Internet network could not engage the responsibility of
        Fitness Soccer Training. `
        ]
      },
      {
        TITLE: `INTELLECTUAL PROPERTY`,
        TEXT: [
        `All content on`,
        `including, but not
        limited to, graphics, images, texts, videos, animations, sounds, logos, gifs
        and icons, as well as their formatting, are the exclusive property of the
        company, with the exception of trademarks, logos or content belonging to
        other
        partner companies or authors. Any reproduction, distribution,
        modification, adaptation, retransmission or publication, even partially, of
        these various elements is strictly prohibited without the express written
        consent of Preparation pysique football. By any means whatsoever, this
        representation or reproduction constitutes an infringement punishable by
        articles L.335-2 under the Intellectual Property Code. The non-observance
        of this prohibition constitutes a counterfeit which can lead to the civil and
        penal liability of the counterfeiter. In addition, the owners of the copied
        Contents could initiate legal action against you.`,
      ]
      },
      {
        TITLE: `DECLARATION TO THE CNIL`,
        TEXT: `Under Law 78-17 of January 6, 1978 (modified by Law 2004-801 of August 6,
          2004 on the protection of individuals with regard to the processing of
          personal data) relating to computers, fichiers and freedoms, this site has
          not been declared to the National Commission on Informatics and Liberty
          (www.cnil.fr). `
      },
      {
        TITLE: `DISPUTES`,
        TEXT: [
          `The present conditions of the website `,
          ` are governed by French law and
          any dispute or litigation that may arise from the interpretation or
          execution of these conditions will be under the exclusive jurisdiction of the
          courts on which the company's registered office depends. The reference
          language for the settlement of any disputes is French.`,
        ],
      },
      {
        TITLE: `PERSONAL DATA`,
        TEXT: [
          `In general, you are not required to provide us with your personal data
          when you visit our website www.fitness-soccer-training.com`,

          `However, this principle has certain exceptions. Indeed, for certain
          services offered by our site, you may be required to provide us with
          certain data such as: your name, your email address. This is the case
          when you complete the form offered to you online, in the "contact"
          section or for the "newsletter". In any case, you can refuse to provide
          your personal data. In this case, you will not be able to use the services
          of the site, in particular that of soliciting information about our company,
          or of receiving newsletters.`,

          `You can unsubscribe from our newsletter at any time by clicking on the
          "unsubscribe" link in every email we send you.`,

          `You can also ask us to modify or delete your personal data. If we do not
          respect this commitment, you can file a complaint with the CNIL.`,
        ]
      },
      {
        TITLE: `CANCELLATION AND REFUND`,
        TEXT: [
          `Our primary ambition is to help you as much as possible and therefore to
          satisfy you. If you are not, we will give you a full refund with no time limit
          for digital products or physical preparation materials.
          In accordance with the legal provisions in force, you have an unlimited
          period from receipt of your products to exercise your right of withdrawal
          without having to justify reasons or pay a penalty.`,

          `The right of withdrawal can be exercised online on our email
          fitnesssoccertraining100@gmail.com, expressing the desire to withdraw.
          If you consider that the problem or dispute has not been resolved, you
          can contact a mediator at this address:
          https://www.mediationconso-ame.com/ or
          https://www.cnpm-mediation-consommation.eu/`
        ],
      },
      {
        TITLE: `TERMS AND CONDITIONS`,
        TEXT: `These General Conditions are intended to warn you in a clear and
        transparent manner of the conditions of sale, to comply with the law and
        above all to ensure that you are satisfied with your purchases.`,
      },
      {
        TITLE: `ARTICLE 1 – Application`,
        TEXT: [
          `These general conditions of sale apply to all purchases made on the
          website `,
          `It concerns the products and services marketed by the site: digital
          product and material. 
          Anyone making a purchase from the site is considered a "customer".`
        ]
      },
      {
        TITLE: `ARTICLE 2 – Orders and delivery`,
        TEXT: [
          `You can place your orders on the Internet via the "shop"`,
          `For digital products`,
          `Once your order has been entered on the site, a confirmation email will
          be sent to you and your order recorded. Upon receipt of this order, you
          will receive the necessary information to download the product or will
          directly receive the product in your mailbox. You agree to provide a valid 
          email address in order to receive your
          product. The electronic information products ordered by the customer
          can be used on his computer (he can also print them at his expense).`,
          `For physical preparation equipment`,
          `Our shipping times vary from 10 to 21 days depending on the items
          ordered. Shipping times depend on the availability of the products
          ordered (expressed in working days, excluding Saturdays, Sundays and
          public holidays) and are understood to start from the validation of your
          order.`,
          `The products offered are available while stocks last`,
          `In the event of a shipment delay, an email will be sent to inform you of a
          new delivery date. Note all the same,
          Fitness Soccer Training can not be responsible for
          late delivery due exclusively to unavailability of the customer after
          several appointment proposals by the carrier.`,
          `Fitness Soccer Training will be exempt from all
          liability only in the event of force majeure defined by the case law of
          French courts as an event of an unforeseeable nature during the
          conclusion of the contract and irresistible in its execution. In any event,
          in accordance with legal provisions, in the event of late delivery, you
          have the option of terminating the contract under the terms and
          conditions defined in Article L 216-1 of the Consumer Code.`,
          `You can of course contact us in the event of a problem (see contact on the site).`,
          `Delivery and responsibility`,
          `In the event of non-compliance upon receipt, you must notify the carrier
          and Fitness Soccer Training of any reservations
          about the delivered product (for example: damaged package, already
          open ...).`,
          `You have 3 days to make any reservations with the carrier in the event of
          a lack or damage. If the delivery is incomplete or a product is damaged,
          you have 2 possibilities :`,
          `1) You accept delivery, imperatively expressing reservations on the carrier's voucher.`,
          `2) You refuse the order in its entirety and enter the reason on the carrier's voucher.`,
          `When the customer personally takes delivery of the objects transported
          and when the carrier does not justify having given him the possibility of
          actually checking their good condition, the 3-day period mentioned in
          Article L. 133-3 of the Commercial Code which extinguishes any action
          against the carrier is extended to 10 days.`,
          `For deliveries outside mainland France, the customer agrees to pay all
          taxes due on the importation of products, customs duty, value added tax,
          and all other taxes due under the laws of the country of receipt of the
          order. Fitness Soccer Training de facto releases any legal responsibility if the
          payment of taxes was not made by the customer.`
        ]
      },
      {
        TITLE: `ARTICLE 3 – Availability of items`,
        TEXT: `As long as our offers and their prices are visible on the site, they are valid.`,
      },
      {
        TITLE: `ARTICLE 4 – Price`,
        TEXT: [
          `The prices indicated in euros are inclusive of all taxes and therefore net.
           The price is payable in full and in a single payment when ordering unless
          otherwise specified. All orders are invoiced in euros and payable in
          euros. Fitness Soccer Training reserves the right
          to change the prices of its products at any time.`,

          `In the case of digital products, no shipping costs will be charged insofar
          as the customer is responsible for himself and at his own expense to
          download the product from the website to his computer.`,

          `For physical preparation equipment, the shipping costs are noted before
          the order (in the basket) and depend on the customer's place of
          residence.`
        ],
      },
      {
        TITLE: `ARTICLE 5 – Payment`,
        TEXT: `Payments are made by credit card via our provider Stripe or otherwise
        using a Paypal account or by credit card via PayPal, the customer is
        subject to PayPal's terms of use. Even if we carefully select our service
        providers, the site cannot be held responsible for any failure related to
        the payment system.`,
      },
      {
        TITLE: `ARTICLE 6 – Refund of products`,
        TEXT: `Our primary ambition is to help my readers as much as possible and
        therefore to satisfy them. If you are not, we will give you a full refund with
        no time limit for digital products or physical preparation materials.`,
      },
      {
        TITLE: `ARTICLE 7 – Applicable law`,
        TEXT: `All of these general conditions of sale, as well as all the purchase and
        sale operations described therein, are subject to French law.`,
      },
      {
        TITLE: `ARTICLE 8 – Customer acceptance`,
        TEXT: `The present general conditions of sale as well as the prices are
        expressly approved and accepted by the customer, who declares and
        acknowledges having a perfect knowledge of them. He therefore waives
        the right to rely on any contradictory document and, in particular, on his
        own general conditions of purchase. The act of purchase implies
        acceptance of these general conditions of sale.`,
      },
      {
        TITLE: `ARTICLE 9 – Right of use and reproduction`,
        TEXT: [
          `All texts and illustrations contained in the products and services
          concerned are protected by copyright and database law, in accordance
        with the Intellectual Property Code.`,
          `The customer refrains from any use of the products and services for
        purposes other than purely documentary, as well as he refrains from
        publishing, distributing or selling, in any way whatsoever, the content to
        which he accesses. Use, reproduction, resale, or simple communication
        is strictly prohibited without the written consent of this site and its
        representatives and will systematically be the subject of legal
        proceedings.`,
          `Any other use not provided for by the Intellectual Property Code is
        subject to prior written authorization.`
        ],
      },
      {
        TITLE: `ARTICLE 10 – Liability`,
        TEXT: [
          `Our responsibility cannot be called into question, both vis-à-vis third
          parties and the customer, for the consequences of the use of research
        results by the customer or of omissions following an unsuccessful,
        defective, partial or incorrect, or the misuse of the responses and texts
        consulted.`,
          `Consequently, we cannot be held civilly liable towards the customer or
        third parties for any direct or indirect damage resulting from the use of
        the information, and in particular resulting from inaccurate or incomplete
        information.`,
          `Under no circumstances can we be held responsible for any damage of
        any kind, including operating loss, loss of data or any other financial loss
        resulting from the use or the inability to use the products and services
        referred to herein. In addition, no assistance provided free of charge in
            the use of the products and services can create any additional warranty
        with respect to these conditions. `
        ],
      },
      {
        TITLE: `ARTICLE 11 - Right of withdrawal`,
        TEXT: [
          `In accordance with the legal provisions in force, you have an unlimited
        period from receipt of your products to exercise your right of withdrawal
        without having to justify reasons or pay a penalty.`,
          `The right of withdrawal can be exercised online on our email
        prepaphysiquefootball@gmail.com, expressing the desire to withdraw.`,
          `In the event of depreciation of the product resulting from manipulations
        other than those necessary to establish the nature, characteristics and
        proper functioning of the product, you may be held liable.`,
          ` If you consider that the problem or dispute has not been resolved, you
          can contact a mediator at this address:`,
          `https://www.mediationconso-ame.com/ ou `,
          `https://www.cnpm-mediation-consommation.eu/`
        ],
      },
    ],
    SITE_WEB: 'Website:',
  },
  QUESTION: {
    BUTTON: "More info",
    FIRST: {
      TITLE: '1. PHYSICAL QUALITIES IN SOCCER',
      SQUARE: {
        TITLE: `Reminder: YOU WILL FIND INFORMATION ON ALL PHYSICAL QUALITIES ON THE BLOG`,
        ITEMS: {
          TEXT_1: `You can find a lot of information about
          <strong>speed work</strong> in soccer here >>>`,
          TEXT_2: `You can find a lot of information about
          <strong>endurance work in soccer</strong> here >>>`,
          TEXT_3: `You can find a lot of information about
          <strong>strength training in soccer</strong> here >>>`,
          TEXT_4: `You can find a lot of information about
          <strong
            >coordination and flexibility work in soccer</strong
          >
          here >>>`,
          TEXT_5: `You can find a lot of information about
          <strong>physical work with young footballers</strong>
          here >>>`,
        },
        LINKS: [
          'Speed',
          'Endurance',
          'Strength',
          'Coordination',
          'Youth',
        ],
        BUTTON: "More info",
      },
      LIST: [{
          id: 0,
          title: "What physical qualities should I work on in pre-season in amateur soccer?",
          description: `
            <p>
            The qualities of endurance (capacity and power), strength and speed are to be worked on before the championship resumes.
            </p>
            <div class="content">
              <p>
              We offer you an example of a 6 weeks program for the pre-season >>>
              </p>
            </div>`,
          link: "TreveEstivale",
        },
        {
          id: 1,
          title: "What physical qualities should I work on during the last training session before a game in amateur soccer?",
          description: `
            <p>
            The priority of the last training session before a match should logically be the preparation of the match.
            </p>
            <p>
            It is no longer a question of wanting to improve or develop physical qualities but rather to prepare for the game as best as possible.
            </p>
            <p>
            Some coaches will do a lot of tactical work, others a lot of technical work...
            </p>
            <p>
            but what about the physical work for this last training before the game?
            </p>
            <div class="content">
              <p>
              Answer here:
              </p>
            </div>`,
          link: "Unavailable",
        },
        {
          id: 2,
          title: "What are the main tests to evaluate endurance in soccer?",
          description: `
            <h5>"Continuous" tests</h5>
            <p>
              <strong>The Cooper Test</strong> is a field test that can be done around a track. It involves running as far as possible in 12 minutes. It can give an indication of the level of endurance and fitness of the players. The half-cooper (over 6 minutes) can give an indication of the continuous VMA of the players.
            </p>
            <p>
              <strong>The "VAMEVAL" test </strong> consists of running at a set speed using a tape. <br />
              The tape emits "beeps" at regular intervals.  <br />
              At each beep, the athlete must be at one of the markers placed on the track (every 20 meters).  <br />
              The speed increases by 0.5 km/h every minute, which corresponds to the crossing of a plateau. <br />
              The first level starts with a speed of 8 km/h.  <br />
              The test ends when the player has a delay of more than 3 m on 2 consecutive poles. It allows to determine the continuous VMA of the players.
            </p>
            <p>
              <strong>The "Light Luc" test </strong> consists of running back and forth until exhaustion between two lines spaced 20 meters apart at a pace that increases by 0.5 km/h every minute. 
              <br />
              This test is used to determine the continuous VMA of the players.
            </p>
            <h5>"Intermittent" tests</h5>
            <p>
              In the <strong>"Gacon 45/15" test</strong>, the players must perform a succession of 45-second runs interspersed with 15 seconds of recovery <br />
              The test begins at 8 Km/h or 10 Km/h (depending on the level).<br />
              The speed increases by 0.5 Km/h each minute. <br />
              It allows to determine the intermittent VMA of the players.
            </p>
            <div class="content">
              <p>
                The test we recommend: 
                <strong>the 30-15 iftIt </strong>
                <br />
                allows you to determine the intermittent VMA of players.  <br />
                More explanations here :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2021',
            id: 'This-is-the-most-adapted-MAS-test-in-football',
          },
        },
        {
          id: 3,
          title: "What warm-up before a soccer practice or game?",
          description: `
            <p>
              The warm-up is an important phase of preparation for the competition. It includes different psychological, technical and physiological aspects.
            </p>
            <p>
            The two physiological roles of the warm-up are injury prevention and performance preparation.
            </p>
            <div class="content">
              <p>
                We share more information in this article
              </p>
            </div>`,
            link: "Unavailable",
        },
        {
          id: 4,
          title: "Can physical work be done with a ball?",
          description: `
            <div class="content">
              <p>
                We give you all the explanations in the online training
              </p>
            </div>`,
          link: "Formation",
        },
        {
          id: 5,
          title: "Do you have any examples of physical training sessions?",
          links: [{
              description: `
            <div class="content">
              <p>
                You will find examples of sessions in some of the articles on the blog, but also in the programs offered (summer and winter breaks) as well as in the sessions over a season
              </p>
            </div>`,
              link: "SaisonSenior",
            },
            {
              description: `
            <div class="content">
              <p>
              and physical training cycles
              </p>
            </div>`,
              link: "CoachSenior",
            },
          ],
        },
        {
          id: 6,
          title: "What physical qualities are essential for a goalkeeper to develop?",
          description: `
            <p>
              All physical qualities are important to work on for a guard.
            </p>
            <div class="content">
              <p>
                We propose examples of speed sessions (reaction, explosiveness, gestures...) in the physical training cycles
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 7,
          title: "What physical qualities are essential to develop in futsal?",
          description: `
            <div class="content">
              <p>
                All physical qualities are important to work on in futsal.  <br />
                We propose examples of speed and endurance sessions in the physical training cycles
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 8,
          title: "What exercises should be offered to work on physical qualities such as speed, endurance or strength?",
          description: `
            <p>
            We offer many examples of exercises in some of our blog posts, in the free application or in our guides and programs.
            </p>
            <p>
            You should find your happiness on the site ;)
            </p>`,
        },
        {
          id: 9,
          title: "How to distribute the physical work in the week and in the session?",
          description: `
            <div class="content">
              <p>
                give you more explanations here
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2021',
            id: 'How-to-distribute-the-physical-work-in-the-week-and-in-the-session',
          },
        },
      ],
    },
    SECOND: {
      TITLE: '2. Injury prevention',
      LIST: [{
          id: 0,
          title: "What physical work should be done to avoid injury?",
          links: [{
              description: `            
                <p>
                  We also suggest that you have a check-up with an osteopath for example or a health professional during the summer break.
                </p>
                <p>
                  Preparing well before resuming training can also help prevent injuries (of which there are many during the resumption).
                </p>
                <div class="content">
                  <p>
                    Before each training session and match, a good warm-up would reduce the probability of having a muscle or joint injury. We give you more information here
                  </p>
                </div>`,
              link: "Unavailable",
            },
            {
              description: `
                <p>
                Secondly, it is important to have a healthy lifestyle and to recover well after training and games.
                </p>
                <p>
                A good recovery will allow you to be less tired and fresher for future training sessions and games.
                </p>
                <p>
                If you are fresh and not tired, the 
                  <span class="red">risk of injury is lower</span> .
                </p>
                <p>
                The training sessions must be adapted to the audience and progressive.
                </p>
                <p>
                For the specific prevention work in soccer, muscle strengthening, sheathing work or proprioception will have a place of choice in your programs.
                </p>`,
            },
          ],
        },
        {
          id: 1,
          title: "What physical program should I do after an injury?",
          description: `
          <p>
            This will depend on the type of injury, the affected joint or muscle, and other parameters specific to the injured person (age, level, etc.).
            </p>
            <p>
            Rehabilitation and reathletization will therefore be personalized.
            </p>
            <p>
              For this reason, we do not offer a reathletization program and we recommend that you call on a physical therapist and/or a physical trainer to help you in these phases before your return to the field.
            </p>
            <div class="content">
              <p>
              However, we do give you some tips for "not too serious" injuries (like a hamstring injury or an ankle sprain) in the blog. <br />
              More info here >>>
              </p>
            </div>`,
          link: "BlogCategory",
          paramId: "Prevention",
        },
        {
          id: 2,
          title: "How do I perform an ankle strapping before a training session or a game?",
          description: `
            <div class="content">
              <p>
                We have shared a video of Anais (osteopath) on our facebook page that might help you >>>
              </p>
            </div>`,
          external: "https://www.facebook.com/preparationphysiquefootball/posts/1178668345659478",
        },
        {
          id: 3,
          title: "What are the main causes of soccer injuries?",
          description: `
            <p>
            There are some factors that would favour the appearance of injuries. We present them to you:
            </p>
            <h5>Individual Factors</h5>
            <p>
              - Loss of muscular elasticity with age <br />
              - Deficit of muscular elasticity (limits the articular amplitudes necessary to the practice of sports)  <br />
              - Convalescence, flu, infectious state <br />
              - Psychological factors (great emotionality, stress)
            </p>
            <h5>Hygienic and dietary factors</h5>
            <p>
              - Insufficient hydration<br />
              - Lack of sleep <br />
              - Unbalanced diet <br />
              - Doping: anabolic drugs (promote the appearance of cramps, increase muscle volume without increasing the resistance of the tendons), corticosteroids (lower the pain threshold, weaken the tissues) <br />
              - Poor dental hygiene (bacteria from cavities that infiltrate the blood and create infections)  <br />
              - Smoking <br />
            </p>
            <h5>Factors related to training</h5>
            <p>
              - <span class="red">Absence or insufficiency of warm-up </span>
              <br />
              - Excessive or badly programmed training <br />
              -
              <span class="red"
                >Disharmonious agonist-antagonist musculation</span
              >
              <br />
              - <span class="red">Deficit of strength in eccentricity</span> <br />
              - Absence of recovery<br />
            </p>
            <h5>Environmental Factors</h5>
            <p>
              - Humidity <br />
              - Cold, wind <br />
              - Slippery floor <br />
            </p>`,
        },
        {
          id: 4,
          title: "What are the main injuries in soccer?",
          description: `
            
            <p>
              <span class="red"
                >Soccer is a risky sport in terms of injuries.
              </span>
            </p>
            <p>
              In a study conducted by UEFA during the 2012-2013 season that included 22 top European clubs, 892 injuries were recorded!
            </p>
            <h5 class="blue">What are these injuries?</h5>
            <p>
            According to a UEFA study, tears, strains and contractures account for 38% of footballers' injuries and the thigh is the member least spared from these injuries (80% for the hamstrings alone).
            </p>
            <p>
            We understand the importance of hamstring prevention work (a weakness in the hamstrings would also have an influence on knee injuries).
            </p>
            <p>
            25.4% of injuries involve the knee or ankle joints (mainly sprains) and 14% of footballer injuries involve the hips and groin (pubalgia).
            </p>
            <h5 class="blue">How do these injuries happen?</h5>
            <p>
            Here is an interesting table on the main actions leading to injury (Hawkins et al, 2001).
            </p>
            <img
              src="https://www.preparationphysiquefootball.com/images/blog/Dessin%20sans%20titre%20(23).jpg"
              class="img-fluid"
              alt="How do these injuries happen"
            />
            </br>
            <p>
            We notice that we are often injured "alone" (running, changing direction, hitting, jumping,...).
            </p>
            <p>
            Almost half of all in-game injuries occur in the last 30 minutes.
            </p>`,
        },
      ],
    },
    THIRD: {
      TITLE: '3. The youth',
      LIST: [{
          id: 0,
          title: "Why doing physical work with the youth?",
          description: `
            <p>
              Physical work in youth has 3 main objectives:
            </p>
            <p>
              - Performance improvement <br />
              - Injury prevention <br />
              - Preparation work <br />
            </p>
            <p>
              But it also allows :
            </p>
            <p>
            To develop the mental, the "hard" side of physical work... to pass levels, "to like to hurt oneself" seems to be compulsory...  <br />
            To give training  habits and attitudes <br />
            To tend towards autonomy and acquire certain essential values: rigor, discipline, work... <br />
            To make the young person "proactive", actor of his practice and not only "consumer"...  <br />
            To make the difference between "going to training" and "going to train"... <br />
            </p>`,
        },
        {
          id: 1,
          title: "What physical qualities should be worked on first with young soccer players?",
          description: `
            <p>
            For each physical quality, there would be key periods named <span class="red">"golden age"</span> .
            </p>
            <p>
              If we work well on a physical quality during this period, its development should be optimal! (the young person should progress a lot on this physical quality).
            </p>
            <br />
            <p>
              Taking into account the "golden ages" of development of physical qualities could be a precious help to target the physical qualities to develop in priority according to the age of the players (biological age).
            </p>
            <p>
              For example, from U6 to U12, coordination work is essential because we are in the golden age for the development of this quality.
            </p>
            <p>
              For strength development, one year after peak growth would be the optimal time.
            </p>
            <div class="content">
              <p>
                We give you more explanations on this topic in this article:
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2021',
            id: 'The-training-of-young-footballers',
          },
        },
        {
          id: 2,
          title: "How to develop young soccer players in an optimal way physically (and mentally)?",
          links: [{
              description: `
                <p>
                  We have seen that there are golden ages for the development of each of the physical qualities.
                </p>
                <p>There is also a "reference plan".</p>
                <p>
                  Coaches and researchers have established a "reference plan" that outlines the steps to follow during childhood and adolescence for optimal development.
                </p>
                <div class="content">
                  <p>
                    We give you more information in these articles:
                  </p>
                </div>`,
              link: "Article",
              article: {
                year: '2021',
                id: 'The-training-of-young-footballers',
              },
            },
          ],
        },
        {
          id: 3,
          title: "Should we do hard work among young footballers?",
          description: `
            <div class="content">
              <p>
                We give you more information in this article:
              </p>
            </div>`,
          link: "Unavailable",
        },
        {
          id: 4,
          title: "Should young soccer players stretch?",
          description: `
            <div class="content">
              <p>
                We give you more information in this article: :
              </p>
            </div>`,
          link: "Unavailable",
        },
        {
          id: 5,
          title: "Should physical work be integrated into youth?",
          description: `
            <div class="content">
              <p>
                You will find more explanations in the online guide (offered) on physical preparation for young soccer players >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 6,
          title: "•	Can young people exercise intensely?",
          description: `
            <div class="content">
              <p>
                You will find more explanations in the online guide (offered) on physical preparation for young soccer players >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 7,
          title: "What physical work to propose to U8-U9?",
          description: `
            <div class="content">
              <p>
                The work of motor coordination and speed are essential for these categories. <br />
                We propose examples of cycles and exercises to work on physical qualities >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 8,
          title: "What physical work to propose to U10-U11?",
          description: `
            <div class="content">
              <p>
                The work of motor coordination and speed are essential for these categories. <br />
                We propose examples of cycles and exercises to work on physical qualities >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 9,
          title: "What physical work to propose to U12-U13?",
          description: `
            <div class="content">
              <p>
                The work of speed is essential for these categories. We propose examples of cycles and exercises to work on this physical quality >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 10,
          title: "What physical work to propose to U14-U15?",
          description: `
            <div class="content">
              <p>
                Power endurance and speed work are essential for these categories. We propose examples of cycles and exercises to work on these physical qualities >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 11,
          title: "What kind of physical training should be offered to U16-U17 students?",
          description: `
            <div class="content">
              <p>
                Power endurance and speed work are essential for these categories. We propose examples of cycles and exercises to work on these physical qualities >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
      ],
    },
    FOURTH: {
      TITLE: '4. Mental preparation in soccer',
      LIST: [{
          id: 0,
          title: "What is mental preparation?",
          description: `
            <p>
              According to J. Fournier, mental preparation is defined as "preparation for competition by learning mental and cognitive skills, the main goal of which is to optimize the athlete's personal performance while promoting the pleasure of practice and encouraging the achievement of autonomy.
            </p>
            <p>
              Mental preparation will allow you to prepare for a match in the best possible way and to optimize your performance in competition.
            </p>
            <br />
            <h5 class="blue">
              What are the objectives of mental preparation?
            </h5>
            <br />
            <p>
              To allow the individual to become aware of the numerous mental parameters present in each activity, whether it is sporting or not, and to work on them to improve.
            </p>
            <p>
              The objective of mental preparation is also to develop, potentiate and optimize the mental capacities of the individual so that he/she can fully express his/her qualities (physical, technical and tactical) during training and competition or in everyday life and on the day of the event, and to allow him/her to be in optimal psychological conditions on D-day at H-hour in order to achieve a performance, autonomously. 
              <br />
              The goal of mental preparation is therefore to improve performance while preserving the balance and health of the individual. <br />
              For Denis Troch, it is a matter of "getting the best out of a potential!".
            </p>`,
        },
        {
          id: 1,
          title: "What methods are used in mental preparation?",
          description: `
            <p>
              Mental Preparation uses various methods and techniques, which can be of a Physiological (relaxation, respiratory control, meditation...), Cognitive (imagery, internal dialogue...), Behavioral (self-confidence, concentration, NLP...) or Composite (stress management, anxiety...) nature.
            </p>`,
        },
        {
          id: 2,
          title: "What are the interests and benefits of mental preparation?",
          description: `
            <p>
              The interests and advantages of working in mental preparation are numerous, both for the individual and for the group:
            </p>
            <h5><i>For the individual:</i></h5>
            <br />
            <ul class="list">
              <li>Improve your self-knowledge</li>
              <li>Improve motivation</li>
              <li>Improve your self-confidence</li>
              <li>Improve interpersonal and communication skills</li>
              <li>Improve your concentration</li>
              <li>Manage your emotions better</li>
              <li>Manage stress better</li>
              <li>Better recovery</li>
              <li>Fostering performance:</li>
              <li>Learning new gestures /Correcting gestures/</li>
              <li>
                Anchoring motor sensations /Anchoring positive memories/
              </li>
              <li>Limit the risk of injury</li>
              <li>Improve reathletization/rehabilitation</li>
            </ul>
            <br />
            <h5><i>For the group:</i></h5>
            <br />
            <ul class="list">
              <li>Develop group cohesion</li>
              <li>Bring out the different leaders</li>
              <li>Develop sports strategies</li>
              <li>
                Thanks to this work of mental preparation, we will be able to:
              </li>
              <li>reinforce successes and reduce errors</li>
              <li>
                fully express their physical, technical and tactical qualities in training and competition
              </li>
              <li>
                to be in optimal psychological conditions on D-day at H-hour
              </li>
            </ul>`,
        },
        {
          id: 3,
          title: "Who can use mental preparation?",
          description: `
              <p>
                Mental preparation is aimed at any athlete wishing to develop his abilities, reach his goals, improve his performance, restore his relationships with his coach, his teammates, become the actor of his performance ... but also to all coaches and all groups of athletes.
            </p>`,
        },
        {
          id: 4,
          title: "I am not a high level soccer player, can I do mental preparation?",
          description: `
            <p>
              Mental preparation aims at your well-being and should help you develop skills (concentration, motivation, self-confidence, emotional management...). You don't need to be a top athlete to know yourself better and want to improve your performance!
            </p>`,
        },
        {
          id: 5,
          title: "Is it difficult to use mental preparation?",
          description: `
            <p>
              No, we often have a wrong image of what mental preparation is but it is much simpler to apply than we imagine.
            </p>`,
        },
        {
          id: 6,
          title: "Do we have to believe in mental preparation to have positive results?",
          description: `
            <p>
              No, mental preparation is a discipline that stems from sports psychology research and has powerful and scientifically validated theories and tools to improve and optimize the mental skills and well-being of athletes!
            </p>`,
        },
        {
          id: 7,
          title: `I don't have any mental "problems", so is mental preparation useless?`,
          description: `
            <p>
            In France, we are far behind in the mental preparation of athletes and we often think that it is only useful when something goes wrong. <br />
            Abroad, many athletes call upon a mental trainer when everything is going well! Why? to strengthen and improve certain aspects related to the mental (concentration, emotional management, ...) <br />
            It's a bit like medicine, we use it when we are sick, others (the Chinese for example) use it when everything is fine!
            </p>
            <p>
            Just like the physical, the technical or the tactical, the mental can and must be trained! <br />
            It is even often the mental that makes the difference in competition! <br />
            It can be learned and developed!
            </p>
            <p>
              "I think it's the mental aspect that is the essential part of tennis but people don't know that. " <br />
              Boris Becker, former tennis player.
            </p>
            <p>
              "You have to be neither stressed out or you'll lose your nerve, nor too relaxed or you won't realize what's at stake. You have to find the right balance. When I left for OM and then Milan, I got used to having the right attitude: knowing how to be a fighter and remain lucid at the same time, but also putting on extra pressure without being in trouble. It's a question of balance."<br />
              Marcel Desailly (footballer).
            </p>
            <p>
              "The physical aspect of sports can only take you so far," said Shannon Miller, Olympic gymnastics champion in an interview with the Dana Foundation. "The mental aspect has to take over, especially when it comes to being the best of the best. In the Olympics, everyone is talented. Everyone trains hard. Everyone works hard. What makes the difference between a gold medalist and a silver medalist is simply the mental aspect."
            </p>`,
        },
      ],
    },
    FIFTH: {
      TITLE: '5. Management',
      LINK_1: 'Management',
      LIST: {
        FAQ_1: {
          TEXT: `You will find information on this theme of management on the blog`,
          BUTTON: "More info",
        },
        FAQ_2: {
          TEXT: `We also offer a management guide for amateur soccer coaches >>>`,
          BUTTON: "More info",
        },
      },
    },
    SIX: {
      TITLE: '6. Other',
      LIST: [{
          id: 0,
          title: "What physical preparation for a women's group?",
          description: `
            <p>
              We recommend you this book : ''La prépa physique soccer féminine - préparation athlétique, spécificités et prévention''
            </p>`,
        },
        {
          id: 1,
          title: "What should a soccer player eat?",
          description: `
            <div class="content">
              <p>
                We give you more explanations on the blog in the nutrition section
              </p>
            </div>`,
          link: "Unavailable",
        },
        {
          id: 2,
          title: "How to recover in soccer?",
          links: [{
              description: `
              <p>
                There are 3 main pillars:
              </p>
  
                <p>
                  Sleep.  <br />
                  We give you more information in this article: :
                </p>
  
              <br />
            `,
              link: "Unavailable",
            },

            {
              description: `
              <div class="content">
                <p>
                  Food. <br />
                  We give you more information in this article: :
                </p>
              </div>
              <br />
            `,
              link: "Unavailable",
            },
            {
              description: `
              <div class="content">
                <p>
                  Hydration. <br />
                  We give you more information in this article: :
                </p>
              </div>
              <br />
            `,
              link: "Unavailable",
            },
            {
              description: `
              <p>
                Then there are complementary techniques that can promote recovery such as:
              </p>
              <br />
              <div class="content">
                <p>
                  Cold.  <br />
                  We give you more information in this article: :
                </p>
              </div>
              <br />
            `,
              link: "Unavailable",
            },
            {
              description: `
              <div class="content">
                <p>
                  Electrostimulation. <br />
                  We give you more information in this article: :
                </p>
              </div>`,
              link: "Unavailable",
            },
            {
              description: `
              <div class="content">
                <p>
                  The scrubbing <br />
                  We give you more information in this article: :
                </p>
              </div>
              <br />
            `,
              link: "Unavailable",
            },
            {
              description: `
              <div class="content">
                <p>
                  Compression socks. <br />
                  We give you more information in this article: :
                </p>
              </div>
              <br />
            `,
              link: "Unavailable",
            },
          ],
        },
        {
          id: 3,
          title: "What equipment should be used for speed work?",
          description: `
              <p>
              It is quite possible to work on speed without equipment.
            </p>
            <p>
              You can do a multitude of exercises with just a few cones!
            </p>
            <p>For example, you can:</p>
            <ul class="list">
              <li>
                Vary the starts (from the back, from the side, in motion, in reverse, ...),
              </li>
              <li>
                You can vary the start triggers (sound, visual, ...),
              </li>
              <li>You can vary the distances,</li>
              <li>
                You can introduce changes of direction (staggered cones),
              </li>
              <li>You can introduce back and forth,</li>
              <li>
                You can introduce sprinting and reversing or changing direction,
              </li>
              <li>
                You can introduce "special cones" where the player has to perform a specific action (a turn of the cone for example),
              </li>
            </ul>
            <p>...</p>
            <p>
              So even without equipment you can come up with interesting things.
            </p>
            <p>
              To bring a little variety, novelty and a more playful side, it is possible to use material.
            </p>
            <p>You can introduce material for :</p>
            <ul class="list">
              <li>
                create resistance (power sled, elastics, parachute, weighted vest),
              </li>
              <li>
                to help or lighten the race (stretched elastic placed in the direction of the race), to create an obstacle (low hurdles, blocks, ...),
              </li>
              <li>
                for the start (for example, medicine ball in the hands in the position "chair" or squat, static 5 seconds, then drop the medicine ball and "explode" at the start, ...) to modify part of the race (place a rhythm scale after 10m of sprint, the player must slow down quickly, change supports on the scale, then start sprinting again for a few meters ...).
              </li>
            </ul>`,
        },
        {
          id: 4,
          title: "What kind of warm-up in soccer?",
          description: `
            <div class="content">
              <p>
                We give you more information in this article: :
              </p>
            </div>`,
          link: "Unavailable",
        },
      ],
    },
    SEVENTH: {
      TITLE: '7. What we offer to help you',
      LIST: {
        FAQ_1: {
          TEXT: `We offer you a blog with totally free information.`,
          BUTTON: "More info",
        },
        FAQ_2: {
          TEXT: `We offer sample programs for the summer and winter break.`,
          BUTTON: "More info",
        },
        FAQ_3: {
          TEXT: `We offer you a guide on the physical preparation of young footballers (free)`,
          BUTTON: "More info",
        },
        FAQ_4: {
          TEXT: `We offer you a PDF with examples of physical exercises to work on speed and endurance (offered)`,
          LINK: 'https://www.preparationphysiquefootball.com/documents/85-exercices-physiques-avec-ballon-ppf.pdf',
          BUTTON: "More info",
        },
        FAQ_5: {
          TEXT: `We offer you some ideas of exercises to work on the physical part`,
          BUTTON: "More info",
        },
        FAQ_6: {
          TEXT: `We offer you examples of programs for the season and training cycles for all categories`,
          BUTTON: "More info",
        },
        FAQ_7: {
          TEXT: `We offer training courses to improve your knowledge on several topics related to physical preparation in amateur soccer.`,
          BUTTON: "More info",
        },
      }
    },
    EIGHTH: {
      TITLE: '8. What we do not offer',
      CONTENT: {
        TEXT_1: `4-4-2, 4-3-3, 4-5-1, offensive-defensive animation, possession, defensive block, team block... We receive a lot of messages about these topics and about tactics in general, but we are "only" specialists in physical preparation and we want to stay in what we know well to offer you quality content.`,
        TEXT_2: `We recommend other very interesting sites that deal with the tactical part and that could meet your needs like <a href="https://planet.training/home" target="_blank">https://planet.training/home</a> or <a href="https://soccer-coaches.com" target="_blank">https://soccer-coaches.com</a>.`,
        TEXT_3: `Same thing for the questions which concern specifically the technical part like the work of passes, controls, dribbles, ...`,
        TEXT_4: `We often include technical or tactical work in our physical exercises (to work in a more integrated way) but we do not offer specific exercises on technique or tactics in soccer.`,
      }
    },
  },
  PROGRAMMES: {
    TITLE: 'OUR SPECIAL PROGRAMS',
    CARDS: [{
        title: "PRE-SEASON PROGRAM",
        subtitle: "GOALKEEPERS",
        text: `<p class="card-text">Your program at <span class="promo">12.90€</span> instead of <span class="price">19.90€</span></p>`,
        button: "Read more",
      },
      {
        title: "PRE-SEASON PROGRAM",
        subtitle: "FUTSAL",
        text: `<p class="card-text">Your program at <span class="promo">12.90€</span> instead of <span class="price">19.90€</span></p>`,
        button: "Read more",
      },
    ],
    COMMANDE: {
      TEXT_1: `After ordering, you will receive an email (within 5 minutes) with the link to access the online platform and your programs.`,
      TEXT_2: `All programs are available on mobile, tablet and computer.`,
    },
    END: {
      TEXT_1: `We want to provide you with quality content and content that helps and satisfies you. Therefore, all our trainings, programs or guides are 100% satisfied or your money back!`,
      TEXT_2: `If the content is not to your liking, just send us an email and we will refund you directly.`,
    }
  },
  FOOTER: {
    LINKS: ['Legal information', 'About us', 'Contact'],
    END: '© 2021 Soccer Fitness Training, All rights reserved.',
    END_MOBILE: `© 2021 Soccer Fitness Training, <br /> All rights reserved.`,
    FACEBOOK: 'https://www.facebook.com/Soccer-Fitness-Training-for-coaches-102255048793855',
    INSTAGRAM: 'https://www.instagram.com/soccer_training_for_coaches/',
  },
  PRODUCT_SHOP: {
    CONTENT: {
      TEXT_1: 'Access the',
      TEXT_2: 'directly after your order.',
    },
    BUTTON: 'Order',
    END: `By clicking on ORDER, you will be redirected to the online store to place your order.`,
  },
  CYCLES: {
    PROGRAMME: `The online program is available on mobile, tablet and computer.`,
    APRES: {
      TITLE: `After ordering, you will receive an email with the link to access the online guide.`,
      END: `The goal is to give you ideas and examples so that you have a basis on which to design your own cycles and exercises!`
    },
    GARANTIE: {
      TITLE: 'Satisfaction or Money Back Guarantee',
      SUBTITLE: 'If you are not completely satisfied, send a simple email for a refund!',
    },
    PAIEMENT: {
      TITLE: '100% secure payment',
      SUBTITLE: 'The payment is totally secured by recognized platforms like Paypal.',
    },
    CONTACT: {
      TEXT_1: "If you have any questions about the guide, don't hesitate to ask us",
      TEXT_2: 'on the chat (bottom right of the page) or on our',
      LINK: 'contact page'
    },
  },
  FUTSAL_P: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL POWER ENDURANCE`,
      SUBTITLE: `With 300 examples of endurance and speed exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Adapted to seniors",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for power endurance in futsal?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on power endurance in futsal?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
        We offer an online program with
        <span>2 cycles of 4 weeks of power endurance training</span>
        and 100 exercise ideas to work on endurance in futsal.
      </p>
      <p>
        This guide is intended for coaches of senior futsal teams who are looking for examples of training cycles and exercise ideas to work on power endurance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
      </p>`,
    APRES: {
      COL1: `<p>
          You will find 2 physical training cycles to work on power endurance in futsal. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
        </p>`,
      COL2: `<p>
          For this, you can vary the intensity, the volume, ... but also the exercises!
        </p>
        <p>
          We offer you more than 100 examples of exercises to work on your endurance (with and without a ball)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL "ENDURANCE POWER"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/4_9cbd9aa4-4698-4add-8d3e-1cb46b78a937_900x.png?v=1620591898",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-physical-training-in-futsal-special-endurance-power",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 AGAINST 4 WITH GOAL KEEPERS",
          text: `
        <p><i class="fas fa-check"></i> 4 zones on a full field.</p>
        <p><i class="fas fa-check"></i> Never more than 2 players from the same team in the same area.</p>
        <p><i class="fas fa-check"></i> Free play, 2 minutes of play, 6 games for example.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "4 VS 2 AND 6 VS 6 CONSERVATION",
          text: `
        <p><i class="fas fa-check"></i> Alternating between conservation in a numerical superiority in a small space (4 against 2) and conservation in a large space at 6 against 6.</p>
        <p><i class="fas fa-check"></i> 2 minutes at 4 against 2 (the 2 players at the pressing change every 30 seconds). 1 point scored for every 10 passes in a row.</p>
        <p><i class="fas fa-check"></i> 1 point scored when the pressing player gets the ball back.</p>
        <p><i class="fas fa-check"></i> Then directly followed by a game at 6 against 6 for 4 minutes.</p>
        <p><i class="fas fa-check"></i> Possibility to score after 10 passes in a row.</p>
        <p><i class="fas fa-check"></i> Then 4 on 2 for 2 minutes and again 4 minutes at 6 on 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2	AGAINST 2 WITH SUPPORT ON THE SIDES",
          text: `
        <p><i class="fas fa-check"></i> 2 teams of 4 players.</p>
        <p><i class="fas fa-check"></i> 2 players on the field, 2 players in support.</p>
        <p><i class="fas fa-check"></i> Change roles every minute for example.</p>
        <p><i class="fas fa-check"></i> 10 minutes for example.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITTENT "REVERSE"`,
          text: `
        <p><i class="fas fa-check"></i> Place 3-4 cones of different colors in a square as shown in the picture. The player must go around the colored cone that his partner announces.</p>
        <p><i class="fas fa-check"></i> "green" and the player backs up to the green cone, goes around it and then quickly returns to the starting point... as soon as the player is back in the center the partner announces the new color... don't let him rest...</p>
        <p><i class="fas fa-check"></i> The player must take the information (colors) before starting to be able to follow very quickly.</p>
        <p><i class="fas fa-check"></i> Work in pairs (one doing the exercise, the other announcing the colors).</p>
        <p><i class="fas fa-check"></i> 20 seconds of work - 20 seconds of rest. 7 minutes for example.</p>
        <p><i class="fas fa-check"></i> Vary the squares (different distances, different colors, etc.).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_P_U15: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL POWER ENDURANCE`,
      SUBTITLE: `With 300 examples of endurance and speed exercises`,
      SUBSUB: "For U15-U18",
    },
    CHECK: {
      TITLE: "Adapted to U15-U18",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for power endurance in futsal?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on power endurance in futsal?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
        We offer an online program with
        <span>2 cycles of 4 weeks of power endurance training</span>
        and 100 exercise ideas to work on endurance in futsal.
      </p>
      <p>
        This guide is intended for coaches of senior futsal teams who are looking for examples of training cycles and exercise ideas to work on power endurance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
      </p>`,
    APRES: {
      COL1: `<p>
          You will find 2 physical training cycles to work on power endurance in futsal. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
        </p>`,
      COL2: `<p>
          For this, you can vary the intensity, the volume, ... but also the exercises!
        </p>
        <p>
          We offer you more than 100 examples of exercises to work on your endurance (with and without a ball)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL "ENDURANCE POWER"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/69_1080x.png?v=1619856076",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-en-futsal-special-endurance-puissance",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 AGAINST 4 WITH GOAL KEEPERS",
          text: `
        <p><i class="fas fa-check"></i> 4 zones on a full field.</p>
        <p><i class="fas fa-check"></i> Never more than 2 players from the same team in the same area.</p>
        <p><i class="fas fa-check"></i> Free play, 2 minutes of play, 6 games for example.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "4 VS 2 AND 6 VS 6 CONSERVATION",
          text: `
        <p><i class="fas fa-check"></i> Alternating between conservation in a numerical superiority in a small space (4 against 2) and conservation in a large space at 6 against 6.</p>
        <p><i class="fas fa-check"></i> 2 minutes at 4 against 2 (the 2 players at the pressing change every 30 seconds). 1 point scored for every 10 passes in a row.</p>
        <p><i class="fas fa-check"></i> 1 point scored when the pressing player gets the ball back.</p>
        <p><i class="fas fa-check"></i> Then directly followed by a game at 6 against 6 for 4 minutes.</p>
        <p><i class="fas fa-check"></i> Possibility to score after 10 passes in a row.</p>
        <p><i class="fas fa-check"></i> Then 4 on 2 for 2 minutes and again 4 minutes at 6 on 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2	AGAINST 2 WITH SUPPORT ON THE SIDES",
          text: `
        <p><i class="fas fa-check"></i> 2 teams of 4 players.</p>
        <p><i class="fas fa-check"></i> 2 players on the field, 2 players in support.</p>
        <p><i class="fas fa-check"></i> Change roles every minute for example.</p>
        <p><i class="fas fa-check"></i> 10 minutes for example.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITTENT "REVERSE"`,
          text: `
        <p><i class="fas fa-check"></i> Place 3-4 cones of different colors in a square as shown in the picture. The player must go around the colored cone that his partner announces.</p>
        <p><i class="fas fa-check"></i> "green" and the player backs up to the green cone, goes around it and then quickly returns to the starting point... as soon as the player is back in the center the partner announces the new color... don't let him rest...</p>
        <p><i class="fas fa-check"></i> The player must take the information (colors) before starting to be able to follow very quickly.</p>
        <p><i class="fas fa-check"></i> Work in pairs (one doing the exercise, the other announcing the colors).</p>
        <p><i class="fas fa-check"></i> 20 seconds of work - 20 seconds of rest. 7 minutes for example.</p>
        <p><i class="fas fa-check"></i> Vary the squares (different distances, different colors, etc.).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL SPEED`,
      SUBTITLE: `With 100 examples of endurance and speed exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Adapted to seniors",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for speed work in futsal?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on speed in futsal?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>2 cycles of 4 weeks of speed training </span>
            and 100 ideas of exercises to work on speed in futsal.
          </p>
          <p>
            This guide is intended for coaches of senior futsal teams who are looking for examples of training cycles and ideas for speed drills.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
          </p>`,
    APRES: {
      COL1: `<p>
              You will find 2 physical training cycles to work on speed in futsal. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
            For this, you can vary the intensity, the volume, ... but also the exercises!
          </p>
          <p>
            We offer you more than 100 examples of exercises to work on speed (with and without ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING IN SPECIAL "SPEED FUTSAL"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/6_46a1b4b3-9755-4245-b862-d5f19e0d5718_900x.png?v=1620593285",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-physical-training-in-special-speed-futsal",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "SPEED WITH CHANGE OF DIRECTION AND DUEL",
          text: `
        <p><i class="fas fa-check"></i> At the top (announcement of a color), the players start by passing through the announced color (small square with 4 colors), then must pass behind the cone of the announced color. Then duel or hit.</p>
        <p><i class="fas fa-check"></i> You can vary the triggers (sound, visual, ...) to work on the reaction time.</p>
        <p><i class="fas fa-check"></i> You can also vary the starting positions (side, back, jogging...)</p>
        <p><i class="fas fa-check"></i> Example: You show the color yellow: start on the yellow side and pass behind the yellow cone.</p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "SUPPORT-STRENGTH + REACTION WORK",
          text: `
        <p><i class="fas fa-check"></i> The defending players work on strength (jumping with hurdles, plyometrics) and then have the objective of preventing the attacker from scoring.</p>
        <p><i class="fas fa-check"></i> The attacker accelerates and then has to score.</p>
        <p><i class="fas fa-check"></i> 2 choices of course (the coach announces a color).</p>
        <p><i class="fas fa-check"></i> You can change the strength work to coordination or support work, or frequency work, or simply acceleration with braking...</p>
        <p><i class="fas fa-check"></i> You can vary the distances, propose several courses, vary the starts (stopped, moving, back, sitting...), ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "REACTION AND DUEL SPEED",
          text: `
        <p><i class="fas fa-check"></i> 2 teams. Each player has a number.</p>
        <p><i class="fas fa-check"></i> The coach announces a number.</p>
        <p><i class="fas fa-check"></i> The two players whose number has been announced must react quickly and sprint to the ball (the ball is placed in the center or sent by the coach to different places on the field at each new announcement).</p>
        <p><i class="fas fa-check"></i> The first player on the ball becomes an attacker and must go and stop the ball in the opponent's zone. The other player becomes a defender and must stop him.</p>
        <p><i class="fas fa-check"></i> Variation: the coach announces 2 or 3 numbers (2 against 2, 3 against 3).</p>
        <p><i class="fas fa-check"></i> Another variation: the player who attacks automatically becomes a defender (attack-defense transition work).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `SPRINT REPETITIONS`,
          text: `
        <p><i class="fas fa-check"></i> Pacman game.</p>
        <p><i class="fas fa-check"></i> Draw lines (with cones for example) on a half of the field (or an area adapted to the number of players available).</p>
        <p><i class="fas fa-check"></i> Players must always run along a line.</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V_U15: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING IN FUTSAL SPECIAL SPEED`,
      SUBTITLE: `With 100 examples of endurance and speed exercises`,
      SUBSUB: "For U15-U18",
    },
    CHECK: {
      TITLE: "Adapted to U15-U18",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for speed work in futsal?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on speed in futsal?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>2 cycles of 4 weeks of speed training </span>
            and 100 ideas of exercises to work on speed in futsal.
          </p>
          <p>
            This guide is intended for coaches of senior futsal teams who are looking for examples of training cycles and ideas for speed drills.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
          </p>`,
    APRES: {
      COL1: `<p>
              You will find 2 physical training cycles to work on speed in futsal. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
            For this, you can vary the intensity, the volume, ... but also the exercises!
          </p>
          <p>
            We offer you more than 100 examples of exercises to work on speed (with and without ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 PHYSICAL TRAINING CYCLES IN FUTSAL SPECIAL "SPEED" U14 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/7_cd8d07d9-0ab0-42a0-bf67-647bb9aa5b78_900x.png?v=1620605372",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-physical-training-cycles-in-futsal-special-speed-u14-u18",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "SPEED WITH CHANGE OF DIRECTION AND DUEL",
          text: `
        <p><i class="fas fa-check"></i> At the top (announcement of a color), the players start by passing through the announced color (small square with 4 colors), then must pass behind the cone of the announced color. Then duel or hit.</p>
        <p><i class="fas fa-check"></i> You can vary the triggers (sound, visual, ...) to work on the reaction time.</p>
        <p><i class="fas fa-check"></i> You can also vary the starting positions (side, back, jogging...)</p>
        <p><i class="fas fa-check"></i> Example: You show the color yellow: start on the yellow side and pass behind the yellow cone.</p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "SUPPORT-STRENGTH + REACTION WORK",
          text: `
        <p><i class="fas fa-check"></i> The defending players work on strength (jumping with hurdles, plyometrics) and then have the objective of preventing the attacker from scoring.</p>
        <p><i class="fas fa-check"></i> The attacker accelerates and then has to score.</p>
        <p><i class="fas fa-check"></i> 2 choices of course (the coach announces a color).</p>
        <p><i class="fas fa-check"></i> You can change the strength work to coordination or support work, or frequency work, or simply acceleration with braking...</p>
        <p><i class="fas fa-check"></i> You can vary the distances, propose several courses, vary the starts (stopped, moving, back, sitting...), ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "REACTION AND DUEL SPEED",
          text: `
        <p><i class="fas fa-check"></i> 2 teams. Each player has a number.</p>
        <p><i class="fas fa-check"></i> The coach announces a number.</p>
        <p><i class="fas fa-check"></i> The two players whose number has been announced must react quickly and sprint to the ball (the ball is placed in the center or sent by the coach to different places on the field at each new announcement).</p>
        <p><i class="fas fa-check"></i> The first player on the ball becomes an attacker and must go and stop the ball in the opponent's zone. The other player becomes a defender and must stop him.</p>
        <p><i class="fas fa-check"></i> Variation: the coach announces 2 or 3 numbers (2 against 2, 3 against 3).</p>
        <p><i class="fas fa-check"></i> Another variation: the player who attacks automatically becomes a defender (attack-defense transition work).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `SPRINT REPETITIONS`,
          text: `
        <p><i class="fas fa-check"></i> Pacman game.</p>
        <p><i class="fas fa-check"></i> Draw lines (with cones for example) on a half of the field (or an area adapted to the number of players available).</p>
        <p><i class="fas fa-check"></i> Players must always run along a line.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING FOR GOALKEEPERS SPECIAL SPEED`,
      SUBTITLE: `With 100 examples of speed exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Suitable for senior goal keepers",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for speed work for goal keepers?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more speed drills for goal keepers?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
        We offer an online program with <span>2 cycles of 4 weeks of speed training</span> and 100 ideas of exercises to work on speed.
      </p>
      <p>
        This guide is for goal keeper coaches who are looking for sample training cycles and drill ideas to work on speed.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
      </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles of physical training to work on speed. For example, you can propose the first cycle in the first part of the season and the second cycle in the second part of the season. To make progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
        </p>`,
      COL2: `<p>
          For this, you can vary the intensity, the volume, ... but also the exercises!
        </p>
        <p>
          We offer you more than 100 examples of exercises to work on speed (with and without ball).
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING FOR GOALKEEPERS SPECIAL "SPEED`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/5_9dc29907-a5e0-4b1f-b3c4-e6543ef13531_900x.png?v=1620593162",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-physical-training-for-goalkeepers-special-speed",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "REACTION, FREQUENCY THEN REACTION",
          text: `
        <p><i class="fas fa-check"></i> The goal keeper starts with a reaction work by touching the post of the color announced by the coach, then frequency work (2 supports between the slats) then he must react quickly to get the ball by diving on the side of the color you announced.</p>
        <p><i class="fas fa-check"></i> You can vary the triggers (sound, visual, ...) to work on the reaction time.</p>
        <p><i class="fas fa-check"></i> You can also vary the starting positions (side, back, sitting...)</p>
        <p><i class="fas fa-check"></i> 6 to 8 times. 45 seconds of recovery between each passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "REACTION SPEED AND GESTURE SPEED",
          text: `
        <p><i class="fas fa-check"></i> The goal keeper is about 1m50 - 2m away.</p>
        <p><i class="fas fa-check"></i> You have a ball in each hand.</p>
        <p><i class="fas fa-check"></i> You drop one of the 2 balloons.</p>
        <p><i class="fas fa-check"></i> The goal keeper must touch the ball before it hits the ground.</p>
        <p><i class="fas fa-check"></i> You can announce colors of cones that the goal keeper must touch to destabilize him before releasing a ball.</p>
        <p><i class="fas fa-check"></i> 8 to 10 times. 20 seconds of recovery.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "REACTION SPEED AND GESTURE SPEED",
          text: `
        <p><i class="fas fa-check"></i> Place cones or small studs randomly a few feet from the cage.</p>
        <p><i class="fas fa-check"></i> The goal keeper starts by reacting and touching the peg of the color announced by the coach, then moves back and stops the coach's shot.</p>
        <p><i class="fas fa-check"></i> Low kick, the ball can hit a cone and have a deflected trajectory.</p>
        <p><i class="fas fa-check"></i> About ten times.</p>
        <p><i class="fas fa-check"></i> 20 seconds of recovery between each passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLYOMETRICS + DIVES",
          text: `
        <p><i class="fas fa-check"></i> 4 "thigh" jumps (ﬂexed legs) over hurdles then horizontal dives.</p>
        <p><i class="fas fa-check"></i> 6 to 8 times. 45 seconds of recovery.</p>
        <p><i class="fas fa-check"></i> The quality of the jumps must be the objective.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V_U15: {
    FIRST: {
      TITLE: `2 CYCLES OF PHYSICAL TRAINING FOR GOALKEEPERS SPECIAL SPEED`,
      SUBTITLE: `With 100 examples of speed exercises`,
      SUBSUB: "For U15-18",
    },
    CHECK: {
      TITLE: "Suitable for U15-18 goal keepers",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for speed work for goal keepers?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more speed drills for goal keepers?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
        We offer an online program with <span>2 cycles of 4 weeks of speed training</span> and 100 ideas of exercises to work on speed.
      </p>
      <p>
        This guide is for goal keeper coaches who are looking for sample training cycles and drill ideas to work on speed.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
      </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles of physical training to work on speed. For example, you can propose the first cycle in the first part of the season and the second cycle in the second part of the season. To make progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
        </p>`,
      COL2: `<p>
          For this, you can vary the intensity, the volume, ... but also the exercises!
        </p>
        <p>
          We offer you more than 100 examples of exercises to work on speed (with and without ball).
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING FOR GOALKEEPERS SPECIAL "SPEED"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/5_9dc29907-a5e0-4b1f-b3c4-e6543ef13531_900x.png?v=1620593162",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-physical-training-for-goalkeepers-special-speed",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "REACTION, FREQUENCY THEN REACTION",
          text: `
        <p><i class="fas fa-check"></i> The goal keeper starts with a reaction work by touching the post of the color announced by the coach, then frequency work (2 supports between the slats) then he must react quickly to get the ball by diving on the side of the color you announced.</p>
        <p><i class="fas fa-check"></i> You can vary the triggers (sound, visual, ...) to work on the reaction time.</p>
        <p><i class="fas fa-check"></i> You can also vary the starting positions (side, back, sitting...)</p>
        <p><i class="fas fa-check"></i> 6 to 8 times. 45 seconds of recovery between each passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "REACTION SPEED AND GESTURE SPEED",
          text: `
        <p><i class="fas fa-check"></i> The goal keeper is about 1m50 - 2m away.</p>
        <p><i class="fas fa-check"></i> You have a ball in each hand.</p>
        <p><i class="fas fa-check"></i> You drop one of the 2 balloons.</p>
        <p><i class="fas fa-check"></i> The goal keeper must touch the ball before it hits the ground.</p>
        <p><i class="fas fa-check"></i> You can announce colors of cones that the goal keeper must touch to destabilize him before releasing a ball.</p>
        <p><i class="fas fa-check"></i> 8 to 10 times. 20 seconds of recovery.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "REACTION SPEED AND GESTURE SPEED",
          text: `
        <p><i class="fas fa-check"></i> Place cones or small studs randomly a few feet from the cage.</p>
        <p><i class="fas fa-check"></i> The goal keeper starts by reacting and touching the peg of the color announced by the coach, then moves back and stops the coach's shot.</p>
        <p><i class="fas fa-check"></i> Low kick, the ball can hit a cone and have a deflected trajectory.</p>
        <p><i class="fas fa-check"></i> About ten times.</p>
        <p><i class="fas fa-check"></i> 20 seconds of recovery between each passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLYOMETRICS + DIVES",
          text: `
        <p><i class="fas fa-check"></i> 4 "thigh" jumps (ﬂexed legs) over hurdles then horizontal dives.</p>
        <p><i class="fas fa-check"></i> 6 to 8 times. 45 seconds of recovery.</p>
        <p><i class="fas fa-check"></i> The quality of the jumps must be the objective.</p>
        `,
        },
      ],
    },
  },
  AVEC: {
    FIRST: {
      TITLE: `2 PHYSICAL TRAINING CYCLES 100% WITH BALL`,
      SUBTITLE: `With 300 examples of endurance and speed exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Adapted to seniors",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for power endurance or speed with a ball?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on endurance and speed with a ball?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with 
            <span>2 cycles of physical training "100% with ball"</span>
            and 300 examples of endurance and speed exercises.
          </p>
          <p>
            This guide is for senior team coaches who are looking for sample training cycles and exercise ideas to work on endurance and speed with the ball.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles (of 4 weeks) of physical trainings to work on the physical with ball. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
            For this, you can vary the intensity, the volume, ... but also the exercises!
          </p>
          <p>
            We offer you more than 300 examples of exercises to work on endurance and speed (with and without a ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING "100% WITH THE BALL"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/1_2d8cf011-d953-4dba-8341-4b119a028929_900x.png?v=1620591290",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-physical-training-100-with-the-ball",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "1 VS 1 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> In the form of a mini-tournament (uphill-downhill) with evolution of the games.</p>
        <p><i class="fas fa-check"></i> Winners move up to ﬁnish on court 1. Losers move down, with the last court being court 4 (or 5 or 6 depending on the number of players present).</p>
        <p><i class="fas fa-check"></i> Field 4: Ball retention (the player who keeps the ball the longest wins).</p>
        <p><i class="fas fa-check"></i> Field 3: stop ball in the red zone.</p>
        <p><i class="fas fa-check"></i> Field 2: The player must pass between the opposing studs (red or blue).</p>
        <p><i class="fas fa-check"></i> Field 1: score in the opponent's small goal. The intensity must be high.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "4 VS 1 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> 5 squares.</p>
        <p><i class="fas fa-check"></i> 4 against 1 in each square.</p>
        <p><i class="fas fa-check"></i> Of the 12 yellow players who must keep the ball, 4 players (white arrows) play in 2 squares at the same time.</p>
        <p><i class="fas fa-check"></i> These 4 players must never receive 2 balls at the same time.</p>
        <p><i class="fas fa-check"></i> You can vary the size of the squares, give specific instructions, propose other constraints, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Avec right-1.png",
          title: "1 VS 1 AND SPEED OF REACTION",
          text: `
        <p><i class="fas fa-check"></i> 1	against 1.</p>
        <p><i class="fas fa-check"></i> Objective: to score in the opponent's camp (zone).</p>
        <p><i class="fas fa-check"></i> 2	teams.</p>
        <p><i class="fas fa-check"></i> Each player has a number.</p>
        <p><i class="fas fa-check"></i> The coach announces a number and sends the ball to a part of the field. The first player on the ball becomes an attacker and has to go and score in his opponent's camp.</p>
        <p><i class="fas fa-check"></i> The other player must defend.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "4 VS 4 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> 3	teams.</p>
        <p><i class="fas fa-check"></i> A team that attacks (in red) and chooses the team against which it will attack (against white or yellow).</p>
        <p><i class="fas fa-check"></i> If they score, they attack the other team. If they lose the ball, they go on defense and the other team goes on offense.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL POWER ENDURANCE TRAINING`,
      SUBTITLE: `With 200 examples of endurance exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Adapted to seniors",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for power endurance work?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on your endurance?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>2 cycles of 4 weeks of power endurance training </span>
            and 200 exercise ideas to work on endurance.
          </p>
          <p>
            This guide is for goal keeper coaches who are looking for sample training cycles and drill ideas to work on speed.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part about endurance
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on power endurance work (with 2 sessions per week or 16 sessions to work on power endurance)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas for exercises to work on endurance without equipment or with small equipment (that all clubs can have, and all with ball)
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles (of 4 weeks) of physical training to work on power endurance. You can propose for example the first one cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
            For this, you can vary the intensity, the volume, ... but also the exercises!
          </p>
          <p>
            We offer you more than 200 examples of exercises to work on your endurance (with and without a ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF PHYSICAL TRAINING SPECIAL "ENDURANCE POWER"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/4_9cbd9aa4-4698-4add-8d3e-1cb46b78a937_900x.png?v=1620591898",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-physical-training-in-futsal-special-endurance-power",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/FutPui left-1.png",
          title: "1 VS 1 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> In the form of a mini-tournament (uphill-downhill) with evolution of the games.</p>
        <p><i class="fas fa-check"></i> Winners move up to ﬁnish on court 1. Losers move down, with the last court being court 4 (or 5 or 6 depending on the number of players present).</p>
        <p><i class="fas fa-check"></i> Field 4: Ball retention (the player who keeps the ball the longest wins). </p>
        <p><i class="fas fa-check"></i> Field 3: stop ball in the red zone.</p>
        <p><i class="fas fa-check"></i> Field 2: The player must pass between the opposing studs (red or blue).</p>
        <p><i class="fas fa-check"></i> Field 1: score in the opponent's small goal. The intensity must be high.</p>
        `,
        },
        {
          img: "Cycles/FutPui left-2.png",
          title: "4 VS 1 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> 5	square.</p>
        <p><i class="fas fa-check"></i> 4 against 1 in each square.</p>
        <p><i class="fas fa-check"></i> Of the 12 yellow players who must keep the ball, 4 players (white arrows) play in 2 squares at the same time.</p>
        <p><i class="fas fa-check"></i> These 4 players must never receive 2 balls at the same time.</p>
        <p><i class="fas fa-check"></i> You can vary the size of the squares, give specific instructions, propose other constraints, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "5 VS 2 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> 3 zones. </p>
        <p><i class="fas fa-check"></i> The team in numerical superiority must pass the 3 ''steps'' to score a point.p>
        <p><i class="fas fa-check"></i> The team on the power play must complete 8 passes before they can move to the next zone.</p>
        <p><i class="fas fa-check"></i> In each zone, 2 new players at the pressing.</p>
        <p><i class="fas fa-check"></i> The team on the power play has for example 2 minutes to pass the 3 steps and score a point.</p>
        <p><i class="fas fa-check"></i> Each time the ball is lost, it starts again from the starting zone.</p>
        `,
        },
        {
          img: "Cycles/FutPui right-2.png",
          title: `4 VS 4 REDUCED GAMES`,
          text: `
        <p><i class="fas fa-check"></i> 3 teams.</p>
        <p><i class="fas fa-check"></i> A team that attacks (in red) and chooses the team against which it will attack (against white or yellow).</p>
        <p><i class="fas fa-check"></i> If they score, they attack the other team. If they lose the ball, they go on defense and the other team goes on offense.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE_J: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL ENDURANCE PUISSANCE`,
      SUBTITLE: `U15-U18`,
      SUBSUB: `Avec 200 exemples d'exercices de vitesse`,
    },
    CHECK: {
      TITLE: "Adaptés aux U15-U18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance chez les U15-U18 ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance chez les U15-U18 ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
            et 200 idées d'exercices pour travailler l'endurance.  
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U15-U18 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail d'endurance puissance (avec 2 séances par semaine soit 16 séances pour travailler l'endurance puissance)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles (de 4 semaines) d'entraînements physiques pour travailler l'endurance puissance chez les U15-U18. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL PHYSICAL TRAINING "POWER ENDURANCE" U15 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/5_6e8430c2-4241-47e3-81cd-b952905afab3_900x.png?v=1620604908",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-special-physical-training-power-endurance-u15-u18",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JEUX RÉDUITS 1 VS 1",
          text: `
        <p><i class="fas fa-check"></i> Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
        <p><i class="fas fa-check"></i> Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
        <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
        <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
        <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
        <p><i class="fas fa-check"></i> Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JEUX RÉDUITS 4 VS 1",
          text: `
        <p><i class="fas fa-check"></i> 5 carrés.</p>
        <p><i class="fas fa-check"></i> 4 contre 1 dans chaque carré.</p>
        <p><i class="fas fa-check"></i> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps.</p>
        <p><i class="fas fa-check"></i> Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "JEUX RÉDUITS 5 VS 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zones. </p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit passer les 3 ''étapes'' pour marquer un point.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit faire 8 passes avant de pouvoir passer à la zone suivante.</p>
        <p><i class="fas fa-check"></i> Dans chaque zone, 2 nouveaux joueurs au pressing.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique a par exemple 2 minutes pour passer les 3 étapes et marquer un point.</p>
        <p><i class="fas fa-check"></i> A chaque perte de balle, elle recommence de la zone de départ.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JEUX RÉDUITS 4 VS 4",
          text: `
        <p><i class="fas fa-check"></i> 3 équipes.</p>
        <p><i class="fas fa-check"></i> Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
        <p><i class="fas fa-check"></i> Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
        `,
        },
      ],
    },
  },
  COORDINATION: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL COORDINATION PHYSICAL TRAINING`,
      SUBTITLE: `U8-U12`,
      SUBSUB: `With 200 examples of motor coordination exercises`,
    },
    IMAGES: [
      "Cycles/Coordination grupo 187.png",
      "Cycles/Coordination grupo 188.png",
      "Cycles/Coordination grupo 189.png",
    ],
    CHECK: {
      TITLE: "Suitable for U8-U12",
      CHECKS: [
        `You are sometimes in need of ideas to bring something new to your training sessions?`,
        `Would you like to have examples of training cycles of coordination work for the U8-U12?`,
        `Would you like to save time in creating your sessions (and not search the internet for hours for new exercises)?`,
        `Would you like to know more exercises that work on coordination and motor skills?`,
        `Would you like to see more motivation and progress in your players when they do exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with 
            <span>2 cycles of 4 weeks of coordination workouts</span>
             and 200 exercise ideas to work on coordination and motor skills
          </p>
          <p>
            This guide is for coaches of U8-U12 teams who are looking for sample training cycles and exercise ideas to work on coordination.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an “explanations” section on coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on coordination work (with 2 sessions per week, i.e. 16 sessions to work on coordination)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas of exercises to work on coordination without equipment or with some equipment (that all clubs have, and all with a ball)
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles of physical training to work on speed. For example, you can use the first cycle in the first part of the season and the second cycle in the second part of the season. To progress, it is sometimes (or often) necessary to bring in variety and novelty in order to surprise the bodies (source of progress) and to avoid monotony (loss of motivation, overtraining ...)
          </p>`,
      COL2: `<p>
            To do this, you can vary the intensities, the length of exercise, ... but also the exercises!
          </p>
          <p>
            We are offering more than 200 examples of exercises to work on speed (with and without a ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL PHYSICAL TRAINING "COORDINATION" U8 - U12`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/1_c5beaa0f-808e-4b71-b7e4-762b46db47da_900x.png?v=1620604498",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-special-physical-training-coordination-u8-u12",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [
        {
          img: "Cycles/Coordination left-1.png",
          title: "PASSING AND COORDINATION",
          text: `
        <p><i class="fas fa-check"></i> The player passes the ball then completes a footwork exercise and takes the place of the partner to whom he passed.</p>
        <p><i class="fas fa-check"></i> A different exercise each time.</p>
        `,
        },
        {
          img: "Cycles/Coordination left-2.png",
          title: "BODY SCHEMA LEARNING",
          text: `
        <p><i class="fas fa-check"></i> Motor skills course.</p>
        <p><i class="fas fa-check"></i> The player begins with a side-stepping slalom(red cone), then unilateral foot propulsion in the yellow hoops</p>
        <p><i class="fas fa-check"></i> Then the player must crawl under a hurdle.</p>
        <p><i class="fas fa-check"></i> While stepping in the yellow hoops, jump above the red hurdle, then under the hurdle, then above the red hurdle.</p>
        <p><i class="fas fa-check"></i> Slalom in backward skips (blue cones) and finally small steps to go around the green hoops.</p>
        `,
        },
      ],
      EXERCICESR: [
        {
          img: "Cycles/Coordination right-1.png",
          title: "EYE TO LOWER LIMBS DISSOCIATION COORDINATION",
          text: `
        <p><i class="fas fa-check"></i> 2 players face to face (between 8 to 15m) with a ball each.</p>
        <p><i class="fas fa-check"></i> Objective: synchronized passes (the 2 balls are kicked at the same time).</p>
        <p><i class="fas fa-check"></i> The player must therefore always look at the ball and his partner.</p>
        `,
        },
        {
          img: "Cycles/Coordination right-2.png",
          title: `HANDLING OF THE BALL AND "PERCEPTUAL ORGANIZATION"`,
          text: `
        <p><i class="fas fa-check"></i> In a defined square (size according to the number of players) free handling. Forbidden to touch a partner (and his ball)</p>
        <p><i class="fas fa-check"></i> nor the cones in the square.</p>
        <p><i class="fas fa-check"></i> Then instruct the foot to be used; right foot, left foot, sole ... Vary the rhythms (slow, normal, fast).</p>
        <p><i class="fas fa-check"></i> At the end of the exercise: the players must make their partner's ball go out of the square without losing theirs. Whoever loses his ball is eliminated. The last on the field is the winner.</p>
        `,
        },
      ],
    },
  },
  PERIODISATION: {
    FIRST: {
      TITLE: `A SPECIAL 4-WEEK "TACTICAL PERIODIZATION" CYCLE"`,
      SUBTITLE: `U15 - U17 - Seniors`,
      SUBSUB: `To discover this training method`,
    },
    CHECK: {
      TITLE: "Suitable for U15 - U17 - Seniors",
      CHECKS: [
        `Would you like to learn about tactical periodization and see how to work on the physical with this training method?`,
        `Would you like to have examples of training cycles from this training method?`,
        `You would like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises used in tactical periodization?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with 
            <span>a 4-week cycle of special "tactical periodization" training.</span>
          </p>
          <p>
            This guide is intended for coaches of U15, U17 and senior teams who want to discover tactical periodization (initiation), who want to understand how to work on the physics with this training method and who are looking for examples of training sessions and ideas for tactical periodization exercises.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part on the tactical periodization
          </p>
          <p>
            <i class="fas fa-check-circle"></i> A 4-week cycle (2 or 3 sessions per week) on tactical periodization.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> For example, in a week with 3 sessions, you will find one session focused on strength work, one session focused on endurance work and one session focused on speed work.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You will also find ideas for exercises used in the tactical periodization
          </p>`,
    APRES: {
      COL1: `<p>
            You will find a cycle (of 4 weeks) on tactical periodization.
          </p>`,
      COL2: `<p>
              We also offer information, examples of exercises and micro-cycles to discover and work with this training method.
            </p>`,
      SUB: `The objective is mainly to introduce you to this training method, to show you how to work on the physical part, to give you ideas and examples so that you have a basis on which to design your own cycles and exercises! But be careful, this program is an initiation to tactical periodization, if you are already an expert or if you know this method well, then it will not be for you ;).`,
    },
    INFOSHOP: {
      title: `A SPECIAL 4-WEEK "TACTICAL PERIODIZATION" CYCLE`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/Copiadeejer-1_900x.png?v=1625687391",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/a-special-4-week-tactical-periodization-cycle",
    },  
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/PLeft-1.png",
          title: "8 VS 4 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> 3 teams of 4 players.</p>
        <p><i class="fas fa-check"></i> 2 zones of 15m x 15m for example (to be adapted according to the category and the level of your group).</p>
        <p><i class="fas fa-check"></i> A "joker" team (here in blue), playing with the team that has the ball, with one player on each side of a zone.</p>
        <p><i class="fas fa-check"></i> The holding team must make 6 consecutive passes (for example), move to the other zone and make 6 consecutive passes again to score a point.</p>
        <p><i class="fas fa-check"></i> When there is a change of zone, the joker players also slide to be supported in the other zone.</p>
        <p><i class="fas fa-check"></i> The tactical objective here is to work on keeping the ball in numerical superiority (8 against 4) and to work on progressing from one zone to another.</p>
        `,
        },
        {
          img: "Cycles/PLeft-2.png",
          title: "7 VS 7 SMALL GAMES",
          text: `
        <p><i class="fas fa-check"></i> Small games to work on aerobic power and team block.</p>
        <p><i class="fas fa-check"></i> On a half court for example.</p>
        <p><i class="fas fa-check"></i> Draw several zones (here 4).</p>
        <p><i class="fas fa-check"></i> A team must always have all its players in 2 zones together.</p>
        <p><i class="fas fa-check"></i> If a player of a team is in another zone, the team loses the ball.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/PRight-1.png",
          title: "STRENGTH WORK - HEADWORK (OFFENSIVE AND DEFENSIVE)",
          text: `
        <p><i class="fas fa-check"></i> 4	against 4 on a small field with 2 players in support to center.</p>
        <p><i class="fas fa-check"></i> Header goal, valid only in the white zone and following a cross.</p>
        <p><i class="fas fa-check"></i> The team in possession of the ball must make at least 8 passes before scoring.</p>
        <p><i class="fas fa-check"></i> 8 minutes.</p>
        <p><i class="fas fa-check"></i> Change roles every 2 minutes.</p>
        `,
        },
        {
          img: "Cycles/PRight-2.png",
          title: `SPEED IN 3 VS 2`,
          text: `
        <p><i class="fas fa-check"></i> Start in the middle of the field for the attackers and goal line for the defenders.</p>
        <p><i class="fas fa-check"></i> 10 seconds maximum to score (for example).</p>
        <p><i class="fas fa-check"></i> 3-4 passes per player in the 2 roles.</p>
        <p><i class="fas fa-check"></i> 45 seconds minimum recovery time between 2 runs.</p>
        `,
        },
      ],
    },
  },
  SPECIAL_V: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL SPEED TRAINING`,
      SUBTITLE: `With 200 examples of speed exercises`,
      SUBSUB: "For seniors",
    },
    CHECK: {
      TITLE: "Adapted to seniors",
      CHECKS: [
        `Are you sometimes short of ideas to bring something new to your workouts?`,
        `Would you like to have examples of training cycles for speed work?`,
        `Would you like to save time in the creation of your sessions (and not look for hours of new exercises on the internet)?`,
        `Would you like to know more exercises to work on speed?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with <span>2 cycles of 4 weeks of speed training</span> and 200 ideas of exercises to work on speed. <br />
            This guide is for senior team coaches who are looking for sample training cycles and drill ideas to work on speed.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> You have an "explanations" part on the speed
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles of 4 weeks on speed work (with 2 sessions per week or 16 sessions to work on speed)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas for exercises to work on speed without equipment or with small equipment (that all clubs can have, and all with ball)
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 cycles of physical training to work on speed. For example, you can propose the first cycle in the first part of the season and the second cycle in the second part of the season. To make progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the organisms (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
            For this, you can vary the intensity, the volume, ... but also the exercises!
          </p>
          <p>
            We offer you more than 200 examples of exercises to work on speed (with and without ball)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL "SPEED" PHYSICAL TRAINING`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/3_13899038-564a-4063-a80d-8ef469f41ad1_900x.png?v=1620591586",
      link:
        "https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/2-cycles-of-special-speed-physical-training",
    },
    EXERCICES: {
      TITLE: `EXAMPLES OF EXERCISES FOUND IN THE PROGRAMS`,
      SUBTITLE: `Here are some examples of physical exercises in 3D format that you will find in the online guide.`,
      EXERCICESL: [{
          img: "Cycles/SpecialV left-1.png",
          title: "SPEED OF REACTION AND SPEED WITH CHANGES IN DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 teams</p>
        <p><i class="fas fa-check"></i> 2 squares Each player of a team has a number.</p>
        <p><i class="fas fa-check"></i> 2 colored studs on each side of a square.</p>
        <p><i class="fas fa-check"></i> The coach announces a color (which corresponds to the course, the players have to go around the 2 poles of the announced color) and a number which corresponds to a player of each team.</p>
        <p><i class="fas fa-check"></i> The players must react quickly to the announcement of their number, go around the markers and return to the starting point.</p>
        `,
        },
        {
          img: "Cycles/SpecialV left-2.png",
          title: "SPEED OF REACTION",
          text: `
        <p><i class="fas fa-check"></i> 4 different colored studs, the coach announces 2 colors. The player must react quickly to the signal, go around the 2 studs of the announced color and then hit in the small cages.</p>
        <p><i class="fas fa-check"></i> In a duel.</p>
        <p><i class="fas fa-check"></i> The player who strikes first has a point.</p>
        <p><i class="fas fa-check"></i> If he scores he gets 3 points.</p>
        <p><i class="fas fa-check"></i> The other player who struck second cannot score any points. Recovery time 45 seconds minimum.</p>
        <p><i class="fas fa-check"></i> You can vary the distances, announce only one color or 3 colors, vary the starts (stopped, moving, back, sitting... ), vary the ﬁnition...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/SpecialV right-1.png",
          title: "STRENGTH AND ENDURANCE WORK",
          text: `
        <p><i class="fas fa-check"></i> 3 groups, one in the center of the field (in red), one on the side (in white), one near the goal (in yellow).</p>
        <p><i class="fas fa-check"></i> 3 squares near the goal, one red, one blue, one yellow.</p>
        <p><i class="fas fa-check"></i> A player from the white group must make a run with the ball and cross. </p>
        <p><i class="fas fa-check"></i> He must center for one of the two red players (announced before the start).</p>
        <p><i class="fas fa-check"></i> The yellow player must sprint to get to one of the two squares.</p>
        <p><i class="fas fa-check"></i> The red players must sprint and go to the other squares.</p>
        <p><i class="fas fa-check"></i> The white player must therefore take the information before centering to see in which square the red player will be placed.</p>
        <p><i class="fas fa-check"></i> The players who have centered and hit return by walking.</p>
        `,
        },
        {
          img: "Cycles/SpecialV right-2.png",
          title: "1 ON 1 THEN STRIKE",
          text: `
        <p><i class="fas fa-check"></i> Attackers must dribble past a defender before they can shoot.</p>
        <p><i class="fas fa-check"></i> If the defender gets the ball back he can go and kick.</p>
        <p><i class="fas fa-check"></i> Set a time limit of 6 to 10 seconds for the duel.</p>
        <p><i class="fas fa-check"></i> 3-4 times in both roles. 45 seconds of recovery between 2 passages. </p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U8: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL SPEED WORKOUTS.`,
      SUBTITLE: `U8-U12`,
      SUBSUB: `With 200 examples of speed drills`,
    },
    CHECK: {
      TITLE: "Designed for U8-U12",
      CHECKS: [
        `Are you sometimes out of options to incorporate new ideas into your workouts?`,
        `Would you like to have examples of practice cycles for speed workouts for U8-U12?`,
        `Would you like to save time designing your sessions and not spend hours searching for new drills on the internet)?`,
        `Would you like to discover more exercises to work on speed?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>two 4-week speed training cycles</span>
            eand 200 speed drill ideas.
          </p>
          <p>
          This guide is meant for coaches of U8-U12 teams who are looking for examples of training cycles and drill ideas to work on speed.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> There is an "explanations" section on coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 4-week cycles on coordination workout (with 2
              sessions per week or 16 sessions to work on coordination)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Drill ideas to work on coordination without
            equipment or with few equipment (that every
            club can have, and all with a ball).
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 physical training cycles to work on speed in U8-U12. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the body (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
      To this end, you can vary the intensities, volumes, ... but also the drills!
          </p>
          <p>
          We offer you more than 200 examples of drills to work on speed (with and without the ball).
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL "SPEED" PHYSICAL TRAINING U8 - U12`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/2_a1e74f21-0339-4058-8feb-9d87838a13b2_900x.png?v=1620604600",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-special-speed-physical-training-u8-u12",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "REACTION TIME AND SPEED WITH CHANGE OF DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 teams</p>
        <p><i class="fas fa-check"></i> 2 squares</p>
        <p><i class="fas fa-check"></i> Each player of a team has a number.</p>
        <p><i class="fas fa-check"></i> 2 colored markers on each side of a square. The coach announces a color (which corresponds to the course, the players have to go around the 2 colored markers) and a number which corresponds to a player of each team.</p>
        <p><i class="fas fa-check"></i> The players must react quickly to the announcement of their number, go around the markers and return to the starting point.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "REACTION TIME",
          text: `
        <p><i class="fas fa-check"></i> 4 different colored markers, the coach announces 2 colors. The player must react quickly to the signal, go around the two colored markers and strike into the small goals.</p>
        <p><i class="fas fa-check"></i> Duel</p>
        <p><i class="fas fa-check"></i> The player who strikes first has a point.</p>
        <p><i class="fas fa-check"></i> If he scores he earns 3 points.</p>
        <p><i class="fas fa-check"></i> The other player who strikes next doesn't earn any points. Recovery time 45 seconds minimum.  </p>
        <p><i class="fas fa-check"></i> You can vary the distances, announce only one color or 3 colors, vary the starts (stopped, moving, back, sitting...), vary the end point ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV right-1.png",
          title: "REACTION TIME",
          text: `
        <p><i class="fas fa-check"></i> In a duel (or with 3 or 4 players).</p>
        <p><i class="fas fa-check"></i> One center square.</p>
        <p><i class="fas fa-check"></i> 4 goals of different colors.</p>
        <p><i class="fas fa-check"></i> 4 balls (each facing a goal).</p>
        <p><i class="fas fa-check"></i> The players jog in the center square.</p>
        <p><i class="fas fa-check"></i> The coach announces a color (blue for example), the players must react quickly, take the ball and pass the goal of the announced color.</p>
        <p><i class="fas fa-check"></i> The first one on the ball takes the ball, the second one has to prevent him from going through the goal with the ball.</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1-ON-1 THEN STRIKE`,
          text: `
        <p><i class="fas fa-check"></i> Attackers must dribble past a defender before shooting the ball.</p>
        <p><i class="fas fa-check"></i> If the defender gets the ball he can proceed to.</p>
        <p><i class="fas fa-check"></i> Set a time limit of 6 to 10 seconds for the duel.</p>
        <p><i class="fas fa-check"></i> 3-4 times in both roles. 45 seconds of recovery between 2 rounds.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U12: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL SPEED WORKOUTS.`,
      SUBTITLE: `U12-U14`,
      SUBSUB: `With 200 examples of speed drills`,
    },
    CHECK: {
      TITLE: "Designed for U12-U14",
      CHECKS: [
        `Are you sometimes out of options to incorporate new ideas into your workouts?`,
        `Would you like to have examples of practice cycles for speed workouts for U12-U14?`,
        `Would you like to save time designing your sessions and not spend hours searching for new drills on the internet)?`,
        `Would you like to discover more exercises to work on speed?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>two 4-week speed training cycles</span>
            eand 200 speed drill ideas.
          </p>
          <p>
          This guide is meant for coaches of U12-U14 teams who are looking for examples of training cycles and drill ideas to work on speed.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> There is an "explanations" section on coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 4-week cycles on coordination workout (with 2
              sessions per week or 16 sessions to work on coordination)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Drill ideas to work on coordination without
            equipment or with few equipment (that every
            club can have, and all with a ball).
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 physical training cycles to work on speed in U12-U14. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the body (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
      To this end, you can vary the intensities, volumes, ... but also the drills!
          </p>
          <p>
          We offer you more than 200 examples of drills to work on speed (with and without the ball).
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL "SPEED" PHYSICAL TRAINING U12 - U14`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/3_8d557b09-6db6-4f55-ba86-e1453508f353_900x.png?v=1620604697",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-special-speed-physical-training-u12-u14",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
        img: "Cycles/JeunesV left-1.png",
        title: "REACTION TIME AND SPEED WITH CHANGE OF DIRECTION",
        text: `
      <p><i class="fas fa-check"></i> 2 teams</p>
      <p><i class="fas fa-check"></i> 2 squares</p>
      <p><i class="fas fa-check"></i> Each player of a team has a number.</p>
      <p><i class="fas fa-check"></i> 2 colored markers on each side of a square. The coach announces a color (which corresponds to the course, the players have to go around the 2 colored markers) and a number which corresponds to a player of each team.</p>
      <p><i class="fas fa-check"></i> The players must react quickly to the announcement of their number, go around the markers and return to the starting point.</p>
      `,
      },
      {
        img: "Cycles/JeunesV left-2.png",
        title: "REACTION TIME",
        text: `
      <p><i class="fas fa-check"></i> 4 different colored markers, the coach announces 2 colors. The player must react quickly to the signal, go around the two colored markers and strike into the small goals.</p>
      <p><i class="fas fa-check"></i> Duel</p>
      <p><i class="fas fa-check"></i> The player who strikes first has a point.</p>
      <p><i class="fas fa-check"></i> If he scores he earns 3 points.</p>
      <p><i class="fas fa-check"></i> The other player who strikes next doesn't earn any points. Recovery time 45 seconds minimum.  </p>
      <p><i class="fas fa-check"></i> You can vary the distances, announce only one color or 3 colors, vary the starts (stopped, moving, back, sitting...), vary the end point ...</p>
      `,
      },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV14 right-1.png",
          title: "SPEED AND FINISHING",
          text: `
        <p><i class="fas fa-check"></i> 3 groups, one in the center of the field (red), one on the side (white), one near the goal (yellow).</p>
        <p><i class="fas fa-check"></i> 3 squares near the goal, one red, one blue, one yellow.</p>
        <p><i class="fas fa-check"></i> A player from the white group must run with the ball and cross it.</p>
        <p><i class="fas fa-check"></i> He must cross to one of the two red players (announced before the start).</p>
        <p><i class="fas fa-check"></i> The player in yellow sprints to one of the two squares.</p>
        <p><i class="fas fa-check"></i> The players in red sprint and go to the other squares.</p>
        <p><i class="fas fa-check"></i> The player in white gets the information before centering to see in which square the player in red will be positioned.</p>
        <p><i class="fas fa-check"></i> Players who have centered and struck return walking.</p>
        `,
        },
        {
          img: "Cycles/JeunesV12 right-2.png",
          title: `SPEED`,
          text: `
        <p><i class="fas fa-check"></i> Each player has a hoop.</p>
        <p><i class="fas fa-check"></i> The coach removes a hoop before every new start.</p>
        <p><i class="fas fa-check"></i> Players must leave their hoop, go around the dummy (or peg) of the color announced by the coach and find a new free hoop.</p>
        <p><i class="fas fa-check"></i> One player is eliminated per round.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U14: {
    FIRST: {
      TITLE: `2 CYCLES OF SPECIAL SPEED WORKOUTS.`,
      SUBTITLE: `U14-U18`,
      SUBSUB: `With 200 examples of speed drills`,
    },
    CHECK: {
      TITLE: "Designed for U14-U18",
      CHECKS: [
        `Are you sometimes out of options to incorporate new ideas into your workouts?`,
        `Would you like to have examples of practice cycles for speed workouts for U14-U18?`,
        `Would you like to save time designing your sessions and not spend hours searching for new drills on the internet)?`,
        `Would you like to discover more exercises to work on speed?`,
        `Would you like to see more motivation and progress in your players when they do physical exercises?`,
      ],
    },
    SEMAINE: `<p>
            We offer an online program with
            <span>two 4-week speed training cycles</span>
            eand 200 speed drill ideas.
          </p>
          <p>
          This guide is meant for coaches of U14-U18 teams who are looking for examples of training cycles and drill ideas to work on speed.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> There is an "explanations" section on coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 4-week cycles on coordination workout (with 2
              sessions per week or 16 sessions to work on coordination)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Drill ideas to work on coordination without
            equipment or with few equipment (that every
            club can have, and all with a ball).
          </p>`,
    APRES: {
      COL1: `<p>
            You will find 2 physical training cycles to work on speed in U14-U18. You can propose for example the first cycle in the first part of the season and the second cycle in the second part of the season. In order to progress, it is sometimes (or often) necessary to bring variety, novelty, in order to surprise the body (source of progress) and to avoid monotony (loss of motivation, overtraining...)
          </p>`,
      COL2: `<p>
      To this end, you can vary the intensities, volumes, ... but also the drills!
          </p>
          <p>
          We offer you more than 200 examples of drills to work on speed (with and without the ball).
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES OF SPECIAL "SPEED" PHYSICAL TRAINING U14 - U16`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/0593/3748/products/4_07d20911-bfba-4ccf-973c-8a304cf43a91_900x.png?v=1620604818",
      link:
        "https://soccer-fitness-training-shop.com/collections/youth-coach-programs/products/2-cycles-of-special-speed-physical-training-u14-u16",
    },
    EXERCICES: {
      TITLE: `PHYSICAL EXERCISES IN 3D FORMAT`,
      SUBTITLE: `Here are some examples of physical exercises that you will find in the online program.`,
      EXERCICESL: [{
        img: "Cycles/JeunesV left-1.png",
        title: "REACTION TIME AND SPEED WITH CHANGE OF DIRECTION",
        text: `
      <p><i class="fas fa-check"></i> 2 teams</p>
      <p><i class="fas fa-check"></i> 2 squares</p>
      <p><i class="fas fa-check"></i> Each player of a team has a number.</p>
      <p><i class="fas fa-check"></i> 2 colored markers on each side of a square. The coach announces a color (which corresponds to the course, the players have to go around the 2 colored markers) and a number which corresponds to a player of each team.</p>
      <p><i class="fas fa-check"></i> The players must react quickly to the announcement of their number, go around the markers and return to the starting point.</p>
      `,
      },
      {
        img: "Cycles/JeunesV left-2.png",
        title: "REACTION TIME",
        text: `
      <p><i class="fas fa-check"></i> 4 different colored markers, the coach announces 2 colors. The player must react quickly to the signal, go around the two colored markers and strike into the small goals.</p>
      <p><i class="fas fa-check"></i> Duel</p>
      <p><i class="fas fa-check"></i> The player who strikes first has a point.</p>
      <p><i class="fas fa-check"></i> If he scores he earns 3 points.</p>
      <p><i class="fas fa-check"></i> The other player who strikes next doesn't earn any points. Recovery time 45 seconds minimum.  </p>
      <p><i class="fas fa-check"></i> You can vary the distances, announce only one color or 3 colors, vary the starts (stopped, moving, back, sitting...), vary the end point ...</p>
      `,
      },
      ],
      EXERCICESR: [{
        img: "Cycles/JeunesV14 right-1.png",
        title: "SPEED AND FINISHING",
        text: `
        <p><i class="fas fa-check"></i> 3 groups, one in the center of the field (red), one on the side (white), one near the goal (yellow).</p>
        <p><i class="fas fa-check"></i> 3 squares near the goal, one red, one blue, one yellow.</p>
        <p><i class="fas fa-check"></i> A player from the white group must run with the ball and cross it.</p>
        <p><i class="fas fa-check"></i> He must cross to one of the two red players (announced before the start).</p>
        <p><i class="fas fa-check"></i> The player in yellow sprints to one of the two squares.</p>
        <p><i class="fas fa-check"></i> The players in red sprint and go to the other squares.</p>
        <p><i class="fas fa-check"></i> The player in white gets the information before centering to see in which square the player in red will be positioned.</p>
        <p><i class="fas fa-check"></i> Players who have centered and struck return walking.</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1-ON-1 THEN STRIKE`,
          text: `
          <p><i class="fas fa-check"></i> Attackers must dribble past a defender before shooting the ball.</p>
          <p><i class="fas fa-check"></i> If the defender gets the ball he can proceed to.</p>
          <p><i class="fas fa-check"></i> Set a time limit of 6 to 10 seconds for the duel.</p>
          <p><i class="fas fa-check"></i> 3-4 times in both roles. 45 seconds of recovery between 2 rounds.</p>
        `,
        },
      ],
    },
  },
  MODAL_LINKS: {
    GUIDE_JEUNES: 'https://soccer-fitness-training-shop.com/collections/e-learning/products/guide-to-physical-preparation-for-young-soccer-players',
    SAISONS_J: 'https://soccer-fitness-training-shop.com/collections/youth-coach-programs',
    SAISONS_S: 'https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs',
    EXERCICES: 'https://2fc70f3b.sibforms.com/serve/MUIEAN0he49Oa7qEw9_C-qVRwI4tqHKFksFtiHY_ByyeAMWuAln6MzQo6ZKUtMeUZWV23lwg2GtbkcGL3jHA6_2bNeVuab_r3ISZWy_C8SjHpdr1jodS5FJpE8MkzqaJ2L1onT9myeJvcj1B2dS8yHpYbfPru3fM4olzlkyZCaSW_7-_zSmv2cUeXrdd_rQRTAv04FWYIOzH9M46',
    FORMATION: '',
    COACHS: '',
    TREVE: 'https://soccer-fitness-training-shop.com/collections/seniors-coaches-programs/products/pre-season-training-program',
  },
  BLOG_LINK: 'https://fitness-soccer-training.com/blog',
  CHANGE_P: 'program',
  CHANGE_G: 'guide',
  GARANTIE_IMG: 'guarantee.png',
  PAY_IMG: 'secure.png',
  DISPONIBLE: 'SOON AVAILABLE',
};