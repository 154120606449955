<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-left">
          <div class="col-12 title" data-aos="fade-right">
            <h3>{{locale[lang].ARTICLES_TITLE}}</h3>
          </div>
        </div>
      </div>
      <div class="col-12" id="cards">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12" v-for="(card, key) in numCards" :key="key">
            <NotiCard :artInfo="artInfo[key]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotiCard from "@/components/NotiCard.vue";
export default {
  props: {
    numCards: Number,
    artInfo: Array
  },
  components: {
    NotiCard,
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 90px;
  padding: 0px 125px;
}

.title h3 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
  position: relative;
}

.title h3::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0;
  bottom: -25px;
  opacity: 1;
}

.container-fluid {
    padding: 0px 30px;
}

#cards {
  padding: 0px 125px;
}

@media (max-width: 1900px) {
  #cards {
    padding: 0px 70px;
  }

  .title {
    padding: 0px 70px;
  }
}

</style>