export default {
  NAV_IMG: 'ppf-trans-original.png',
  HOME: {
    INTRO: {
      TITLE: "Entraîneurs de football amateur",
      SUBTITLE: "Nous vous aidons à créer des séances physiques plus efficaces",
      BUTTON: "Choisir votre programme offert",
      WORDS: ["U8 aux U17", "Séniors", "Futsal", "Gardiens"],
      INITIAL: "U8 aux U17",
    },
    CARDS: {
      TITLE: "Vous êtes coach, que souhaiteriez-vous améliorer?",
      CARDS: [{
          IMG: "Grupo 1028.png",
          TITLE: "Améliorer l'entraînement physique des séniors en Football",
          TEXT: `<p>
                            Vous trouverez des informations sur la préparation physique en football pour les séniors. 
                       </p>
                       <p>
                            Mais aussi, des exemples de préparation physique d’avant saison des exemples de préparation physique au cours de la trêve hivernale.
                       </p>`,
          BUTTON: "Programme trêve estivale",
          LINK: "TreveEstivale"
        },
        {
          IMG: "Grupo 1029.png",
          TITLE: "Améliorer l'entraînement physique des U8 aux U17 en Football",
          TEXT: `<p>
                            Vous trouverez des exemples d’exercices pour développer les qualités de coordination, de vitesse, d’endurance, de souplesse ou encore de force.
                       </p>
                       <p>
                            Vous trouverez également des exemples de séances et de planification sur 4 semaines pour chaque catégories des U8 aux U17.
                       </p>`,
          BUTTON: "Guide jeunes footballeurs",
          LINK: "GuideJeunes"
        },
        {
          IMG: "Grupo 1031.png",
          TITLE: "Améliorer vos connaissances sur la préparation physique des jeunes",
          TEXT: `<p>
                            Vous trouverez de nombreuses informations sur le développement des jeunes footballeurs. Entraîner des jeunes est complexe et bien connaître leurs caractéristiques (qui évoluent avec l'âge) nous semble essentiel. Vous retrouverez par catégorie d'âge les caractéristiques générales.
                       </p>
                       <p>
                            Mais aussi des modules d'explications sur chaque qualité physique, ainsi que sur la prévention des blessures, le testing ou encore la préparation mentale.
                       </p>`,
          BUTTON: "Guide jeunes footballeurs",
          LINK: "GuideJeunes"
        },
      ],
    },
    ICONINFO: [{
        img: "Grupo 665.png",
        text: `<h3>+ de 10000</h3>
        <p>Coachs et joueurs nous font confiance</p>`,
      },
      {
        img: "Icon awesome-users.png",
        text: `<h3>+ de 40000</h3>
        <p>Membres actifs</p>`,
      },
      {
        img: "Icon awesome-facebook-square.png",
        text: `<h3>+ de 100000</h3>
        <p>abonnés Facebook et Instagram</p>`,
      },
      {
        img: "Grupo 1034.png",
        text: `<h3>1 million</h3>
        <p>De visiteurs sur le site provenant de 172 pays</p>`,
      },
    ],
    FORMATION: `Vous pouvez vous aider et vous inspirer de ses programmes offerts pour créer vos propres planifications.`,
    INFOSEC2: {
      img: "Gif-B.gif",
      text: `
              <h4 class="first">
                Si vous souhaitez aller plus loin, nous vous proposons également : 
              </h4>
              <h4 class="subtitle">
              Des formations en ligne sur la préparation physique en football amateur
              </h4>
              <p>
               Nous avons pris en compte tous les besoins et demandes de nombreux coachs et nous avons créé des formations pour pouvoir y répondre et vous aider au maximum.
              </p>
              <p>L'objectif est de vous donner les clés de la préparation physique au football, clés que vous pouvez utiliser rapidement sur le terrain. Les formations ont été créées par des entraîneurs physiques spécialisés dans le football.
             </p>`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    INFOSEC3: {
      img: "gif-a (1).gif",
      title: "Des programmes d’entraînements physiques en ligne",
      text: `<p>
              Nous proposons des programmes d'entraînements physiques pour les jeunes (u8 à u17) et les séniors.
              </p>
              <p>
              Ils comprennent la partie athlétique, l'échauffement et la récupération, mais pas la partie technique et tactique.
              </p>
              <p>
              Nous vous proposons des programmations progressives sur des cycles de 4 semaines ou sur une saison complète (10 mois) avec un thème différent chaque mois (puissance aérobie, force, vitesse, explosivité, ...) en fonction de la période de l'année.
             </p>`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    INFOSEC4: {
      img: "Gif-A.gif",
      title: "Des idées d’exercices physiques",
      text: `<p>
              Découvrez des guides en ligne avec de nombreux exemples d’exercices physiques pour les jeunes, les séniors, les gardiens et le futsal.
              </p>
              <p>
              Les exercices sont présentés sous format vidéo et schémas 3D .
              </p>
              <p>Tous les guides contiennent également une partie théorique afin de comprendre comment proposer les exercices en termes de charge de travail (temps d’exercice, temps de récupération, intensité de l’exercice, nombre de répétitions, nombre de séries..).
             </p>`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    INFOSEC5: {
      img: "Grupo 133.png",
      title: "Devenez membre et recevez ‘‘Les Emails Privés de thibaud’’",
      text: `<p>
              Découvrez gratuitement des informations exclusives chaque semaine pour
              améliorer vos entraînements physiques et votre activité de coach (idées
              d’exercices, conseils sur le coaching, le management et la préparation
              mentale).
              </p>
              <p>
              + de 40000 coachs reçoivent chaque semaine nos emails !
             </p>`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    INFOSEC5_MOBILE: {
      img: "Grupo 133.png",
      title: "Devenez membre et recevez ‘‘Les Emails Privés de thibaud’’",
      text: `<p>
              Découvrez gratuitement des informations exclusives chaque semaine pour
              améliorer vos entraînements physiques et votre activité de coach (idées
              d’exercices, conseils sur le coaching, le management et la préparation
              mentale).
              </p>`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    APPSEC: {
      img: "Home/gif-g (6).gif",
      title: `Découvrez gratuitement 300 idées d'exercices physiques pour 
      les coachs de football avec notre application mobile !`,
      title_mobile: 'Découvrez gratuitement 300 exercices physiques avec notre application mobile !',
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
    },
    OPINION_TITLE: `Des coachs nous font confiance: + de 10000 coachs ont déjà souscrit à un de nos programmes.`,
    OPINION_MOBILE_TITLE: "Des coachs et joueurs nous font confiance…",
  },
  MENU: {
    PAGE_1: [
      'Accueil',
      'Blog',
      `Coach de jeunes,<br>
      commencez ici`,
      `Coach de séniors,<br> commencez ici`,
      `Exemples<br />
      d'exercices`,
      'Programmes',
      'Formations',
      'Voir plus',
    ],
    PAGE_2: [
      'Boutique en ligne',
      'Les emails privés',
      `Espace<br />membres`,
      'Contact',
      'FAQ',
    ],
    PRINCIPAL: 'Menu Principal',
    PROGRAMMES: [
      `Programmes pour <br> Coach de jeunes`,
      `Programmes pour <br> Coach séniors`,
    ],
    IDEES: {
      TITLE: "Idées D'exercices",
      EXERCICES: [
        {
          id: 0,
          title: "Guide 800 exercices",
        },
        {
          id: 1,
          title: "200 idées d'exercices de jeux réduits",
        },
        {
          id: 2,
          title: "300 idées D'exercices Physiques - Application",
        },
        {
          id: 3,
          title: "200 exercices physiques pour le gardien de but",
        },
        {
          id: 4,
          title: "Blog",
        },
        {
          id: 5,
          title: "200 exercices physique pour le futsal",
        },
        {
          id: 6,
          title: "Les packs",
        },]
    },
    FORMATION: {
      TITLE: 'Formation',
      ITEMS: [
        {
          id: 0,
          title:
            "Formation complète sur la préparation physique en football amateur",
        },
        {
          id: 1,
          title: "Comment planifer une saison en football amateur",
        },
        {
          id: 2,
          title:
            "Comment travailler et développer la vitesse et l'endurance en football amateur",
        },
        {
          id: 3,
          title:
            "Comment travailler et développer la motricité chez les jeunes footballeurs",
        },
        {
          id: 4,
          title: "Guide sur le management",
        },
        {
          id: 5,
          title:
            "Guide sur la préparation physique chez les jeunes footballeurs",
        },
        {
          id: 6,
          title: "Les packs",
        },
      ],
    },
  },
  FORMATION: {
    INFOSEC1: {
      img: "Gif-B.gif",
      title:
        "Formation en ligne sur la préparation physique en footbal amateur",
    },
    INFOSEC2: {
      img: "Grupo 1006.png",
      title: "Rejoignez la liste d'attente",
      text: `<p>Vous recevrez toutes les informations sur la formation préparation physique football, vous serez prioritaire sur les inscriptions et vous bénéficierez de réductions</p>`,
      button: "Rejoindre la liste d’attente",
    },
    INFOSEC3: {
      img: "Grupo 1111.png",
      title: "Vous avez une garantie totale de satisfaction!",
      text: `<p>Si vous n’êtes pas satisfait par la formation, quelle qu’en soit la raison, nous vous remboursons intégralement! Il vous suffit de nous envoyer un email en nous informant de votre décision.</p>`,
    },
    CARD: {
      TITLE: `«J'aimerais avoir un groupe de joueurs physiquement au top,
      sans blessés, qui progresse et qui applique mes idées de
      jeu, qui se régale aux entraînements et en match (et qui
      donne du plaisir à mes proches et aux spectateurs) »
      (Ludovic, coach R3 séniors)`,
      TEXT: `Nous avons fait un sondage récemment pour connaître les
      besoins ou les difficultés des coachs. Nous avons eu des
      centaines de réponses. Nous avons pris en compte tous les
      besoins et demandes des coachs et nous souhaitons que cette
      nouvelle version de la formation en ligne ‘’préparation
      physique football’’ réponde à vos attentes (comme celle de
      Ludovic ci-dessus). Nous vous dévoilerons toutes les
      informations très bientôt (pour ceux qui sont pré-inscrits).`,
    },
    IMG_CARDS: {
      CARDS: {
        CARD_1: `PRÉPARATION<br />PHYSIQUE<br />INTEGRÉE`,
        CARD_2: `PÉRIODISATION<br />TACTIQUE`
      },
      CONTENT: {
        TITLE: `Vous retrouverez par exemple :`,
        TEXT: `De nouvelles méthodes et approches de l'entraînement et du
        football, des séances novatrices (où les joueurs prendront du
        plaisir à participer), des idées peut être plus en adéquation avec
        votre philosophie et vos envies.`,
      }
    },
    SAISON: {
      TEXT: `Des planifications sur une saison complète (10 mois d'entraînement
      physique avec exemples d’exercices) pour chaque catégorie des u8 aux
      séniors (mais aussi pour l'entraînement des gardiens et pour le
      futsal) pour vous donner un fil conducteur et des exemples sur
      lesquels vous appuyer pour bien réaliser le travail physique et
      atteindre vos objectifs.`,
      IMG: {
        TITLE: `UNE SAISON COMPLETE`,
        SUB_1: `Gardiens Futsal`,
        SUB_2: `U8 á U17 Seniors`,
      }
    },
    VIDEO_SUB: `De nouveaux exercices ludiques et efficaces (que les joueurs adorent)`,
    EXEMPLES: {
      TITLE: `Vous retrouverez par exemple :`,
      CARDS: [
        {
          link:
            "https://www.preparationphysiquefootball.com/images/exercices-inedits.png",
          text: `      
          <h5 class="card-title">
            Des exercices inédits
          </h5>`,
        },
        {
          link:
            "https://www.preparationphysiquefootball.com/images/formation-fabien-richard.png",
          text: `
          <h5 class="card-title">
            Un super bonus (pour l’un des participants) avec l’inscription offerte à une 
            formation de 3 jours (en live) proposée par le préparateur physique de R.Marhez, S.Mané, F.Thauvin, M.pjanic... 
            (plus d’infos sur <a href="https://www.fabienrichardconsulting.com/individuel" target="_blank">cette formation ICI)</a>.
          </h5>`,
        },
      ],
      ATTENTION: {
        TITLE: `Le reste vous sera dévoilé très bientôt.`,
        CONTENT: [
          `Attention, Afin de pouvoir proposer un suivi plus personnalisé et
          de qualité, nous devons limiter les inscriptions pour la formation
          en ligne (des centaines de coachs ont suivi la première version et
          nous recevons de nombreuses questions tous les jours.`,
          `Pour effectuer un suivi de qualité, nous devons donc limiter le
          nombre d'inscriptions).`,
          `Si cette formation vous intéresse, nous vous proposons de
          rejoindre la liste d’attente (vous recevrez toutes les
          informations sur la formation préparation physique football, vous
          serez prioritaire sur les inscriptions et vous bénéficierez de
          réductions).`,
        ]
      }
    },
    QUESTIONS: {
      TITLE: `Questions fréquentes`,
      ITEMS: [
        {
          id: 71,
          question:
            "Doit-on avoir des bases dans le domaine de la préparation physique pour faire la E-Formation?",
          description: `
            <p>
              Non, cette formation est accessible à tous. Nous avons tenté de la rendre compréhensible au maximum.
            </p>
            <p>
              Toutefois avoir des bases est évidemment un plus !
            </p>`,
        },
        {
          id: 72,
          question:
            "Je suis expert dans la préparation physique, la E-Formation est-elle adaptée à mon niveau?",
          description: `
            <p>
              Nous n'avons ni la prétention ni la légitimité pour proposer des formations pour "experts"! Nous proposons donc une formation plutôt adaptée à ceux qui débutent dans l'apprentissage de la préparation physique !
            </p>`,
        },
        {
          id: 73,
          question: "Est-ce une formation 100% théorique?",
          description: `
          <p>
            Non, l’objectif de cette formation est de vous donner les clés de la préparation physique en football mais de vous donnez surtout des clés dont vous pourrez vous servir rapidement sur le terrain!
          </p>
          <p>
            Une partie théorique-connaissance est incontournable mais la partie pratique sera consistante !
          </p>
          <p>
            Vous aurez accès à de nombreuses vidéos d’exercices !
          </p>`,
        },
        {
          id: 74,
          question: "Comment ca se passe après la commande?",
          description: `
          <p>
            1 à 2 minutes après avoir commandé vous allez recevoir un email de confirmation avec le lien d’accès à la formation en ligne. Vous devez vous inscrire (nom, prénom, ...) et vous avez accès directement à la formation. Soit quelques minutes après votre commande.
          </p>`,
        },
        {
          id: 75,
          question: "Je ne comprend pas un point dans la formation?",
          description: `
          <p>
            Faites part de vos interrogations sur le forum de la formation, de nombreuses personnes sauront certainement vous répondre. Il est normal de connaître des difficultés quand on apprend!
          </p>`,
        },
        {
          id: 76,
          question: "Je ne suis pas d’accord sur un point dans la formation?",
          description: `
          <p>
            Nous sommes là pour échanger, faites nous part de vos désaccords! C’est le principe d’une formation dynamique!
          </p>`,
        },
        {
          id: 77,
          question: "Sur quel support est accessible la formation?",
          description: `
          <p>
            Notre formation est accessible sur tous types de support (téléphone, tablette, ordinateur).
          </p>`,
        },
        {
          id: 78,
          question: "Combien de temps a-t-on accès à la formation?",
          description: `
          <p>
            Une fois que vous avez commandé, vous avez accès pendant 12 mois à la plateforme de formation... ce qui devrait vous laissez le temps de bien tout voir! Nous renouvelons gratuitement d’une année (ou plus) si vous avez besoin.
          </p>`,
        },
        {
          id: 79,
          question: "Le paiement est-il sécurisé?",
          description: `
          <p>
            Oui nous utilisons Paypal qui est reconnu et très sécurisé.
          </p>`,
        },
        {
          id: 80,
          question: "Si je n’aime pas la formation, puis-je être remboursé?",
          description: `
          <p>
            Bien sûr! Soit vous êtes satisfait de ce que vous avez appris, et votre investissement est rentable, soit vous ne l'êtes pas, nous vous remboursons directement et vous récupérez ainsi votre argent. Il n'y a donc pas de risque!
          </p>`,
        },
        {
          id: 81,
          question: "Vous avez d’autres questions ou interrogations?",
          description: `
          <p>
            Pas de problèmes, écrivez nous, nous vous répondrons rapidement.
          </p>`,
        },
        {
          id: 82,
          question: "Vous avez une garantie totale de satisfaction!",
          description: `
          <p>
            Si vous n’êtes pas satisfait par la formation, quelle qu’en soit la raison, nous vous remboursons intégralement! Il vous suffit de nous envoyer un email (prepaphysiquefootball@gmail.com) en nous informant de votre décision.
          </p>`,
        },
      ],
    },
    MODULOS: {
      TITLE: `Contenu de votre formation en ligne`,
      ITEMS: [
        {
          name: "Modules",
          count: "1-8",
          items: [
            {
              id: 1,
              title: "Module 1 : L'analyse du football moderne",
              description: `<p>
                  Vous découvrirez:
                </p>
                <p>
                  Comment analyser les efforts de match en football.Quels sont les efforts types du footballeur (ils ne sont pas toujours ce que l’on pense).En quoi cela influence t-il la préparation physique? Les différentes conceptions de la préparation physique (celle qui a inspiré, les nouvelles tendances,...)
                </p>`,
            },
            {
              id: 2,
              title:
                "Module 2 : Les différentes planifications de l'entraînement",
              description: `<p>
                  Vous découvrirez:
                </p>
                <p>
                  Les particularités de la planification en football ( assez différente de la planification ‘’classique’’ que l’on retrouve dans d’autres sports).
                </p>
                <p>
                  Comment évaluer la charge d'entraînement en football amateur et savoir si vos séances sont adaptées (et connaître l’état de forme des joueur).
                </p>
                <p>
                  Des exemples concrets de planification pour la saison complète en amateur et toutes les périodes clés d’une saison. 
                </p>
                <p>
                  Le meilleur logiciel de suivi (gratuit) selon nous.
                </p>`,
            },
            {
              id: 3,
              title: "Module 3 : La physiologie du sport",
              description: `<p>
                  La physiologie permet de comprendre les effets des exercices sur le corps et montre comment le corps s’adapte. Nous aborderons les notions de base à connaître.
                </p>`,
            },
            {
              id: 4,
              title: "Module 4 : L'évaluation du footballeur",
              description: `<p>
                  Vous découvrirez par exemple:
                </p>
                <p>
                  Pourquoi faire des tests. Le meilleur test pour les différentes qualités physiques. Une application qui permet de faire un test simple (et à moins de 10 euros) et très utile (le plus utile selon nous en amateur). Les limites des tests.
                </p>`,
            },
            {
              id: 5,
              title: "Module 5 : La motricité du joueur moderne",
              description: `<p>
                  Vous découvrirez par exemple:
                </p>
                <p>
                  Ce qu’est la motricité, comment la développer en football. Un nouveau concept très intéressant pour vous aider à trouver le poste parfait pour vos joueurs, mais aussi “à optimiser leurs performances et éviter les blessures”.
                </p>`,
            },
            {
              id: 6,
              title: "Module 6 : L’endurance",
              description: `<p>
                  Vous découvrirez par exemple:
                </p>
                <p>
                  Les notions clés de l’endurance, Les méthodes de développement de l’endurance en football, Quelle méthode utiliser en fonction de la période de l’année, Comment planifier l’endurance sur l’année, Comment la tester, Comment l’utiliser chez les jeunes Comment la travailler avec ballon Vous aurez accès à de très nombreux exemples d’exercices.
                </p>`,
            },
            {
              id: 7,
              title: "Module 7 : La vitesse",
              description: `<p>
                  Vous découvrirez par exemple:
                </p>
                <p>
                  Ce qu’est la vitesse en football Les méthodes de développement de la vitesse Quand la travailler dans l’année, dans la semaine, dans la séance, Comment la planifier sur l’année, Comment la tester, Peut-on la travailler avec ballon? Vous aurez accès à de très nombreux exemples d’exercices.
                </p>`,
            },
            {
              id: 8,
              title: "Module 8 : La force",
              description: `<p>
                  Vous découvrirez par exemple:
                </p>
                <p>
                  Les méthodes de développement de la force Quand la travailler dans l’année, dans la semaine, dans la séance, Comment la planifier sur l’année, Comment la tester, Peut-on la travailler avec ballon? Vous aurez accès à de très nombreux exemples d’exercices.
                </p>`,
            },
          ],
        },
        {
          name: "Modules",
          count: "9-16",
          items: [
            {
              id: 9,
              title: "Module 9 : La coordination",
              description: `
              <p>
                Vous découvrirez par exemple:
              </p>
              <p>
                Les méthodes de développement de la force Quand la travailler dans l’année, dans la semaine, dans la séance, Comment la planifier sur l’année, Comment la tester, Peut-on la travailler avec ballon? Vous aurez accès à de très nombreux exemples d’exercices.
              </p>`,
            },
            {
              id: 10,
              title: "Module 10 : La souplesse",
              description: `
              <p>
                Vous découvrirez par exemple:
              </p>
              <p>
                La souplesse est-elle importante en football? Faut-il s’étirer avant un match, après un match? Existe t-il une seule méthode d’étirements? Comment améliorer sa souplesse? A quel âge commencer à s’étirer? Concrètement comment on fait?
              </p>`,
            },
            {
              id: 11,
              title: "Module 11 : L'échauffement en football",
              description: `
              <p>
                Vous apprendrez quels sont les objectifs et les problématiques de l’échauffement, par quoi commencer un échauffement (et par quoi ne pas commencer), vous aurez des exemples d’échauffement type. Vous verrez aussi les exercices à réaliser en échauffement pour diminuer de 50% le taux de blessures (selon l’UEFA).
              </p>`,
            },
            {
              id: 12,
              title: "Module 12 : La récupération",
              description: `
              <p>
                Vous prendrez connaissances de tous les moyens de récupération pour le footballeur, des 3 piliers de la récupération ou encore d’un protocole de récupération.
              </p>`,
            },
            {
              id: 13,
              title: "Module 13 : La conception des séances",
              description: `
              <p>
                Vous apprendrez comment créer vos séances en fonction de différents paramètres (votre philosophie, vos objectifs, le moment, les consignes...) et comment animer une séance.
              </p>`,
            },
            {
              id: 14,
              title: "Module 14 : Les jeunes",
              description: `
              <p>
                Vous verrez quelles sont les différences entre les séniors et les jeunes, les notions à connaître pour entraîner les jeunes, comment travailler le physique pour chaque catégorie d’âge, et nous répondrons à de nombreuses questions sur le travail physique chez les jeunes (peuvent-ils faire de la force, un travail de puissance, travailler la souplesse, à quel âge travailler la vitesse, ….)
              </p>`,
            },
            {
              id: 15,
              title: "Module 15 : Le mental",
              description: `
              <p>
                Le travail du mental en football n’est pas encore très utilisé.. Et pourtant son importance est très grande ! vous apprendrez par exemple comment améliorer la confiance en soi, gérer ses émotions, la technique pour se fixer des objectifs, comment améliorer la cohésion de groupe...
              </p>`,
            },
            {
              id: 16,
              title: "Module 16 : La prévention des blessures",
              description: `
              <p>
              Pourquoi se blesse t-on? comment renforcer les ischio-jambiers, quadriceps, les adducteurs, les mollets, ... quelles méthodes de renforcement pour les chevilles et les genoux ? Vous trouverez ici nos réponses et nos solutions. Vous verrez quels sont les exercices à réaliser pour diminuer de 50% le taux de blessures (selon l’UEFA).
              </p>`,
            },
          ],
        },
        {
          name: "Modules",
          count: "17-24",
          items: [
            {
              id: 17,
              title: "Module 17 : Le matériel",
              description: `
              <p>
                Comment utiliser les différents outils pour vos entraînements en football amateur, quels sont leurs avantages et leurs inconvénients.
              </p>`,
            },
            {
              id: 18,
              title: "Module 18 : La nutrition du footballeur",
              description: `
              <p>
                Pourquoi la nutrition est si importante en football, quelles sont les règles de bases, que manger avant un match, après un match, comment changer les habitudes alimentaires, comment faire perdre du poids à vos joueurs ?
              </p>`,
            },
            {
              id: 19,
              title: "Module 19 : Le management",
              description: `
              <p>
                Vous découvrirez :
              </p>
              <p>
                Comment de grands coachs managent leur équipe. Comment ils choisissent leur leader, comment il font passer leurs idées, ... Quel discours font-ils lors du premier entraînement avec les joueurs, à la mi-temps, comment gèrent-ils les remplaçants, ...
              </p>`,
            },
            {
              id: 20,
              title: "module 20 : Programmes d’entraînements physiques",
              description: `
              <p>
                Vous aurez accès, pour chaque catégorie (des U8 aux seniors), à un exemple de planification du travail physique sur l’année (avec en moyenne 2 séances d’entraînements physiques par semaine, soit plus de 80 séances pour chaque catégorie).Vous aurez également accès à 40 séances physiques pour le futsal et les gardiens de but.
              </p>`,
            },
            {
              id: 21,
              title: "Module 21 : La préparation physique intégrée",
              description: `
              <p>
                Nous verrons s’il est pertinent d’intégrer le travail physique en football. Si oui, peut-on travailler toutes les qualités physiques avec ballon? Pour chaque qualités physiques, nous proposerons les avantages et inconvénients de travailler avec le ballon. Nous donnerons de nombreux exemples d’exercices.
              </p>`,
            },
            {
              id: 22,
              title: "Module 22 : La périodisation tactique",
              description: `
              <p>
                Nous vous présenterons cette méthode d’entraînement qui devient de plus en plus utilisée.
              </p>
              <p>
                Vous découvrirez quelle est sa philosophie, comment la mettre en place, ses avantages, ses inconvénients, des exemples d’exercices, comment créer ses propres exercices, ...
              </p>`,
            },
            {
              id: 23,
              title: "Module 23 : Bonus 1",
              description: `
              <p>
                Ici vous aurez des conseils de coaching, de management, de la physiologie, de la biomécanique, ... mais aussi des informations sur la réathlétisation, sur le futsal, …
              </p>`,
            },
            {
              id: 24,
              title: "Module 24 : Bonus 2",
              description: `
              <p>
                Dans ce dernier module, nous donnons notre bibliographie, les livres et les formations que nous recommandons, ... nous vous proposons des quizz en lien avec les modules précédents.
              </p>`,
            },
          ],
        },
      ],
    },
    ICONINFO: [
      {
        img: "soccer.png",
        text: `<h3>ADAPÉE</h3>
      <p>Aux amateurs</p>`,
      },
      {
        img: "uniform.png",
        text: `<h3>PRACTIQUE</h3>
      <p>Avec de nombreux exemples en vidéos et des exemples de programmes</p>`,
      },
      {
        img: "ball.png",
        text: `<h3>DYNAMIQUE</h3>
      <p>Grâce au forum et aux échanges avec les autres coachs et formateurs</p>`,
      },
      {
        img: "field.png",
        text: `<h3>REFLEXIVE</h3>
      <p>Afin de mieux intégrer et comprendre la préparation physique</p>`,
      },
    ],
    OPINION_TITLE: `Des coachs nous font confiance: + de 10000 coachs ont déjà souscrit à un de nos programmes.`,
    OPINION_TITLE_MOBILE: `Des coachs et joueurs nous font confiance…`,
    CONFIANCE: {
      TITLE: `POURQUOI NOUS FAIRE CONFIANCE VOUS AUSSI?`,
      CONTENT: [
        `Nous avons développé notre approche en prenant le meilleur de chaque
        grand préparateur physique ou entraîneur que nous avons eu la chance
        de côtoyer, mais aussi le meilleur de nos lectures et de nos
        formations universitaires et privées.`,
        `La conception de cette E-Formation est donc basée sur cette approche
        que nous avons essayé d’adapter du mieux possible à vos besoins...`,
        `Une formation créée par des préparateurs physiques diplômés
        spécialisés Football.`,
      ],
    },
    END: {
      TITLE: `Rejoignez la liste d'attente`,
      TEXT: `Vous recevrez toutes les informations sur la formation
      préparation physique football, vous serez prioritaire sur les
      inscriptions et vous bénéficierez de réductions`,
      BUTTON: `Rejoindre la liste d’attente`,
    }
  },
  COACH_JEUNES: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
              Si vous arrivez sur le site, vous pouvez vous demander : Pourquoi ce blog sur la préparation physique en football amateur et en quoi il peut m’aider ?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
              Il y a quelques années, au cours d’une formation avec différents préparateurs physiques et coachs amateurs, un constat fut réalisé par tout le monde : Le manque d’informations sur la préparation physique en football amateur.
             </p>
             <p>
              L’idée de créer un blog pour partager et échanger des connaissances sur la préparation physique était lancée! </p> 
             <p>Et rapidement des centaines de coachs m’ont fait part de leur reconnaissance et de leurs besoins (et s’il était possible de les aider).
             </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      Il y a quelques années, au cours d’une formation avec différents préparateurs physiques et coachs amateurs, un constat fut réalisé par tout le monde :
     </p>
     <p>Le manque d’informations sur la préparation physique en football amateur. </p>
     <p>L’idée de créer un blog pour partager et échanger des connaissances sur la préparation physique était lancée!</p>
     <p>
      Et rapidement des centaines de coachs m’ont fait part de leur reconnaissance et de leurs besoins (et s’il était possible de les aider).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "Pour cela, j’ai mis en place plusieurs moyens:",
      text: `<p>
              Vous avez par exemple accès un blog avec des informations totalement gratuites sur de nombreux 
              sujets liés à la préparation physique en football, mais aussi sur le management, 
              la préparation mentale ou encore la nutrition. 
              </p>
              <p>Il y a beaucoup d'articles à lire, et ce n'est pas forcément facile de vous y retrouver ! 
              C'est pour ça que nous avons créé des catégories pour faciliter votre recherche.
             </p>`,
      button: "Aller au blog",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "Pour cela, j’ai mis en place plusieurs moyens:",
      text: `<p>
          Vous avez par exemple accès un blog avec des informations totalement gratuites sur de nombreux sujets liés à la préparation physique en football, mais aussi sur le management, la préparation mentale ou encore la nutrition.
        </p>
        <p>Il y a beaucoup d'articles à lire, et ce n'est pas forcément facile de vous y retrouver ! 
        C'est pour ça que nous avons créé des catégories pour faciliter votre recherche.
        </p>`,
      button: "Aller au blog",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `Vous pouvez retrouver 300 exemples d’exercices physiques sur notre APPLICATION MOBILE`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
      button_mobile: "Plus d'informations",
    },
    LIST_1: {
      TITLE: `Voici un rapide tour d’horizon des besoins et demandes qui reviennent souvent:`,
      ITEMSL: [
        "J’aimerais des idées d’exercices physiques pour varier mes entrainements (pour diminuer la monotonie et motiver mes joueurs)",
        "J’aimerais savoir quelle qualité physique travailler en priorité avec mes u12",
        "J’aimerais savoir comment développer l’endurance, la force et la vitesse de mes joueurs u15",
        "J’aimerais savoir comment planifier le travail physique dans la saison (pour éviter les erreurs et permettre à mes joueurs u14 de s’améliorer sur la saison)",
      ],
      ITEMSR: [
        "J’aimerais des idées d’exercices physiques adaptés à mon groupe u10.",
        "J’aimerais savoir comment travailler la coordination avec mes u 8",
        "J’aimerais savoir comment utiliser les étirements avec mes jeunes u13 (et pouvoir ainsi conseiller mes joueurs et leur proposer un travail efficace)",
        "j’aimerais avoir des informations sur différentes méthodes de préparation physique en football amateur comme la préparation physique intégrée ou la périodisation tactique par exemple (et savoir si ces méthodes sont pertinentes avec des jeunes)",
      ],
      END: `Nous avons reçus des centaines de demandes comme celles-ci !`
    },
    ROTATE: {
      TITLE: "Nous avons reçus des centaines de demandes comme celles-ci !",
      ICONINFO: [
        `<h3>+ de 10000</h3>
        <p>Actuellement, plus de 10000 coachs ont rejoint un de nos guides, programmes ou formation !</p>`,
        `<h3>+ de 40000</h3>
        <p>Plus de 40000 lisent les conseils quotidiens par mails.</p>`,
        `<h3>+ de 100000</h3>
        <p>Plus de 100000 suivent les publications sur Facebook et Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>+ de 10000</h3>
        <p>Coachs et joueurs nous font confiance</p>`,
        `<h3>+ de 40000</h3>
        <p>Plus de 40000 lisent les conseils quotidiens par mails.</p>`,
        `<h3>+ de 100000</h3>
        <p>Membres actifs</p>`,
      ],
      END: `Pour résumer rapidement, je souhaite donc vous aider en essayant de
      répondre aux besoins de chacun.`,
    },
    CARDS: [{
        img: "Grupo 1022.png",
        text: "Vous avez accès également à un petit guide d’information sur les jeunes",
        buttons: [{
          text: "Guide jeune",
          link: 'GuideJeunes',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "Nous trouverez des exemples d’exercices sur nos pages facebook et instagram",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/preparationphysiquefootball/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/preparationphysiquefootball/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `Si vous souhaitez aller plus loin, vous avez aussi la possibilité
      d’avoir accès à des guides, des programmes et une formation en ligne:`,
      CHECKSL: [{
          text: "Un guide avec plus de 800 exercices physiques",
          next: "Exercices",
        },
        {
          text: "Des programmes sur des cycles de 4 semaines",
          next: "CoachJeunes",
        },
      ],
      CHECKSR: [{
          text: "Un guide Management",
          next: "GuideManagement",
        },
        {
          text: "Des programmes sur une saison complète",
          next: "SaisonJeunes",
        },
      ],
      CHECKM: {
        text: "une formation avec plus de 20 modules sur tout ce qui touche a l’optimisation de la performance",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'Nous espérons répondre à tous vos besoins et vous permettre, finalement :',
      ITEMSA: [
        `D’être autonome dans l’application de la préparation physique et ne plus avoir à demander des conseils tout le temps (devenez la personne à qui l’on demande conseils plutôt ;) )`,
        `D’avoir un sentiment de compétence dans le domaine de la préparation physique et voir votre travail reconnu par vos joueurs, les autres coachs, vos proches..`,
        `Ou encore de vous permettre d’échanger plus facilement sur la préparation physique avec les autres joueurs et les autres coachs!`,
      ],
    }
  },
  COACH_SENIOR: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
              Si vous arrivez sur le site, vous pouvez vous demander : Pourquoi ce blog sur la préparation physique en football amateur et en quoi il peut m’aider ?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
              Il y a quelques années, au cours d’une formation avec différents préparateurs physiques et coachs amateurs, un constat fut réalisé par tout le monde : Le manque d’informations sur la préparation physique en football amateur.
             </p>
             <p>
              L’idée de créer un blog pour partager et échanger des connaissances sur la préparation physique était lancée! 
              </p> 
              <p>Et rapidement des centaines de coachs m’ont fait part de leur reconnaissance et de leurs besoins 
              (et s’il était possible de les aider).
             </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      Il y a quelques années, au cours d’une formation avec différents préparateurs physiques et coachs amateurs, un constat fut réalisé par tout le monde :
     </p>
     <p>Le manque d’informations sur la préparation physique en football amateur. </p>
     <p>L’idée de créer un blog pour partager et échanger des connaissances sur la préparation physique était lancée!</p>
     <p>
      Et rapidement des centaines de coachs m’ont fait part de leur reconnaissance et de leurs besoins (et s’il était possible de les aider).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "Pour cela, j’ai mis en place plusieurs moyens:",
      text: `<p>
              Vous avez par exemple accès un blog avec des informations totalement gratuites sur de nombreux sujets 
              liés à la préparation physique en football, mais aussi sur le management, la préparation mentale ou 
              encore la nutrition.
              </p>
              <p>Il y a beaucoup d'articles à lire, et ce n'est pas forcément facile de vous y retrouver ! 
              C'est pour ça que nous avons créé des catégories pour faciliter votre recherche.<\p>`,
      button: "Aller au blog",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "Pour cela, j’ai mis en place plusieurs moyens:",
      text: `<p>
          Vous avez par exemple accès un blog avec des informations totalement gratuites sur de nombreux sujets liés à la préparation physique en football, mais aussi sur le management, la préparation mentale ou encore la nutrition.
        </p>
        <p>Il y a beaucoup d'articles à lire, et ce n'est pas forcément facile de vous y retrouver ! 
        C'est pour ça que nous avons créé des catégories pour faciliter votre recherche.</p>`,
      button: "Aller au blog",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `Vous pouvez retrouver 300 exemples d’exercices physiques sur notre APPLICATION MOBILE`,
      button: "POUR EN SAVOIR PLUS CLIQUEZ ICI",
      button_mobile: "Plus d'informations",
    },
    LIST_1: {
      TITLE: `Voici un rapide tour d’horizon des besoins et demandes qui reviennent souvent:`,
      ITEMSL: [
        "J’aimerais des idées d’exercices physiques pour varier mes entrainements (pour diminuer la monotonie et motiver mes joueurs)",
        "J’aimerais savoir comment développer l’endurance, la force et la vitesse de mes joueurs",
        "J’aimerais savoir comment utiliser les étirements (et pouvoir ainsi conseiller mes joueurs et leur proposer un travail efficace)",
        "J’aimerais avoir des informations sur différentes méthodes de préparation physique en football amateur comme la préparation physique intégrée ou la périodisation tactique par exemple",
      ],
      ITEMSR: [
        "J’aimerais des idées de séances physiques adaptées à mon groupe séniors.",
        "J’aimerais savoir comment optimiser la récupération des joueurs (et pouvoir ainsi leur répondre quand ils me demandent des conseils )",
        "J’aimerais savoir comment planifier le travail physique dans la saison (pour éviter les erreurs et permettre à mes joueurs de s’améliorer sur la saison)",
      ],
      END: `Nous avons reçus des centaines de demandes comme celles-ci !`
    },
    ROTATE: {
      TITLE: "Nous avons reçus des centaines de demandes comme celles-ci !",
      ICONINFO: [
        `<h3>+ de 10000</h3>
        <p>Actuellement, plus de 10000 coachs ont rejoint un de nos guides, programmes ou formation !</p>`,
        `<h3>+ de 40000</h3>
        <p>Plus de 40000 lisent les conseils quotidiens par mails.</p>`,
        `<h3>+ de 100000</h3>
        <p>Plus de 100000 suivent les publications sur Facebook et Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>+ de 10000</h3>
        <p>Coachs et joueurs nous font confiance</p>`,
        `<h3>+ de 40000</h3>
        <p>Plus de 40000 lisent les conseils quotidiens par mails.</p>`,
        `<h3>+ de 100000</h3>
        <p>Membres actifs</p>`,
      ],
      END: `Pour résumer rapidement, je souhaite donc vous aider en essayant de
      répondre aux besoins de chacun.`,
    },
    CARDS: [{
        img: "player.svg",
        text: "Vous avez accès également à un exemple de programme pour le travail physique de pré-saison",
        buttons: [{
          text: "Programme pré-saison",
          link: 'TreveEstivale',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "Nous trouverez des exemples d’exercices sur nos pages facebook et instagram",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/preparationphysiquefootball/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/preparationphysiquefootball/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `Si vous souhaitez aller plus loin, vous avez aussi la possibilité
      d’avoir accès à des guides, des programmes et une formation en ligne:`,
      CHECKSL: [{
          text: "Un guide avec plus de 800 exercices physiques",
          next: "Exercices",
        },
        {
          text: "Des programmes sur des cycles de 4 semaines",
          next: "special-vitesse",
        },
      ],
      CHECKSR: [{
          text: "Un guide Management",
          next: "GuideManagement",
        },
        {
          text: "Des programmes sur une saison complète",
          next: "SaisonSenior",
        },
      ],
      CHECKM: {
        text: "une formation avec plus de 20 modules sur tout ce qui touche a l’optimisation de la performance",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'Nous espérons répondre à tous vos besoins et vous permettre, finalement :',
      ITEMSA: [
        `D’être autonome dans l’application de la préparation physique et ne plus avoir à demander des conseils tout le temps (devenez la personne à qui l’on demande conseils plutôt ;) )`,
        `D’avoir un sentiment de compétence dans le domaine de la préparation physique et voir votre travail reconnu par vos joueurs, les autres coachs, vos proches..`,
        `Ou encore de vous permettre d’échanger plus facilement sur la préparation physique avec les autres joueurs et les autres coachs!`,
      ],
    }
  },
  SAISON_SENIOR: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "Vous n'avez pas de préparateur physique dans votre club?",
      text: `<p>
            Planifier le travail physique et créer des séances efficaces est assez complexe.
           </p>
           <p>
            Beaucoup de coachs nous ont demandé une petite "aide" pour la création de cycle de travail et de séances physiques.
           </p>`,
      button: "Voir les seánces SÉNIORS",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "Mais aussi les séances sont disponibles sur mobile, tablette et ordinateur.",
      text: `<p>
            Programmation progressive avec thème différent chaque mois (cycle de 4 semaines) pendant 10 mois. Les séances sont avec et sans ballon. <br> 1 séance par semaine pour les catégories FUTSAL et GARDIENS et 2 ou 3 séances par semaine pour les séniors (au choix).
           </p>
           <p>
            Tout est adapté au football amateur et vous avez le choix de modifier ou non les exercices proposés en fonction de votre groupe.
           </p>
           <p>
            Les thèmes sont variés et programmés en fonction de la période de l’année ("puissance aérobie", "force", "vitesse", "explosivité", ...) Les séances comprennent la partie athlétique, l’échauffement et le retour au calme mais pas la partie technico tactique.
           </p>`,
      button: "Voir les seánces JEUNES",
    },
    ROTATE: {
      TITLE: `Séances d’entraînements physiques sur une saison complète`,
      SUBTITLE: `Nous avons essayé de répondre à ce besoin et nous proposons à présent
      des séances d'entraînements physiques(cycle de 4 semaines) sur une
      saison complète (10 mois)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>Vous recevez 10 mois de séances de préparation physique!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>Vous gagnez un temps précieux! et vous apprenez en même temps.</p>`,
        },
        {
          img: "mail.png",
          text: `<p>Vous pouvez discuter sur le forum avec d’autres coachs et les préparateurs physiques</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>Vous pouvez modifier vos séances, les adapter, ou vous en inspirer...</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `Après avoir commandé, vous allez recevoir directement un email
      avec le lien d’accès à la plateforme en ligne et à vos séances.`,
      TITLE: `Séances d'entraînements physiques sur une saison complète`,
      TITLE_MOBILE: `SÉANCES SUR UNE SAISON`,
      BUTTON: 'Toutes les informations ici (Séances jeunes et séniors)',
      CARDS1: [{
          img: "Senior2.png",
          category: "2 séances par semaine",
          body: {
            sessions: "8 séances / mois",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
        {
          img: "Senior3.png",
          category: "3 séances par semaine",
          body: {
            sessions: "12 séances / mois",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        }
      ],
      CARDS2: [{
          img: "Senior4.png",
          category: "Séances Gardien De But",
          body: {
            sessions: "4 séances / mois",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
        {
          img: "Senior1.png",
          category: "Séances Futsal",
          body: {
            sessions: "4 séances / mois",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        }
      ],
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices de préparation physique",
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans
      les séances d'entraînements physiques`,
      CARDS: [{
          id: 0,
          open: false,

          img: "Imagen 90.png",
          title: 'INTERMITTENT 30-30 À 100 % DE LA VMA "INTERMITTENTE" DU JOUEUR (AVEC PASSE)',
          sub: "Exemple d'exercice d'endurance",
          text: `
      <p>Travail d'appuis + en fin de course le joueur doit faire une passe (objectif : précision, passe appliquée malgrè la fatigue et la vitesse).</p>
      <p>2 groupes: quand un groupe court, le deuxième récupère et fait la passe aux joueurs qui arrivent en fin de course. </p>`,
          hide: ` <p>Vous pouvez varier la passe ( à terre , en l'air, poitrine-passe, tête, ...) Chaque distance est individualisée selon la VMA intermittente de vos joueurs (VMA calculée par un test intermittent type 30-15 ou 45-15 par exemple). 2 séries de 10 répétitions ( 10 courses de 30 secondes par série) selon le niveau et la forme de vos joueurs. 3 minutes de récupération entre les 2 séries. Récupération passive ( marcher) entre les courses.</p>`,
        },
        {
          id: 1,
          open: false,

          img: "Imagen 92.png",
          title: '"TRAVAIL APPUIS-FORCE +RÉACTION"',
          sub: "Exemple d'exercice d'endurance",
          text: `<p>Les joueurs effectuent un travail de force (saut avec haies, pliométrie) puis arrivés aux plots jaunes, un des deux joueurs (ici le joueur en bleu sur le schéma) choisit la direction du sprint (à droite ou à gauche).</p>`,
          hide: `<p>L'autre joueur doit arriver à le toucher avant qu'il passe la ligne d'arrivée (les 2 plots oranges). Vous pouvez changer le travail de force par un travail de coordination ou d'appui, ou bien un travail de fréquence, ou simplement une accélération avec freinage... 5 ou 6 passages pour chaque joueur (récupération de 45 secondes à 1 minute)</p>`,
        },
        {
          id: 2,
          open: false,

          img: "Imagen 91.png",
          title: "INTERMITTENT FORCE",
          sub: "Exemple d'exercice d'endurance",
          text: `<p>15 secondes d’effort type pliométrie (bonds) - 15 secondes de récupération</p>
      <p>15 secondes de course à VMA - 15 secondes de récupération</p>`,
          hide: `<p>15 secondes d’effort type pliométrie (bonds) - 15 secondes de récupération 15 secondes de course à VMA - 15 secondes de récupération... etc pendant 7 à 10 minutes (en fonction du niveau et de la forme des joueurs) 2 séries 1. sauts en aller retour au dessus de bancs 2. appuis dans échelle de rythme 3. bondissements jambes tendues 4. bondissements jambes fléchies 5. appuis décalés</p>`,
        }
      ],
    },
    CHECK: {
      TITLE: 'Chaque séance comprend:',
      CONTENT: `Une proposition d'échauffement avec 2 à 4 exercices <br />
      Le corps de séance avec 3 à 4 exercices de travail d'une qualité
      physique <br />
      Le retour au calme.`,
      CONTENT_MOBILE: [
        `Une proposition d'échauffement avec 2 à 4 exercices`,
        `Le corps de séance avec 3 à 4 exercices de travail d'une qualité
        physique`,
        `Le retour au calme.`,
      ]
    },
    OPINION_TITLE: 'Des coachs et joueurs nous font confiance…',
    END: {
      TEXT: `Vous êtes intéressé par un programme?`,
      BUTTON: 'Toutes les informations ici',
    }
  },
  SAISON_JEUNES: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "Vous n'avez pas de préparateur physique dans votre club?",
      text: `<p>
            Planifier le travail physique et créer des séances efficaces est assez complexe.
           </p>
           <p>
            Beaucoup de coachs nous ont demandé une petite "aide" pour la création de cycle de travail et de séances physiques.
           </p>`,
      button: "Voir les seánces JEUNES",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "Mais aussi les séances sont disponibles sur mobile, tablette et ordinateur.",
      text: `<p>
            Programmation progressive avec thème différent chaque mois (cycle de 4 semaines). Les séances sont avec et sans ballon. 2 séances par semaine soit 80 séances au total (sur 10 mois)
           </p>
           <p>
            Tout est adapté au football amateur et vous avez le choix de modifier ou non les exercices proposés en fonction de votre groupe.
           </p>
           <p>
            Les thèmes sont variés et programmés en fonction des catégories d'âge et de la période de l'année ("puissance aérobie", "force", "vitesse", "explosivité", ...)
           </p>
           <p>
            Les séances comprennent la partie athlétique, l’échauffement et le retour au calme mais pas la partie technico tactique.
           </p>`,
      button: "Voir les seánces JEUNES",
    },
    ROTATE: {
      TITLE: `Séances d’entraînements physiques sur une saison complète`,
      SUBTITLE: `Nous avons essayé de répondre à ce besoin et nous proposons à présent
      des séances d'entraînements physiques(cycle de 4 semaines) sur une
      saison complète (10 mois)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>Vous recevez 10 mois de séances de préparation physique!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>Vous gagnez un temps précieux! et vous apprenez en même temps.</p>`,
        },
        {
          img: "mail.png",
          text: `<p>Vous pouvez discuter sur le forum avec d’autres coachs et les préparateurs physiques</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>Vous pouvez modifier vos séances, les adapter, ou vous en inspirer...</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `Après avoir commandé, vous allez recevoir directement un email
      avec le lien d’accès à la plateforme en ligne et à vos séances.`,
      TITLE: `Séances d'entraînements physiques sur une saison complète`,
      TITLE_MOBILE: `SÉANCES SUR UNE SAISON`,
      BUTTON: 'Toutes les informations ici (Séances jeunes et séniors)',
      CARDS1: [{
          img: "Grupo 1051.png",
          category: "U8 - U9",
          body: {
            sessions: "2 séances par semaine",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
        {
          img: "Grupo 157.png",
          category: "U10 - U11",
          body: {
            sessions: "2 séances par semaine",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
        {
          img: "Grupo 1053.png",
          category: "U12 - U13",
          body: {
            sessions: "2 séances par semaine",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
      ],
      CARDS2: [{
          img: "Grupo 1055.png",
          category: "U14 - U15",
          body: {
            sessions: "2 séances par semaine",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
        {
          img: "Grupo 1057.png",
          category: "U16 - U17",
          body: {
            sessions: "2 séances par semaine",
            interval: "Vos séances pendant 10 mois (de août à mai)",
          },
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices de préparation physique",
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans
      les séances d'entraînements physiques`,
      CARDS: [{
          id: 0,
          img: "Imagen 90.png",
          title: 'INTERMITTENT 30-30 À 100 % DE LA VMA "INTERMITTENTE" DU JOUEUR (AVEC PASSE)',
          sub: "Exemple d'exercice d'endurance",
          text: `
        <p>Travail d'appuis + en fin de course le joueur doit faire une passe (objectif : précision, passe appliquée malgrè la fatigue et la vitesse).</p>
        <p>2 groupes: quand un groupe court, le deuxième récupère et fait la passe aux joueurs qui arrivent en fin de course. </p>`,
          hide: ` <p>Vous pouvez varier la passe ( à terre , en l'air, poitrine-passe, tête, ...) Chaque distance est individualisée selon la VMA intermittente de vos joueurs (VMA calculée par un test intermittent type 30-15 ou 45-15 par exemple). 2 séries de 10 répétitions ( 10 courses de 30 secondes par série) selon le niveau et la forme de vos joueurs. 3 minutes de récupération entre les 2 séries. Récupération passive ( marcher) entre les courses.</p>`,
          open: false,
        },
        {
          id: 1,
          img: "Imagen 92.png",
          title: '"TRAVAIL APPUIS-FORCE +RÉACTION"',
          sub: "Exemple d'exercice d'endurance",
          text: `<p>Les joueurs effectuent un travail de force (saut avec haies, pliométrie) puis arrivés aux plots jaunes, un des deux joueurs (ici le joueur en bleu sur le schéma) choisit la direction du sprint (à droite ou à gauche).</p>`,
          hide: `<p>L'autre joueur doit arriver à le toucher avant qu'il passe la ligne d'arrivée (les 2 plots oranges). Vous pouvez changer le travail de force par un travail de coordination ou d'appui, ou bien un travail de fréquence, ou simplement une accélération avec freinage... 5 ou 6 passages pour chaque joueur (récupération de 45 secondes à 1 minute)</p>`,
          open: false,
        },
        {
          id: 2,
          img: "Imagen 91.png",
          title: "INTERMITTENT FORCE",
          sub: "Exemple d'exercice d'endurance",
          text: `<p>15 secondes d’effort type pliométrie (bonds) - 15 secondes de récupération</p>
        <p>15 secondes de course à VMA - 15 secondes de récupération</p>`,
          hide: `<p>15 secondes d’effort type pliométrie (bonds) - 15 secondes de récupération 15 secondes de course à VMA - 15 secondes de récupération... etc pendant 7 à 10 minutes (en fonction du niveau et de la forme des joueurs) 2 séries 1. sauts en aller retour au dessus de bancs 2. appuis dans échelle de rythme 3. bondissements jambes tendues 4. bondissements jambes fléchies 5. appuis décalés</p>`,
          open: false,
        },
      ],
    },
    CHECK: {
      TITLE: 'Chaque séance comprend:',
      CONTENT: `Une proposition d'échauffement avec 2 à 4 exercices <br />
      Le corps de séance avec 3 à 4 exercices de travail d'une qualité
      physique <br />
      Le retour au calme.`,
      CONTENT_MOBILE: [
        `Une proposition d'échauffement avec 2 à 4 exercices`,
        `Le corps de séance avec 3 à 4 exercices de travail d'une qualité
        physique`,
        `Le retour au calme.`,
      ]
    },
    OPINION_TITLE: 'Des coachs et joueurs nous font confiance…',
    END: {
      TEXT: `Vous êtes intéressé par un programme?`,
      BUTTON: 'Toutes les informations ici',
    }
  },
  EXERCICES: {
    QUESTIONS: {
      TITLE: 'GUIDE 800 EXERCICES',
      SUBTITLE: 'SCHÉMAS 3D ET VIDÉOS',
      ITEMS: [
        "Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos entraînements (collectifs ou individuels) ?",
        "Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?",
        "Vous aimeriez proposer des échauffements et des exercices physiques plus ludiques et plus appréciés par vos joueurs ?",
        "Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?",
      ],
    },
    LE_GUIDE: {
      TITLE: 'Le guide en ligne est disponible sur mobile, tablette et ordinateur.',
      CONTENT: {
        TEXT_1_1: `Nous proposons un “guide” en ligne avec plus de 800 exercices sous format vidéos et schémas 3D.`,
        TEXT_1_2: `Vous avez une partie “explications” pour chaque qualité physique et une partie “exercices”. Ces exercices sont sans matériel (avec et sans ballon) ou avec petit matériel (que tous les clubs peuvent avoir, avec et sans ballon).`,
        TEXT_2_1: `Ce guide s’adresse aux coachs et aux joueurs qui cherchent des idées d’exercices pour leurs séances d’entraînements physiques`,
        TEXT_2_2: `+ de 700 joueurs`,
        TEXT_2_3: `et coachs suivent déjà ce guide!`,
        TEXT_3_1: "A travers",
        TEXT_3_2: "plus de 800 exercices sous format vidéos et schémas 3D",
        TEXT_3_3: ", vous allez découvrir les différentes méthodes (et exercices) d’entraînements possibles de :",
        TEXT_4: `Le guide en ligne est "satisfait ou remboursé", vous ne prenez donc aucun risque en le commandant. Si vous n'aimez pas, nous vous remboursons directement.`,
        CHECKS: [
          'La vitesse',
          'La force',
          'L’endurance',
          'La coordination',
          'La souplesse',
          'L’échauffement',
          'La prévention des blessures',
        ],
      }
    },
    VOTRE: {
      TITLE: 'VOTRE GUIDE',
      CONTENT: {
        TEXT_1: `Vous allez retrouver une "bibliothèque" ou un "répertoire" d'exercices afin de varier vos entraînements (en club ou individuel). Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)`,
        TEXT_2: 'Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!',
        TEXT_3: "Vous pouvez proposer (ou faire) les mêmes exercices mais vous pouvez surtout vous en inspirer et créer vos propres exercices.",
        TEXT_4: "L'objectif est surtout là, vous donner des idées, des exemples afin que vous ayez une base sur laquelle vous appuyer pour concevoir vos propres exercices!",
        TEXT_5: 'Vous êtes intéressé par le guide 800 exercices ?',
        BUTTON: 'Toutes les informations ici',
      }
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXAMPLES: {
      TITLE: "Exemples d'exercices retrouvés dans le guide",
      SUBTITLE: "Voici des exemples d'exercices physiques sous format 3D que vous retrouverez dans le guide en ligne.",
      EXERCICES1: [{
          img: "Exercices/exercice1.png",
          category: "Echauffement",
          title: "EXERCICES DE PASSES ET DE COORDINATION",
          sub: "Exemple d'exercice d'échauffement",
          text: `Le joueur fait une passe puis va faire un travail d'appuis coordination.. puis va prendre la place du partenaire à qui il a fait la passe.. un exercice différent à chaque fois.`,
        },
        {
          img: "Exercices/exercice2.png",
          category: "Vitesse",
          title: "TRAVAIL DE FRÉQUENCE GESTUELLE",
          sub: "Exemple d'exercice de vitesse",
          text: `Travail de fréquence gestuelle entre les lattes ( 2 appuis entre chaque latte, de côté), puis accélération et passe, marche arrière, le joueur se retourne, travail de fréquence entre les lattes ( 2 appuis entre chaque latte, de côté), puis accélération, passe et marche arrière.`,
        },
        {
          img: "Exercices/exercice3.png",
          category: "Endurance",
          title: "CONSERVATION - PRESSING",
          sub: "Exemple d'exercice d'endurance puissance",
          text: `4 équipes de 3 joueurs dans 4 carrés définis. Au top, l'entraîneur annonce une équipe qui part au pressing pour récupérer le ballon. Chaque joueur de l'équipe annoncée va dans un carré (un joueur par carré, soit 1 contre 3).. 30 secondes d'effort par exemple puis une nouvelle équipe va faire le pressing...`,
        },
        {
          img: "Exercices/exercice4.png",
          category: "Vitesse",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice de vitesse",
          text: `les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher le cône de la couleur annoncée. Puis reviennent pour prendre le ballon et frapper au but.. le premier sur le ballon frappe ou bien part en duel..`,
        },
      ],
      EXERCICES2: [{
          img: "Exercices/vivacite-force-1.png",
          category: "Echauffement",
          title: "APPUIS COORDINATION",
          sub: "Exemple d'exercice d'échauffement",
          text: `Travail d'appuis, de coordination, de bondissements en échauffement pour préparer les joueurs à la séance et en même temps travailler différentes qualités physiques...`,
        },
        {
          img: "Exercices/vivacite-force-2.png",
          category: "Vitesse",
          title: "FORCE ET VITESSE",
          sub: "Exemple d'exercice de vitesse",
          text: `Les joueurs démarrent avec un travail de pliométrie puis un des deux joueurs choisis un côté et accélérer.. l'autre joueur doit arriver à le toucher avant la ligne d'arrivée.`,
        },
      ]
    },
    OPINION_TITLE: `CE QU'ILS PENSENT DU GUIDE EN LIGNE`,
    OPINION_MOBILE_TITLE: `Des coachs et joueurs nous font confiance…`,
    END_PART: {
      TEXT: 'Vous êtes intéressé par le guide 800 exercices ?',
      BUTTON: 'Toutes les informations ici',
    }
  },
  EXERCICES_APP: {
    FIRST: {
      TITLE: '300 idées d’exercices physiques',
      SUB: 'Pourquoi nous vous proposons cette application?',
      CONTENT_1: `Le blog contient pas mal d’exemples d’exercices physiques. <br />
      Mais ils sont ''dispatchés'' dans tous les articles (+ de 100
      articles).`,
      CONTENT_2: `Cette application nous permet de mieux organiser les exercices et va
      vous <br />
      permettre de trouver plus rapidement les exercices que vous
      recherchez.`,
      CONTENT_3: {
        TEXT: `Nous avons organisé les exercices en 6 grandes thématiques :`,
        LIST: [
          `L’échauffement`,
          `La vitesse`,
          `L’endurance`,
          `La coordination`,
          `La force`,
          `Les jeunes`,
        ],
      },
      END: `Cette application s’adresse aux coachs de football amateurs qui
      cherchent des idées d’exercices pour leurs séances d'entraînements
      physiques.`,
    },
    CATEGORIES: [
      'L’échauffement',
      'L’endurance',
      'La vitesse',
      'La force',
      'La coordination',
      'Les jeunes',
    ],
    LAST: {
      TITLE: `Quelles sont les caractéristiques de cette application mobile:`,
      ITEMS: [
        `Totalement gratuite`,
        `Simple d’utilisation`,
        `Nouveaux exercices ajoutés régulièrement`,
        `Partie contact pour toutes questions ou suggestions`,
        `Ajout des exercices favoris`,
        `Partage de l’application avec vos amis ou d’autres coachs`,
        `Fonction recherche pour trouver rapidement les exercices qui vous intéressent`,
      ]
    },
    TEXT_MOBILE: `Pour visualiser cette page, veuillez accéder depuis votre ordinateur`,
    BACK_HOME: `Back to home`,
  },
  IDEES: {
    TOP: {
      TITLE: '200 idées de jeux réduits pour travailler le physique',
      SUBTITLE: 'Tout en travaillant la technique et la tactique',
    },
    QUESTIONS: {
      TITLE: '200 IDÉES DE JEUX RÉDUITS',
      SUBTITLE: 'Adaptés aux jeunes et aux séniors',
      ITEMS: [
        "Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?",
        "Vous aimeriez connaître plus d’exercices pour travailler l'endurance tout en travaillant la technique et la tactique ?",
        "Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?",
        "Vous aimeriez proposer des exercices physiques avec ballon plus ludiques et plus appréciés par vos joueurs ?",
        "Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?",
      ],
    },
    GUIDE: {
      TITLE: `Nous proposons un “guide” en ligne avec
      <span>200 idées de jeux réduits sous forme de schémas 3D</span>
      pour travailler le physique (tout en travaillant la technique et
      la tactique).`,
      CONTENT: {
        TEXT_1: `Ce guide s’adresse aux coachs d'équipes de jeunes et de séniors
        qui cherchent des idées d’exercices pour travailler le physique de
        manière intégrée.`,
        TEXT_2: `Les jeux réduits sont des oppositions avec ballon et permettent de
        travailler les aspects techniques et tactiques.`,
        TEXT_3: `Ils permettraient de travailler également certains aspects du
        travail physique (vous aurez plus d'explications dans le guide sur
        les effets des jeux réduits sur les qualités physiques)!`,
        TEXT_4: `Ce qui est particulièrement intéressant en football amateur où
        l'on doit travailler le physique mais aussi la tactique, la
        technique ou le mental avec un temps d'entraînement très limité.`,
        CHECKS: [
          `Vous avez une partie “explications”
          sur les jeux réduits (avantages, comment les concevoir soi-même,
          ...)`,
          `Et des explications sur le travail
          d'endurance`,
          `Ces exercices sont sans matériel ou
          avec petit matériel (que tous les clubs peuvent avoir, et tous
          avec ballon)`,
        ],
      },
      BUTTON: 'Recevoir le progamme par email gratuitement'
    },
    COMMANDE: {
      TITLE: 'Après avoir commandé, vous allez recevoir directement un email avec le lien d’accès au guide en ligne.',
      CONTENT: {
        TEXT_1: `Vous allez retrouver une "bibliothèque" ou un "répertoire"
        d'exercices afin de varier vos entraînements. Pour progresser, il
        faut parfois (voir souvent) apporter de la variété, de la
        nouveauté, afin de surprendre les organismes (source de progrès)
        et d'éviter la monotonie (perte de motivation, surentraînement...)`,
        TEXT_2: `Pour cela, vous pouvez varier les intensités, les volumes, ...
        mais aussi les exercices!`,
        TEXT_3: `Vous pouvez proposer (ou faire) les mêmes exercices mais vous
        pouvez surtout vous en inspirer et créer vos propres exercices.`,
        TEXT_4: `Pour cela, vous pouvez varier les intensités, les volumes, ...
        mais aussi les exercices!`,
      }
    },
    INFOSHOP: {
      title: 'Nous vous proposons ce nouveau "GUIDE 200 IDÉES DE JEUX RÉDUITS" en ligne',
      img: "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/32_1_1080x.png?v=1619800104",
      link: "https://preparationphysiquefootball-shop.com/collections/idees-dexercices/products/200-idees-de-jeux-reduits",
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices retrouvés dans les programmes",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne.`,
      E_LEFT: [{
          img: "Idees/Left1.png",
          title: "JEUX RÉDUITS 1 VS 1",
          text: `
        <p>Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
        <p>Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
        <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
        <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
        <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
        <p><i class="fas fa-check"></i> Terrain 1 : Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JEUX RÉDUITS 4 VS 1",
          text: `
        <p>5 carrés. <br /> 4 contre 1 dans chaque carré. <br /> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps. Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.4 équipes de 3 joueurs dans 4 carrés définis.</p>
        <p>Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
        `,
        },
      ],
      E_RIGHT: [{
          img: "Idees/Right1.png",
          title: "JEUX RÉDUITS 5 VS 2",
          text: `
        <p>3 zones.</p>
        <p>L'équipe en supériorité numérique doit passer les 3 ''étapes'' pour marquer un point.</p>
        <p>L'équipe en supériorité numérique doit faire 8 passes avant de pouvoir passer à la zone suivante.</p>
        <p>Dans chaque zone, 2 nouveaux joueurs au pressing.</p>
        <p>L'équipe en supériorité numérique a par exemple 2 minutes pour passer les 3 étapes et marquer un point.</p>
        <p>A chaque perte de balle, elle recommence de la zone de départ.</p>
        `,
        },
        {
          img: "Idees/Right2.png",
          title: "JEUX RÉDUITS 4 VS 4",
          text: `
        <p>3 équipes.</p>
        <p>Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
        <p>Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
        `,
        },
      ],
    }
  },
  TREVE_ESTIVALE: {
    TOP: {
      TITLE: 'Afin de vous aider dans vos entraînements',
      SUBTITLE: `Nous vous offrons ce programme pour vous donner une trame, des idées
      ou encore des exemples sur lesquels vous pourrez vous appuyer pour
      concevoir votre programmation!`,
      BUTTON: 'Recevoir le progamme par email gratuitement',
    },
    QUESTIONS: {
      TITLE: 'PROGRAMME TRÊVE ESTIVALE EN FOOTBALL',
      SUBTITLE: 'EXERCICES PRÉSENTÉS SOUS FORME DE SCHÉMAS 3D',
      ITEMS: [
        "Vous aimeriez avoir un exemple, un modèle sur lequel vous appuyer pour réaliser votre préparation d'avant saison ?",
        "Vous aimeriez gagner du temps dans la création de vos séances et de vos programmes de pré-saison (et ne pas chercher des heures de nouveaux exercices sur internet) ?",
        "Vous aimeriez proposer des échauffements et des exercices physiques plus ludiques et plus appréciés par vos joueurs ?",
        "Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'Les programmes en ligne sont disponibles sur mobile, tablette et ordinateur.',
      CONTENT: {
        TEXT_1: `Nous proposons un nouveau “programme trêve estivale” en ligne avec
        de nombreux exercices avec ballon présentés sous format schémas 3D.`,
        TEXT_2: `Vous avez une partie “explications” pour la planification et pour
        chaque qualité physique et une partie “séances et exercices”.`,
        TEXT_3: `Ces exercices sont sans matériel ou avec petit matériel (que tous
          les clubs amateurs peuvent avoir).`,
        TEXT_4: `Ce programme s’adresse principalement aux coachs qui cherchent des
        exemples de programmes pour la trêve hivernale en football.
        <br /><span>+ de 2000</span> coachs suivent déjà ce programme!`,
        TEXT_5: `<span>Nous vous offrons ce nouveau programme.</span><br />
        Cliquez sur le bouton ci-dessous pour le recevoir directement dans
        votre boîte mail.`,
      },
      BUTTON: 'Recevoir le progamme par email gratuitement',
      EMAIL: `Après vous être inscrit, vous allez recevoir directement un email
      avec le lien d’accès au programme en ligne (attention le mail
      arrive parfois dans les spams).`
    },
    VOTRE: {
      TITLE: "Votre programme d'avant saison",
      CONTENT: {
        TEXT_1: `Vous allez retrouver une "programmation" sur 6 semaines avec 2,
        3 ou 4 entrainements par semaine.`,
        TEXT_2: `Vous pouvez proposer (ou faire) la même programmation et les
        mêmes exercices mais vous pouvez surtout vous en inspirer et
        créer votre programmation.`,
        TEXT_3: `L'objectif est surtout là, vous donner des idées, des exemples
        afin que vous ayez une base sur laquelle vous appuyer pour
        concevoir votre programmation!`,
      }
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices retrouvés dans les programmes",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne.`,
      E_LEFT: [{
          img: "Training/ELeft1.png",
          title: "EXERCICE DE FORCE SPÉCIFIQUE ET DE PUISSANCE AÉROBIE",
          sub: "Exemple d'exercice mixant force et endurance",
          text: `
          <p>Exercice de frappe : Le joueur fait une tête en visant une cible (ici bleue et rouge) puis se replace directement et recommence. Le plus vite possible.</p>
          <p>En 10-20 par exemple <br /> Puis : Jeux réduits <br /> 2 contre 2 <br /> 1 minute 30 de jeu, 45 secondes de récupération <br /> 3 séquences.</p>
          `,
        },
        {
          img: "Training/ELeft2.png",
          title: "CONSERVATION - PRESSING",
          sub: "Exemple d'exercice d'endurance puissance",
          text: `
          <p>4 équipes de 3 joueurs dans 4 carrés définis.</p>
          <p>Au top, l'entraîneur annonce une équipe qui part au pressing pour récupérer le ballon. Chaque joueur de l'équipe annoncée va dans un carré (un joueur par carré, soit 1 contre 3).</p>
          <p>30 secondes d'effort par exemple puis une nouvelle équipe va faire le pressing..</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/ERight1.png",
          title: "TRAVAIL DE L'ENDURANCE CAPACITÉ ET DE L'ADRESSE",
          sub: "Exemple d'exercice mixant endurance capacité et adresse",
          text: `
          <h5>Parcours "adresse"</h5>
          <p><i class="fas fa-check"></i> Exercice 1 : faire tomber le plot</p>
          <p><i class="fas fa-check"></i> Exercice 2 : se rapprocher le plus possible du piquet (comme "pointer" à la pétanque)</p>
          <p><i class="fas fa-check"></i> Exercice 3 : toucher la barre transversale</p>
          <p><i class="fas fa-check"></i> Exercice 4 : piquer le ballon et le mettre à l'intérieur d'un gros pneu (ce peut être un cerceau ou autre)</p>
          <p><i class="fas fa-check"></i> Exercice 5 : faire tomber le plot</p>
          <p><i class="fas fa-check"></i> Exercice 6 : se rapprocher le plus possible du piquet (comme "pointer" à la pétanque)</p>
          <p><i class="fas fa-check"></i> Exercice 7 : passer le ballon sous la haie qui se trouve au milieu de la cage</p>
          <p class="last">Pas d'arrêt. Allure moyenne <br /> Consignes possibles : travail du pied faible. <br /> Par exemple : 15 minutes</p>
          `,
        },
        {
          img: "Training/ERight2.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice d'endurance puissance",
          text: `
          <p>Les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher le cône de la couleur annoncée. Puis reviennent pour prendre le ballon et frapper au but.</p>
          <p>Le premier sur le ballon frappe ou bien part en duel.</p>
          `,
        },
      ],
    }
  },
  TREVE_HIVERNALE: {
    TOP: {
      TITLE: 'Afin de vous aider dans vos entraînements',
      SUBTITLE: `Nous vous offrons ce programme pour vous donner une trame, des idées
      ou encore des exemples sur lesquels vous pourrez vous appuyer pour
      concevoir votre programmation!`,
      BUTTON: 'Recevoir le progamme par email gratuitement',
    },
    QUESTIONS: {
      TITLE: 'PROGRAMME TRÊVE HIVERNALE EN FOOTBALL',
      SUBTITLE: 'EXERCICES PRÉSENTÉS SOUS FORME DE SCHÉMAS 3D',
      ITEMS: [
        "Vous aimeriez avoir un exemple, un modèle sur lequel vous appuyer pour réaliser votre préparation pendant la trêve hivernale ?",
        "Vous aimeriez gagner du temps dans la création de vos séances et de votre programme pendant la trêve hivernale (et ne pas chercher des heures de nouveaux exercices sur internet) ?",
        "Vous aimeriez proposer des échauffements et des exercices physiques plus ludiques et plus appréciés par vos joueurs ?",
        "Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'Les programmes en ligne sont disponibles sur mobile, tablette et ordinateur.',
      CONTENT: {
        TEXT_1: `Nous proposons un nouveau “programme trêve hivernale” en ligne
        avec de nombreux exercices avec ballon présentés sous format
        schémas 3D.`,
        TEXT_2: `Vous avez une partie “explications” pour la planification et pour
        chaque qualité physique et une partie “séances et exercices”.`,
        TEXT_3: `Ces exercices sont sans matériel ou avec petit matériel (que tous
          les clubs amateurs peuvent).`,
        TEXT_4: `Ce programme s’adresse principalement aux coachs qui cherchent des
        exemples de programmes pour la trêve hivernale en football.
        <br /><span>+ de 700</span> coachs suivent déjà ce programme!`,
        TEXT_5: `<span>Nous vous offrons ce nouveau programme.</span><br />
        Cliquez sur le bouton ci-dessous pour le recevoir directement dans
        votre boîte mail.`,
      },
      BUTTON: 'Recevoir le progamme par email gratuitement',
      EMAIL: `Après vous être inscrit, vous allez recevoir directement un email
      avec le lien d’accès au programme en ligne (attention le mail
      arrive parfois dans les spams).`
    },
    VOTRE: {
      TITLE: "Votre programme spécial trêve hivernale",
      CONTENT: {
        TEXT_1: `Vous allez retrouver une "programmation" sur 6 semaines avec 2
        entrainements par semaine.`,
        TEXT_2: `Vous pouvez proposer (ou faire) la même programmation et les
        mêmes exercices mais vous pouvez surtout vous en inspirer et
        créer votre programmation.`,
        TEXT_3: `L'objectif est surtout là, vous donner des idées, des exemples
        afin que vous ayez une base sur laquelle vous appuyer pour
        concevoir votre programmation!`,
      }
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices retrouvés dans les programmes",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne.`,
      E_LEFT: [{
          img: "Training/HLeft1.png",
          title: "EXERCICE DE FORCE SPÉCIFIQUE ET DE PUISSANCE AÉROBIE",
          sub: "Exemple d'exercice mixant force et endurance",
          text: `
          <p>Circuit training pendant 3 minutes puis travail de course pendant 7 minutes (par exemple)</p>
          <p>Sous forme 15-15 (ou 10-20..)</p>
          `,
        },
        {
          img: "Training/left2.png",
          title: "VITESSE DE RÉACTION",
          sub: "Exemple d'exercice de vitesse de réaction",
          text: `
          <p>Le joueur doit aller toucher avec le pied le cône de couleur que lui annonce son partenaire.</p>
          <p>"vert" et le joueur va vers le cône vert puis se replace rapidement au centre. Dès que le joueur est replacé au centre le partenaire lui annonce la nouvelle couleur... ne pas lui laisser de temps de repos.</p>
          <p>Le joueur doit prendre les informations (les couleurs) avant de commencer pour pouvoir enchaîner très vite.</p>
          <p>Travail par deux (un qui fait l'exercice, l'autre qui annonce les couleurs).</p>
          <p>Effort court (5-6 secondes) et récupération longue ( 1 minute par exemple).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/HRight1.png",
          title: "TRAVAIL DE FORCE ET D'ENDURANCE",
          sub: "Exemple d'exercice mixant endurance et force",
          text: `
          <p><i class="fas fa-check"></i> Jeux réduits 1 vs 1 - 5 matchs</p>
          <p><i class="fas fa-check"></i> Ici, les joueurs vont faire des duels (1mn de jeu, 30 secondes de repos).</p>
          <p><i class="fas fa-check"></i> Objectif, aller déposer le ballon dans le camp de son adversaire (zone rouge).</p>
          <p><i class="fas fa-check"></i> L'intensité doit être grande.</p>
          <p><i class="fas fa-check"></i> Mettez des ballons autour des petits terrains.</p>
          <p><i class="fas fa-check"></i> Dimension des terrains : 5m x 5m environ.</p>
          <p><i class="fas fa-check"></i> Possibilité de faire sous forme de mini-tournoi (montée-descente).</p>
          `,
        },
        {
          img: "Training/right2.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher les cônes des couleurs annoncées.</p>
          <p>L'entraîneur annonce 2 ou 3 couleurs.</p>
          <p>Les joueurs doivent toucher les cônes le plus vite possible puis aller au duel et frapper.</p>
          `,
        },
      ],
    }
  },
  GUIDE_JEUNES: {
    TOP: {
      TITLE: 'Afin de vous aider dans vos entraînements',
      SUBTITLE: `Nous vous offrons ce guide qui nous l'espérons pourra vous apporter
      des connaissances, des idées ou encore des exemples sur lesquels vous
      pourrez vous appuyer pour concevoir votre programmation!`,
      BUTTON: 'Recevoir le progamme par email gratuitement',
    },
    CHECKS: {
      TITLE: 'GUIDE "LA PRÉPARATION PHYSIQUE DES JEUNES FOOTBALLEURS"',
      ITEMS: [
        `Il n'est pas toujours facile de trouver des informations sur les jeunes sportifs et sur les connaissances du fonctionnement de l'enfant à l'exercice.`,
        `Les études et recherches sont beaucoup moins nombreuses que pour les adultes.`,
        `Afin de vous aider, nous avons regroupé les informations qui nous semblent essentielles à connaître pour proposer à vos jeunes des contenus qui vont leur permettre de progresser et se développer harmonieusement.`,
      ],
    },
    LE_GUIDE: {
      TITLE: 'Le guide en ligne est disponible sur mobile, tablette et ordinateur.',
      SUBTITLE: `Beaucoup de coachs nous ont demandé si nous pouvions les aider
      sur la préparation physique des jeunes footballeurs. <br />Nous
      avons essayé de répondre à ce besoin en créant ce petit guide
      pour vous.`,
      CONTENT: {
        TEXT_1: `Nous vous proposons donc un “guide” en ligne sur la préparation
        physique des jeunes footballeurs. <br />
        Vous avez accès à un module d'informations générales sur les
        jeunes et des modules d'informations par catégories avec des
        exemples d'exercices à proposer.`,
        TEXT_2: `Ce guide s’adresse principalement aux coachs qui cherchent des
        informations sur`,
        TEXT_3: `les jeunes mais aussi des idées d’exercices pour leurs séances
        d’entraînements physiques.`,
        TEXT_4_1: `+ de 3000`,
        TEXT_4_2: `coachs suivent déjà ce guide!`,
        TEXT_5: `Vous allez aussi découvrir les différentes méthodes (et exercices)
        d’entraînements possibles de :`,
        TEXT_6_1: "Nous vous offrons ce nouveau programme.",
        TEXT_6_2: `Cliquez sur le bouton ci-dessous pour le recevoir directement dans
        votre boîte mail.`,
        TEXT_7: `Après vous être inscrit, vous allez recevoir directement un email
        avec le lien d’accès au programme en ligne (attention le mail
        arrive parfois dans les spams).`,
        CHECKS: [
          'La vitesse',
          'La force',
          'L’endurance',
          'La coordination',
          'La souplesse',
          'L’échauffement',
          'La prévention des blessures',
        ],
        BUTTON: 'Recevoir le progamme par email gratuitement',
      }
    },
    VOTRE: {
      TITLE: "VOTRE GUIDE",
      CONTENT: {
        TEXT_1: ` Vous allez aussi retrouver une petite "bibliothèque" ou un
        "répertoire" d'exercices afin de varier vos entraînements. Pour
        progresser, il faut parfois (voir souvent) apporter de la
        variété, de la nouveauté, afin de surprendre les organismes
        (source de progrès) et d'éviter la monotonie (perte de
        motivation, surentraînement...)`,
        TEXT_2: `Pour cela, vous pouvez varier les intensités, les volumes, ...
        mais aussi les exercices!`,
        TEXT_3: `Vous pouvez proposer (ou faire) les mêmes exercices mais vous
        pouvez surtout vous en inspirer et créer vos propres exercices.`,
        TEXT_4: `L'objectif est surtout là, vous donner des idées, des exemples
        afin que vous ayez une base sur laquelle vous appuyer pour
        concevoir vos propres exercices!`
      }
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices retrouvés dans le guide",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne pour vous donner des idées.`,
      E_LEFT: [{
          img: "Guide/GuideLeft1.png",
          title: "EXERCICES DE PASSES ET DE COORDINATION",
          sub: "Exemple d'exercice d'échauffement",
          text: `
          <p>Le joueur fait une passe puis va faire un travail d'appuis coordination. Puis va prendre la place du partenaire à qui il a fait la passe. Un exercice différent à chaque fois.</p>
          `,
        },
        {
          img: "Guide/GuideLeft2.png",
          title: "FRÉQUENCE + RÉACTION",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Les joueurs effectuent un travail de fréquence sur échelle de rythme (2 appuis par carré d'échelle, très rapides) puis arrivés aux plots jaunes, un des deux joueurs (ici le joueur en bleu sur le schéma) choisit la direction du sprint (à droite ou à gauche). L'autre joueur doit arriver à le toucher avant qu'il passe la ligne d'arrivée (les 2 plots oranges).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Guide/GuideRight1.png",
          title: "TRAVAIL DE FRÉQUENCE GESTUELLE",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Travail de fréquence gestuelle entre les lattes ( 2 appuis entre chaque latte, de côté), puis accélération et passe, marche arrière, le joueur se retourne, travail de fréquence entre les lattes ( 2 appuis entre chaque latte, de côté), puis accélération, passe et marche arrière.</p>
          `,
        },
        {
          img: "Guide/GuideRight2.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher le cône de la couleur annoncée. Puis reviennent pour prendre le ballon et frapper au but. Le premier sur le ballon frappe ou bien part en duel.</p>
          `,
        },
      ],
    }
  },
  GUIDE_M: {
    TOP: {
      TITLE: 'AFIN DE VOUS AIDER DANS VOTRE PRATIQUE DE COACH',
      SUBTITLE: `Nous vous proposons ce GUIDE "MANAGEMENT" <br />
      qui comprend 5 modules (et un bonus).`,
    },
    LINK_VIDEO: 'https://www.youtube.com/embed/Ku3rGLc3CjE',
    CHECKS: {
      TITLE: 'GUIDE MANAGEMENT',
      ITEMS: [
        `Vous êtes coach et vous aimeriez avoir des solutions pour résoudre
            des conflits (entre les joueurs, avec vos joueurs, avec les
            parents…) ?`,
        `Vous aimeriez savoir comment gérer les joueurs "difficiles", les
            forts caractères ?`,
        `Vous aimeriez développer votre leadership ou travailler sur votre
            développement personnel ?`,
        `Vous aimeriez vous améliorer sur l'organisation ou encore
            l'animation de groupe ?`,
      ],
    },
    LA_GUIDE: {
      TITLE: 'LA GUIDE EN LIGNE EST DISPONIBLE SUR MOBILE, TABLETTE ET ORDINATEUR.',
      CONTENT: {
        COL_1_1: {
          TITLE: 'Vous permettre de progresser',
          TEXT: `L'idée de ce guide est de vous permettre de progresser en tant
          qu'entraineur sur la gestion de groupe, les relations avec les
          joueurs, l'organisation ou encore l'animation.`
        },
        COL_1_2: {
          TITLE: 'Vous donner des clés',
          TEXT: `Nous avons essayé de vous donner des clés sur le management de
          votre groupe, de vous donner des "billes" pour créer votre propre
          méthode.`,
        },
        TEXT_1: `Les modules MANAGEMENT, PRÉPARATION MENTALE ET GESTION DES SÉANCES
        D'ENTRAÎNEMENTS ont pour buts de répondre à ces objectifs.`,
        COL_2_1: {
          TITLE: 'Développer vos capacités',
          TEXT: `Ce guide doit vous aider à développer vos capacités à gérer, à
          responsabiliser et à organiser vos groupes. Pour cela, nous
          pensons que "s'améliorer" soi-même est essentiel.`
        },
        COL_2_2: {
          TITLE: 'Vous apporter des conseils',
          TEXT: `Nous essayons donc de vous apporter des conseils, des moyens pour
          vous puissiez progresser personnellement.`,
        },
        TEXT_2: `Les modules LEADERSHIP et DÉVELOPPEMENT PERSONNEL - MINDSET ont
        pour buts de répondre à ces objectifs.`,
      }
    },
    INFOSHOP: {
      title: 'Nous proposons ce nouveau "GUIDE MANAGEMENT" en ligne',
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/83_1080x.png?v=1619856827",
      link:
        "https://preparationphysiquefootball-shop.com/collections/les-formations/products/guide-management",
    },
    MODULES: {
      TITLE: 'CONTENU DE VOTRE GUIDE EN LIGNE',
      SUBTITLE: "Vous allez retrouver 5 modules avec + de 100 vidéos de conseils d'experts du management et du coaching !",
      INITIAL: `<h4>MODULE 1 MANAGEMENT</h4>
      <p class="gray">Vous découvrirez par exemple:</p>
      <p>
        <i class="fas fa-check-circle"></i> Comment gérer les
        critiques?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comment gérer les
        conflits avec les joueurs, entre les joueurs, avec les
        parents?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comment gérer les égos
        et les "forts caractères"? Comment impliquer les joueurs aux
        entraînements?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comment gérer les
        défaites, comment gérer les remplaçants, les leaders, ...
      </p>
      <p class="gray">
        Vous découvrirez aussi une méthode de communication qui
        permet de résoudre de nombreux problèmes!
      </p>`,
      ITEMS: [{
          id: 1,
          content: `<h4>MODULE 1 MANAGEMENT</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les
                    critiques?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les
                    conflits avec les joueurs, entre les joueurs, avec les
                    parents?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les égos
                    et les "forts caractères"? Comment impliquer les joueurs aux
                    entraînements?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les
                    défaites, comment gérer les remplaçants, les leaders, ...
                  </p>
                  <p class="gray">
                    Vous découvrirez aussi une méthode de communication qui
                    permet de résoudre de nombreux problèmes!
                  </p>`,
        },
        {
          id: 2,
          content: `<h4>MODULE 2 LEADERSHIP</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment devenir un leader?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment s'exprimer devant un groupe?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment faire son pitsh?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment développer son charisme? ou encore comment se faire respecter?
                  </p>`,
        },
        {
          id: 3,
          content: `<h4>MODULE 3 PRÉPARATION MENTALE</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les
                    critiques?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Des méthodes sur la gestion des émotions
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La gestion du stress
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La confiance en soi
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La cohésion de groupe
                  </p><p>
                    <i class="fas fa-check-circle"></i> La motivation ou encore la concentration
                  </p>`,
        },
        {
          id: 4,
          content: `<h4>MODULE 4 DÉVELOPPEMENT PERSONNEL - MINDSET</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment construire 
                    son succès?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment développer 
                    son auto-discipline?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment agrandir 
                    sa zone de confort?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment devenir acteur de sa vie, 
                    comment se fixer des objectifs ou encore comment devenir plus heureux?
                  </p>`,
        },
        {
          id: 5,
          content: `<h4>MODULE 5 GESTION DES SÉANCES D'ENTRAÎNEMENTS</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment présenter 
                    les séances d'entraînements aux joueurs?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment calibrer 
                    les séances d'entraînements?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment animer 
                    les entraînements?
                  </p>`,
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
  },
  OPINIONS: {
    FIRST_SLIDE: [{
        text: `
        "Le guide 800 exercices physiques" apporte des idées d’exercices
        et cela permet de gagner du temps pour faire ses séances. Comme je
        n’ai pas beaucoup de temps libre cela m’aide vraiment, les joueurs
        apprécient les exercices et les résultats cette année sont très bons !
        Merci`,
        person: 'Laurent D.'
      },
      {
        text: `
        J’ai souhaité testé le programme ‘’une année d’entraînements
        physiques’’ car je sentais que je commençais à proposer toujours la
        même chose à mes entraînements. J’ai découvert des nouveaux
        exercices et j’arrive maintenant à en inventer de nouveaux à partir de
        ceux présentés. Sinon c’est bien expliqué, et pas cher donc je
        recommande aux entraîneurs (j’ai déjà recommandé à un ami qui
        est aussi entraîneurs ;) )`,
        person: 'Chris T.'
      },
      {
        text: `
        Je trouve que cette formation est très complète pour le prix demandé.
        Cela est très adapté et efficace pour les personnes qui souhaitent débuter.
        Ce sont des bonnes bases pour commencer. Me concernant, la formation
        me permet de m’apporter des informations complémentaires et de
        renforcer les contenus que je mets en place au quotidien. Merci.
        Cordialement.`,
        person: 'Rabah'
      }
    ],
    SECOND_SLIDE: [{
        text: `
      Je suis en plein dans le module mental de la formation. Je ne suis pas déçu
      par la formation que je suis en train de suivre, à mon rythme c'est certain car
      mon temps libre est restreint pour l'instant. Mais votre formation m'éclaircit
      sur beaucoup de points et je pense qu'en temps qu'amateur on pourra aborder
      la préparation physique plus sereinement et avec beaucoup moins de
      restrictions. Merci à vous pour tout ce que vous mettez en place et tous ses
      supports que vous mettez à notre disposition.`,
        person: 'Anne'
      },
      {
        text: `
      J’ai des U9 cette année et je n’avais pas trop d’idées sur les exercices de
      coordination et motricité à proposer. Vous m’avez conseillé ce guide et je suis
      très satisfait. Les exercices de vitesse aussi sont très bien. Les petits sont
      contents c’est l’essentiel.`,
        person: 'Yohan J.'
      },
      {
        text: `
      J’ai commencé les séances il y a peu mais les joueurs ont bien aimé. C’est
      beaucoup avec ballon et ludique. Les exercices sont simples à comprendre.
      Je savais pas trop planifier le travail physique donc là je n’ai qu’à suivre ce que
      vous proposez. En plus je vais apprendre en même temps je pense. A ce prix
      c’est très bien ! merci en tout cas`,
        person: 'Amine B.'
      }
    ],
    POSITION: 'Entraîneur',
  },
  SUBMENU_JEUNES: {
    TITLE: 'Programmes pour les coachs de jeunes',
    TITLE_MOBILE: 'Coach de Jeunes',
    ITEMS_LEFT: [{
        text: "Programme sur une saison complète (U8 à U17)",
        next: "SaisonJeunes",
      },
      {
        text: "Programme personnalisé",
        next: "",
      },
      {
        text: "Les packs",
        next: "Packs",
        param: "jeunes",
      },
    ],
    ITEMS_RIGHT: [{
        text: `2 cycles d'entraînements physiques spécial "coordination" U8-U12`,
        next: "Coordination",
      },
      {
        text: `2 cycles d'entraînements physiques spécial "vitesse" U8-U12`,
        next: "VitesseJeunesU8",
      },
      {
        text: `2 cycles d'entraînements physiques spécial "vitesse" U12-U14`,
        next: "VitesseJeunesU12",
      },
      {
        text: `2 cycles d'entraînements physiques spécial "vitesse" U14-U18`,
        next: "VitesseJeunesU14",
      },
      {
        text: `2 cycles d'entraînements physiques spécial "endurance puissance" U15-U18`,
        next: "PuissanceJeunes",
      },
      {
        text: `Un cycle de 4 semaines spécial "périodisation tactique U15- U18"`,
        next: "Periodisation",
      },
      {
        text: `2 cycles d'entraînements physiques pour les gardiens de but spécial "vitesse" U15 - U18`,
        next: "GardienVitesseU15",
      },
      {
        text: `2 cycles d'entraînements physiques en futsal spécial "vitesse" U15 - U18`,
        next: "FutsalVitesseU15",
      },
      {
        text: `2 cycles d'entraînements physiques en futsal spécial "endurance puissance" U15 - U18`,
        next: "FutsalPuissanceU15",
      },
    ],
  },
  SUBMENU_SENIOR: {
    TITLE: 'Programmes pour les coachs de séniors',
    TITLE_MOBILE: 'Coach de Séniors',
    ITEMS_LEFT: [{
        text: "Programme séniors sur une saison complète",
        next: "SaisonSenior",
      },
      {
        text: "Trêve estivale",
        next: "TreveEstivale",
      },
      {
        text: "Trêve hivernale",
        next: "TreveHivernale",
      },
      {
        text: "Pré-saison Gardiens et futsal",
        next: "Programmes",
      },
      {
        text: "Programme personnalisé",
        next: "",
      },
      {
        text: "Les packs",
        next: "Packs",
        param: "senior",
      }
    ],
    ITEMS_RIGHT: [{
        text: `2 cycles d'entraînements physiques spécial "Vitesse"`,
        next: "SpecialVitesse",
      },
      {
        text: `2 cycles d'entraînements physiques spécial "endurance puissance"`,
        next: "Puissance",
      },
      {
        text: `2 cycles d'entraînements physiques "100% avec ballon"`,
        next: "Avec",
      },
      {
        text: `Un cycle de 4 semaines spécial "périodisation tactique"`,
        next: "Periodisation",
      },
      {
        text: `2 cycles d'entraînements physiques pour les gardiens de but spécial "vitesse"`,
        next: "GardienVitesse",
      },
      {
        text: `2 cycles d'entraînements physiques en futsal spécial "vitesse"`,
        next: "FutsalVitesse",
      },
      {
        text: `2 cycles d'entraînements physiques en futsal spécial "endurance puissance"`,
        next: "FutsalPuissance",
      }
    ],
  },
  CONTACTO: {
    TITLE: 'Vous avez une question?',
    SUBTITLE: "N'hésitez pas à nous écrire",
    PLACEHOLDERS: {
      NAME: "Prénom",
      EMAIL: "Email",
      MESSAGE: "Message",
    },
    BUTTON: "Envoyer",
    ALERT: 'Tous les champs sont obligatories',
  },
  MEMBERS: {
    INFOSEC1: {
      img: "Grupo 1012@2x.png",
      title: "Vous êtes membre d'un de nos programmes, guides ou formations?",
      text: `<h3>
                Pour vous connecter à la plateforme "spéciale membre", cliquez sur le lien ci-dessous:
              </h3>`,
      button: "Accès à la plateforme en ligne",
    },
    INFOSEC1_MOBILE: {
      img: "Grupo 1012@2x.png",
      title: "Vous êtes membre d'un de nos programmes, guides ou formations?",
      text: `<h3>
                Pour vous connecter à la plateforme "spéciale membre", cliquez sur le lien ci-dessous:
              </h3>`,
      button: "Accès à la plateforme en ligne",
    },
    INFOSEC2: {
      img: "Llave.png",
      title: "Vous avez perdu votre mot de passe?",
      text: `<h3>
              Vous pouvez le réinitialiser en cliquant sur le lien ci-dessous
              </h3>`,
      button: "Récupérer le mot de passe",
    },
    INFOSEC2_MOBILE: {
      img: "Llave.png",
      text: `<h3>
              Vous avez perdu votre mot de passe? Vous pouvez le réinitialiser en cliquant sur le lien ci-dessous
              </h3>`,
      button: "Récupérer le mot de passe",
    },
    LINKS: [
      'http://membres.preparationphysiquefootball.com/formation/index/',
      'http://membres.preparationphysiquefootball.com/reset/',
    ],
    PROBLEME: {
      TITLE: 'Vous avez un autre problème?',
      CONTENT: {
        TEXT_1: 'Ecrivez-nous sur le chat ou',
        TEXT_2: 'ICI',
        TEXT_3: 'nous vous répondrons le plus rapidement possible.',
      },
      CONTENT_MOBILE: 'Vous avez un autre problème? Ecrivez-nous sur le chat ou ICI, nous vous répondrons le plus rapidement possible.'
    }
  },
  EMAILS: {
    TITLE: 'Les emails privés de thibaud',
    CONTENT: `Rejoignez plus de <strong>40000 coachs amateurs</strong> et retrouvez chaque
    semaine des <strong>idées d’exercices physiques</strong>, des <strong>informations sur la
      préparation physique</strong> en football amateur et des <strong>offres spéciales</strong>
    sur les programmes et guide en ligne.`,
    PLACEHOLDER: 'Votre adresse email',
    BUTTON: 'Je veux recevoir les emails privés',
    LOCK: 'Les emails sont gratuits, vos données resteront privées et vous pourrez vous désinscrire à tout moment..'
  },
  SHOP: {
    TITLE: `Si vous souhaitez commander un de nos programmes, guides ou
    formations en ligne, vous allez être redirigé sur notre
    ‘’shop’’ ou boutique en ligne.`,
    SUBTITLE: 'Pourquoi cette boutique en ligne?',
    CONTENT: {
      TEXT_1: `Notre objectif est de vous proposer via cette plateforme un
      <strong>accès facile</strong> à tous les programmes en ligne
      et de vous mettre à disposition des moyens de paiement
      simples, rapides et <strong>hautement sécurisés. </strong>`,
      TEXT_2: `Cela nous permet aussi de vous proposer plus facilement des
      <strong>packs</strong> des
      <strong>offres spéciales</strong> ou encore du
      <strong>matériel de préparation physique.</strong>`,
      TEXT_3: `Nous souhaitons que votre expérience sur notre site soit la
      plus plaisante possible et nous espérons que vous serez
      satisfaits ;)`,
      TEXT_4: `<i>Donc, quand vous allez cliquer sur ‘’commander’’ sur un
      programme, vous allez être redirigé vers la boutique en
      ligne. Une fois sur la boutique, vous pouvez faire votre
      commande puis, si vous souhaitez revenir au site, vous
      trouverez un lien dans la page d'accueil de la boutique pour
      revenir ici.</i>`,
    },
    BUTTON: 'ALLER SUR LA BOUTIQUE EN LIGNE',
    LINK: 'https://preparationphysiquefootball-shop.com',
  },
  PACKS: {
    INFOEXERCICES: {
      id: 'exercices',
      title: "PACK EXERCICES PHYSIQUES",
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/37_1080x.png?v=1619801278",
      link:
        "https://preparationphysiquefootball-shop.com/collections/idees-dexercices/products/pack-exercices-physiques",
    },
    INFOJEUNES: {
      title: "LES PACKS D’ENTRAÎNEMENTS PHYSIQUES POUR LES COACHS DE JEUNES",
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/63_1080x.png?v=1619805022",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/les-packs-d-entrainements-physiques-pour-les-coachs-de-jeunes",
    },
    INFOSENIOR: {
      title: "LES PACKS D’ENTRAÎNEMENTS POUR LES COACHS SÉNIORS",
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/CopiadeCopiadeCopiadeCopiadeejer-1_900x.png?v=1624313873",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/les-packs-d-entrainements-pour-les-coachs-seniors",
    },
    INFOFORMATION: {
      id: 'formation',
      title: "LES PACKS FORMATIONS",
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/89_1080x.png?v=1619856875",
      link:
        "https://preparationphysiquefootball-shop.com/collections/les-formations/products/les-packs-formations",
    },
  },
  BLOG: {
    RESULT: {
      TEXT_1: 'Environs',
      TEXT_2: 'résultat',
    },
    BLOG_TOP: {
      TITLE: "“ Le vrai pouvoir, c'est la connaissance ”",
      AUTHOR: 'F.Bacon',
      PLACEHOLDER: 'Rechercher...'
    },
    BLOG_ICONS: {
      TITLE: 'Les catégories',
      ICONS: [{
          img: "Trazado 2375.png",
          name: "Force",
          category: "Force",
        },
        {
          img: "noun_speed_1109796.png",
          name: "Vitesse",
          category: "Vitesse",
        },
        {
          img: "Grupo 819.png",
          name: "Endurance",
          category: "Endurance",
        },
        {
          img: "noun_run_562324.png",
          name: "Coordination souplesse",
          category: "Coordination",
        },
        {
          img: "Grupo 862.png",
          name: "Prévention des blessures",
          category: "Prevention",
        },
        {
          img: "Grupo 820.png",
          name: "Matériel Autres",
          category: "Autres",
        },
        {
          img: "Grupo 816.png",
          name: "Planification",
          category: "Planification",
        },
        {
          img: "Grupo 817.png",
          name: "Préparation mentale",
          category: "PM",
        },
        {
          img: "noun_Fruits_3480584.png",
          name: "Nutrition",
          category: "Nutrition",
        },
        {
          img: "Trazado 2397.png",
          name: "Magnagement",
          category: "Management",
        },
        {
          img: "healthy-boy.png",
          name: "Jeunes",
          category: "Jeunes",
        },
        {
          img: "Grupo 861.png",
          name: "Futsal",
          category: "Futsal",
        },
        {
          img: "noun_glove_1827610.png",
          name: "Gardien",
          category: "Gardien",
        },
      ],
    },
    NEWS: {
      TITLE: 'DES IDÉES D’EXERCICES',
      VIDEOS: [{
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video1.mp4?alt=media&token=628b6a24-a76a-4d60-805f-30cd8ae8132e",
          caption: "Vitesse de réaction"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video2.mp4?alt=media&token=4efd2e34-4bc7-4011-a3cd-eee3b7ec5512",
          caption: "Appuis - vivacité"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video3.mp4?alt=media&token=9777ec02-3860-49b4-b899-8dd0d51cb116",
          caption: "Appuis - vivacité"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video4.mp4?alt=media&token=333f1882-9751-4ebd-baf6-6413ca62e19f",
          caption: "Vivacité"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video5.mp4?alt=media&token=a450ae9e-ca2b-4023-b44e-4dd5a8e9657e",
          caption: "Echauffement"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video6.mp4?alt=media&token=b74a483f-429b-49ca-82fb-af4d3874a467",
          caption: "Echauffement"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video7.mp4?alt=media&token=5f0d08cb-c08a-4708-aa9f-d89636ef3e68",
          caption: "Echauffement"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video8.mp4?alt=media&token=f4a817f6-0812-45f0-ba3b-5dd2d7267483",
          caption: "Vitesse de réaction"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Video9.mp4?alt=media&token=1b3ef1ec-f646-464a-bdce-d208ae1db3b2",
          caption: "Echauffement"
        },
      ]
    },
    BLOG_CATE: {
      RETURN: 'Retour au blog',
      NOT_FOUND: "Aucun article n'a été trouvé dans cette catégorie.",
    }
  },
  ARTICLES_TITLE: 'Découvrez nos derniers articles…',
  ARTICLE_LINK: 'Lire la suite',
  APPSEC_MSG: 'Bientôt disponible',
  FAQ: {
    TITLE: 'Vous avez une question?',
    SUBTITLE: 'Nous avons peut être la réponse!',
    PLACEHOLDER: 'Rechercher...',
    ITEMS: [{
        id: 1,
        param: "firstQ",
        text: "Les qualités physiques en football",
      },
      {
        id: 5,
        param: "fifthQ",
        text: "Management",
      },
      {
        id: 2,
        param: "secondQ",
        text: "La prévention des blessures",
      },
      {
        id: 6,
        param: "sixQ",
        text: "Autre",
      },
      {
        id: 3,
        param: "thirdQ",
        text: "Jeunes",
      },
      {
        id: 7,
        param: "seventhQ",
        text: "Ce que nous proposons pour vous aider",
      },
      {
        id: 4,
        param: "fourthQ",
        text: "Préparation mentale",
      },
      {
        id: 8,
        param: "eighthQ",
        text: "Ce que nous ne proposons pas",
      },
    ]
  },
  APROPOS: {
    TITLE: 'A Propos',
    SUBTITLE: 'Pour cela, j’ai mis en place plusieurs moyens:',
    CONTENT_1: [
      `Il y a quelques années, au cours d’une formation avec différents
      préparateurs physiques et coachs amateurs, un constat fut réalisé
      par tout le monde : le manque d’informations sur la préparation
      physique en football amateur.`,
      `L’idée de créer un blog pour partager et échanger des connaissances
      sur la préparation physique était lancée! <br />
      Et rapidement des centaines de coachs m’ont fait part de leur
      reconnaissance et de leurs besoins (et si il était possible de les
      aider).`,
      `Mon objectif principal est donc de répondre au maximum à vos
      besoins.`,
    ],
    CONTENT_2: [
      `Vous avez par exemple accès à un blog avec des informations
      totalement gratuites sur de nombreux sujets liés à la préparation
      physique en football, mais aussi sur le management, la préparation
      mentale ou encore la nutrition.`,
      `Vous avez la possibilité de recevoir les ‘’emails privés’’ pour
      retrouvez chaque semaine des idées d’exercices physiques et des
      informations sur la préparation physique en football amateur.`,
      `Vous avez accès à des guides et programmes gratuits sur la
      préparation physique chez les jeunes footballeurs et sur la
      pré-saison.`,
      `Enfin, vous retrouverez de nombreux guides, programmes et formations
      sur la préparation physique en football.`,
      `J’essaie de me former continuellement pour pouvoir répondre à tous
      vos besoins. Si vous ne trouvez pas ce que vous recherchez sur la
      préparation physique en football amateur sur ce site, vous pouvez me
      contacter par mail et j'essaierai de vous aider ;)`,
      `Sportivement`,
    ],
    END: 'Préparateur physique',
  },
  MENTIONS: {
    LINK: {
      TEXT: 'www.preparationphysiquefootball.com',
      LINK: 'https://www.preparationphysiquefootball.com',
    },
    CONTENT: [
      {
        TITLE: 'MENTIONS LÉGALES',
        TEXT: `Merci de lire avec attention les différentes modalités d’utilisation du présent site avant d’y parcourir
        ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi,
        conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie
        numérique, les responsables du présent site internet www.preparationphysiquefootball.com sont :`,
      },
      {
        TITLE: `Editeur du Site`,
        TEXT: [
          `Conte Thibaud`,
          `Numéro de SIRET : 80922462900034`,
          `95 chemin des combes – 07270 Le crestet`,
          `Email : prepaphysiquefootball@gmail.com`,
          `Site Web : https://www.preparationphysiquefootball.com/`,
        ]
      },
      {
        TITLE: `Hébergement`,
        TEXT: [
          `Hébergeur: 1and1`,
          `7 place de la gare 57201 Sarreguemines`,
        ]
      },
      {
        TITLE: `Développement`,
      },
      {
        TITLE: `Objet du service`,
        TEXT: [
          `www.preparationphysiquefootball.com est un site d’information et de formation sur la préparation 
        physique en football.`,
          `Le lecteur doit cependant être averti que les résultats obtenus 
        (qu’ils soient positifs ou négatifs) dépendent uniquement de l’utilisation qui sera effectuée de 
        l’information et que l’auteur ne peut être tenu pour responsable de ces résultats et décline toute 
        responsabilité à l’égard des conséquences éventuelles de l’utilisation du site. 
        Le lecteur doit également être conscient que les avis, retours d’expérience ou conseils prodigués 
        ne sont pas des recommandations.`
        ],
      },
      {
        TITLE: `Conditions d’utilisation`,
        TEXT: [
          `Ce site est proposé en différents langages web (HTML5, Javascript, CSS3, JQuery, PHP, etc…) pour un
          meilleur
          confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs
          modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc… Les mentions légales ont été
          générées
          sur le site Générateur de mentions légales, offert par Welye. Préparation physique football met en œuvre
          tous
          les moyens dont il dispose, pour assurer une information fiable et une mise à jour fiable de ses sites
          internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc s'assurer de
          l'exactitude des informations auprès de , et signaler toutes modifications du site qu'il jugerait utile.
          n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou
          indirect pouvant en découler.`,
           `Préparation physique football s’engage à fournir une opinion honnête et sincère sur 
          l’ensemble des produits ou des services qui sont évoqués.`
        ]
      },
      {
        TITLE: `Cookies`,
        TEXT:
          `Le site www.preparationphysiquefootball.com peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage.
          Un cookies est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il
          contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un
          serveur accède pour lire et enregistrer des informations. Aucune information personnelle de l’utilisateur n’est 
          publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. `
      },
      {
        TITLE: `Liens hypertextes`,
        TEXT: [
            `Les sites internet de peuvent offrir des liens vers d’autres sites internet ou d’autres ressources
        disponibles sur Internet. Préparation physique football ne dispose d'aucun moyen pour contrôler les sites
        en
        connexion avec ses sites internet. Ne répond pas de la disponibilité de tels sites et sources externes, ni
        ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit,
        résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services
        qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette
        utilisation
        incombent pleinement à l'internaute, qui doit se conformer à leurs conditions d'utilisation. `,
        `Les utilisateurs, les abonnés et les visiteurs des sites internet de ne peuvent mettre en place un hyperlien
        en
        direction de ce site sans l'autorisation expresse et préalable de Préparation Physique Football.
        Préparation
        Physique Football se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa
        décision.`
        ]
      },
      {
        TITLE: `Services fournis`,
        TEXT:
          `L'ensemble des activités de la société ainsi que ses informations sont
          présentés sur notre site www.preparationphysiquefootball.com.
          Préparation physique football s’efforce de fournir sur le site
          www.preparationphysiquefootball.com des informations aussi précises
          que possible. Les renseignements figurant sur le site
          www.preparationphysiquefootball.com ne sont pas exhaustifs et les
          photos non contractuelles. Ils sont donnés sous réserve de modifications
          ayant été apportées depuis leur mise en ligne. Par ailleurs, toutes les
          informations indiquées sur le site www.preparationphysiquefootball.com
          sont données à titre indicatif, et sont susceptibles de changer ou
          d’évoluer sans préavis.`
      },
      {
        TITLE: `Limitation contractuelles sur les données`,
        TEXT:[
            `Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à
        différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous
        constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler
        par email, à l’adresse prepaphysiquefootball@gmail.com, en décrivant le problème de la manière la plus
        précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).`,
          `Tout contenu
        téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence,
        ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une
        quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à
        accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de
        dernière génération mis-à-jour Les liens hypertextes mis en place dans le cadre du présent site internet en
        direction d'autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de Préparation
        Physique Football.`]
      },
      {
        TITLE: `Propriété intellectuelle`,
        TEXT: [
        `Tout le contenu du présent sur le site `,
        ` incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, 
        gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l'exception des marques, 
        logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs. Toute reproduction, distribution,
        modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est
        strictement interdite sans l'accord exprès par écrit de Préparation Physique football. Cette représentation
        ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles
        L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue
        une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les
        propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.`,
      ]
      },
      {
        TITLE: `Déclaration à la CNIL`,
        TEXT: `Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la
          protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à
          l'informatique, aux fichiers et aux libertés, ce site n'a pas fait l'objet d'une déclaration auprès de la
          Commission nationale de l'informatique et des libertés (www.cnil.fr). `
      },
      {
        TITLE: `Litiges`,
        TEXT: [
          `Les présentes conditions du site `,
          ` sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de
          l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la
          société. La langue de référence, pour le règlement de contentieux éventuels, est le français.`,
        ],
      },
      {
        TITLE: `Données personnelles`,
        TEXT: [
          `De manière générale, vous n’êtes pas tenu de nous communiquer vos 
          données personnelles lorsque vous visitez notre site Internet.`,

          `En effet, pour certains services proposés par notre site, vous pouvez être amenés à 
          nous communiquer certaines données telles que : votre nom, votre adresse électronique. 
          Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, 
          dans la rubrique « contact » ou pour la "newsletter". Dans tous les cas, vous pouvez 
          refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser 
          les services du site, notamment celui de solliciter des renseignements sur notre société, 
          ou de recevoir les lettres d’information.`,
          
          `Vous pouvez vous désabonner de notre newsletter quand vous le souhaitez en cliquant 
          sur le lien "se désabonner" présent dans chaque email que nous vous envoyons`,
          
          `Vous pouvez également nous demander de modifier ou supprimer vos données personnelles. 
          Si nous ne respectons pas cet engagement vous pouvez faire une réclamation auprès de la CNIL. `
        ]
      },
      {
        TITLE: `Annulation et remboursement`,
        TEXT: [
          `Notre ambition première est de vous aider au maximum et donc de vous satisfaire. 
        Si vous ne l’êtes pas, nous vous remboursons intégralement sans limite de temps que ce soit 
        pour les produits numériques ou le matériel de préparation physique. 
        Conformément aux dispositions légales en vigueur, vous disposez d’un délai illimité à compter 
        de la réception de vos produits pour exercer votre droit de rétractation sans avoir à justifier 
        de motifs ni à payer de pénalité. 
        Le droit de rétractation peut-être exercé en ligne sur notre email prepaphysiquefootball@gmail.com, 
        exprimant la volonté de se rétracter.`,
        `Si vous considérez que le problème ou litige n’est pas résolu, 
        vous pouvez contacter un médiateur à cette adresse : 
        https://www.cnpm-mediation-consommation.eu/
        CNPM - MÉDIATION - CONSOMMATION - 27, avenue de la Libération – 42400 SAINT-CHAMOND`
        ],
      },
      {
        TITLE: `CONDITIONS GÉNÉRALES DE VENTES`,
        TEXT: `Ces conditions générales visent à vous avertir de manière claire et transparente 
        des conditions de ventes, d’être conforme à la loi et surtout de faire en sorte que vous soyez 
        satisfait de vos achats.`,
      },
      {
        TITLE: `ARTICLE 1 – Champ d’application`,
        TEXT: [
            `Les présentes conditions générales de vente s’appliquent à tous les achats réalisés sur le 
             site internet `,
            `Elle concerne les produits et services commercialisés par le site : produit numérique et matériel. 
            Toute personne réalisant un achat auprès du site est considérée comme « client ». `
        ]
      },
      {
        TITLE: `ARTICLE 2 – Commandes et livraison`,
        TEXT: [
          `Vous pouvez passer vos commandes sur Internet via le "shop"`,
          `Pour les produits numériques`,
          `Une fois votre commande saisie sur le site, un mail de confirmation vous
          sera adressé et votre commande enregistrée. A réception de cette
          commande, vous recevrez les informations nécessaires pour télécharger
          le produit ou recevrez directement le produit sur votre boite mail.
          Vous vous engagez à fournir une adresse email valide afin de pouvoir
          recevoir votre produit. Les produits d’informations électroniques
          commandés par le client pourront être utilisés sur son ordinateur (il
          pourra aussi les imprimer à ses frais).`,
          `Pour le matériel de préparation physique`,
          `Nos délais d'expédition varient de 10 à 21 jours en fonction des articles
          commandés. Les délais d'expédition dépendent de la disponibilité des
          produits commandés (exprimée en jours ouvrés, hors samedi, dimanche
          et jours fériés) et s'entendent à partir de la validation de votre
          commande`,
          `Les produits proposés sont disponibles dans la limite des stocks`,
          `En cas de retard d’expédition, un courrier électronique vous sera
          adressé pour vous informer d’une nouvelle date de livraison. A noter tout
          de même, Préparation Physique Football  ne peut être
          responsable de retard de livraison dû exclusivement à une indisponibilité
          du client après plusieurs propositions de rendez-vous par le
          transporteur.`,
          `Préparation Physique Football sera exonéré de toute
          responsabilité uniquement en cas de force majeure défini par la
          jurisprudence des tribunaux français comme un événement présentant
          un caractère imprévisible lors de la conclusion de contrat et irrésistible
          dans son exécution. En tout état de cause, conformément aux
          dispositions légales, en cas de retard de livraison, vous bénéficiez de la
          possibilité de résoudre le contrat dans les conditions et modalités
          définies à l’Article L 216-1 du Code de la consommation.`,
          `Vous pouvez bien sûr nous contacter en cas de problème (voir contact
          sur le site).`,
          `Livraison et responsabilité`,
          `En cas de non-conformité lors de la réception, vous devez notifier au
          transporteur et à Préparation Physique Football toutes
          réserves sur le produit livré (par exemple : colis endommagé, déjà
          ouvert...)`,
          `Vous disposez d'un délai de 3 jours pour faire d'éventuelles réserves
          auprès du transporteur en cas de manque ou de dégradation. Si la
          livraison est incomplète ou qu’un produit est détérioré, vous avez 2
          possibilités :`,
          `1) Vous acceptez la livraison en émettant impérativement des réserves
          sur le bon du transporteur`,
          `2) Vous refusez la commande dans sa totalité et inscrivez le motif sur le
          bon du transporteur.`,
          `Lorsque le client prend personnellement livraison des objets transportés
          et lorsque le transporteur ne justifie pas lui avoir laissé la possibilité de
          vérifier effectivement leur bon état, le délai de 3 jours mentionné à
          l'Article L. 133-3 du code de commerce qui éteint toute action contre le
          transporteur est porté à 10 jours.`,
          `Pour les livraisons hors France métropolitaine le client s'engage à régler
          toutes les taxes dues à l'importation de produits, droit de douane, taxe
          sur la valeur ajoutée, et toutes autres taxes dues en vertu des lois du
          pays de réception de la commande dégage de fait de toute
          responsabilité juridiques si l'acquittement des taxes n'était pas effectué
          par le client.`
        ]
      },
      {
        TITLE: `ARTICLE 3 – Disponibilités des articles`,
        TEXT: `Tant que nos offres et leurs prix sont visibles sur le site, elles sont valables. `,
      },
      {
        TITLE: `ARTICLE 4 – Prix`,
        TEXT: [
          `Les prix indiqués en euros s’entendent toutes taxes comprises et donc
          nets. Le prix est payable en totalité et en un seul versement à la
          commande sauf mention contraire. Toutes les commandes sont
          facturées en euros et payables en euros. 
          Préparation Physique Football se réserve le droit de
          changer les tarifs de ses produits à tout moment.`,

          `Dans le cas de produits numériques aucun frais de port ne sera facturé
          dans la mesure où le client se charge lui-même et à ses frais de
          télécharger le produit depuis le site web vers son ordinateur.`,

          `Pour le matériel de préparation physique, les frais de port sont notés
          avant la commande (dans le panier) et dépendent du lieu de résidence
          du client.`
        ],
      },
      {
        TITLE: `ARTICLE 5 – Paiement`,
        TEXT: `Les paiements s’effectuent par carte bancaire via notre prestataire Stripe ou 
        sinon à l’aide d’un compte Paypal ou bien par carte bancaire via PayPal, le client est 
        soumis aux conditions d’utilisations de PayPal. Même si nous sélectionnons scrupuleusement 
        nos prestataires, le site ne pourrait être tenu responsable d’un manquement lié au système de paiement.`,
      },
      {
        TITLE: `ARTICLE 6 – Remboursement des produits`,
        TEXT: `Notre ambition première est d’aider au maximum mes lecteurs et donc de les satisfaire. 
        Si vous ne l’êtes pas, nous vous remboursons intégralement sans limite de temps que ce 
        soit pour les produits numériques ou le matériel de préparation physique.`,
      },
      {
        TITLE: `ARTICLE 7 – Droit applicable`,
        TEXT: `L’ensemble de ces conditions générales de vente, ainsi que toutes 
        les opérations d’achat et de vente qui y sont décrites, sont soumises au droit français.`,
      },
      {
        TITLE: `ARTICLE 8 – Acceptation du client`,
        TEXT: `Les présentes conditions générales de vente ainsi que les tarifs sont expressément 
        agréés et acceptés par le client, qui déclare et reconnaît en avoir une parfaite connaissance. 
        Il renonce, de ce fait, à se prévaloir de tout document contradictoire et, 
        notamment de ses propres conditions générales d’achat. L’acte d’achat entraîne 
        l’acceptation des présentes conditions générales de vente. `,
      },
      {
        TITLE: `ARTICLE 9 – Droit d’utilisation et de reproduction`,
        TEXT: [
          `L’ensemble des textes et illustrations contenus dans les produits et services 
        visés sont protégés par le droit d’auteur et par le droit des bases de données, conformément au 
        Code de la propriété intellectuelle.`,
          `Le client s’interdit tout usage des produits et services à des fins autres que purement 
        documentaires, de même qu’il s’interdit de publier, diffuser ou vendre, de quelque manière 
        que ce soit, les contenus auxquels il accède. L’utilisation, reproduction, revente, ou simple 
        communication est formellement interdite sans le consentement écrit de ce site et de ses représentants 
        et fera systématiquement l’objet de poursuites.`,
          `Toute autre utilisation non prévue par le Code de la propriété intellectuelle est soumise à 
        l’autorisation préalable et écrite. `
        ],
      },
      {
        TITLE: `ARTICLE 10 – Responsabilité`,
        TEXT: [
          `Notre responsabilité ne peut être mise en cause, tant vis à vis des tiers que du client, 
        pour les conséquences de l’utilisation des résultats des recherches par le client ou d’omissions 
        à la suite d’une recherche infructueuse, défectueuse, partielle ou erronée, ou de la mauvaise 
        utilisation des réponses et textes consultés.`,
          `En conséquence, nous ne pourrons être tenu comme civilement responsable envers le client ou 
        des tiers de quelconque dommage direct ou indirect découlant de l’utilisation des informations, 
        et notamment consécutif à une information inexacte ou incomplète.`,
          `En aucun cas, nous ne pourrons être tenu responsable d’un quelconque dommage, de quelque nature 
        que ce soit, notamment perte d’exploitation, perte de données ou toute autre perte financière 
        résultant de l’utilisation ou de l’impossibilité d’utiliser les produits et services visés aux présentes. 
        En outre, aucune assistance procurée de manière
        gracieuse dans l’utilisation des produits et services ne peut créer de garantie supplémentaire 
        par rapport aux présentes conditions. `
        ],
      },
      {
        TITLE: `ARTICLE 11 - Droit de rétractation `,
        TEXT: [
          `Conformément aux dispositions légales en vigueur, vous disposez d’un 
        délai illimité à compter de la réception de vos produits pour exercer votre droit 
        de rétractation sans avoir à justifier de motifs ni à payer de pénalité. `,
          `Le droit de rétractation peut-être exercé en ligne sur notre email prepaphysiquefootball@gmail.com, 
        exprimant la volonté de se rétracter. `,
          `En cas de dépréciation du produit résultant de manipulations autres que celles nécessaires pour 
        établir la nature, les caractéristiques et le bon fonctionnement du produit, votre 
        responsabilité peut être engagée. `,
          `Si vous considérez que le problème ou litige n’est pas résolu, vous pouvez contacter un 
        médiateur à cette adresse : `,
        `https://www.cnpm-mediation-consommation.eu/`,
        `CNPM - MÉDIATION - CONSOMMATION - 27, avenue de la Libération – 42400 SAINT-CHAMOND`
        ],
      },
    ],
    SITE_WEB: 'Site Web:',
  },
  QUESTION: {
    BUTTON: "Plus d'infos",
    FIRST: {
      TITLE: '1. LES QUALITÉS PHYSIQUES EN FOOTBALL',
      SQUARE: {
        TITLE: `Rappel: VOUS RETROUVEREZ DES INFORMATIONS SUR TOUTES LES
        QUALITÉS PHYSIQUES SUR LE BLOG`,
        ITEMS: {
          TEXT_1: `Vous trouverez de nombreuses informations sur le
          <strong>travail de vitesse</strong> en football ici >>>`,
          TEXT_2: `Vous trouverez de nombreuses informations sur le
          <strong>travail d’endurance en football</strong> ici >>>`,
          TEXT_3: `Vous trouverez de nombreuses informations sur le
          <strong>travail de force en football</strong> ici >>>`,
          TEXT_4: `Vous trouverez de nombreuses informations sur le
          <strong
            >travail de coordination et de souplesse en football</strong
          >
          ici >>>`,
          TEXT_5: `Vous trouverez de nombreuses informations sur le
          <strong>travail physique avec les jeunes footballeurs</strong>
          ici >>>`,
        },
        LINKS: [
          'Vitesse',
          'Endurance',
          'Force',
          'Coordination',
          'Jeunes',
        ],
        BUTTON: "Plus d'infos",
      },
      LIST: [{
          id: 0,
          title: "Quelles qualités physiques travailler en pré-saison en football amateur?",
          description: `
            <p>
              Les qualités d’endurance (capacité et puissance), de force et de
              vitesse sont à travailler avant la reprise du championnat.
            </p>
            <div class="content">
              <p>
                Nous vous offrons un exemple de programme sur 6 semaines pour la
                pré-saison >>>
              </p>
            </div>`,
          link: "TreveEstivale",
        },
        {
          id: 1,
          title: "Quelles qualités physiques travailler au cours du dernier entraînement avant un match en football amateur?",
          description: `
            <p>
              La priorité du dernier entraînement avant un match doit
              logiquement être la préparation du match.
            </p>
            <p>
              Il n’est plus question de vouloir améliorer ou développer des
              qualités physiques mais plutôt de préparer au mieux le match.
            </p>
            <p>
              Certains entraîneurs feront beaucoup de travail tactique, d’autres
              beaucoup de travail technique…
            </p>
            <p>
              mais quid du travail physique pour ce dernier entraînement avant
              le match?
            </p>
            <div class="content">
              <p>
                Réponse ici :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201705',
            id: 'Quel-travail-physique-avant-un-match',
          },
        },
        {
          id: 2,
          title: "Quels sont les principaux tests pour évaluer l’endurance en football?",
          description: `
            <h5>Les tests ‘‘continus’’</h5>
            <p>
              <strong>Le Test « cooper »</strong> est un test de terrain qui
              peut s’effectuer autour d’une piste d'athlétisme. Il s'agit de
              parcourir la plus grande distance possible en 12 minutes. Il peut
              donner une indication sur le niveau en endurance et la forme des
              joueurs. Le demi-cooper (sur 6mn) peut donner une indication sur
              la VMA continue des joueurs.
            </p>
            <p>
              <strong>Le test « VAMEVAL »</strong> consiste à courir à une
              vitesse de course réglée au moyen d’une bande sonore. <br />
              Cette dernière émet des « bips » à intervalles réguliers. <br />
              A chaque bip, le sportif doit se trouver au niveau d'un des plots
              placés sur la piste (tous les 20 mètres). <br />
              La vitesse augmente de 0,5 km/h toutes les minutes ce qui
              correspond au franchissement d’un palier. <br />
              Le premier palier démarre sur un rythme de 8 km/h. <br />
              Le test fini quand le joueur aura un retard de plus de 3 m sur 2
              plots consécutifs. Il permet de déterminer la VMA continue des
              joueurs.
            </p>
            <p>
              <strong>Le test « luc leger »</strong> consiste à effectuer des
              allers-retours jusqu'à épuisement entre deux lignes espacées de 20
              mètres à une allure qui augmente de 0,5 km/h toutes les minutes.
              <br />
              Il permet de déterminer la VMA continue des joueurs.
            </p>
            <h5>Les tests ‘‘intermittents’’</h5>
            <p>
              Dans le <strong>test « Gacon 45/15 »</strong>, les joueurs doivent
              réaliser une succession de courses de 45 secondes d’effort
              entrecoupées par 15 secondes de récupération. <br />
              Le test débute à 8 Km/h ou 10 Km/h (en fonction du niveau). <br />
              La vitesse augmente de 0,5 Km/h chaque minute. <br />
              Il permet de déterminer la VMA intermittente des joueurs.
            </p>
            <div class="content">
              <p>
                Le test que nous recommandons :
                <strong>le test 30-15 ift</strong>
                <br />
                Il permet de déterminer la VMA intermittente des joueurs. <br />
                Plus d’explications ici :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201701',
            id: 'C-est-le-test-VMA-le-plus-adapte-en-football',
          },
        },
        {
          id: 3,
          title: "Quel échauffement avant un entraînement ou un match de football?",
          description: `
                        <p>
              L’échauffement constitue une phase importante de préparation à la
              compétition. Il comporte différents aspects psychologiques,
              techniques et physiologiques.
            </p>
            <p>
              Les deux rôles physiologiques de l'échauffement sont la prévention
              des blessures et la préparation à la performance .
            </p>
            <div class="content">
              <p>
                Nous vous partageons plus d’informations dans cet article
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2018',
            id: 'echauffement-en-football',
          },
        },
        {
          id: 4,
          title: "Le travail physique peut-il se faire avec ballon?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons toutes les explications dans la formation en
                ligne
              </p>
            </div>`,
          link: "Formation",
        },
        {
          id: 5,
          title: "Proposez-vous des exemples de séances d'entraînements physiques ?",
          links: [{
              description: `
            <div class="content">
              <p>
                Vous trouverez des exemples de séances dans certains articles du
                blog, mais aussi dans les programmes offerts (trêves estivale et
                hivernale) ainsi que dans les séances sur une saison
              </p>
            </div>`,
              link: "SaisonSenior",
            },
            {
              description: `
            <div class="content">
              <p>
                et les cycles d’entraînements physiques
              </p>
            </div>`,
              link: "CoachSenior",
            },
          ],
        },
        {
          id: 6,
          title: "Quelles qualités physiques sont essentielles à développer pour un gardien de but?",
          description: `
            <p>
              Toutes les qualités physiques sont importantes à travailler pour
              un gardien.
            </p>
            <div class="content">
              <p>
                Nous proposons des exemples de séance de vitesse (réaction,
                explosivité, gestuelle..) dans les cycles d’entraînements
                physiques
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 7,
          title: "Quelles qualités physiques sont essentielles à développer au futsal?",
          description: `
            <div class="content">
              <p>
                Toutes les qualités physiques sont importantes à travailler au
                futsal. <br />
                Nous proposons des exemples de séance de vitesse et d’endurance
                dans les cycles d’entraînements physiques
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 8,
          title: "Quels exercices proposer pour travailler les qualités physiques comme la vitesse, l’endurance ou la force ?",
          description: `
            <p>
            Nous proposons de nombreux exemples d’exercices dans certains articles du blog, dans l’application gratuite ou encore dans nos guides et programmes.
            </p>
            <p>
            Vous devriez trouver votre bonheur sur le site ;)
            </p>`,
        },
        {
          id: 9,
          title: "Comment répartir le travail physique dans la semaine et dans la séance?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’explications ici
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201707',
            id: 'Comment-repartir-le-travail-physique-dans-la-semaine-et-dans-la-seance',
          },
        },
      ],
    },
    SECOND: {
      TITLE: '2. La prévention des blessures',
      LIST: [{
          id: 0,
          title: "Quel travail physique réaliser pour éviter les blessures ?",
          links: [{
              description: `            
                <p>
                  Nous vous suggérons aussi de faire un check up avec un ostéopathe
                  par exemple ou un professionnel de santé pendant la trêve
                  estivale.
                </p>
                <p>
                  Bien se préparer avant la reprise des entraînements pourra
                  également aider à prévenir les blessures (qui sont nombreuses au
                  cours de la reprise).
                </p>
                <div class="content">
                  <p>
                    Avant chaque entraînement et match, un bon échauffement
                    permettrait de réduire la probabilité d’avoir une blessure
                    musculaire ou articulaire. Nous vous donnons plus d’informations
                    ici
                  </p>
                </div>`,
              link: "Article",
              article: {
                year: '201707',
                id: 'L-echauffement-Prevention-des-blessures-en-Football',
              },
            },
            {
              description: `
                <p>
                  Ensuite, il est important d’avoir une bonne hygiène de vie et de
                  bien récupérer après les entraînements et les matchs.
                </p>
                <p>
                  Une bonne récupération va permettre d'être moins fatigué et plus
                  frais pour les futurs entraînements et matchs.
                </p>
                <p>
                  Si l'on est frais et pas fatigué, le
                  <span class="red">risque de blessure est plus faible</span> .
                </p>
                <p>
                  Les entraînements doivent être adaptés au public et progressifs.
                </p>
                <p>
                  Pour le travail de prévention spécifique en football, le
                  renforcement musculaire, le travail de gainage ou encore la
                  proprioception auront une place de choix dans vos programmes.
                </p>`,
            },
          ],
        },
        {
          id: 1,
          title: "Quel programme physique réaliser après une blessure?",
          description: `
          <p>
              Cela va dépendre du type de blessure, de l’articulation ou du
              muscle touché, et d’autres paramètres spécifiques à la personne
              blessée (âge, niveau, …).
            </p>
            <p>
              La rééducation et la réathlétisation vont donc être
              personnalisées.
            </p>
            <p>
              Pour cela, nous ne proposons pas de programme de réathlétisation
              et nous vous recommandons de faire appel à un kinésithérapeuthe
              et/ou un préparateur physique pour vous aider dans ces phases là
              avant votre retour au terrain.
            </p>
            <div class="content">
              <p>
                Nous vous donnons toutefois quelques conseils pour des blessures
                ‘’pas trop graves’’ (comme une blessure musculaire aux ischios
                jambiers ou une entorse de cheville) dans le blog. <br />
                Plus d’infos ici >>>
              </p>
            </div>`,
          link: "BlogCategory",
          paramId: "Prevention",
        },
        {
          id: 2,
          title: "Comment réaliser un strapping à la cheville avant un entraînement ou un match?",
          description: `
            <div class="content">
              <p>
                Nous avons partagé une vidéo de Anais (osteopathe) sur notre
                page facebook qui pourrait vous aider >>>
              </p>
            </div>`,
          external: "https://www.facebook.com/preparationphysiquefootball/posts/1178668345659478",
        },
        {
          id: 3,
          title: "Quelles sont les causes principales de blessures en football?",
          description: `
            <p>
              Il existe des facteurs qui favoriseraient l’apparition des
              blessures. Nous vous les présentons:
            </p>
            <h5>Les Facteurs individuels</h5>
            <p>
              - Perte d’élasticité musculaire avec l’âge <br />
              - Déficit d’élasticité musculaire (limite les amplitudes
              articulaires nécessaires à la pratique sportive) <br />
              - Convalescence, grippe, état infectieux <br />
              - Facteurs psychologiques (grande émotivité, stress)
            </p>
            <h5>Les Facteurs hygiéno-diététiques</h5>
            <p>
              -Hydratation insuffisante <br />
              - Manque de sommeil <br />
              - Alimentation déséquilibrée <br />
              - Dopage : anabolisants (favorisent l’apparition des crampes,
              augmentent le volume musculaire sans pour autant augmenter la
              résistance des tendons), corticoïdes (reculent le seuil de
              douleur, fragilisent les tissus) <br />
              - Mauvaise hygiène dentaire (bactéries des caries qui infiltrent
              le sang et crée des infections) <br />
              - Tabagisme <br />
            </p>
            <h5>Les Facteurs liés à l’entraînement</h5>
            <p>
              - <span class="red">Absence ou insuffisance d’échauffement</span>
              <br />
              - Entraînement excessif ou mal programmé <br />
              -
              <span class="red"
                >Musculation disharmonieuse agoniste-antagoniste</span
              >
              <br />
              - <span class="red">Déficit de force en excentrique</span> <br />
              - Absence de récupération <br />
            </p>
            <h5>Les Facteurs environnementaux</h5>
            <p>
              - Humidité <br />
              - Froid, vent <br />
              - Sol glissant <br />
            </p>`,
        },
        {
          id: 4,
          title: "Quelles sont les principales blessures en football?",
          description: `
            
            <p>
              <span class="red"
                >Le football est un sport à risques au niveau des blessures.
              </span>
            </p>
            <p>
              Dans une étude réalisée par l'UEFA durant la saison 2012-2013 et
              qui portait sur 22 clubs du top européen, 892 blessures ont été
              recensées!
            </p>
            <h5 class="blue">Quelles sont ces blessures?</h5>
            <p>
              D’après une étude de l’UEFA, Les déchirures, les claquages, les
              contractures représentent 38% des blessures des footballeurs et la
              cuisse est le membres le moins épargné par ces blessures (80% rien
              que pour les ischio-jambiers )
            </p>
            <p>
              On comprend l’importance d’un travail de prévention des
              ischio-jambiers ( une faiblesse des ischio-jambiers aurait
              également une influence sur les blessures aux genoux ..).
            </p>
            <p>
              25,4% des blessures concernent les articulations du genou ou des
              chevilles (entorses principalement) et 14% des blessures du
              footballeur concernent les hanches et l’aine (pubalgie).
            </p>
            <h5 class="blue">Comment arrivent ces blessures?</h5>
            <p>
              Voici un tableau intéressant sur les principales actions menant à
              des blessures (Hawkins et al, 2001).
            </p>
            <img
              src="https://www.preparationphysiquefootball.com/images/blog/Dessin%20sans%20titre%20(23).jpg"
              class="img-fluid"
              alt="table"
            />
            <p>
              On remarque que l’on se blesse très souvent “seul” (course,
              changement de direction, frappe, saut,..).
            </p>
            <p>
              Presque la moitié des blessures en match apparaîtraient dans les
              30 dernières minutes.
            </p>`,
        },
      ],
    },
    THIRD: {
      TITLE: '3. Les jeunes',
      LIST: [{
          id: 0,
          title: "Pourquoi faire du physique chez les jeunes?",
          description: `
            <p>
              Le travail physique chez les jeunes a 3 grands objectifs :
            </p>
            <p>
              - L'amélioration des performances <br />
              - La prévention des blessures <br />
              - Travail préparatoire <br />
            </p>
            <p>
              Mais il permet aussi :
            </p>
            <p>
              De développer le mental, le côté "dureté" du travail physique...
              pour passer des paliers, "aimer se faire mal" semble être
              obligatoire.. <br />
              De donner des habitudes et des attitudes d'entraînements <br />
              De tendre vers l'autonomie et acquérir certaines valeurs
              essentielles : rigueur, discipline, travail.. <br />
              De rendre le jeune "proactif", acteur de sa pratique et non
              seulement "consommateur"... <br />
              De faire la différence entre "aller à l'entraînement" et "aller
              s'entraîner".. <br />
            </p>`,
        },
        {
          id: 1,
          title: "Quelles qualités physiques travailler en priorité avec les jeunes footballeurs?",
          description: `
            <p>
              Pour chaque qualité physique , il existerait des périodes clés
              nommées <span class="red">“âge d’or”</span> .
            </p>
            <p>
              Si l’on travaille bien une qualité physique pendant cette période,
              son développement devrait être optimal ! (le jeune devrait
              beaucoup progresser sur cette qualité physique).
            </p>
            <br />
            <p>
              Prendre en compte les ” âges d’or” de développement des qualités
              physiques pourrait être une aide précieuse pour bien cibler les
              qualités physiques à développer en priorité selon l’âge des
              joueurs (âge biologique).
            </p>
            <p>
              Par exemple, des U6 aux U12, le travail de coordination est
              essentiel car on est dans l’âge d’or pour le développement de
              cette qualité.
            </p>
            <p>
              Pour développer la force, un an après le pic de croissance serait
              la période optimale.
            </p>
            <div class="content">
              <p>
                Nous vous donnons plus d’explications sur ce thème dans cet
                article :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201706',
            id: 'La-formation-des-jeunes-footballeurs',
          },
        },
        {
          id: 2,
          title: "Comment développer de façon optimale les jeunes footballeurs sur le plan physique (et mental)?",
          links: [{
              description: `
                <p>
                  Nous avons vu qu’il y a des âges d’or pour le développement de
                  chacune des qualités physiques.
                </p>
                <p>Il existe également un ‘’plan de référence’’</p>
                <p>
                  Des entraîneurs et des chercheurs ont établi ‘’un plan de
                  référence ‘’ qui donne les étapes à suivre au cours de l’enfance
                  et l’adolescence pour un développement optimal.
                </p>
                <div class="content">
                  <p>
                    Nous vous donnons plus d’informations dans ces articles :
                  </p>
                </div>`,
              link: "Article",
              article: {
                year: '201706',
                id: 'La-formation-des-jeunes-footballeurs',
              },
            },
            {
              link: "Article",
              article: {
                year: '2019',
                id: 'developpement-a-long-terme-du-jeune-footballeur',
              },
            },
          ],
        },
        {
          id: 3,
          title: "Faut-il faire du travail de force chez les jeunes footballeurs?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’informations dans cet article
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201705',
            id: 'Faut-il-faire-un-renforcement-musculaire-chez-les-jeunes-footballeurs',
          },
        },
        {
          id: 4,
          title: "Les jeunes footballeurs doivent-ils s'étirer?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’informations dans cet article :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2018',
            id: 'jeunes-et-etirements',
          },
        },
        {
          id: 5,
          title: "Faut-il intégrer le travail physique chez les jeunes?",
          description: `
            <div class="content">
              <p>
                Vous trouverez plus d’explications dans le guide en ligne
                (offert) sur la préparation physique chez les jeunes
                footballeurs >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 6,
          title: "Les jeunes peuvent-ils faire des exercices intenses?",
          description: `
            <div class="content">
              <p>
                Vous trouverez plus d’explications dans le guide en ligne
                (offert) sur la préparation physique chez les jeunes
                footballeurs >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 7,
          title: "Quel travail physique proposer à des U8-U9?",
          description: `
            <div class="content">
              <p>
                Le travail de coordination motricité et de la vitesse sont
                essentiels pour ces catégories. <br />
                Nous proposons des exemples de cycles et d’exercices pour
                travailler ses qualités physiques >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 8,
          title: "Quel travail physique proposer à des U10-U11?",
          description: `
            <div class="content">
              <p>
                Le travail de coordination motricité et de la vitesse sont
                essentiels pour ces catégories. <br />
                Nous proposons des exemples de cycles et d’exercices pour
                travailler ses qualités physiques >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 9,
          title: "Quel travail physique proposer à des U12-U13?",
          description: `
            <div class="content">
              <p>
                Le travail de la vitesse est essentiel pour ces catégories. Nous
                proposons des exemples de cycles et d’exercices pour travailler
                cette qualité physique >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 10,
          title: "Quel travail physique proposer à des U14-U15?",
          description: `
            <div class="content">
              <p>
                Le travail d’endurance puissance et de vitesse sont essentiels
                pour ces catégories. Nous proposons des exemples de cycles et
                d’exercices pour travailler ses qualités physiques >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 11,
          title: "Quel travail physique proposer à des U16-U17?",
          description: `
            <div class="content">
              <p>
                Le travail d’endurance puissance et de vitesse sont essentiels
                pour ces catégories. Nous proposons des exemples de cycles et
                d’exercices pour travailler ses qualités physiques >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
      ],
    },
    FOURTH: {
      TITLE: '4. La préparation mentale en football',
      LIST: [{
          id: 0,
          title: "C’est quoi la préparation mentale?",
          description: `
            <p>
              Selon J. Fournier, la préparation mentale se définit comme la
              «préparation à la compétition par un apprentissage d’habiletés
              mentales et d’habiletés cognitives, et dont le but principal est
              d’optimiser la performance personnelle de l’athlète tout en
              promouvant le plaisir de la pratique et en favorisant l’atteinte
              de l’autonomie ».
            </p>
            <p>
              La préparation mentale va vous permettre de préparer un match de
              la meilleure manière possible et d’optimiser votre performance en
              compétition.
            </p>
            <br />
            <h5 class="blue">
              Quels sont les objectifs de la préparation mentale?
            </h5>
            <br />
            <p>
              Permettre à l’individu de prendre conscience des nombreux
              paramètres mentaux présents dans chaque activité, qu' elle soit
              sportive ou non, et de les travailler pour s’améliorer.
            </p>
            <p>
              L’objectif de la préparation mentale c’est aussi de développer,
              potentialiser et optimiser les capacités mentales de l’individu
              afin qu’il exprime pleinement ses qualités (physiques, techniques
              et tactiques) à l’entraînement et en compétition ou dans la vie de
              tous les jours et le jour de l’échéance, et de lui permettre
              d’être dans des conditions psychologiques optimales le jour J à
              l’heure H en vue de réaliser une performance, de manière autonome.
              <br />
              Le but de la préparation mentale est donc d’améliorer les
              performances tout en préservant l’équilibre et la santé de
              l’individu. <br />
              Pour Denis Troch, il s’agit de “tirer la quintessence d’un
              potentiel !”.
            </p>`,
        },
        {
          id: 1,
          title: "Quelles méthodes sont utilisées en préparation mentale?",
          description: `
            <p>
              La Préparation Mentale utilise des méthodes et des techniques
              diverses, qui peuvent être d’ordre Physiologique (relaxation,
              contrôle respiratoire, méditation..), Cognitive (imagerie,
              dialogue interne…), Comportementale (confiance en soi,
              concentration, PNL…) ou encore Composite (gestion du stress, de
              l’anxiété..) .
            </p>`,
        },
        {
          id: 2,
          title: "Quels sont les intérêts et avantages de la préparation mentale?",
          description: `
            <p>
              Les intérêts et avantages à travailler en préparation mentale sont
              nombreux, aussi bien pour l’individu que pour le groupe et en
              voici une liste non exhaustive :
            </p>
            <h5><i>Pour l’individu :</i></h5>
            <br />
            <ul class="list">
              <li>Améliorer sa connaissance de soi</li>
              <li>Améliorer sa motivation</li>
              <li>Améliorer sa confiance en soi</li>
              <li>Améliorer le relationnel et la communication</li>
              <li>Améliorer sa concentration</li>
              <li>Mieux gérer ses émotions</li>
              <li>Mieux gérer son stress</li>
              <li>Mieux récupérer</li>
              <li>Favoriser les performances :</li>
              <li>Apprentissage de nouveaux gestes /Correction de gestes/</li>
              <li>
                Ancrer les sensations motrices /Ancrer les souvenirs positifs/
              </li>
              <li>Limiter le risque de blessure</li>
              <li>Améliorer la réathlétisation / rééducation</li>
            </ul>
            <br />
            <h5><i>Pour le groupe :</i></h5>
            <br />
            <ul class="list">
              <li>Développer la cohésion de groupe</li>
              <li>Faire émerger les différents leaders</li>
              <li>Développer des stratégies sportives</li>
              <li>
                Grâce à ce travail de préparation mentale, on va pouvoir:
              </li>
              <li>renforcer les réussites et réduire les erreurs</li>
              <li>
                exprimer pleinement ses qualités physiques, techniques et
                tactiques à l’entraînement et en compétition
              </li>
              <li>
                être dans des conditions psychologiques optimales,le jour J à
                l’heure H
              </li>
            </ul>`,
        },
        {
          id: 3,
          title: "Qui peut utiliser la préparation mentale?",
          description: `
              <p>
              La préparation mentale s’adresse à tout athlète désirant
              développer ses capacités, atteindre ses objectifs, améliorer ses
              performances , restaurer ces relations avec son entraîneur, ses
              coéquipiers, redevenir l’acteur de sa performance… mais aussi à
              tous les entraîneurs et à tous les groupes de sportifs.
            </p>`,
        },
        {
          id: 4,
          title: "Je ne suis pas un footballeur de haut niveau, puis-je faire de la préparation mentale?",
          description: `
            <p>
              La préparation mentale a pour objectif votre bien être et doit
              vous aider à développer des habiletés (concentration, motivation,
              confiance en soi, gestion des émotions...) Pas besoin d'être
              sportif de haut niveau pour mieux se connaître et vouloir
              améliorer ses performances !
            </p>`,
        },
        {
          id: 5,
          title: "Utiliser la préparation mentale est ce difficile?",
          description: `
            <p>
              Non, on a souvent une image erronée de ce qu’est la préparation
              mentale mais elle est beaucoup plus simple à appliquer que ce
              qu’on imagine.
            </p>`,
        },
        {
          id: 6,
          title: "Faut-il croire à la préparation mentale pour avoir des résultats positifs?",
          description: `
            <p>
              Non, la préparation mentale est une discipline issue de recherche
              en psychologie du sport et dispose de théories et d'outils
              performants et validés scientifiquement pour améliorer et
              optimiser les habiletés mentales et le bien être des sportifs !
            </p>`,
        },
        {
          id: 7,
          title: "Je n’ai pas de “problème” sur le plan mental, la préparation mentale est donc inutile?",
          description: `
            <p>
              En France nous avons beaucoup de retard sur la préparation mentale
              des sportifs et souvent nous pensons qu’elle n’est utile que
              lorsque quelque chose ne va pas. <br />
              A l’étranger, de nombreux sportifs font appel à un préparateur
              mental quand justement tout va bien ! Pourquoi? pour renforcer et
              encore améliorer certains aspects en lien avec le mental
              (concentration, gestion des émotions, ...) <br />
              C’est un peu comme la médecine, nous l’utilisons quand nous sommes
              malades, d’autres (les chinois par exemple) l’utilisent quand tout
              va bien!
            </p>
            <p>
              Au même titre que le physique, la technique ou la tactique, le
              mental peut et doit être entraîné! <br />
              C’est même souvent le mental qui fait la différence en
              compétition! <br />
              Cela s’apprend , se développe!
            </p>
            <p>
              « Je crois que c’est l’aspect mental qui est la partie essentielle
              du tennis mais les gens ne le savent pas. » <br />
              Boris Becker, ancien tennisman.
            </p>
            <p>
              « Il faut être ni stressé sinon tu perds tes moyens, ni trop
              décontracté sinon tu ne prends pas conscience de l’enjeu. Tu dois
              donc trouver le juste milieu. En partant à l’OM puis au milan,
              j’ai pris l’habitude d’avoir le bon comportement: savoir être un
              combattant et rester lucide en même temps, mais aussi se mettre un
              supplément de pression sans pour autant être en difficulté. C’est
              une question d’équilibre. » <br />
              Marcel Desailly (footballeur).
            </p>
            <p>
              "L'aspect physique du sport ne peut pas vous mener au-delà de
              certaines limites," a déclaré Shannon Miller, champion olympique
              de gymnastique lors d'une interview accordée à la Dana Foundation.
              "L'aspect mental doit prendre le relais, surtout lorsqu'il s'agit
              d'être le meilleur parmi les meilleurs. Aux Jeux olympiques, tout
              le monde est talentueux. Tout le monde s'entraîne dur. Tout le
              monde travaille. Ce qui fait la différence entre un médaillé d'or
              et un médaillé d'argent, c'est simplement l'aspect mental.”
            </p>`,
        },
      ],
    },
    FIFTH: {
      TITLE: '5. Le management',
      LINK_1: 'Management',
      LIST: {
        FAQ_1: {
          TEXT: `Vous retrouverez des informations sur ce thème du management
          sur le blog`,
          BUTTON: "Plus d'infos",
        },
        FAQ_2: {
          TEXT: `Nous proposons également un guide sur le management pour les
          entraîneurs de football amateur >>>`,
          BUTTON: "Plus d'infos",
        },
      },
    },
    SIX: {
      TITLE: '6. Autre',
      LIST: [{
          id: 0,
          title: "Quelle préparation physique pour un groupe féminin?",
          description: `
            <p>
              Nous vous recommandons ce livre : ‘’La prépa physique football
              féminin - préparation athlétique, spécificités et prévention’’
            </p>`,
        },
        {
          id: 1,
          title: "Que doit manger un footballeur?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’explications sur le blog dans la partie
                nutrition
              </p>
            </div>`,
          link: "BlogCategory",
          paramId: "Nutrition",
        },
        {
          id: 2,
          title: "Comment récupérer en football?",
          links: [{
              description: `
              <p>
                Il y a 3 grands piliers :
              </p>
  
                <p>
                  Le sommeil. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
  
              <br />
            `,
              link: "Article",
              article: {
                year: '2018',
                id: '7-astuces-pour-bien-dormir',
              },
            },

            {
              description: `
              <div class="content">
                <p>
                  L’alimentation. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '201706',
                id: 'Que-manger-apres-les-matchs-et-les-entrainements',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  L’hydratation. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '201705',
                id: 'Hydratation',
              },
            },
            {
              description: `
              <p>
                Ensuite il y a des techniques complémentaires qui peuvent
                favoriser la récupération comme par exemple :
              </p>
              <br />
              <div class="content">
                <p>
                  Le froid. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '201706',
                id: 'Recuperation-douche-froide-douche-chaude',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  L’électrostimulation. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>`,
              link: "Article",
              article: {
                year: '201707',
                id: 'Recuperer-avec-l-electrostimulation',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  Les décrassage <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '201706',
                id: 'Le-decrassage-en-football',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  Les chaussettes de contention. <br />
                  Nous vous donnons plus d’informations dans cet article :
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '201706',
                id: 'Recuperation-les-chaussettes-de-contention',
              },
            },
          ],
        },
        {
          id: 3,
          title: "Quel matériel utiliser pour le travail de vitesse?",
          description: `
              <p>
              Il est tout à fait possible de travailler la vitesse sans
              matériel.
            </p>
            <p>
              Vous pouvez faire une multitude d'exercices seulement avec
              quelques cônes !
            </p>
            <p>Vous pouvez par exemple :</p>
            <ul class="list">
              <li>
                varier les départs (de dos, de côté, en mouvement, en marche
                arrière, ...),
              </li>
              <li>
                vous pouvez varier les déclencheurs de départ ( sonore, visuel,
                ..),
              </li>
              <li>vous pouvez varier les distances,</li>
              <li>
                vous pouvez introduire des changements de direction ( cônes
                décalés),
              </li>
              <li>vous pouvez introduire des allers retours,</li>
              <li>
                vous pouvez introduire des allers en sprints et retour en marche
                arrière ou avec changement de direction,
              </li>
              <li>
                vous pouvez introduire des "cônes spéciaux" où le joueur doit
                effectuer une action spécifique (un tour du cône par exemple),
              </li>
            </ul>
            <p>...</p>
            <p>
              Donc même sans matériel vous pouvez proposer des choses
              intéressantes.
            </p>
            <p>
              Pour apporter un peu de variété, de nouveauté et un côté plus
              ludique, il est possible d'utiliser du matériel.
            </p>
            <p>Vous pouvez introduire du matériel pour :</p>
            <ul class="list">
              <li>
                créer de la résistance ( traineau de puissance, élastiques,
                parachute, gilet lesté..),
              </li>
              <li>
                pour aider ou alléger la course ( élastique tendu placé dans le
                sens de la course), pour créer un obstacle (haies basses, plots,
                ..),
              </li>
              <li>
                pour le départ ( par exemple, medecine ball dans les mains en
                position "chaise" ou squat , statique 5 secondes, puis lâcher le
                médecine ball et "exploser" au démarrage, ..) pour modifier une
                partie de la course ( placer une échelle de rythme après 10m de
                sprint, le joueur doit ralentir rapidement, changer d'appuis sur
                l'échelle, puis repartir en sprint sur quelques mètres...).
              </li>
            </ul>`,
        },
        {
          id: 4,
          title: "Quel échauffement en football ?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’informations dans cet article :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2018',
            id: 'echauffement-en-football',
          },
        },
        {
          id: 5,
          title: "Faut-il faire un décrassage après un match?",
          description: `
            <div class="content">
              <p>
                Nous vous donnons plus d’informations dans cet article :
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '201706',
            id: 'Le-decrassage-en-football',
          },
        },
      ],
    },
    SEVENTH: {
      TITLE: '7. Ce que nous proposons pour vous aider',
      LIST: {
        FAQ_1: {
          TEXT: `Nous vous proposons un blog avec des informations totalement
          gratuites.`,
          BUTTON: "Plus d'infos",
        },
        FAQ_2: {
          TEXT: `Nous vous proposons des exemples de programmes (offerts)
          pour la reprise estivale et la trêve hivernale.`,
          BUTTON: "Plus d'infos",
        },
        FAQ_3: {
          TEXT: `Nous vous proposons un guide sur la préparation physique des
          jeunes footballeurs (offert)`,
          BUTTON: "Plus d'infos",
        },
        FAQ_4: {
          TEXT: `Nous vous proposons un PDF avec des exemples d’exercices
          physiques pour travailler la vitesse et l’endurance (offert)`,
          BUTTON: "Plus d'infos",
          LINK: 'https://www.preparationphysiquefootball.com/documents/85-exercices-physiques-avec-ballon-ppf.pdf',
        },
        FAQ_5: {
          TEXT: `Nous vous proposons des idées d’exercices pour travailler la
          partie physique`,
          BUTTON: "Plus d'infos",
        },
        FAQ_6: {
          TEXT: `Nous vous proposons des exemples de programmes sur la saison
          et de cycles d’entrainements pour toutes les catégories`,
          BUTTON: "Plus d'infos",
        },
        FAQ_7: {
          TEXT: `Nous vous proposons des formations pour améliorer vos
          connaissances sur plusieurs thèmes en lien avec la
          préparation physique en football amateur.`,
          BUTTON: "Plus d'infos",
        },
      }
    },
    EIGHTH: {
      TITLE: '8. Ce que nous ne proposons pas',
      CONTENT: {
        TEXT_1: `4-4-2, 4-3-3, 4-5-1, animation offensive-défensive, possession,
        bloc défensif, bloc équipe... : Nous recevons beaucoup de
        messages sur ces thèmes-là et à propos de la tactique en général
        mais nous sommes ‘’seulement’’ spécialistes de la préparation
        physique et nous souhaitons rester dans ce que nous connaissons
        bien pour vous proposer un contenu de qualité.`,
        TEXT_2: `Nous vous recommandons d’autres sites très intéressants qui
        abordent la partie tactique et qui pourraient répondre à vos
        besoins comme <a href="https://entrainementfootballpro.fr/" target="_blank">https://entrainementfootballpro.fr/</a> 
        ou a <a href="https://entrainementdefoot.fr/" target="_blank">https://entrainementdefoot.fr/</a>.`,
        TEXT_3: `Même chose pour les questions qui concernent spécifiquement la
        partie technique comme le travail de passes, de contrôles, de
        dribbles, …`,
        TEXT_4: `Nous incluons souvent du travail technique ou tactique dans nos
        exercices physiques (pour travailler de manière plus intégrée)
        mais nous ne proposons pas d’exercices spécifiques sur la
        technique ou la tactique en football.`,
      }
    },
  },
  PROGRAMMES: {
    TITLE: 'NOS PROGRAMMES SPÉCIAL',
    CARDS: [{
        title: "Programme Pré-Saison",
        subtitle: "Gardiens de But",
        text: `<p class="card-text">Votre programme à <span class="promo">12.90€</span> au lieu de <span class="price">19.90€</span></p>`,
        button: "En savoir plus",
      },
      {
        title: "Programme Pré-Saison",
        subtitle: "futsal",
        text: `<p class="card-text">Votre programme à <span class="promo">12.90€</span> au lieu de <span class="price">19.90€</span></p>`,
        button: "En savoir plus",
      },
    ],
    COMMANDE: {
      TEXT_1: `Après avoir commandé, vous allez recevoir directement un email
      (dans les 5 minutes) avec le lien d’accès à la plateforme en ligne
      et à vos programmes.`,
      TEXT_2: `Tous les programmes sont disponibles sur mobile, tablette et
      ordinateur.`,
    },
    END: {
      TEXT_1: `Nous souhaitons vous proposer un contenu de qualité et un
      contenu qui vous aide et vous satisfait pleinement. Ainsi,
      toutes nos formations, programmes ou guides sont 100% satisfait
      ou remboursé!`,
      TEXT_2: `Si le contenu ne vous convient pas, il vous suffit de nous
      envoyer un email et nous vous remboursons directement.`,
    }
  },
  FOOTER: {
    LINKS: ['Mentions légales', 'À propos', 'Contact'],
    END: '© 2023 Préparation Physique Football, Tous droits réservés',
    END_MOBILE: `© 2023 Préparation Physique Football, <br /> Tous droits réservés`,
    FACEBOOK: 'https://www.facebook.com/preparationphysiquefootball/',
    INSTAGRAM: 'https://www.instagram.com/preparationphysiquefootball/',
  },
  PRODUCT_SHOP: {
    CONTENT: {
      TEXT_1: 'Accédez directement au',
      TEXT_2: 'après votre commande.',
    },
    BUTTON: 'Commander',
    END: `En cliquant sur COMMANDER, vous allez être redirigé vers la
    boutique en ligne afin de pouvoir passer votre commande.`,
  },
  CYCLES: {
    PROGRAMME: `Le programme en ligne est disponible sur mobile, tablette et
    ordinateur.`,
    APRES: {
      TITLE: `Après avoir commandé, vous allez recevoir directement un email
      avec le lien d’accès au guide en ligne.`,
      END: `L'objectif est surtout là, vous donner des idées, des exemples
      afin que vous ayez une base sur laquelle vous appuyer pour
      concevoir vos propres cycles et exercices!`
    },
    GARANTIE: {
      TITLE: 'Garantie Satisfait ou Remboursé',
      SUBTITLE: 'Si vous n’êtes pas complètement satisfait, envoyez un simple email pour être remboursé !',
    },
    PAIEMENT: {
      TITLE: 'Paiement 100% sécurisé',
      SUBTITLE: 'Le paiement est totalement sécurisé par des plate-formes reconnues comme Paypal.',
    },
    CONTACT: {
      TEXT_1: 'Si vous avez des questions sur le guide n’hésitez pas à nous les poser',
      TEXT_2: 'sur le chat(en bas à droite de la page) ou bien sur notre',
      LINK: 'page de contact'
    },
  },
  FUTSAL_P: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL ENDURANCE PUISSANCE`,
      SUBTITLE: `Avec 100 exemples d'exercices d'endurance`,
      SUBSUB: "Pour les séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance en futsal ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance puissance en futsal?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
        Nous proposons un programme en ligne avec
        <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
        et 100 idées d'exercices pour travailler l'endurance en futsal.
      </p>
      <p>
        Ce guide s’adresse aux coachs d'équipes séniors de futsal qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de l'endurance puissance (avec 2 séances par semaine soit 16 séances pour travailler l'endurance puissance)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
      </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles d'entraînements physiques pour travailler l'endurance puissance en futsal. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
        </p>`,
      COL2: `<p>
          Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
        </p>
        <p>
          Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL "ENDURANCE PUISSANCE''`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/69_1080x.png?v=1619856076",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-en-futsal-special-endurance-puissance",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 CONTRE 4 AVEC GARDIENS",
          text: `
        <p><i class="fas fa-check"></i> 4 zones sur un terrain complet.</p>
        <p><i class="fas fa-check"></i> Jamais plus de 2 joueurs d'une même équipe dans une même zone.</p>
        <p><i class="fas fa-check"></i> Jeu libre, 2 minutes de jeu, 6 parties par exemple.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "CONSERVATION 4 CONTRE 2 ET 6 CONTRE 6",
          text: `
        <p><i class="fas fa-check"></i> Alternance entre conservation en supériorité numérique dans un petit espace (4 contre 2) et conservation sur grand espace à 6 contre 6.</p>
        <p><i class="fas fa-check"></i> 2 minutes à 4 contre 2 (les 2 joueurs au pressing changent toutes les 30 secondes). 1 point marqué toutes les 10 passes de suite.</p>
        <p><i class="fas fa-check"></i> 1 point marqué quand le joueur au pressing récupère le ballon.</p>
        <p><i class="fas fa-check"></i> Puis directement enchainé par un jeu à 6 contre 6 pendant 4 minutes.</p>
        <p><i class="fas fa-check"></i> Possibilité de marquer après 10 passes de suite.</p>
        <p><i class="fas fa-check"></i> Puis 4 contre 2 pendant 2 minutes et de nouveau 4 minutes à 6 contre 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2 CONTRE 2 AVEC APPUIS SUR LES CÔTÉS",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes de 4 joueurs.</p>
        <p><i class="fas fa-check"></i> 2 joueurs sur le terrain, 2 joueurs en appui.</p>
        <p><i class="fas fa-check"></i> Changer les rôles chaque minute par exemple.</p>
        <p><i class="fas fa-check"></i> 10 minutes par exemple.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITTENT "MARCHE ARRIÈRE-AVANT"`,
          text: `
        <p><i class="fas fa-check"></i> Placer 3-4 cônes de couleurs différentes dans un carré délimité comme sur la photo. Le joueur doit aller contourner le cône de couleur que lui annonce son partenaire.</p>
        <p><i class="fas fa-check"></i> "vert" et le joueur recule vers le cône vert, le contourne puis revient rapidement au point de départ.. dès que le joueur est replacé au centre le partenaire lui annonce la nouvelle couleur... ne pas lui laisser de temps de repos...</p>
        <p><i class="fas fa-check"></i> Le joueur doit prendre les informations (les couleurs) avant de commencer pour pouvoir enchaîner très vite..</p>
        <p><i class="fas fa-check"></i> Travail par deux (un qui fait l'exercice, l'autre qui annonce les couleurs).</p>
        <p><i class="fas fa-check"></i> 20 secondes de travail - 20 secondes de repos. 7 minutes par exemple.</p>
        <p><i class="fas fa-check"></i> Varier les carrés (distances différentes, couleurs dispositionnées différemment,..).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_P_U15: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL ENDURANCE PUISSANCE`,
      SUBTITLE: `Avec 100 exemples d'exercices d'endurance`,
      SUBSUB: "Pour les U15-U18",
    },
    CHECK: {
      TITLE: "Adaptés aux U15-U18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance en futsal ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance puissance en futsal?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
        Nous proposons un programme en ligne avec
        <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
        et 100 idées d'exercices pour travailler l'endurance en futsal.
      </p>
      <p>
        Ce guide s’adresse aux coachs d'équipes séniors de futsal qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de l'endurance puissance (avec 2 séances par semaine soit 16 séances pour travailler l'endurance puissance)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
      </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles d'entraînements physiques pour travailler l'endurance puissance en futsal. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
        </p>`,
      COL2: `<p>
          Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
        </p>
        <p>
          Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL "ENDURANCE PUISSANCE''`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/69_1080x.png?v=1619856076",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-en-futsal-special-endurance-puissance",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 CONTRE 4 AVEC GARDIENS",
          text: `
        <p><i class="fas fa-check"></i> 4 zones sur un terrain complet.</p>
        <p><i class="fas fa-check"></i> Jamais plus de 2 joueurs d'une même équipe dans une même zone.</p>
        <p><i class="fas fa-check"></i> Jeu libre, 2 minutes de jeu, 6 parties par exemple.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "CONSERVATION 4 CONTRE 2 ET 6 CONTRE 6",
          text: `
        <p><i class="fas fa-check"></i> Alternance entre conservation en supériorité numérique dans un petit espace (4 contre 2) et conservation sur grand espace à 6 contre 6.</p>
        <p><i class="fas fa-check"></i> 2 minutes à 4 contre 2 (les 2 joueurs au pressing changent toutes les 30 secondes). 1 point marqué toutes les 10 passes de suite.</p>
        <p><i class="fas fa-check"></i> 1 point marqué quand le joueur au pressing récupère le ballon.</p>
        <p><i class="fas fa-check"></i> Puis directement enchainé par un jeu à 6 contre 6 pendant 4 minutes.</p>
        <p><i class="fas fa-check"></i> Possibilité de marquer après 10 passes de suite.</p>
        <p><i class="fas fa-check"></i> Puis 4 contre 2 pendant 2 minutes et de nouveau 4 minutes à 6 contre 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2 CONTRE 2 AVEC APPUIS SUR LES CÔTÉS",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes de 4 joueurs.</p>
        <p><i class="fas fa-check"></i> 2 joueurs sur le terrain, 2 joueurs en appui.</p>
        <p><i class="fas fa-check"></i> Changer les rôles chaque minute par exemple.</p>
        <p><i class="fas fa-check"></i> 10 minutes par exemple.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITTENT "MARCHE ARRIÈRE-AVANT"`,
          text: `
        <p><i class="fas fa-check"></i> Placer 3-4 cônes de couleurs différentes dans un carré délimité comme sur la photo. Le joueur doit aller contourner le cône de couleur que lui annonce son partenaire.</p>
        <p><i class="fas fa-check"></i> "vert" et le joueur recule vers le cône vert, le contourne puis revient rapidement au point de départ.. dès que le joueur est replacé au centre le partenaire lui annonce la nouvelle couleur... ne pas lui laisser de temps de repos...</p>
        <p><i class="fas fa-check"></i> Le joueur doit prendre les informations (les couleurs) avant de commencer pour pouvoir enchaîner très vite..</p>
        <p><i class="fas fa-check"></i> Travail par deux (un qui fait l'exercice, l'autre qui annonce les couleurs).</p>
        <p><i class="fas fa-check"></i> 20 secondes de travail - 20 secondes de repos. 7 minutes par exemple.</p>
        <p><i class="fas fa-check"></i> Varier les carrés (distances différentes, couleurs dispositionnées différemment,..).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL VITESSE`,
      SUBTITLE: `Avec 100 exemples d'exercices de vitesse`,
      SUBSUB: "Pour les séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse en futsal ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse en futsal?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la vitesse</span>
            et 100 idées d'exercices pour travailler la vitesse en futsal.
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes séniors de futsal qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la vitesse
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de vitesse (avec 2 séances par semaine soit 16 séances pour travailler la vitesse)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la vitesse sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse en futsal. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL "VITESSE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/68_1080x.png?v=1619856026",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-en-futsal-special-vitesse",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "VITESSE AVEC CHANGEMENT DE DIRECTION ET DUEL",
          text: `
        <p><i class="fas fa-check"></i> Au top (annonce d'une couleur), les joueurs démarrent en passant par la couleur annoncée (petit carré avec 4 couleurs), puis doivent passer derrière le cône de la couleur annoncée. Puis duel ou frappe ..</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les déclencheurs ( sonore, visuel, ...) pour travailler le temps de réaction.</p>
        <p><i class="fas fa-check"></i> Vous pouvez aussi varier les positions de départ ( de côté, de dos, en trottinant..)</p>
        <p><i class="fas fa-check"></i> Exemple : Vous montrez la couleur jaune : départ côté jaune puis passage derrière le cône jaune.</p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "TRAVAIL APPUIS-FORCE + RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> Les joueurs défenseurs effectuent un travail de force (saut avec haies, pliométrie) puis ont l'objectif d'empêcher l'attaquant de marquer..</p>
        <p><i class="fas fa-check"></i> L'attaquant effectue une accélération puis doit marquer..</p>
        <p><i class="fas fa-check"></i> 2 choix de parcours (l'entraîneur annonce une couleur).</p>
        <p><i class="fas fa-check"></i> Vous pouvez changer le travail de force par un travail de coordination ou d'appui, ou bien un travail de fréquence, ou simplement une accélération avec freinage...</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, proposer plusieurs parcours, varier les départs (arrêté, en mouvement, de dos, assis... ), ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes. Chaque joueur a un numéro.</p>
        <p><i class="fas fa-check"></i> L'entraîneur annonce un numéro.</p>
        <p><i class="fas fa-check"></i> Les deux joueurs dont le numéro a été annoncé doivent réagir vite et sprinter jusqu'au ballon (le ballon est placé au centre ou envoyé par l'entraîneur a des endroits différents du terrain à chaque nouvelle annonce).</p>
        <p><i class="fas fa-check"></i> Le premier sur le ballon devient attaquant et doit aller stopper le ballon dans la zone de son adversaire. L'autre joueur devient défenseur et doit l'en empêcher.</p>
        <p><i class="fas fa-check"></i> Variante : l'entraîneur annonce 2 ou 3 numéros (2 contre 2, 3 contre 3).</p>
        <p><i class="fas fa-check"></i> Autre variante : le joueur qui attaque devient automatiquement défenseur ( travail de transition attaque-défense).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `RÉPÉTITIONS DE SPRINTS`,
          text: `
        <p><i class="fas fa-check"></i> Jeu du Pacman.</p>
        <p><i class="fas fa-check"></i> Tracer des lignes (avec des cônes par exemple) sur une moitié de terrain (ou une zone adaptée au nombre de joueurs à disposition.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent toujours courir le long d'une ligne.</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V_U15: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL VITESSE`,
      SUBTITLE: `Avec 100 exemples d'exercices de vitesse`,
      SUBSUB: "U15-18",
    },
    CHECK: {
      TITLE: "Adaptés aux U15-18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse en futsal ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse en futsal?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la vitesse</span>
            et 100 idées d'exercices pour travailler la vitesse en futsal.
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes séniors de futsal qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la vitesse
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de vitesse (avec 2 séances par semaine soit 16 séances pour travailler la vitesse)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la vitesse sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse en futsal. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES EN FUTSAL SPÉCIAL "VITESSE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/68_1080x.png?v=1619856026",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-en-futsal-special-vitesse",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "VITESSE AVEC CHANGEMENT DE DIRECTION ET DUEL",
          text: `
        <p><i class="fas fa-check"></i> Au top (annonce d'une couleur), les joueurs démarrent en passant par la couleur annoncée (petit carré avec 4 couleurs), puis doivent passer derrière le cône de la couleur annoncée. Puis duel ou frappe ..</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les déclencheurs ( sonore, visuel, ...) pour travailler le temps de réaction.</p>
        <p><i class="fas fa-check"></i> Vous pouvez aussi varier les positions de départ ( de côté, de dos, en trottinant..)</p>
        <p><i class="fas fa-check"></i> Exemple : Vous montrez la couleur jaune : départ côté jaune puis passage derrière le cône jaune.</p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "TRAVAIL APPUIS-FORCE + RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> Les joueurs défenseurs effectuent un travail de force (saut avec haies, pliométrie) puis ont l'objectif d'empêcher l'attaquant de marquer..</p>
        <p><i class="fas fa-check"></i> L'attaquant effectue une accélération puis doit marquer..</p>
        <p><i class="fas fa-check"></i> 2 choix de parcours (l'entraîneur annonce une couleur).</p>
        <p><i class="fas fa-check"></i> Vous pouvez changer le travail de force par un travail de coordination ou d'appui, ou bien un travail de fréquence, ou simplement une accélération avec freinage...</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, proposer plusieurs parcours, varier les départs (arrêté, en mouvement, de dos, assis... ), ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes. Chaque joueur a un numéro.</p>
        <p><i class="fas fa-check"></i> L'entraîneur annonce un numéro.</p>
        <p><i class="fas fa-check"></i> Les deux joueurs dont le numéro a été annoncé doivent réagir vite et sprinter jusqu'au ballon (le ballon est placé au centre ou envoyé par l'entraîneur a des endroits différents du terrain à chaque nouvelle annonce).</p>
        <p><i class="fas fa-check"></i> Le premier sur le ballon devient attaquant et doit aller stopper le ballon dans la zone de son adversaire. L'autre joueur devient défenseur et doit l'en empêcher.</p>
        <p><i class="fas fa-check"></i> Variante : l'entraîneur annonce 2 ou 3 numéros (2 contre 2, 3 contre 3).</p>
        <p><i class="fas fa-check"></i> Autre variante : le joueur qui attaque devient automatiquement défenseur ( travail de transition attaque-défense).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `RÉPÉTITIONS DE SPRINTS`,
          text: `
        <p><i class="fas fa-check"></i> Jeu du Pacman.</p>
        <p><i class="fas fa-check"></i> Tracer des lignes (avec des cônes par exemple) sur une moitié de terrain (ou une zone adaptée au nombre de joueurs à disposition.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent toujours courir le long d'une ligne.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES POUR LE GARDIEN DE BUT SPÉCIAL VITESSE`,
      SUBTITLE: `Avec 100 exemples d'exercices de vitesse`,
      SUBSUB: "Adaptés aux gardiens U17 et séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux gardiens U17 et séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse pour les gardiens de but?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse pour les gardiens de but?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
        Nous proposons un programme en ligne avec <span>2 cycles de 4 semaines d'entraînements de la vitesse</span> et 100 idées d'exercices pour travailler la vitesse.
      </p>
      <p>
        Ce guide s’adresse aux coachs de gardiens de but qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la vitesse
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de vitesse (avec 2 séances par semaine soit 16 séances pour travailler la vitesse)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la vitesse sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
      </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
        </p>`,
      COL2: `<p>
          Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices! 
        </p>
        <p>
          Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler la vitesse (explosivité, vitesse gestuelle, fréquence gestuelle, vitesse de réaction, vivacité...)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES POUR LES GARDIENS DE BUT SPÉCIAL "VITESSE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/70_1080x.png?v=1",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-pour-les-gardiens-de-but-special-vitesse-u15-u18",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "RÉACTION, FRÉQUENCE PUIS RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> Le gardien démarre par un travail de réaction en allant toucher le piquet de la couleur annoncée par l'entraîneur, puis travail de fréquence (2 appuis entre les lattes) puis il doit réagir vite pour aller chercher le ballon en plongeon du côté de la couleur que vous avez annoncez.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les déclencheurs ( sonore, visuel, ...) pour travailler le temps de réaction.</p>
        <p><i class="fas fa-check"></i> Vous pouvez aussi varier les positions de départ ( de côté, de dos, assis...)</p>
        <p><i class="fas fa-check"></i> 6 à 8 fois. 45 secondes de récupération entre chaque passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "VITESSE DE RÉACTION ET VITESSE GESTUELLE",
          text: `
        <p><i class="fas fa-check"></i> Le gardien est à 1m50 - 2m environ.</p>
        <p><i class="fas fa-check"></i> Vous avez un ballon dans chaque main.</p>
        <p><i class="fas fa-check"></i> Vous laissez tomber un des 2 ballons. </p>
        <p><i class="fas fa-check"></i> Le gardien doit toucher le ballon avant qu'il ne touche le sol.</p>
        <p><i class="fas fa-check"></i> Vous pouvez annoncer des couleurs de cônes que le gardien doit aller toucher pour le destabiliser avant de lâcher un ballon. </p>
        <p><i class="fas fa-check"></i> 8 à 10 fois. 20 secondes de récupération.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE GESTUELLE",
          text: `
        <p><i class="fas fa-check"></i> Placer des cônes ou petits plots aléatoirement à quelques mètres de la cage.</p>
        <p><i class="fas fa-check"></i> Le gardien démarre par un travail de réaction en allant toucher le piquet de la couleur annoncée par l'entraîneur puis se replace et vient stopper la frappe de l'entraîneur.</p>
        <p><i class="fas fa-check"></i> Frappe à ras de terre, le ballon peut toucher un cône et avoir une trajectoire déviée.</p>
        <p><i class="fas fa-check"></i> Une dizaine de fois.</p>
        <p><i class="fas fa-check"></i> 20 secondes de récupération entre chaque passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLIOMÉTRIE + PLONGEONS",
          text: `
        <p><i class="fas fa-check"></i> 4 sauts "en cuisse" (jambes fléchies) au dessus des haies puis plongeons horizontaux.</p>
        <p><i class="fas fa-check"></i> 6 à 8 fois. 45 secondes de récupération.</p>
        <p><i class="fas fa-check"></i> La qualité des sauts doit être l'objectif.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V_U15: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES POUR LE GARDIEN DE BUT SPÉCIAL VITESSE`,
      SUBTITLE: `Avec 100 exemples d'exercices de vitesse`,
      SUBSUB: "Adaptés aux gardiens U15-U18",
    },
    CHECK: {
      TITLE: "Adaptés aux gardiens U15-U18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse pour les gardiens de but?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse pour les gardiens de but?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
        Nous proposons un programme en ligne avec <span>2 cycles de 4 semaines d'entraînements de la vitesse</span> et 100 idées d'exercices pour travailler la vitesse.
      </p>
      <p>
        Ce guide s’adresse aux coachs de gardiens de but qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la vitesse
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de vitesse (avec 2 séances par semaine soit 16 séances pour travailler la vitesse)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la vitesse sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
      </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
        </p>`,
      COL2: `<p>
          Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices! 
        </p>
        <p>
          Nous vous proposons ainsi plus de 100 exemples d'exercices pour travailler la vitesse (explosivité, vitesse gestuelle, fréquence gestuelle, vitesse de réaction, vivacité...)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES POUR LES GARDIENS DE BUT SPÉCIAL "VITESSE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/70_1080x.png?v=1",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-pour-les-gardiens-de-but-special-vitesse-u15-u18",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "RÉACTION, FRÉQUENCE PUIS RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> Le gardien démarre par un travail de réaction en allant toucher le piquet de la couleur annoncée par l'entraîneur, puis travail de fréquence (2 appuis entre les lattes) puis il doit réagir vite pour aller chercher le ballon en plongeon du côté de la couleur que vous avez annoncez.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les déclencheurs ( sonore, visuel, ...) pour travailler le temps de réaction.</p>
        <p><i class="fas fa-check"></i> Vous pouvez aussi varier les positions de départ ( de côté, de dos, assis...)</p>
        <p><i class="fas fa-check"></i> 6 à 8 fois. 45 secondes de récupération entre chaque passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "VITESSE DE RÉACTION ET VITESSE GESTUELLE",
          text: `
        <p><i class="fas fa-check"></i> Le gardien est à 1m50 - 2m environ.</p>
        <p><i class="fas fa-check"></i> Vous avez un ballon dans chaque main.</p>
        <p><i class="fas fa-check"></i> Vous laissez tomber un des 2 ballons. </p>
        <p><i class="fas fa-check"></i> Le gardien doit toucher le ballon avant qu'il ne touche le sol.</p>
        <p><i class="fas fa-check"></i> Vous pouvez annoncer des couleurs de cônes que le gardien doit aller toucher pour le destabiliser avant de lâcher un ballon. </p>
        <p><i class="fas fa-check"></i> 8 à 10 fois. 20 secondes de récupération.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE GESTUELLE",
          text: `
        <p><i class="fas fa-check"></i> Placer des cônes ou petits plots aléatoirement à quelques mètres de la cage.</p>
        <p><i class="fas fa-check"></i> Le gardien démarre par un travail de réaction en allant toucher le piquet de la couleur annoncée par l'entraîneur puis se replace et vient stopper la frappe de l'entraîneur.</p>
        <p><i class="fas fa-check"></i> Frappe à ras de terre, le ballon peut toucher un cône et avoir une trajectoire déviée.</p>
        <p><i class="fas fa-check"></i> Une dizaine de fois.</p>
        <p><i class="fas fa-check"></i> 20 secondes de récupération entre chaque passage.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLIOMÉTRIE + PLONGEONS",
          text: `
        <p><i class="fas fa-check"></i> 4 sauts "en cuisse" (jambes fléchies) au dessus des haies puis plongeons horizontaux.</p>
        <p><i class="fas fa-check"></i> 6 à 8 fois. 45 secondes de récupération.</p>
        <p><i class="fas fa-check"></i> La qualité des sauts doit être l'objectif.</p>
        `,
        },
      ],
    },
  },
  AVEC: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES 100% AVEC BALLON`,
      SUBTITLE: `Avec 300 exemples d'exercices d'endurance et de vitesse`,
      SUBSUB: "Pour les séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance ou la vitesse avec ballon ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance et la vitesse avec ballon?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles d'entraînements physiques "100% avec ballon"</span>
            et 300 exemples d'exercices d'endurance et de vitesse.
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes séniors qui cherchent des
            exemples de cycles d'entraînements et des idées d’exercices pour
            travailler l'endurance et la vitesse avec le ballon.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance et la vitesse
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail physique avec
            ballon (avec 2 séances par semaine soit 16 séances pour travailler l'endurance et la vitesse avec ballon)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance 
            et la vitesse sans matériel ou avec petit matériel (que tous 
              les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles (de 4 semaines) d'entraînements physiques pour travailler le physique avec ballon. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
      Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
          Nous vous proposons ainsi plus de 300 exemples d'exercices pour travailler l'endurance et la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES "100% BALLON"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/ejer-1_1_1080x.png?v=1619855979",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-100-ballon",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JEUX RÉDUITS 1 VS 1",
          text: `
        <p><i class="fas fa-check"></i> Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
        <p><i class="fas fa-check"></i> Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
        <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
        <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
        <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
        <p><i class="fas fa-check"></i> Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JEUX RÉDUITS 4 VS 1",
          text: `
        <p><i class="fas fa-check"></i> 5 carrés.</p>
        <p><i class="fas fa-check"></i> 4 contre 1 dans chaque carré.</p>
        <p><i class="fas fa-check"></i> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps.</p>
        <p><i class="fas fa-check"></i> Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Avec right-1.png",
          title: "1 VS 1 ET VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> 1 contre 1.</p>
        <p><i class="fas fa-check"></i> Objectif, aller marquer dans le camp de son adversaire (zone).</p>
        <p><i class="fas fa-check"></i> 2 équipes.</p>
        <p><i class="fas fa-check"></i> Chaque joueur a un numéro.</p>
        <p><i class="fas fa-check"></i> L'entraineur annonce un numéro et envoie le ballon dans une partie du terrain.. le premier joueur sur le ballon devient attaquant et doit aller marquer dans le camp de son adversaire.</p>
        <p><i class="fas fa-check"></i> L'autre joueur doit défendre.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JEUX RÉDUITS 4 VS 4",
          text: `
        <p><i class="fas fa-check"></i> 3 équipes.</p>
        <p><i class="fas fa-check"></i> Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
        <p><i class="fas fa-check"></i> Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL ENDURANCE PUISSANCE`,
      SUBTITLE: `Avec 200 exemples d'exercices d'endurance`,
      SUBSUB: "Pour les séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
            et 200 idées d'exercices pour travailler l'endurance.
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes séniors qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail d'endurance puissance (avec 2 séances par semaine soit 16 séances pour travailler l'endurance puissance)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles (de 4 semaines) d'entraînements physiques pour travailler l'endurance puissance. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "ENDURANCE PUISSANCE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/65_1080x.png?v=1619855888",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-special-endurance-puissance",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/FutPui left-1.png",
          title: "JEUX RÉDUITS 1 VS 1",
          text: `
        <p><i class="fas fa-check"></i> Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
        <p><i class="fas fa-check"></i> Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
        <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
        <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
        <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
        <p><i class="fas fa-check"></i> Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
        `,
        },
        {
          img: "Cycles/FutPui left-2.png",
          title: "JEUX RÉDUITS 4 VS 1",
          text: `
        <p><i class="fas fa-check"></i> 5 carrés.</p>
        <p><i class="fas fa-check"></i> 4 contre 1 dans chaque carré.</p>
        <p><i class="fas fa-check"></i> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps.</p>
        <p><i class="fas fa-check"></i> Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "JEUX RÉDUITS 5 VS 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zones. </p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit passer les 3 ''étapes'' pour marquer un point.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit faire 8 passes avant de pouvoir passer à la zone suivante.</p>
        <p><i class="fas fa-check"></i> Dans chaque zone, 2 nouveaux joueurs au pressing.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique a par exemple 2 minutes pour passer les 3 étapes et marquer un point.</p>
        <p><i class="fas fa-check"></i> A chaque perte de balle, elle recommence de la zone de départ.</p>
        `,
        },
        {
          img: "Cycles/FutPui right-2.png",
          title: `JEUX RÉDUITS 4 VS 4`,
          text: `
        <p><i class="fas fa-check"></i> 3 équipes.</p>
        <p><i class="fas fa-check"></i> Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
        <p><i class="fas fa-check"></i> Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE_J: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL ENDURANCE PUISSANCE`,
      SUBTITLE: `U15-U18`,
      SUBSUB: `Avec 200 exemples d'exercices de vitesse`,
    },
    CHECK: {
      TITLE: "Adaptés aux U15-U18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de l'endurance puissance chez les U15-U18 ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler l'endurance chez les U15-U18 ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de l'endurance puissance</span>
            et 200 idées d'exercices pour travailler l'endurance.  
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U15-U18 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler l'endurance puissance.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur l'endurance
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail d'endurance puissance (avec 2 séances par semaine soit 16 séances pour travailler l'endurance puissance)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler l'endurance sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
          Vous allez retrouver 2 cycles (de 4 semaines) d'entraînements physiques pour travailler l'endurance puissance chez les U15-U18. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler l'endurance (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "ENDURANCE PUISSANCE" U15 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/59_1_1080x.png?v=1619854967",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/2-cycles-dentrainements-physiques-special-endurance-puissance-u15-u18-1",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JEUX RÉDUITS 1 VS 1",
          text: `
        <p><i class="fas fa-check"></i> Sous forme de mini-tournoi (montée-descente) avec évolution des jeux.</p>
        <p><i class="fas fa-check"></i> Les gagnants montent pour finir sur le terrain 1. Les perdants descendent, le dernier terrain étant le terrain 4 (ou 5 ou 6 en fonction du nombre de joueurs présents).</p>
        <p><i class="fas fa-check"></i> Terrain 4 : Conservation du ballon (le joueur qui conserve le plus longtemps le ballon a gagné). </p>
        <p><i class="fas fa-check"></i> Terrain 3 : stop ball dans la zone rouge. </p>
        <p><i class="fas fa-check"></i> Terrain 2 : Le joueur doit passer entre les plots adverses (rouges ou bleus). </p>
        <p><i class="fas fa-check"></i> Terrain 1 : marquer dans le petit but adverse. L'intensité doit être grande.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JEUX RÉDUITS 4 VS 1",
          text: `
        <p><i class="fas fa-check"></i> 5 carrés.</p>
        <p><i class="fas fa-check"></i> 4 contre 1 dans chaque carré.</p>
        <p><i class="fas fa-check"></i> Parmi les 12 joueurs jaunes qui doivent conserver le ballon, 4 joueurs (flèches blanches) jouent dans 2 carrés en même temps.</p>
        <p><i class="fas fa-check"></i> Ces 4 joueurs ne doivent jamais recevoir 2 ballons en même temps.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier la taille des carrés, donner des consignes particulières, proposer d'autres contraintes, ....</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "JEUX RÉDUITS 5 VS 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zones. </p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit passer les 3 ''étapes'' pour marquer un point.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique doit faire 8 passes avant de pouvoir passer à la zone suivante.</p>
        <p><i class="fas fa-check"></i> Dans chaque zone, 2 nouveaux joueurs au pressing.</p>
        <p><i class="fas fa-check"></i> L'équipe en supériorité numérique a par exemple 2 minutes pour passer les 3 étapes et marquer un point.</p>
        <p><i class="fas fa-check"></i> A chaque perte de balle, elle recommence de la zone de départ.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JEUX RÉDUITS 4 VS 4",
          text: `
        <p><i class="fas fa-check"></i> 3 équipes.</p>
        <p><i class="fas fa-check"></i> Une équipe qui attaque (en rouge) et choisie l'équipe contre laquelle elle va attaquer (contre les blancs ou les jaunes).</p>
        <p><i class="fas fa-check"></i> Si elle marque, elle attaque contre l'autre équipe. Si elle perd le ballon, elle passe en défense et l'autre équipe passe en attaque.</p>
        `,
        },
      ],
    },
  },
  COORDINATION: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL COORDINATION`,
      SUBTITLE: `U8-U12`,
      SUBSUB: `Avec 200 exemples d'exercices de coordination-motricité`,
    },
    CHECK: {
      TITLE: "Adaptés aux U8-U12",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la coordination ches les U8-U12?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la coordination et la motricité ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la coordination et</span>
            et 200 idées d'exercices pour travailler la coordination et la motricité
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U8-U12 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la coordination.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de coordination (avec 2 séances par semaine soit 16 séances pour travailler la coordination )
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la coordination sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "COORDINATION" U8 - U12`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/56_1080x.png?v=1619804533",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/2-cycles-dentrainements-physiques-special-coordination-u8-u12",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/Coordination left-1.png",
          title: "EXERCICE DE PASSES ET COORDINATION",
          text: `
        <p><i class="fas fa-check"></i> Le joueur passe puis va faire un exercice d'appuis coordination puis va prendre la place du partenaire à qui il a fait la passe.2.</p>
        <p><i class="fas fa-check"></i> Un exercice différent à chaque fois.</p>
        `,
        },
        {
          img: "Cycles/Coordination left-2.png",
          title: "APPRENTISSAGE SCHÉMA CORPOREL",
          text: `
        <p><i class="fas fa-check"></i> Parcours motricité.</p>
        <p><i class="fas fa-check"></i> Le joueur débute par un slalom en pas chassés (plot rouge), puis appuis décalés dans les cerceaux jaunes.</p>
        <p><i class="fas fa-check"></i> Puis le joueur doit passer sous une haie en rampant.</p>
        <p><i class="fas fa-check"></i> Appuis dans cerceaux jaunes, saut au dessus de la haie rouge, de nouveau passer en dessous de la haie, puis au dessus de la haie rouge.</p>
        <p><i class="fas fa-check"></i> Slalom en pas chassés arrière (plots bleus) et enfin petits appuis pour faire le tour des cerceaux verts.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Coordination right-1.png",
          title: "COORDINATION DISSOCIATION OEIL-MEMBRES INFÉRIEURS",
          text: `
        <p><i class="fas fa-check"></i> 2 joueurs en face à face (8 à 15m) avec un ballon chacun.</p>
        <p><i class="fas fa-check"></i> Objectif : passes synchronisées (les 2 ballons partent en même temps).</p>
        <p><i class="fas fa-check"></i> Le joueur doit donc toujours regarder le ballon et son partenaire.</p>
        `,
        },
        {
          img: "Cycles/Coordination right-2.png",
          title: `CONDUITE DE BALLE ET "ORGANISATION PERCEPTIVE"`,
          text: `
        <p><i class="fas fa-check"></i> Dans un carré défini (taille en fonction du nombre de joueur) Conduite libre. Interdit de toucher un partenaire (et son ballon)</p>
        <p><i class="fas fa-check"></i> ni les cônes dans le carré.</p>
        <p><i class="fas fa-check"></i> Puis imposer pied droit, pied gauche, semelle... Varier les</p>
        <p><i class="fas fa-check"></i> rythmes (lent, normal, rapide). <br /> En fin d'exercice : les joueurs doivent faire sortir le ballon du partenaire sans perdre le leur. Celui perd son ballon est éliminé. Le dernier sur le terrain est le gagnant.</p>
        `,
        },
      ],
    },
  },
  PERIODISATION: {
    FIRST: {
      TITLE: `UN CYCLE DE 4 SEMAINES SPÉCIAL "PÉRIODISATION TACTIQUE"`,
      SUBTITLE: `U15 - U17 - Séniors`,
      SUBSUB: `Pour découvrir cette méthode d'entraînement`,
    },
    CHECK: {
      TITLE: "Adaptés aux U15 - U17 - Séniors",
      CHECKS: [
        `Vous aimeriez découvrir la périodisation tactique et voir comment travailler le physique avec cette méthode d'entraînement?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements de cette méthode d'entraînement ?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices utilisés dans la périodisation tactique?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques ?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>un cycle de 4 semaines d'entraînements spécial "périodisation tactique".</span>
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U15, U17 et séniors qui veulent découvrir la périodisation tactique (initiation), qui veulent comprendre comment travailler le physique avec cette méthode d'entraînement et qui cherchent des exemples de séances d'entraînements et des idées d’exercices de la périodisation tactique.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la périodisation tactique
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Un cycle de 4 semaines (2 ou 3 séances par semaine) sur la périodisation tactique.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Par exemple dans la semaine avec 3 séances, vous retrouverez une séance axée sur le travail de force, une séance axée sur le travail d'endurance et une séance axée sur le travail de vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous retrouverez aussi des idées d'exercices utilisés dans la périodisation tactique
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver un cycle (de 4 semaines) sur la périodisation tactique.
          </p>`,
      COL2: `<p>
            Nous vous proposons aussi des informations, des exemples d'exercices et de micro-cycles pour découvrir et travailler avec cette méthode d'entraînement.
            </p>`,
      SUB: `L'objectif est surtout là, vous présenter cette méthode d'entraînement, vous montrer comment travailler la partie physique, vous donner des idées et des exemples afin que vous ayez une base sur laquelle vous appuyer pour concevoir vos propres cycles et exercices! <br /> <br /> Mais attention, ce programme est une initiation à la périodisation tactique, si vous être déjà expert ou si vous connaissez bien cette méthode, alors ce ne sera pas pour vous ;).`,
    },
    INFOSHOP: {
      title: `UN CYCLE DE 4 SEMAINES SPÉCIAL "PÉRIODISATION TACTIQUE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/66_1080x.png?v=1619855931",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/un-cycle-de-4-semaines-special-periodisation-tactique",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/PLeft-1.png",
          title: "JEUX RÉDUITS 8 VS 4",
          text: `
        <p><i class="fas fa-check"></i> 3 équipes de 4 joueurs.</p>
        <p><i class="fas fa-check"></i> 2 zones de 15m x 15m par exemple (à adapter en fonction de la catégorie et du niveau de votre groupe).</p>
        <p><i class="fas fa-check"></i> Une équipe en ''joker'' (ici en bleue), qui jouent avec l'équipe qui a le ballon, avec un joueur de chaque côté d'une zone.</p>
        <p><i class="fas fa-check"></i>L'équipe en conservation doit faire 6 passes consécutives (par exemple), passer dans l'autre zone et refaire 6 passes consécutives pour marquer un point.</p>
        <p><i class="fas fa-check"></i> Quand il y a changement de zone, les joueurs joker coulissent également pour être appuis dans l'autre zone.</p>
        <p><i class="fas fa-check"></i> L'objectif tactique ici est le travail de conservation du ballon en supériorité numérique (8 contre 4) et le travail de progression d'une zone à une autre.</p>
        `,
        },
        {
          img: "Cycles/PLeft-2.png",
          title: "JEUX RÉDUITS 7 VS 7",
          text: `
        <p><i class="fas fa-check"></i> Jeux réduits pour travailler la puissance aérobie et le bloc équipe.</p>
        <p><i class="fas fa-check"></i> Sur un demi terrain par exemple.</p>
        <p><i class="fas fa-check"></i> Tracer plusieurs zones (ici 4).</p>
        <p><i class="fas fa-check"></i> Une équipe doit toujours avoir tous ses joueurs dans 2 zones collées.</p>
        <p><i class="fas fa-check"></i> Si un joueur d'une équipe est dans une autre zone, l'équipe perd le ballon.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/PRight-1.png",
          title: "TRAVAIL DE FORCE - JEU DE TÊTE (OFFENSIF ET DÉFENSIF)",
          text: `
        <p><i class="fas fa-check"></i> 4 contre 4 sur petit terrain avec 2 joueurs en appui pour centrer.</p>
        <p><i class="fas fa-check"></i> But de la tête, valable seulement dans la zone blanche et consécutif à un centre.</p>
        <p><i class="fas fa-check"></i> L'équipe en possession du ballon doit faire au moins 8 passes avant de pouvoir marquer.</p>
        <p><i class="fas fa-check"></i> 8mn.</p>
        <p><i class="fas fa-check"></i> Changer les rôles toutes les 2mn.</p>
        `,
        },
        {
          img: "Cycles/PRight-2.png",
          title: `VITESSE EN 3 CONTRE 2`,
          text: `
        <p><i class="fas fa-check"></i> Départ au milieu de terrain pour les attaquants et ligne de but pour les défenseurs.</p>
        <p><i class="fas fa-check"></i> 10 secondes maximum pour marquer (par exemple).</p>
        <p><i class="fas fa-check"></i> 3-4 passages par joueur dans les 2 rôles.</p>
        <p><i class="fas fa-check"></i> 45 secondes minimum de récupération entre 2 courses.</p>
        `,
        },
      ],
    },
  },
  SPECIAL_V: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL VITESSE`,
      SUBTITLE: `Avec 200 exemples d'exercices de vitesse`,
      SUBSUB: "Pour les séniors",
    },
    CHECK: {
      TITLE: "Adaptés aux séniors",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec <span>2 cycles de 4 semaines d'entraînements de la vitesse</span> et 200 idées d'exercices pour travailler la vitesse. <br />
            Ce guide s’adresse aux coachs d'équipes séniors qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la vitesse 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de vitesse (avec 2 séances par semaine soit 16 séances pour travailler la vitesse)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la vitesse sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "VITESSE"`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/64_1_1080x.png?v=1619855839",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-seniors/products/2-cycles-dentrainements-physiques-special-vitesse",
    },
    EXERCICES: {
      TITLE: `Exemples d'exercices retrouvés dans les programmes`,
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous retrouverez dans le guide en ligne.`,
      EXERCICESL: [{
          img: "Cycles/SpecialV left-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE AVEC CHANGEMENTS DE DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes</p>
        <p><i class="fas fa-check"></i> 2 carrés Chaque joueur d'une équipe a un numéro..</p>
        <p><i class="fas fa-check"></i> 2 plots de couleurs de chaque côté d'un carré..</p>
        <p><i class="fas fa-check"></i> L'entraîneur annonce une couleur (qui correspond au parcours, les joueurs doivent contourner les 2 plots de la couleur annoncé) et un numéro qui correspond a un joueur de chaque équipe. </p>
        <p><i class="fas fa-check"></i> Les joueurs doivent réagir vite à l'annonce de leur numéro, contourner les plots et revenir au point de départ.</p>
        `,
        },
        {
          img: "Cycles/SpecialV left-2.png",
          title: "VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> 4 plots de couleurs différentes, l'entraîneur annonce 2 couleurs. Le joueur doit réagir vite au signal, contourner les 2 plots de la couleur annoncée puis frapper dans les petites cages.</p>
        <p><i class="fas fa-check"></i> En duel.</p>
        <p><i class="fas fa-check"></i> Le joueur qui frappe le premier à un point.</p>
        <p><i class="fas fa-check"></i> Si il marque il a 3 points.</p>
        <p><i class="fas fa-check"></i> L'autre joueur qui a frappé en second ne peut marquer aucun point.. Récupération 45 secondes minimum.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, annoncer seulement une couleur ou 3 couleurs, varier les départs (arrêté, en mouvement, de dos, assis... ), varier la finition...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/SpecialV right-1.png",
          title: "VITESSE ET FINITION",
          text: `
        <p><i class="fas fa-check"></i> 3 groupes, un au centre du terrain (en rouge), un autre sur le côté (en blanc), un proche du but (en jaune).</p>
        <p><i class="fas fa-check"></i> 3 carrés proche du but, un rouge, un bleu, un jaune.</p>
        <p><i class="fas fa-check"></i> Un joueur du groupe blanc doit effectuer une course avec ballon et centrer. </p>
        <p><i class="fas fa-check"></i> Il doit centrer pour un des deux joueurs rouge (annoncé avant le départ)..</p>
        <p><i class="fas fa-check"></i> Le joueur jaune doit sprinter pour aller se placer dans un des 2 carrés.</p>
        <p><i class="fas fa-check"></i> Les joueurs rouges doivent sprinter et aller dans les autres carrés. </p>
        <p><i class="fas fa-check"></i> Le joueur blanc doit donc prendre l'information avant de centrer pour voir dans quel carré le joueur rouge sera placé. </p>
        <p><i class="fas fa-check"></i> Les joueurs qui ont centré et frappé reviennent en marchant.</p>
        `,
        },
        {
          img: "Cycles/SpecialV right-2.png",
          title: "1 CONTRE 1 PUIS FRAPPE",
          text: `
        <p><i class="fas fa-check"></i> Les attaquants doivent dribbler un défenseur avant de pouvoir tirer.</p>
        <p><i class="fas fa-check"></i> Si le défenseur récupère le ballon il peut aller frapper.</p>
        <p><i class="fas fa-check"></i> Mettre un temps limite de 6 á 10 secondes pour le duel.</p>
        <p><i class="fas fa-check"></i> 3-4 fois dans les 2 rôles. 45 secondes de récupération entre 2 passages.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U8: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL VITESSE`,
      SUBTITLE: `U8-U12`,
      SUBSUB: `Avec 200 exemples d'exercices de vitesse`,
    },
    CHECK: {
      TITLE: "Adaptés aux U8-U12",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse ches les U8-U12?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet)?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la vitesse</span>
            et 200 idées d'exercices pour travailler la vitesse. 
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U8-U12 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de coordination (avec 2 séances par semaine soit 16 séances pour travailler la coordination )
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la coordination sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse chez les U8-U12. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "VITESSE" U8 - U12`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/55_1080x.png?v=1619804584",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/2-cycles-dentrainements-physiques-special-vitesse-u8-u12",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE AVEC CHANGEMENTS DE DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes</p>
        <p><i class="fas fa-check"></i> 2 carrés</p>
        <p><i class="fas fa-check"></i> Chaque joueur d'une équipe a un numéro..</p>
        <p><i class="fas fa-check"></i> 2 plots de couleurs de chaque côté d'un carré.. L'entraîneur annonce une couleur (qui correspond au parcours, les joueurs doivent contourner les 2 plots de la couleur annoncé) et un numéro qui correspond a un joueur de chaque équipe.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent réagir vite à l'annonce de leur numéro, contourner les plots et revenir au point de départ.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> 4 plots de couleurs différentes, l'entraîneur annonce 2 couleurs. Le joueur doit réagir vite au signal, contourner les 2 plots de la couleur annoncée puis frapper dans les petites cages.</p>
        <p><i class="fas fa-check"></i> En duel.</p>
        <p><i class="fas fa-check"></i> Le joueur qui frappe le premier à un point.</p>
        <p><i class="fas fa-check"></i> Si il marque il a 3 points.</p>
        <p><i class="fas fa-check"></i> L'autre joueur qui a frappé en second ne peut marquer aucun point.. Récupération 45 secondes minimum.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, annoncer seulement une couleur ou 3 couleurs, varier les départs (arrêté, en mouvement, de dos, assis... ), varier la finition ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV right-1.png",
          title: "VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> En duel (ou à 3 ou 4 joueurs).</p>
        <p><i class="fas fa-check"></i> Un carré central.</p>
        <p><i class="fas fa-check"></i> 4 portes de couleurs différentes.</p>
        <p><i class="fas fa-check"></i> 4 ballons (chacun en face d'une porte).</p>
        <p><i class="fas fa-check"></i> les joueurs trottinent dans le carré central.</p>
        <p><i class="fas fa-check"></i> L'entraîneur annonce une couleur (la couleur bleue par exemple) les joueurs doivent réagir vite, prendre le ballon et passer la porte de la couleur annoncée.</p>
        <p><i class="fas fa-check"></i> Le premier sur le ballon prend le ballon, le second doit l'empécher de franchir la porte avec le ballon.</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1 CONTRE 1 PUIS FRAPPE`,
          text: `
        <p><i class="fas fa-check"></i> Les attaquants doivent dribbler un défenseur avant de pouvoir tirer.</p>
        <p><i class="fas fa-check"></i> Si le défenseur récupère le ballon il peut aller frapper.</p>
        <p><i class="fas fa-check"></i> Mettre un temps limite de 6 á 10 secondes pour le duel.</p>
        <p><i class="fas fa-check"></i> 3-4 fois dans les 2 rôles. 45 secondes de récupération entre 2 passages.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U12: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL VITESSE`,
      SUBTITLE: `U12-U14`,
      SUBSUB: `Avec 200 exemples d'exercices de vitesse`,
    },
    CHECK: {
      TITLE: "Adaptés aux U12-U14",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse ches les U12-U14?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la vitesse</span>
            et 200 idées d'exercices pour travailler la vitesse. 
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U12-U14 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de coordination (avec 2 séances par semaine soit 16 séances pour travailler la coordination )
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la coordination sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse chez les U12-U14. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "VITESSE" U12 - U14`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/57_1080x.png?v=1619804630",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/2-cycles-dentrainements-physiques-special-vitesse-u12-u14",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE AVEC CHANGEMENTS DE DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes</p>
        <p><i class="fas fa-check"></i> 2 carrés</p>
        <p><i class="fas fa-check"></i> Chaque joueur d'une équipe a un numéro..</p>
        <p><i class="fas fa-check"></i> 2 plots de couleurs de chaque côté d'un carré.. L'entraîneur annonce une couleur (qui correspond au parcours, les joueurs doivent contourner les 2 plots de la couleur annoncé) et un numéro qui correspond a un joueur de chaque équipe.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent réagir vite à l'annonce de leur numéro, contourner les plots et revenir au point de départ.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> 4 plots de couleurs différentes, l'entraîneur annonce 2 couleurs. Le joueur doit réagir vite au signal, contourner les 2 plots de la couleur annoncée puis frapper dans les petites cages.</p>
        <p><i class="fas fa-check"></i> En duel.</p>
        <p><i class="fas fa-check"></i> Le joueur qui frappe le premier à un point.</p>
        <p><i class="fas fa-check"></i> Si il marque il a 3 points.</p>
        <p><i class="fas fa-check"></i> L'autre joueur qui a frappé en second ne peut marquer aucun point.. Récupération 45 secondes minimum.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, annoncer seulement une couleur ou 3 couleurs, varier les départs (arrêté, en mouvement, de dos, assis... ), varier la finition ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV14 right-1.png",
          title: "VITESSE ET FINITION",
          text: `
        <p><i class="fas fa-check"></i> 3 groupes, un au centre du terrain (en rouge), un autre sur le côté (en blanc), un proche du but (en jaune).</p>
        <p><i class="fas fa-check"></i> 3 carrés proche du but, un rouge, un bleu, un jaune.</p>
        <p><i class="fas fa-check"></i> Un joueur du groupe blanc doit effectuer une course avec ballon et centrer.</p>
        <p><i class="fas fa-check"></i> Il doit centrer pour un des deux joueurs rouge (annoncé avant le départ).</p>
        <p><i class="fas fa-check"></i> Le joueur jaune doit sprinter pour aller se placer dans un des 2 carrés.</p>
        <p><i class="fas fa-check"></i> Les joueurs rouges doivent sprinter et aller dans les autres carrés.</p>
        <p><i class="fas fa-check"></i> Le joueur blanc doit donc prendre l'information avant de centrer pour voir dans quel carré le joueur rouge sera placé.</p>
        <p><i class="fas fa-check"></i> Les joueurs qui ont centré et frappé reviennent en marchant.</p>
        `,
        },
        {
          img: "Cycles/JeunesV12 right-2.png",
          title: `VITESSE`,
          text: `
        <p><i class="fas fa-check"></i> Chaque joueur a un cerceau.</p>
        <p><i class="fas fa-check"></i> L'entraineur enlève un cerceau avant chaque nouveau départ.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent sortir de leur cerceau, aller contourner le mannequin (ou piquet) de la couleur annoncée par l'entraîneur et trouver un nouveau cerceau libre.</p>
        <p><i class="fas fa-check"></i> Un joueur est éliminé à chaque partie.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U14: {
    FIRST: {
      TITLE: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL VITESSE`,
      SUBTITLE: `U14-U18`,
      SUBSUB: `Avec 200 exemples d'exercices de vitesse`,
    },
    CHECK: {
      TITLE: "Adaptés aux U14-U18",
      CHECKS: [
        `Vous êtes parfois en manque d’idées pour apporter de la nouveauté à vos séances d'entraînements ?`,
        `Vous aimeriez avoir des exemples de cycles d'entraînements pour le travail de la vitesse ches les U14-U18?`,
        `Vous aimeriez gagner du temps dans la création de vos séances (et ne pas chercher des heures de nouveaux exercices sur internet) ?`,
        `Vous aimeriez connaître plus d’exercices pour travailler la vitesse ?`,
        `Vous aimeriez voir plus de motivation et de progrès chez vos joueurs quand ils font des exercices physiques?`,
      ],
    },
    SEMAINE: `<p>
            Nous proposons un programme en ligne avec
            <span>2 cycles de 4 semaines d'entraînements de la vitesse</span>
            et 200 idées d'exercices pour travailler la vitesse. 
          </p>
          <p>
            Ce guide s’adresse aux coachs d'équipes U14-U18 qui cherchent des exemples de cycles d'entraînements et des idées d’exercices pour travailler la vitesse.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Vous avez une partie “explications” sur la coordination
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 cycles de 4 semaines sur le travail de coordination (avec 2 séances par semaine soit 16 séances pour travailler la coordination )
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Des idées d'exercices pour travailler la coordination sans matériel ou avec petit matériel (que tous les clubs peuvent avoir, et tous avec ballon)
          </p>`,
    APRES: {
      COL1: `<p>
            Vous allez retrouver 2 cycles d'entraînements physiques pour travailler la vitesse chez les U14-U18. Vous pouvez proposer par exemple le premier cycle dans la première partie de saison et le second cycle dans le seconde partie de saison. Pour progresser, il faut parfois (voir souvent) apporter de la variété, de la nouveauté, afin de surprendre les organismes (source de progrès) et d'éviter la monotonie (perte de motivation, surentraînement...)
          </p>`,
      COL2: `<p>
            Pour cela, vous pouvez varier les intensités, les volumes, ... mais aussi les exercices!
          </p>
          <p>
            Nous vous proposons ainsi plus de 200 exemples d'exercices pour travailler la vitesse (avec et sans ballon)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CYCLES D'ENTRAÎNEMENTS PHYSIQUES SPÉCIAL "VITESSE" U14 - U16`,
      img:
        "https://cdn.shopify.com/s/files/1/0565/6028/7923/products/58_1080x.png?v=1619804674",
      link:
        "https://preparationphysiquefootball-shop.com/collections/programmes-pour-coachs-jeunes/products/2-cycles-dentrainements-physiques-special-vitesse-u14-u16",
    },
    EXERCICES: {
      TITLE: `EXERCICES PHYSIQUES SOUS FORMAT 3D`,
      SUBTITLE: `Voici des exemples d'exercices physiques que vous retrouverez dans le programme en ligne.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "VITESSE DE RÉACTION ET VITESSE AVEC CHANGEMENTS DE DIRECTION",
          text: `
        <p><i class="fas fa-check"></i> 2 équipes</p>
        <p><i class="fas fa-check"></i> 2 carrés</p>
        <p><i class="fas fa-check"></i> Chaque joueur d'une équipe a un numéro..</p>
        <p><i class="fas fa-check"></i> 2 plots de couleurs de chaque côté d'un carré.. L'entraîneur annonce une couleur (qui correspond au parcours, les joueurs doivent contourner les 2 plots de la couleur annoncé) et un numéro qui correspond a un joueur de chaque équipe.</p>
        <p><i class="fas fa-check"></i> Les joueurs doivent réagir vite à l'annonce de leur numéro, contourner les plots et revenir au point de départ.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "VITESSE DE RÉACTION",
          text: `
        <p><i class="fas fa-check"></i> 4 plots de couleurs différentes, l'entraîneur annonce 2 couleurs. Le joueur doit réagir vite au signal, contourner les 2 plots de la couleur annoncée puis frapper dans les petites cages.</p>
        <p><i class="fas fa-check"></i> En duel.</p>
        <p><i class="fas fa-check"></i> Le joueur qui frappe le premier à un point.</p>
        <p><i class="fas fa-check"></i> Si il marque il a 3 points.</p>
        <p><i class="fas fa-check"></i> L'autre joueur qui a frappé en second ne peut marquer aucun point.. Récupération 45 secondes minimum.</p>
        <p><i class="fas fa-check"></i> Vous pouvez varier les distances, annoncer seulement une couleur ou 3 couleurs, varier les départs (arrêté, en mouvement, de dos, assis... ), varier la finition ...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV14 right-1.png",
          title: "VITESSE ET FINITION",
          text: `
        <p><i class="fas fa-check"></i> 3 groupes, un au centre du terrain (en rouge), un autre sur le côté (en blanc), un proche du but (en jaune).</p>
        <p><i class="fas fa-check"></i> 3 carrés proche du but, un rouge, un bleu, un jaune.</p>
        <p><i class="fas fa-check"></i> Un joueur du groupe blanc doit effectuer une course avec ballon et centrer.</p>
        <p><i class="fas fa-check"></i> Il doit centrer pour un des deux joueurs rouge (annoncé avant le départ).</p>
        <p><i class="fas fa-check"></i> Le joueur jaune doit sprinter pour aller se placer dans un des 2 carrés.</p>
        <p><i class="fas fa-check"></i> Les joueurs rouges doivent sprinter et aller dans les autres carrés.</p>
        <p><i class="fas fa-check"></i> Le joueur blanc doit donc prendre l'information avant de centrer pour voir dans quel carré le joueur rouge sera placé.</p>
        <p><i class="fas fa-check"></i> Les joueurs qui ont centré et frappé reviennent en marchant.</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1 CONTRE 1 PUIS FRAPPE`,
          text: `
        <p><i class="fas fa-check"></i> Les attaquants doivent dribbler un défenseur avant de pouvoir tirer.</p>
        <p><i class="fas fa-check"></i> Si le défenseur récupère le ballon il peut aller frapper.</p>
        <p><i class="fas fa-check"></i> Mettre un temps limite de 6 á 10 secondes pour le duel.</p>
        <p><i class="fas fa-check"></i> 3-4 fois dans les 2 rôles. 45 secondes de récupération entre 2 passages.</p>
        `,
        },
      ],
    },
  },
  MODAL_LINKS: {
    GUIDE_JEUNES: '',
    SAISONS_J: '',
    SAISONS_S: '',
    EXERCICES: 'https://2fc70f3b.sibforms.com/serve/MUIEAN0he49Oa7qEw9_C-qVRwI4tqHKFksFtiHY_ByyeAMWuAln6MzQo6ZKUtMeUZWV23lwg2GtbkcGL3jHA6_2bNeVuab_r3ISZWy_C8SjHpdr1jodS5FJpE8MkzqaJ2L1onT9myeJvcj1B2dS8yHpYbfPru3fM4olzlkyZCaSW_7-_zSmv2cUeXrdd_rQRTAv04FWYIOzH9M46',
    FORMATION: '',
    COACHS: '',
    TREVE: '',
  },
  BLOG_LINK: 'https://preparationphysiquefootball.com/blog',
  CHANGE_P: 'programme',
  CHANGE_G: 'guide',
  GARANTIE_IMG: 'garantie.png',
  PAY_IMG: 'Grupo 1086.png',
  DISPONIBLE: 'BIENTÔT DISPONIBLE',
};