export default {
  data() {
    return {
      mobile: false,
    };
  },
  created() {
    var w = window.innerWidth;
    if (w <= 1024) {
      this.mobile = true;
    }
  },
};