import { render, staticRenderFns } from "./OpinionCard.vue?vue&type=template&id=239a1907&scoped=true&"
import script from "./OpinionCard.vue?vue&type=script&lang=js&"
export * from "./OpinionCard.vue?vue&type=script&lang=js&"
import style0 from "./OpinionCard.vue?vue&type=style&index=0&id=239a1907&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "239a1907",
  null
  
)

export default component.exports