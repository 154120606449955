<template>
  <div class="card">
    <img
      class="card-img-top"
      :src="require('@/assets/Images/Home/' + cardInfo.IMG)"
      alt="Img-card"
    />
    <div class="card-body">
      <h5 class="card-title">{{ cardInfo.TITLE }}</h5>
      <div class="card-text" v-html="cardInfo.TEXT"></div>
      <div class="card-button">
        <button class="btn" @click.prevent="goTo(cardInfo.LINK)">{{ cardInfo.BUTTON }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: Object,
  },
  methods: {
    goTo(link){
      this.$router.push({ name: link })
    }
  },
  data() {
    return {
      img: "logo.png",
    };
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 20px !important;
  max-width: 360px;
}

.card-body {
  padding: 25px 34px;
  position: relative;
}

.card-title {
  text-align: left;
  font: normal normal bold 18px/28px Montserrat;
  letter-spacing: 0px;
  color: #1b1a1a;
  opacity: 1;
}

.card-text >>> p {
  text-align: left;
  font: normal normal 500 14px/25px Montserrat;
  letter-spacing: 0px;
  color: #1b1a1a;
  opacity: 1;
}

.btn {
  background-image: url("../../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  border-radius: 10px;
  text-align: center;
  /* border: 0.4px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  font: normal normal bold 16px/27px Montserrat;
  color: #0f1010;
  padding: 7px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-size: 95px 80px;
  height: 80px;
  width: 100%;
}

.btn:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

@media (min-width: 1920px) {
  .card {
    width: 494px;
  }

  .card-button {
    left: 20%;
  }
}
</style>
