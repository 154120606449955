<template>
  <div id="app">
    <transition name="fade" mode="out-in" v-bind:enter-active-class="transition" v-bind:leave-active-class="transition">
      <router-view />
    </transition>
  </div>
</template>

<script>
import AOS from "aos/dist/aos.js";
export default {
  computed: {
    transition() {
      return this.$store.state.transition;
    },
  },
  mounted() {
    AOS.init({ offset: 200, once: true });
  },
};
</script>

<style>
#nav-container {
  background-color: #f7f7f7;
}
#app > div > div:nth-child(2) {
  background-color: #f7f7f7;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.next.fade-enter { transform: translateX(10%); }
.next.fade-leave-to { transform: translateX(-10%); }
.prev.fade-enter { transform: translateX(-10%); }
.prev.fade-leave-to { transform: translateX(10%); }

.next,
.prev {
  transition: all .35s ease;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  overflow-x: hidden;
}

#nav-container {
  height: 260px;
  background-image: url("./assets/Images/Background/Nav.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

#footer-container {
  background-image: url("./assets/Images/Footer/Enmascarar grupo 128@2x.png"),
    url("./assets/Images/Footer/Grupo 978@2x.png"),
    url("./assets/Images/Footer/Enmascarar grupo 174@2x.png");
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 100% 100%, 15% 50%, 0 0;
  background-size: 20%, 13%, cover;
  height: 473px;
  position: relative;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.carousel-indicators li {
  border: 1px solid red;
  width: 10px !important;
  height: 10px !important;
}

@media (max-width: 1024px) {
  #nav {
    padding: 20px 0px;
  }

  #nav-container {
    height: 150px;
    background-image: url("./assets/Images/Background/NavBarMobile.png");
    background-repeat: no-repeat;
  }

  #footer-container {
    background-image: url("./assets/Images/Footer/Enmascarar grupo 128@2x.png"),
      url("./assets/Images/Footer/Grupo 978@2x.png"), 
      url("./assets/Images/Background/FooterMobile.png");
    background-repeat: no-repeat;
    background-size: 30%, 30%, cover;
    background-position: 100% 100%, 0% 30%, 0 0;
    height: 420px;
    padding: 0 0 40px 0;
  }
}

@media (max-width: 425px) {
  #nav {
    padding: 0;
  }

  #nav-container {
    height: 121px;
    background-size: cover;
  }

  #footer-container {
    height: 400px;
    padding: 0 0 40px 0;
    background-position: 108% 108%, 0% 40%, 0 0;
    background-size: 30%, 30%, cover;
  }
}

@media (max-width: 375px) {
  #nav-container {
    height: 121px;
    background-size: auto;
  }

  #footer-container {
    background-size: 35%, 30%, auto;
  }
}
</style>
