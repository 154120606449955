<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="home">
      <div class="row" id="first">
        <div class="col-12">
          <div class="row">
            <Intro id="sec1" class="padding" :mobile="mobile" :move="'#seccard'" />
          </div>
          <div class="row">
            <HomeCards id="seccard" class="padding" />
          </div>
        </div>
      </div>
      <div class="row">
        <RotateSec id="secRot" class="padding" :iconInfo="locale[lang].HOME.ICONINFO" :mobile="mobile" />
      </div>
      <div class="row my-5 justify-content-center" id="formation">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12 my-3">
          <div class="cardF px-md-5 px-4 py-5">
            <h1>{{ locale[lang].HOME.FORMATION }}</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec2" class="homesec padding" :imgLeft="false" :infoSec="locale[lang].HOME.INFOSEC2"
          :haveButton="true" :openMenu="'formation'" />
        <MobileSec v-else id="mobile2" :infoSec="locale[lang].HOME.INFOSEC2" :titleTop="true" :haveButton="true"
          :openMenu="'formation'" />
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec3" class="homesec padding" :imgLeft="true" :infoSec="locale[lang].HOME.INFOSEC3"
          :haveButton="true" :openMenu="'programmes'" />
        <MobileSec v-else id="mobile3" :infoSec="locale[lang].HOME.INFOSEC3" :titleTop="true" :haveButton="true"
          :openMenu="'programmes'" />
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec4" class="homesec padding" :imgLeft="false" :infoSec="locale[lang].HOME.INFOSEC4"
          :haveButton="true" :openMenu="'exercices'" />
        <MobileSec v-else id="mobile4" :infoSec="locale[lang].HOME.INFOSEC4" :titleTop="true" :haveButton="true"
          :openMenu="'exercices'" />
      </div>
      <div class="row">
        <AppSection id="appsec" :appSec="appSec" :titleTop="mobile" />
      </div>
      <div class="row">
        <HomeSection v-if="!mobile" id="sec5" class="homesec padding" :imgLeft="false" :infoSec="locale[lang].HOME.INFOSEC5" :page="'Emails'"
          :haveButton="true" />
        <MobileSec v-else id="mobile5" :infoSec="locale[lang].HOME.INFOSEC5_MOBILE" :titleTop="true" :haveButton="true" :page="'Emails'" />
      </div>
      <div class="row">
        <OpinionCarousel id="carousel" :title="titleCarousel" :mobile="mobile" />
      </div>
      <div class="row">
        <ArticlesCard :artInfo="arts" v-if="!mobile && !load" id="articles" :numCards="6" />
        <div class="col-12" id="notiCarousel" v-else-if="!load">
          <div class="row">
            <div class="col-12 title-art" data-aos="fade-right">
              <h1>{{locale[lang].ARTICLES_TITLE}}</h1>
            </div>
          </div>
          <div class="row">
            <NotiCarousel :mobile="mobile" :infoNoti="arts" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container">
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from "@/components/Home/Intro.vue";
import HomeCards from "@/components/Home/HomeCards.vue";
import HomeSection from "@/components/Home/HomeSection.vue";
import RotateSec from "@/components/Home/RotateSec.vue";
import ArticlesCard from "@/components/Home/ArticlesCards.vue";
import AppSection from "@/components/Home/AppSection.vue";
import OpinionCarousel from "@/components/OpinionCarousel.vue";
import MobileSec from "@/components/MobileSec.vue";
import NotiCarousel from "@/components/NotiCarousel.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";

export default {
  name: "Home",
  components: {
    HomeCards,
    HomeSection,
    RotateSec,
    ArticlesCard,
    AppSection,
    OpinionCarousel,
    MobileSec,
    NotiCarousel,
    Intro,
    NavBar,
    Footer,
  },
  created() {
    var w = window.innerWidth;
    this.titleCarousel = this.locale[this.lang].HOME.OPINION_TITLE
    this.appSec = this.locale[this.lang].HOME.APPSEC
    if (w <= 1024) {
      this.mobile = true;
      this.appSec.title = this.locale[this.lang].HOME.APPSEC.title_mobile;
      this.titleCarousel = this.locale[this.lang].HOME.OPINION_MOBILE_TITLE;
    }
    if (this.arts.length == 0) {
      this.$store.dispatch("GET_POSTS")
        .then(() => this.load = false)
        .catch(console.error);
    }
    this.load = false
  },
  computed: {
    arts() {
      return this.$store.state.arts;
    },
  },
  data() {
    return {
      load: true,
      titleCarousel: '',
      mobile: false,
      appSec: {},
    };
  },
};
</script>

<style scoped>
.home {
  padding: 0px 0px;
  margin-bottom: 20px;
}

#nav-container {
  background-color: #0f1010;
}

.padding {
  padding: 0px 30px;
}

.homesec >>> .title-sec {
  position: relative;
}

.homesec >>> .title-sec::before {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0;
  bottom: -30px;
  opacity: 1;
}

.homesec >>> h2, .cardF h1 {
  text-align: left;
  font: normal normal 800 34px/40px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.homesec >>> h4 {
  text-align: left;
  font: normal normal 600 24px/30px Montserrat;
  letter-spacing: 0px;
  color: #383838;
  opacity: 1;
}

.cardF h1 {
  text-align: center;
}

.cardF {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 30px;
  opacity: 1;
  padding: 15px 20px;
}

#articles >>> .card {
  height: 94%;
}

.homesec >>> .button-sec {
  text-align: left;
}

#first {
  background-image: url("../assets/Images/Background/Enmascarar grupo 129.png");
  background-size: 15%;
  background-position: 0 70%;
  background-repeat: no-repeat;
}

#sec1 {
  background-image: url("../assets/Images/Coaches/Enmascarar grupo 129.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  background-size: cover;
  padding-bottom: 5%;
}

#seccard {
  background-image: url("../assets/Images/Background/Trazado 1883.png"),
    url("../assets/Images/Background/Grupo 685.png"),
    url("../assets/Images/Background/Grupo 685.png");
  background-repeat: no-repeat;
  background-size: 30%, 8%, 8%;
  background-position: 100% 20%, 98% 0%, 50% 50%;
  padding: 5% 90px;
}

#secRot >>> #rot-0 .img-fluid {
  top: 16px !important;
  left: 98px !important;
}

#secRot >>> #rot-1 .img-fluid {
  top: 46px !important;
  left: 95px !important;
}

#secRot >>> #rot-1 {
  padding-top: 20px;
}

#secRot >>> #rot-2 .img-fluid {
  top: 96px !important;
  left: 104px !important;
}

#secRot >>> #rot-2 {
  padding-top: 70px;
}

#secRot >>> #rot-3 .img-fluid {
  top: 47% !important;
  left: 103px !important;
}

#secRot >>> #rot-3 {
  padding-top: 150px;
}

#secRot {
  background-image: url("../assets/Images/Background/SOCCER-3 - FUERZA.gif"),
    url("../assets/Images/Background/Enmascarar grupo 130.png"),
    url("../assets/Images/Background/Grupo 685.png");
  background-repeat: no-repeat;
  background-size: auto, auto, 10%;
  background-position: 98% 0, 0 0, 70% 0%;
  padding: 13% 15% 15% 12%;
}

#secRot >>> .container-fluid {
  padding: 0;
}

#sec2 {
  background-image: url("../assets/Images/Background/Grupo 687.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 58% 0;
  padding: 0 90px;
}

#sec2 >>> .title-sec::before {
  border-bottom: none;
}

#sec2 >>> .text-sec {
  position: relative;
}



#sec2 >>> h4.subtitle {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 50px;
}

#sec2 >>> img {
  margin-top: 50px !important;
}

#sec2 >>> p {
  text-align: left;
  font: normal normal 500 18px/30px Montserrat;
  letter-spacing: 0px;
  color: #383838;
}

#sec2 >>> .text {
  padding: 80px 0px 0px !important;
}

#sec2 >>> .text-sec h4{
  position: relative;
}

#sec2 >>> .text-sec h4.subtitle::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 61px;
  left: 0;
  bottom: -30px;
  opacity: 1;
}

  #appsec >>> h2 {
    padding-right: 10%;
  }

#appsec {
  padding: 20% 35px 27% 0;
  background-image: url("../assets/Images/Background/appHome.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 0;
}

#sec3 {
  background-image: url("../assets/Images/Background/Elipse 102.png"),
    url("../assets/Images/Background/Trazado 2754.png"),
    url("../assets/Images/Background/Trazado 2786.png"),
    url("../assets/Images/Background/Enmascarar grupo 131.png");
  background-repeat: no-repeat;
  padding-top: 20%;
  padding-bottom: 15%;
  background-size: auto;
  background-position: 25% 10%, 50% 0, 100% -7%, 0 100%;
}

#sec3 >>> img {
  margin-top: 35px !important;
  height: auto !important;
}

#sec3 >>> .text {
  padding: 10px 40px 0px !important;
}

#sec3 >>> h2 {
  color: #ffffff;
}

#sec3 >>> p {
  text-align: left;
  font: normal normal 500 24px/40px Montserrat;
  letter-spacing: 0px;
  color: #dfdfdf;
  opacity: 1;
  margin-bottom: 40px;
}

#sec3 >>> .btn,
#mobile3 >>> .btn-sc {
  background-color: #0f1010;
  color: #ffffff;
  border-color: #ffffff !important;
}

#sec3 >>> .btn:hover {
  background-color: rgba(75, 75, 75, 0.507);
}

#sec4 >>> p {
  text-align: left;
  font: normal normal 500 22px/34px Montserrat;
  letter-spacing: 0px;
  color: #383838;
  opacity: 1;
}

#sec5 {
  background-image: url("../assets/Images/Background/Red.gif");
  background-repeat: no-repeat;
  padding-bottom: 20%;
  background-size: auto;
  background-position: 20% 90%;
}

#sec5 >>> .text {
  padding: 80px 0px 80px 40px;
}

#sec5 >>> img {
  position: absolute;
  right: -12%;
  height: auto;
}

#sec5 >>> p {
  text-align: left;
  font: normal normal 500 22px/34px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

#articles {
  background-image: url("../assets/Images/Background/Trazado 2786.png"),
    url("../assets/Images/Background/Trazado 1903.png"),
    url("../assets/Images/Background/Grupo 691.png"),
    url("../assets/Images/Background/Enmascarar grupo 135.png");
  background-repeat: no-repeat;
  padding: 10% 0%;
  background-size: auto;
  background-position: 110% 80%, 0% 10%, 4% 73%, 100% 0;
}

#carousel {
  background-image: url("../assets/Images/Background/Black.gif"),
    url("../assets/Images/Saison/Grafismo1.png");
  background-repeat: no-repeat;
  padding: 5% 100px 15% 100px !important;
  background-size: 40%, 40%;
  background-position: 50% 100%, 0 70%;
}

@media (max-width: 1024px) {
  #seccard {
    background-image: url("../assets/Images/Background/Trazado 1883.png"),
    url("../assets/Images/Background/Grupo 685.png"),
    url("../assets/Images/Background/Grupo 1013.png");
    background-repeat: no-repeat;
    padding: 12px 18px;
  }

  #sec1 {
    background-image: url("../assets/Images/Coaches/Enmascarar grupo 217.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 0 0 25% 0;
  }

  #footer-container {
    background-color: #2D2D2D;
  }

  #first, #seccard {
    background-size: 30%, 30%, 50%;
  }

  #seccard {
    background-position: 100% 20%, 98% 0%, 40% 90%;
  }

  #formation {
    padding: 0 30px;
  }

  #mobile1 >>> h2, .cardF h1 {
    font: normal normal 800 25px/30px Montserrat;
  }

  #mobile1 >>> p {
    color: #707070;
    line-height: 24px;
  }

  #mobile1 >>> .btn-sc {
    padding: 35px 50px;
  }

  #mobile4 {
    background-image: url("../assets/Images/Background/Enmascarar grupo 133.png"),
    url("../assets/Images/Idees/Enmascarar grupo 150.png");
    background-repeat: no-repeat;
    background-size: 40%, 20%;
    background-position: 100% 70%, 4% 10%;
  }

  #mobile5 {
    background-image: url("../assets/Images/Background/Red.gif");
    background-repeat: no-repeat;
    padding-bottom: 30%;
    background-position: 50% 100%;
    background-size: 55%;
  }

  #mobile2 {
    background-image: url("../assets/Images/Training/Enmascarar grupo 150.png"),
    url("../assets/Images/Training/Trazado 2465.png");
    background-repeat: no-repeat;
    background-size: 30%, 20%;
    background-position: 100% 70%, 4% 15%;
  }

  #mobile5 >>> .btn-sc {
    height: auto;
    padding: 25px;
  }

  #mobile1 >>> p,
  #mobile2 >>> p,
  #mobile3 >>> p,
  #mobile4 >>> p,
  #mobile5 >>> p,
  #mobile2 >>> h4.first {
    font-size: 16px;
  }

  #mobile2 >>> h4.subtitle {
    font-size: 20px;
    font-weight: 700;
  }

  #mobile2 >>> .btn-sc {
    margin-top: 40px;
    margin-bottom: 54px;
  }

  #mobile3 >>> h2,
  #mobile3 >>> p {
    color: #ffffff;
  }

  #mobile3 >>> p {
    margin-bottom: 25px;
  }

  #mobile3 {
    background-image: url("../assets/Images/Background/Enmascarar grupo 198.png"),
    url("../assets/Images/Background/Trazado 2754.png");
    background-repeat: no-repeat;
    background-position: 15px 0, 95% 100%;
    background-size: 100% 90%, 40%;
    padding: 32% 5% 45% 5%;
  }

  #mobile3 >>> img {
    margin-top: 15px;
    margin-bottom: 30px;
    height: 330px;
  }

  #mobile3 >>> .btn-sc {
    margin-top: 45px;
  }

  #mobile4 >>> .title-sec {
    padding: 0 5%;
    position: relative;
  }

  #mobile4 >>> .title-sec::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 61px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -35px;
    opacity: 1;
  }

  #mobile4 >>> .img-fluid {
    margin-bottom: 40px;
    margin-top: 60px;
  }

  #mobile5 >>> img {
    margin-left: 24%;
    margin-top: 32px;
    margin-bottom: 46px;
  }

  #cardsM {
    padding: 86px 0;
  }

  #secRot {
    background-image: url("../assets/Images/Background/Enmascarar grupo 197.png");
    background-repeat: no-repeat;
    background-size: 100% 98%;
    background-position: 15px 0;
    padding: 18% 3%;
  }

  #secRot >>> #rot-0,
  #secRot >>> #rot-1 {
    margin-bottom: 70px;
  }

  #secRot >>> .rotate {
    height: 75px;
    width: 75px;
    margin-left: 200px;
  }

  #secRot >>> .text h3 {
    font-size: 16px;
    line-height: 19px;
  }

  #secRot >>> .text p {
    font-size: 14px;
    line-height: 17px;
  }

  #secRot >>> #rot-0 .img-fluid {
    top: 15px !important;
    left: 235px !important;
    width: 40px;
  }

  #secRot >>> #rot-1 .img-fluid {
    top: 23px !important;
    left: 231px !important;
    width: 46px;
  }

  #secRot >>> #rot-2 .img-fluid {
    top: 23px !important;
    left: 237px !important;
    width: 32px;
  }

  #secRot >>> #rot-3 .img-fluid {
    top: 18px !important;
    left: 233px !important;
    width: 40px;
  }

  #secRot >>> #rot-1,
  #secRot >>> #rot-2,
  #secRot >>> #rot-3 {
    padding-top: 0;
  }

  #appsec {
    padding: 10% 0;
    background-image: url("../assets/Images/Training/Enmascarar grupo 150.png"),
    url("../assets/Images/Training/Trazado 2687.png"),
    url("../assets/Images/Blog/Elipse 117.png"),
    url("../assets/Images/Blog/Grupo 691.png");
    background-repeat: no-repeat;
    background-size: 30%, 20%, 20%, 30%;
    background-position: 100% 70%, 4% 25%, 85% 0, 10% 95%;
  }

  #appsec >>> h2 {
    text-align: center;
    font: normal normal 800 21px/25px Montserrat;
    padding: 0 10% 10% 10%;
  }

  #appsec >>> .title-sec::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 30px;
    left: 47%;
    top: 80%;
    opacity: 1;
  }

  #appsec >>> .button-sec {
    margin-top: 46px;
    justify-content: center;
  }

  #appsec >>> .button-sec::after {
    border-bottom: none;
  }

  #appsec >>> button {
    font-size: 16px;
    line-height: 15px;
    padding: 15px 25px;
  }

  #appsec >>> .links {
    margin-top: 95px;
    padding-left: 15%;
  }

  #appsec >>> a .img-fluid {
    height: 46px;
    margin-right: 20px;
  }

  #carousel {
    /* background-image: none; */
    padding: 0 0 20% !important;
  }

  #carousel >>> .title {
    padding: 0 10%;
    margin-top: 40px;
    margin-bottom: 30px;
    position: relative;
  }

  #carousel >>> .title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 30px;
    left: 47%;
    bottom: -30%;
    opacity: 1;
  }

  #seccard >>> .title {
    padding: 0 10%;
    margin-bottom: 65px;
  }

  #seccard >>> .title h3 {
    text-align: center;
    font: normal normal 800 21px/25px Montserrat;
    letter-spacing: 0px;
    color: #1c1c1c;
    opacity: 1;
  }

  #carousel >>> .title h3 {
    text-align: center;
    font-size: 21px;
    line-height: 25px;
  }

  #carousel >>> .opinion-card {
    padding: 15px 16px;
  }

  #carousel >>> .carousel-indicators {
    left: 0;
    bottom: -20px;
  }

  #cardsM >>> .carousel-indicators li,
  #carousel >>> .carousel-indicators li,
  #notiCarousel >>> .carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 1;
  }

  #carousel >>> .left-img {
    display: flex;
  }

  #carousel >>> .img-cm {
    width: 46px;
    height: 46px;
    margin-right: 14px;
  }

  #carousel >>> p {
    text-align: left;
    font: italic normal 500 12px/21px Montserrat;
    letter-spacing: 0px;
    color: #444444;
  }

  #carousel >>> .body h3 {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0;
  }

  #carousel >>> .body h5 {
    font-size: 12px;
    line-height: 22px;
  }

  #notiCarousel {
    background-image: url("../assets/Images/Background/Grafismo Footer Home.png");
    background-repeat: no-repeat;
    background-size: 100% 105%;
    background-position: 15px 0;
    padding: 15% 15px 10% 15px;
  }

  .title-art {
    padding: 0 10%;
    margin-bottom: 40px;
    position: relative;
  }

  .title-art h1 {
    text-align: center;
    font: normal normal 800 21px/25px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  #notiCarousel >>> .card-body {
    padding: 35px 18px 35px 26px;
  }

  #notiCarousel >>> .card-title {
    line-height: 19px;
    font-size: 16px;
  }

  #notiCarousel >>> .card-text {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }

  .home {
    margin-bottom: 0;
  }
}

@media (max-width: 825px) {
  #mobile5 >>> img {
    margin-left: 20%;
  }

  #secRot >>> .rotate {
    margin-left: 143px;
  }

  #secRot >>> #rot-0 .img-fluid {
    top: 15px !important;
    left: 176px !important;
    width: 40px;
  }

  #secRot >>> #rot-1 .img-fluid {
    top: 23px !important;
    left: 173px !important;
    width: 46px;
  }

  #secRot >>> #rot-2 .img-fluid {
    top: 23px !important;
    left: 180px !important;
    width: 32px;
  }

  #secRot >>> #rot-3 .img-fluid {
    top: 18px !important;
    left: 176px !important;
    width: 40px;
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  #notiCarousel,
  #carousel {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}

@media (max-width: 425px) {
  #appsec {
    padding: 15% 0;
  }

  #secRot >>> .rotate {
    height: 75px;
    width: 75px;
    margin-left: 52px;
  }

  #secRot >>> #rot-0 .img-fluid {
    top: 15px !important;
    left: 84px !important;
    width: 40px;
  }

  #secRot >>> #rot-1 .img-fluid {
    top: 23px !important;
    left: 81px !important;
    width: 46px;
  }

  #secRot >>> #rot-2 .img-fluid {
    top: 23px !important;
    left: 88px !important;
    width: 32px;
  }

  #secRot >>> #rot-3 .img-fluid {
    top: 18px !important;
    left: 85px !important;
    width: 40px;
  }
}

@media (max-width: 375px) {
  #mobile3 {
    background-size: 100% 95%;
  }

  #mobile3 >>> .btn-sc {
    margin-top: 10px;
  }

  #mobile5 >>> img {
    margin-left: 20%;
  }

  #notiCarousel {
    padding: 15% 15px;
  }
}

@media (min-width: 1920px) {
  .homesec >>> h2 {
    font: normal normal 800 38px/47px Montserrat;
  }

  #carousel {
    background-size: 38%, auto;
  }

  #sec2 {
    padding: 0 160px;
  }

  .homesec >>> h4 {
    font: normal normal 600 28px/34px Montserrat;
  }

  #seccard {
    background-position: 100% 45%, 98% 15%, 69% 120%;
    background-size: 25%, 7%, 15%;
    padding: 5% 10%;
    height: 900px;
  }

  #secRot {
    background-size: auto, auto, 15%;
    background-position: 98% 0%, 0 160%, 69% -18%;
    padding: 5% 160px 8% 160px;
  }

  #secRot >>> #rot-0 .img-fluid {
    top: 16px !important;
    left: 182px !important;
  }

  #secRot >>> #rot-1 .img-fluid {
    top: 46px !important;
    left: 178px !important;
  }

  #secRot >>> #rot-1 {
    padding-top: 20px;
  }

  #secRot >>> #rot-2 .img-fluid {
    top: 96px !important;
    left: 188px !important;
  }

  #secRot >>> #rot-2 {
    padding-top: 70px;
  }

  #secRot >>> #rot-3 .img-fluid {
    top: 47% !important;
    left: 186px !important;
  }

  #secRot >>> #rot-3 {
    padding-top: 150px;
  }

  #secRot >>> .container-fluid {
    padding: 0 85px;
  }

  #secRot >>> #row {
    margin-top: 310px;
  }

  #sec2 >>> .text-sec::after {
    top: 48%;
  }

  #sec3 {
    background-position: 25% 15%, 50% 0, 98% -7%, 0 100%;
    padding-top: 20%;
    padding-bottom: 15%;
  }

  #appsec {
    padding: 17% 0;
  }

  .padding {
    padding: 0px 160px;
  }

  #articles {
    padding: 10% 7%;
  }

  #sec5 >>> img {
    right: -16%;
  }
}
</style>
