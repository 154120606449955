import locale from "@/locale";
export default {
  data() {
    return {
      locale,
    }
  },
  computed: {
    lang() {
      return process.env.VUE_APP_LOCALE;
    }
  }
};