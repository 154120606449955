<template>
  <div class="container-fluid">
    <div class="row mx-auto my-auto" id="carouselOpinion">
      <div class="col-6" v-if="!mobile"></div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-12 title" data-aos="fade-right">
        <h3>
          {{ title }}
        </h3>
      </div>
      <div class="col-12">
        <!--Carousel Wrapper-->
        <div
          id="multi-item-opinion"
          class="carousel slide carousel-multi-item"
          data-ride="carouselOpinion"
        >
          <!--Indicators-->
          <ol class="carousel-indicators">
            <li
              data-target="#multi-item-opinion"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#multi-item-opinion" data-slide-to="1"></li>
            <li
              v-if="mobile"
              data-target="#multi-item-opinion"
              data-slide-to="2"
            ></li>
          </ol>
          <!--/.Indicators-->

          <!--Slides-->
          <div class="carousel-inner" role="listbox">
            <!--First slide-->
            <div class="carousel-item active">
              <div class="row item">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :mobile="mobile" :cardInfo="locale[lang].OPINIONS.FIRST_SLIDE[0]" />
                </div>
                <div v-if="!mobile" class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :cardInfo="locale[lang].OPINIONS.FIRST_SLIDE[1]"/>
                </div>
                <div v-if="!mobile" class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :cardInfo="locale[lang].OPINIONS.FIRST_SLIDE[2]"/>
                </div>
              </div>
            </div>
            <!--/.First slide-->

            <!--Second slide-->
            <div class="carousel-item">
              <div class="row item">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12" v-if="mobile">
                  <OpinionCard :mobile="mobile" :cardInfo="locale[lang].OPINIONS.FIRST_SLIDE[1]" />
                </div>
                <div v-if="!mobile" class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :cardInfo="locale[lang].OPINIONS.SECOND_SLIDE[0]" />
                </div>
                <div v-if="!mobile" class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :cardInfo="locale[lang].OPINIONS.SECOND_SLIDE[1]" />
                </div>
                <div v-if="!mobile" class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :cardInfo="locale[lang].OPINIONS.SECOND_SLIDE[2]" />
                </div>
              </div>
            </div>
            <!--/.Second slide-->

            <!-- .Third slide (only in response view) -->
            <div class="carousel-item" v-if="mobile">
              <div class="row item">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                  <OpinionCard :mobile="mobile" :cardInfo="locale[lang].OPINIONS.FIRST_SLIDE[2]" />
                </div>
              </div>
            </div>
            <!-- .Third slide (only in response view) -->
          </div>
          <!--/.Slides-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpinionCard from "@/components/OpinionCard.vue";
export default {
  components: {
    OpinionCard,
  },
  props: {
    title: String,
    mobile: Boolean,
  },
  data() {
    return {
      example: {
        text: `Excellente application, cela m'a aidé à faire tous mes entraînements`,
        person: 'Andres Fernandez'
      },
      cardInfo: []
    }
  },
};
</script>

<style scoped>
@media (min-width: 1920px) {
  #carousel {
    padding: 0px 100px !important;
  }
  .svg-inline--fa.fa-w-8 {
    width: 37em !important;
  }

  .carousel-indicators {
    left: -94% !important;
  }

  .title {
    padding-right: 90px;
  }

  .title h3 {
    font-size: 38px !important;
    line-height: 47px !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 65px !important;
  }
}

.item {
  margin: 25px 10px;
  justify-content: center;
}

.title {
  padding-right: 20px;
  margin-bottom: 50px;
}

.title h3 {
  text-align: left;
  font: normal normal 800 26px/34px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
}

.carousel-indicators li {
  background-color: transparent !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.carousel-indicators .active {
  background-color: #ff0313 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-indicators {
  bottom: -10%;
  left: -90%;
}

svg:not(:root).svg-inline--fa {
  font-size: 40px;
  color: #ff0313;
  margin-top: 20px;
}

.svg-inline--fa.fa-w-8 {
  width: 25em;
}

.svg-inline--fa {
  height: 1.5em;
}

@media (max-width: 1370px) {
  .svg-inline--fa {
    height: 35px;
  }
}

@media (max-width: 1024px) {
  #carouselOpinion {
    justify-content: center;
  }

  .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 760px) {
  .row {
    justify-content: center;
  }

  .col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
