<template>
  <div class="container-fluid">
    <div class="row">
      <template v-if="mobile">
        <div class="col-12">
          <div class="p-1">
            <img
              class="img-fluid"
              src="https://preparationphysiquefootball.com/images/Gif-d-(1)%20(1).gif"
              alt="img"
            />
          </div>
        </div>
        <div class="col-12 text">
          <div class="p-1">
            <div class="title-sec">
              <h2>{{locale[lang].HOME.INTRO.TITLE}},</h2>
            </div>
            <div class="text-sec">
              <p>
                {{locale[lang].HOME.INTRO.SUBTITLE}}
              </p>
              <h5 :class="{ 'animated flipInX': change }">{{ word }}</h5>
            </div>
            <div class="button-sec">
              <a :href="move" class="btn">{{locale[lang].HOME.INTRO.BUTTON}}</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text">
          <div class="p-1">
            <div class="title-sec">
              <h2>{{locale[lang].HOME.INTRO.TITLE}},</h2>
            </div>
            <div class="text-sec">
              <p>
                {{locale[lang].HOME.INTRO.SUBTITLE}}
              </p>
              <h5 :class="{ 'animated flipInX': change }">{{ word }}</h5>
            </div>
            <div class="button-sec">
              <a :href="move" class="btn">{{locale[lang].HOME.INTRO.BUTTON}}</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="p-1">
            <img
              class="img-fluid"
              src="https://preparationphysiquefootball.com/images/Gif-d-(1)%20(1).gif"
              alt="img"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mobile: Boolean,
    move: String
  },
  data() {
    return {
      words: [],
      word: "",
      change: true,
    };
  },
  created() {
    this.words = this.locale[this.lang].HOME.INTRO.WORDS
    this.word = this.locale[this.lang].HOME.INTRO.INITIAL
  },
  mounted() {
    this.changeWord(1);
  },
  methods: {
    changeWord(i) {
      setTimeout(async () => {
        this.change = false;

        await new Promise((resolve) =>
          setTimeout(() => {
            this.change = true;
            resolve();
          }, 1000)
        );
        this.word = this.words[i];

        if (i < 3) {
          this.changeWord(i + 1);
          this.change = true;
        } else {
          this.changeWord(0);
          this.change = true;
        }
      }, 2000);
    },
  },
};
</script>

<style scoped>
.text {
  padding: 80px 40px 0px;
}

.title-sec {
  padding-right: 0px;
}

.title-sec h2 {
  text-align: left;
  font: normal normal 800 38px/40px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-sec {
  padding: 25px 0px;
}

.text-sec p {
  text-align: left;
  font: normal normal 500 24px/34px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 25px;
}

.text-sec h5 {
  text-align: left;
  font: normal normal 600 32px/38px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 25px;
}

.btn {
  background-image: url("../../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  border-radius: 10px;
  border: 0.4px solid #ffffff !important;
  box-shadow: 0px 3px 30px #bbbbbb29;
  font: normal normal bold 22px/27px Montserrat;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 24px 55px;
  color: #ffffff;
  background-size: 95px 80px;
  height: 80px;
}

.btn:hover {
  background-color: rgba(75, 75, 75, 0.507);
  margin-left: 12px;
}

.img-fluid {
  width: auto;
  object-fit: cover;
}

.button-sec {
  text-align: left;
}

@media (max-width: 1024px) {
  .text {
    padding-top: 0;
  }

  .button-sec {
    text-align: center;
  }

  .title-sec h2 {
    text-align: center;
    font: normal normal 800 25px/25px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }

  .text-sec p {
    text-align: center;
    font: normal normal 500 16px/18px Montserrat;
  }

  .text-sec h5 {
    text-align: center;
    font: normal normal 700 20px/22px Montserrat;
  }

  .btn {
    font: normal normal bold 14px/18px Montserrat;
    padding: 30px 25px;
  }

  .img-fluid {
    height: auto;
  }
}
</style>
