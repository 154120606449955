<template>
  <div class="container-fluid">
    <div class="row mx-auto my-auto" id="carousel">
      <div class="col-12">
        <!--Carousel Wrapper-->
        <div
          id="multi-item-noti"
          class="carousel slide carousel-multi-item"
          data-ride="carousel"
        >
          <!--Indicators-->
          <ol class="carousel-indicators">
            <li
              data-target="#multi-item-noti"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#multi-item-noti" data-slide-to="1"></li>
            <li data-target="#multi-item-noti" data-slide-to="2"></li>
          </ol>
          <!--/.Indicators-->

          <!--Slides-->
          <div class="carousel-inner" role="listbox">
            <!--First slide-->
            <div class="carousel-item active">
              <div class="row">
                <div class="col-3" v-if="!mobile"></div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 cards">
                  <NotiCard :artInfo="infoNoti[0]" />
                </div>
                <div class="col-3" v-if="!mobile"></div>
              </div>
            </div>
            <!--/.First slide-->

            <!--Second slide-->
            <div class="carousel-item">
              <div class="row">
                <div class="col-3" v-if="!mobile"></div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 cards">
                  <NotiCard :artInfo="infoNoti[1]" />
                </div>
                <div class="col-3" v-if="!mobile"></div>
              </div>
            </div>
            <!--/.Second slide-->

            <div class="carousel-item">
              <div class="row">
                <div class="col-3" v-if="!mobile"></div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 cards">
                  <NotiCard :artInfo="infoNoti[2]" />
                </div>
                <div class="col-3" v-if="!mobile"></div>
              </div>
            </div>
            <!-- Third slide -->
          </div>
          <!--/.Slides-->
        </div>
        <!--/.Carousel Wrapper-->
      </div>
    </div>
  </div>
</template>

<script>
import NotiCard from "@/components/NotiCard.vue";
export default {
  components: {
    NotiCard,
  },
  props: {
    infoNoti: Array,
    mobile: Boolean,
  },
};
</script>

<style scoped>
.carousel-indicators li {
  background-color: transparent !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.carousel-indicators .active {
  background-color: #ff0313 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-indicators {
  bottom: -15px;
}

@media (max-width: 1370px) {
  .col-3 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-6 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (max-width: 1024px) {
  .carousel-item .row {
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .cards >>> .card {
    margin-right: 15px;
    margin-left: 15px;
  }
}
</style>
