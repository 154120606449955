export default {
  ["/coach-senior"]                                                 : "/coach-senior",
  ["/coach-jeunes"]                                                 : "/coach-jeunes",
  ["/espace-membres"]                                               : "/espace-membres",
  ["/blog/category"]                                                : "/blog/category",
  ["/guide-800-exercices"]                                          : "/guide-800-exercices",
  ["/300-exercices-app"]                                            : "/300-exercices-app",
  ["/seances-coach-jeunes"]                                         : "/seances-coach-jeunes",
  ["/seances-coach-seniors"]                                        : "/seances-coach-seniors",
  ["/coach-de-senior"]                                              : "/coach-de-senior",
  ["/coach-de-jeunes"]                                              : "/coach-de-jeunes",
  ["/contact"]                                                      : "/contact",
  ["/preparation-physique-coach"]                                   : "/preparation-physique-coach",
  ["/programmes"]                                                   : "/programmes",
  ["/programmes/treve-hivernale"]                                   : "/programmes/treve-hivernale",
  ["/programmes/treve-estivale-coach"]                              : "/programmes/treve-estivale-coach",
  ["/guide/200-exercices-jeux-reduits"]                             : "/guide/200-exercices-jeux-reduits",
  ["/guide/management"]                                             : "/guide/management",
  ["/cycle-entrainement/seniors/100-pour-100-ballon"]               : "/cycle-entrainement/seniors/100-pour-100-ballon",
  ["/cycle-entrainement/seniors/vitesse"]                           : "/cycle-entrainement/seniors/vitesse",
  ["/cycle-entrainement/seniors/vitesse-gardien"]                   : "/cycle-entrainement/seniors/vitesse-gardien",
  ["/cycle-entrainement/seniors/vitesse-gardien-U15-U18"]           : "/cycle-entrainement/seniors/vitesse-gardien-U15-U18",
  ["/cycle-entrainement/seniors/endurance-puissance-futsal"]        : "/cycle-entrainement/seniors/endurance-puissance-futsal",
  ["/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18"]: "/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18",
  ["/cycle-entrainement/seniors/vitesse-futsal"]                    : "/cycle-entrainement/seniors/vitesse-futsal",
  ["/cycle-entrainement/seniors/vitesse-futsal-U15-U18"]            : "/cycle-entrainement/seniors/vitesse-futsal-U15-U18",
  ["/cycle-entrainement/seniors/endurance-puissance"]               : "/cycle-entrainement/seniors/endurance-puissance",
  ["/cycle-entrainement/jeunes/endurance-puissance-U15-U18"]        : "/cycle-entrainement/jeunes/endurance-puissance-U15-U18",
  ["/cycle-entrainement/jeunes/coordination-U8-U12"]                : "/cycle-entrainement/jeunes/coordination-U8-U12",
  ["/cycle-entrainement/jeunes/vitesse-U8-U12"]                     : "/cycle-entrainement/jeunes/vitesse-U8-U12",
  ["/cycle-entrainement/jeunes/vitesse-U12-14"]                     : "/cycle-entrainement/jeunes/vitesse-U12-14",
  ["/cycle-entrainement/jeunes/vitesse-U14-18"]                     : "/cycle-entrainement/jeunes/vitesse-U14-18",
  ["/cycle-entrainement/seniors/periodisation-tactique"]            : "/cycle-entrainement/seniors/periodisation-tactique",
  ["/les-emails"]                                                   : "/les-emails",
  ["/preparation-physique-des-jeunes-footballeurs"]                 : "/preparation-physique-des-jeunes-footballeurs",
  ["/boutique-en-ligne"]                                            : "/boutique-en-ligne",
  ["/bientot-disponible"]                                           : "/bientot-disponible",
  ["/mention-legal"]                                                : "/mention-legal",
  ["/qui-sommes-nous"]                                              : "/qui-sommes-nous",
}