import Vue from 'vue'
import Vuex from 'vuex'
import Parse from "parse";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nav: false,
    footer: false,
    footerbc: '',
    showMenu: false,
    menuOption: '',
    post: false,
    arts: [],
    transition: "next",
  },
  mutations: {
    ADD_POST(state, post) {
      state.arts.push(post);
    },
    SET_TRANSITION(state, transition) {
      state.transition = transition;
    }
  },
  actions: {
    GET_POSTS({ commit }) {
      return new Promise((resolve, reject) => {
        const Blog = Parse.Object.extend("Blog");
        const query = new Parse.Query(Blog);
        query.equalTo('locale', process.env.VUE_APP_LOCALE)
        query.descending("creation_date");
        query.limit(200);
        query.find()
          .then(results => {
            for (const e of results) {
              const { objectId, title, expertise, image_url, slug, content, year, description } = e.toJSON();
      
              commit("ADD_POST", {
                objectId,
                title,
                slug,
                content,
                description,
                category: expertise.charAt(0).toUpperCase() + expertise.slice(1),
                picture : image_url != "" ? image_url : null,
                year,
              });
            }
            resolve();
          })
          .catch(error => reject(error));

      });
    },
  },
  modules: {}
})