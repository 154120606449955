<template>
  <div class="opinion-card">
    <div class="post-info">
      <div class="left-img">
        <a href="#"><img src="@/assets/Images/Logos/logo.png" class="img-cm" alt="Profile Image" /></a>
        <p v-if="mobile">
          <span class="quote">"</span>{{ cardInfo.text }}<span class="quote">"</span>
        </p>
      </div>
    </div>
    <div class="body">
      <p v-if="!mobile">
        <span class="quote">"</span>{{ cardInfo.text }}<span class="quote">"</span>
      </p>
      <h3>{{ cardInfo.person }}</h3>
      <h5>{{ locale[lang].OPINIONS.POSITION }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardInfo: Object,
    mobile: Boolean
  },
};
</script>

<style scoped>
.opinion-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 30px;
  opacity: 1;
  padding: 15px 20px;
}

.left-img {
  text-align: left;
}

.body p {
  text-align: left;
  font: italic normal 500 16px/26px Montserrat;
  letter-spacing: 0px;
  color: #444444;
}

.body h3 {
  text-align: left;
  font: normal normal bold 22px/22px Montserrat;
  letter-spacing: 0px;
  color: #131313f2;
  opacity: 1;
}

.body h5 {
  text-align: left;
  font: normal normal medium 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #bbbbbb;
  opacity: 1;
}

.quote {
  color: #ff0000f2;
}

.img-cm {
  width: 60px;
  margin-bottom: 10px;
}
</style>
