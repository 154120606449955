<template>
    <div class="container-fluid">
        <div class="rotate"></div>
        <img class="img-fluid" @mouseover="hover($event)" @mouseleave="noHover" :src="require('@/assets/Images/Logos/'+info.img)" alt="logo">
        <div class="text" v-html="info.text"></div>
    </div>
</template>

<script>
export default {
    props: {
        info: Object
    },
    methods: {
        hover(e) {     
            var v = window.$(e.target).closest('.container-fluid')
            v.find('.rotate').css('transform', 'matrix(-0.71, -0.71, 0.71, -0.71, 0, 0)') 
        },
        noHover() {
            window.$('.container-fluid > .rotate').css('transform', 'matrix(0, -1, 1, 0, 0, 0)') 
        }
    }
}
</script>

<style scoped>
.rotate {
  height: 100px;
  width: 100px;
  background: #232323 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  transform: matrix(0, -1, 1, 0, 0, 0);
  transition: transform .2s linear;
  margin-left: 60px;
}

.rotate:hover {
    transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
}

.img-fluid {
    position: absolute;
}

.text {
    margin-top: 30px;
}

.text >>> h3 {

    text-align: center;
    font: normal normal 600 29px/35px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.text >>> p {
    text-align: center;
    font: normal normal 500 13px/20px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 0px 15px;
}

.container-fluid {
    padding: 0px 85px;
}

</style>