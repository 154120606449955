<template>
  <div class="container-fluid">
    <div class="row">
      <template>
        <div class="col-12">
          <div class="title-sec" v-if="titleTop">
            <h2 data-aos="fade-right">
              {{ appSec.title }}
            </h2>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="first">
          <div class="p-1">
            <img
              class="img-fluid"
              :src="require('@/assets/Images/' + appSec.img)"
              alt="img"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="second">
          <div class="p-1" id="content">
            <div class="title-sec" v-if="!titleTop">
              <h2 data-aos="fade-right">
                {{ appSec.title }}
              </h2>
            </div>
            <div class="row button-sec">
              <button @click="$router.push( { name: 'ExercicesApp' } )" type="button" class="btn">
                {{ appSec.button }}
              </button>
            </div>
            <div class="row links">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <a href="javascript:;" @click="goTo">
                  <img
                    class="img-fluid"
                    src="@/assets/Images/Home/App store.png"
                    alt="app-store"
                  />
                </a>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <a href="https://play.google.com/store/apps/details?id=com.adeveloper.soccer10" target="_blank">
                  <img
                    class="img-fluid"
                    src="@/assets/Images/Home/google play.png"
                    alt="google-play"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appSec: Object,
    page: String,
    titleTop: Boolean,
  },
  methods: {
    goTo() {
      alert(this.locale[this.lang].APPSEC_MSG)
    },
  },
};
</script>

<style scoped>
.title-sec {
  margin-top: 30px;
  margin-bottom: 40px;
}

.title-sec h2 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #0f0f0f;
  opacity: 1;
}

.links {
  margin-top: 150px;
}

.button-sec button {
  font: normal normal bold 18px/34px Montserrat;
  background-image: url("../../assets/Images/Background/ball.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 95px 80px;
  /* border: 0.4px solid #0f1010 !important; */
  box-shadow: 0px 3px 30px #00000029;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #0f1010;
  display: block;
  border-radius: 15px;
  padding: 20px 91px;
  height: 80px;
}

.button-sec button:hover {
  background-color: rgba(209, 209, 209, 0.507);
  margin-left: 12px;
}

#content {
  position: relative;
}

.button-sec::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 1%;
  bottom: 28%;
  opacity: 1;
}

a > .img-fluid {
  height: 80px;
  margin-bottom: 20px;
}

@media (max-width: 825px) {
  .links {
    justify-content: center;
    padding-left: 0 !important;
  }
}
</style>
