<template>
    <div>
        <!-- ** START: CARD_GROUP_COACH ** -->
      <div class="b-card-group">
        <div class=" row ">

          <!-- ** START: CARD__COACH ** -->
          <div class=" col-md-6 px-md-1" @click="toCoachJeunes">
            <div class="b-card-group__item" :style="{'height': height+'px '}">
              <p class="b-card-group__item__title left-top pl-3" :class="{ es: lang == 'es' }" v-if="!programmes" v-html="locale[lang].MENU.PAGE_1[2]">
              </p>
              <p class="b-card-group__item__title left-top pl-3" :class="{ es: lang == 'es' }" v-else v-html="locale[lang].MENU.PROGRAMMES[0]">
              </p>
              <svg :style="{'height': height+'px '}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="454"
                height="455" viewBox="0 0 454 455">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectángulo_441" data-name="Rectángulo 441" width="454" height="455" rx="15"
                      transform="translate(0 63.938)" fill="#eaf8fb" stroke="#fff" stroke-width="1" />
                  </clipPath>
                </defs>
                <g id="Enmascarar_grupo_72" data-name="Enmascarar grupo 72" transform="translate(0 -63.938)"
                  clip-path="url(#clip-path)">
                  <path id="Sustracción_1" data-name="Sustracción 1"
                    d="M326.421,321.565,38.4,333.12s-64.737-96.428-26.1-214.168C36.273,44.131,108.381,9.729,183.662,1.247c51.955-5.3,94.367,6.362,142.759,33.93Z"
                    transform="translate(127.579 198.446)" fill="#a8e6f3" />
                  <path id="Trazado_1498" data-name="Trazado 1498"
                    d="M1347.906,449.969,1746.9,681.834,1394.161,385.743a115.936,115.936,0,0,0-28.844,25.458C1352.254,427.529,1347.906,449.969,1347.906,449.969Z"
                    transform="translate(-1292.897 -164.243)" fill="#84dbef" />
                  <path id="Trazado_1497" data-name="Trazado 1497"
                    d="M1537.016,305.268l227.062,484.524L1447.4,354.813s10.333-15.993,42.534-34.191S1537.016,305.268,1537.016,305.268Z"
                    transform="translate(-1310.078 -271.677)" fill="#60d2e9" />
                  <g id="Grupo_607" data-name="Grupo 607" transform="translate(137.324 123.776)">
                    <path id="Trazado_1190" data-name="Trazado 1190"
                      d="M-262.241,996.587l-15.148,5.761a23.43,23.43,0,0,0-8.38,5.47l-5.835,5.928a7.391,7.391,0,0,0,.2,10.56l6.612,6.238a7.393,7.393,0,0,0,9.555.5l8.491-6.475s17.217-9.056,13.885-18.981Z"
                      transform="translate(293.726 -747.2)" fill="#ffb29a" />
                    <path id="Trazado_1191" data-name="Trazado 1191"
                      d="M-159.592,1172.834c-.569-76.053,15.139-149.093,15.139-149.093l-58.85-1.016s2.981,110.195,9.121,155.853c8.256,61.393,7.517,180.793,7.517,180.793h17.435S-149.089,1243.007-159.592,1172.834Z"
                      transform="translate(315.895 -740.792)" fill="#ff8b7b" />
                    <path id="Trazado_1192" data-name="Trazado 1192"
                      d="M-184.179,1291.619l18.751.259a1.164,1.164,0,0,1,1.167,1.053l1.437,13.488a2.463,2.463,0,0,1-2.482,2.654c-5.936-.194-16.754-.679-24.229-.8-8.741-.137-7.891.221-18.141.061-6.2-.1-7.2-6.4-4.537-6.928,12.109-2.4,13.33-2.528,24.261-8.671A7.587,7.587,0,0,1-184.179,1291.619Z"
                      transform="translate(313.372 -674.867)" fill="#263238" />
                    <path id="Trazado_1193" data-name="Trazado 1193"
                      d="M-168.579,1074.8c-4.367,5.5-4.5,19.783,2.951,35.089.491-6.858,1.075-13.546,1.7-19.99Z"
                      transform="translate(323.668 -728.024)" opacity="0.2" />
                    <path id="Trazado_1194" data-name="Trazado 1194"
                      d="M-110.012,1170.842c-10.484-67.973-4.185-131.718-14.247-146.9l-58.477-.862s29.5,107.467,40.881,152.745c11.832,47.078,40.876,183.286,40.876,183.286l15.918.346S-87.06,1224.079-110.012,1170.842Z"
                      transform="translate(320.938 -740.704)" fill="#ff8b7b" />
                    <path id="Trazado_1195" data-name="Trazado 1195"
                      d="M-117.31,1290.144l21.126.441a1.371,1.371,0,0,1,1.357,1.228l1.764,15.63a2.807,2.807,0,0,1-2.848,3.056c-6.863-.27-11.091-.859-19.732-1.052-10.1-.227-5.44.221-17.289-.045-7.166-.161-8.817-6.666-5.8-7.492,9.534-2.617,12.724-6.393,17.075-10.5A6.09,6.09,0,0,1-117.31,1290.144Z"
                      transform="translate(331.374 -675.23)" fill="#263238" />
                    <path id="Trazado_1196" data-name="Trazado 1196"
                      d="M-170.391,1100.662c-6.782-22.889-14.545-78.7-14.545-78.7s47.965-1.07,67.015,0c19.025,30.586,7.3,56.616,7.3,56.616,3.286,14.3,7.928,95.359,7.928,95.359s24.076,107.4,18.776,181.9l-26.7-1.271s-32.64-152.6-39.422-169.979S-166.955,1118.482-170.391,1100.662Z"
                      transform="translate(320.398 -741.026)" fill="#263238" />
                    <path id="Trazado_1197" data-name="Trazado 1197"
                      d="M-202.923,1098.755c0-19.311-1.936-76.828-1.936-76.828s42.392.366,61.4,1.436c17.912,30.586-1.9,77.311-1.9,77.311l-7.437,74.553s3.815,55.317,2.544,88.379-12.081,93.468-12.081,93.468l-34.9-.56s6.286-116.432-1.345-167.935C-198.571,1188.579-202.188,1120.839-202.923,1098.755Z"
                      transform="translate(315.514 -740.987)" fill="#263238" />
                    <path id="Trazado_1198" data-name="Trazado 1198"
                      d="M-139.723,1024.021s5.087,29.566,6.358,42.6,3.073,67.4,3.709,103c0,0,20.029,80.752,25.433,116.676s7.313,62.947,7.63,68.034"
                      transform="translate(331.483 -740.474)" fill="none" stroke="#fff" stroke-miterlimit="10"
                      stroke-width="1" />
                    <path id="Trazado_1199" data-name="Trazado 1199"
                      d="M-145,1024.362s6.358,30.625,7.63,43.661,2.013,65.914,2.648,101.52c0,0,20.029,80.751,25.434,116.676s7.312,62.947,7.63,68.033"
                      transform="translate(330.189 -740.39)" fill="none" stroke="#fff" stroke-miterlimit="10"
                      stroke-width="1" />
                    <path id="Trazado_1200" data-name="Trazado 1200"
                      d="M-204.522,1017.5l88.351,1.824a.9.9,0,0,1,.878.9v5.23a.9.9,0,0,1-.9.9l-87.941-.511a.9.9,0,0,1-.891-.843l-.41-6.542A.9.9,0,0,1-204.522,1017.5Z"
                      transform="translate(315.371 -742.072)" fill="#263238" />
                    <path id="Trazado_1201" data-name="Trazado 1201"
                      d="M-175.6,823.464c-.817-7.024-1.772-20.7,4.09-23.52,15.818-2.1,32.809,13.951,34.855,17.9s1.181,21.449-6.18,24.72S-175.6,823.464-175.6,823.464Z"
                      transform="translate(324.98 -785.903)" fill="#263238" />
                    <path id="Trazado_1202" data-name="Trazado 1202"
                      d="M-257.889,1046.275c.761-.58.544-.953,1.382-1.457.186-.111,1.606-.228,1.767-.356,11.589-9.163,20.564-18.193,32.027-28.183,6.139-5.418,11.856-12.611,17.873-18.518l4.5-4.546,4.488-4.83c1.505-1.724,3-3.509,4.514-5.347l2.366-3.329,2.068-3.269a154.362,154.362,0,0,0,11.563-25.063,244.233,244.233,0,0,0,7.574-24.525c2.071-8.166,3.76-16.339,5.162-24.532a71.1,71.1,0,0,0,1.339-12.388,44.429,44.429,0,0,0-1.62-12.885A19.789,19.789,0,0,0-171.93,865.5a19.745,19.745,0,0,0-26.99,7.159l-.572.984c-4.051,6.974-5.409,14.705-6.246,22.275-.945,7.608-1.952,15.186-3.026,22.682S-211.01,933.5-212.4,940.65a117.19,117.19,0,0,1-5.378,19.689l-.829,1.973-.823,1.742-2.524,4.347-2.975,4.544-3.25,4.593c-4.476,6.14-9.31,12.27-14.263,18.349-9.894,12.175-18.8,20.974-29.255,32.818l.106.9-1.642,1.483a69.777,69.777,0,0,0,6.862,7.975A74.186,74.186,0,0,0-257.889,1046.275Z"
                      transform="translate(298.75 -779.994)" fill="#407bff" />
                    <path id="Trazado_1203" data-name="Trazado 1203"
                      d="M-87.869,863.351l-58.012-9.954-49.319,8.084a22.244,22.244,0,0,0-18.6,20.351c-3.851,54.1,11.441,100.417,6.353,187.971a3.532,3.532,0,0,0,3.42,3.752c6.485.173,20.9.742,40.228,2.472,7.964.714,21.555-.019,29.792.351,15.055.675,26.186,1.538,31.063,1.951a2.4,2.4,0,0,0,2.612-2.462c-1.436-47.387,25.865-131.029,30.88-188.533A22.313,22.313,0,0,0-87.869,863.351Z"
                      transform="translate(313.168 -782.305)" fill="#407bff" />
                    <path id="Trazado_1204" data-name="Trazado 1204"
                      d="M-141.525,824.582c-.7,9.634-1.048,22.269,1.6,33.787-1.58,6.422-13.028,13.217-26.339,16.382-10.425-5.212-9.07-13.114-5.831-17.116,11.067-1.8,8.465-12.583,6.832-20.347Z"
                      transform="translate(325.124 -783.887)" fill="#ffb29a" />
                    <path id="Trazado_1205" data-name="Trazado 1205"
                      d="M-172.264,913.616a.991.991,0,0,1-.209-.023.98.98,0,0,1-.642-.468c-21.108-36.425-5.507-56.192-4.833-57.016a.99.99,0,0,1,1.387-.137.988.988,0,0,1,.137,1.387c-.633.772-15.156,19.189,4.474,53.841,30.273-21.661,45.088-45.547,46.3-53.614a.992.992,0,0,1,1.121-.827.985.985,0,0,1,.827,1.12c-1.265,8.432-16.639,33.388-47.99,55.557A.975.975,0,0,1-172.264,913.616Z"
                      transform="translate(320.596 -781.728)" fill="#263238" />
                    <g id="Grupo_185" data-name="Grupo 185" transform="translate(141.533 125.688)">
                      <path id="Rectángulo_339" data-name="Rectángulo 339"
                        d="M2.026,0h8.039a0,0,0,0,1,0,0V19.8a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2.026A2.026,2.026,0,0,1,2.026,0Z"
                        transform="translate(0 0)" fill="#263238" />
                      <path id="Trazado_1206" data-name="Trazado 1206"
                        d="M-169.867,907.145h0a4.95,4.95,0,0,1-4.951-4.951v-4.951h4.951a4.951,4.951,0,0,1,4.951,4.951h0A4.95,4.95,0,0,1-169.867,907.145Z"
                        transform="translate(181.346 -897.243)" fill="#263238" />
                    </g>
                    <path id="Trazado_1207" data-name="Trazado 1207"
                      d="M-164.86,838.992l-1.014.539a49.987,49.987,0,0,1,1.439,9.6c4.892.048,9.963-1.358,12.63-4.434,2.225-2.569,3.992-5.333,4.449-6.969Z"
                      transform="translate(325.82 -782.819)" opacity="0.2" />
                    <path id="Trazado_1208" data-name="Trazado 1208"
                      d="M-104.977,877.375c-4.385,1.89-11.869,13.942-11.159,26.757.788,14.73,5.592,27.177,12.63,36.472,3.519-15.833,6.775-31.956,8.8-48.158Z"
                      transform="translate(337.254 -776.426)" opacity="0.2" />
                    <path id="Trazado_1209" data-name="Trazado 1209"
                      d="M-138.426,818.956c-2.065,12.852-2.62,20.484-9.808,26.424-10.813,8.934-26.207,1.3-28.027-11.949-1.638-11.922,2.014-30.936,15.188-34.727A17.939,17.939,0,0,1-138.426,818.956Z"
                      transform="translate(324.947 -786.048)" fill="#ffb29a" />
                    <path id="Trazado_1210" data-name="Trazado 1210"
                      d="M-139.789,830.021c-5.329-4.431-9.818-14.328-6.07-22.371-9.747,1.978-25.973,2.966-30.656-6.257-3.254-6.408-1.234-13.406,6.179-14.451,3.861,6.135,11.993,4.7,18.878,3.612s24.675-3.617,22.948,9.778C-120.238,801.257-115.92,819.66-139.789,830.021Z"
                      transform="translate(324.834 -786.942)" fill="#263238" />
                    <path id="Trazado_1211" data-name="Trazado 1211"
                      d="M-135.8,830.378a12.252,12.252,0,0,1-7.869,5.186c-4.267.778-6.042-3.138-4.555-6.962,1.338-3.44,5.172-7.993,9.311-7.039C-134.84,822.5-133.6,827.039-135.8,830.378Z"
                      transform="translate(327.21 -784.141)" fill="#ffb29a" />
                    <g id="Grupo_187" data-name="Grupo 187" transform="translate(165.718 190.516)">
                      <path id="Rectángulo_340" data-name="Rectángulo 340"
                        d="M1.036,0H69.3A1.036,1.036,0,0,1,70.34,1.036V89.79A1.036,1.036,0,0,1,69.3,90.827H1.037A1.037,1.037,0,0,1,0,89.79V1.036A1.036,1.036,0,0,1,1.036,0Z"
                        transform="translate(0 60.011) rotate(-56.652)" fill="#407bff" />
                      <g id="Grupo_186" data-name="Grupo 186" transform="translate(0.824 0)">
                        <path id="Rectángulo_341" data-name="Rectángulo 341"
                          d="M1.036,0H69.3A1.036,1.036,0,0,1,70.34,1.036V89.79A1.036,1.036,0,0,1,69.3,90.827H1.037A1.037,1.037,0,0,1,0,89.79V1.036A1.036,1.036,0,0,1,1.036,0Z"
                          transform="translate(0 58.758) rotate(-56.652)" fill="#407bff" />
                        <path id="Rectángulo_342" data-name="Rectángulo 342"
                          d="M1.036,0H69.3A1.036,1.036,0,0,1,70.34,1.036V89.79A1.036,1.036,0,0,1,69.3,90.827H1.037A1.037,1.037,0,0,1,0,89.79V1.036A1.036,1.036,0,0,1,1.036,0Z"
                          transform="translate(0 58.758) rotate(-56.652)" fill="#fff" opacity="0.7" />
                      </g>
                      <path id="Trazado_1212" data-name="Trazado 1212"
                        d="M-52.182,998.555-86.5,1052.087l-69.386-45.661,34.952-53.113Z"
                        transform="translate(161.803 -948.325)" opacity="0.2" />
                      <path id="Trazado_1213" data-name="Trazado 1213"
                        d="M-51.792,996.923l-35.37,53.748a740.825,740.825,0,0,1-68.893-45.337l35.369-53.748A741.011,741.011,0,0,0-51.792,996.923Z"
                        transform="translate(161.761 -948.748)" fill="#fff" />
                      <path id="Trazado_1214" data-name="Trazado 1214"
                        d="M-143.619,981.048l-.654,1.015a2.255,2.255,0,0,1-3.272.6l-1.779-1.316a.259.259,0,0,1-.062-.351l1.848-2.808a.26.26,0,0,1,.371-.066l3.485,2.58A.258.258,0,0,1-143.619,981.048Z"
                        transform="translate(163.386 -942.255)" opacity="0.4" />
                      <path id="Trazado_1215" data-name="Trazado 1215"
                        d="M-140.147,964.157l-11.128,16.909a2.1,2.1,0,0,0,.743,3.023l3.948,2.205a2.505,2.505,0,0,0,3.255-.792l11.128-16.91a2.1,2.1,0,0,0-.745-3.023l-3.948-2.205A2.5,2.5,0,0,0-140.147,964.157Z"
                        transform="translate(162.845 -945.934)" fill="#263238" />
                    </g>
                    <path id="Trazado_1216" data-name="Trazado 1216"
                      d="M-103.024,997.378l-13.9,8.332a23.45,23.45,0,0,0-7.288,6.858l-4.7,6.861a7.39,7.39,0,0,0,2.048,10.361l7.6,4.981a7.393,7.393,0,0,0,9.494-1.185l7.222-7.866s15.359-11.939,10.335-21.124Z"
                      transform="translate(333.815 -747.006)" fill="#ffb29a" />
                    <path id="Trazado_1217" data-name="Trazado 1217"
                      d="M-97.381,866.949a40.9,40.9,0,0,1,9.725,8.065,65.285,65.285,0,0,1,7.124,9.735c4.322,6.794,8.407,13.771,12.155,21.052A205.85,205.85,0,0,1-58.22,928.65c2.924,8.086,5.636,16.4,7.038,26.317.182,1.22.329,2.488.416,3.83l.147,1.968c.041.684.034,1.561.04,2.33a35.035,35.035,0,0,1-.238,4.512c-.111,1.485-.369,2.752-.593,4.083A75.962,75.962,0,0,1-55.5,985.495a158,158,0,0,1-11.388,22.6,319.428,319.428,0,0,1-27.974,38.808,9.136,9.136,0,0,1-12.865,1.177,9.138,9.138,0,0,1-2.17-11.407l.12-.217c3.5-6.395,6.921-13.239,10.092-19.94,3.206-6.725,6.151-13.524,8.749-20.29a128.983,128.983,0,0,0,6.127-19.848,41.3,41.3,0,0,0,.981-8.577,10.616,10.616,0,0,0-.116-1.64,4.49,4.49,0,0,0-.253-1.231l-.391-1.412c-.133-.563-.324-1.181-.549-1.832a91.851,91.851,0,0,0-8-17.014c-3.459-5.9-7.279-11.85-11.27-17.8s-8.121-11.908-12.323-17.847a72.586,72.586,0,0,1-5.932-9.181,35.615,35.615,0,0,1-3.853-10.478l-.36-2.325a19.766,19.766,0,0,1,16.51-22.557A19.727,19.727,0,0,1-97.381,866.949Z"
                      transform="translate(334.576 -779.643)" fill="#407bff" />
                  </g>
                </g>
              </svg>
            </div>

          </div>
          <!-- ** END: CARD__COACH ** -->

          <!-- ** START: CARD__COACH ** -->
          <div class=" col-md-6  mt-2 mt-md-0 px-md-1" @click="toCoachSenior">
            <div class=" b-card-group__item " :style="{'height': height+'px '}">
              <p class="b-card-group__item__title left-top pl-3" :class="{ es: lang == 'es' }" v-if="!programmes" v-html="locale[lang].MENU.PAGE_1[3]">
              </p>
              <p class="b-card-group__item__title left-top pl-3 last" :class="{ es: lang == 'es' }" v-else v-html="locale[lang].MENU.PROGRAMMES[1]">
              </p>
              <svg :style="{'height': height+'px '}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="439"
                height="402" viewBox="0 0 439 402">
                <defs>
                  <clipPath id="a">
                    <path d="M15,0H439a0,0,0,0,1,0,0V387a15,15,0,0,1-15,15H15A15,15,0,0,1,0,387V15A15,15,0,0,1,15,0Z"
                      transform="translate(0 -0.062)" fill="none" stroke="#fff" stroke-width="1" />
                  </clipPath>
                </defs>
                <g transform="translate(0 0.062)" clip-path="url(#a)">
                  <path
                    d="M4273.2,298.065V701.078H3932.745s-14.093-47.8,17.322-92.878c30.049-51.9,68.293-57.367,98.342-98.343,27.492-32.543,30.049-112,91.514-165.27S4273.2,298.065,4273.2,298.065Z"
                    transform="translate(-3834.202 -298.104)" fill="#ff525d" />
                  <g transform="translate(46.8 28.122)">
                    <ellipse cx="28.187" cy="89.109" rx="28.187" ry="89.109"
                      transform="matrix(0.043, -0.999, 0.999, 0.043, 184.846, 575.012)" fill="#e5e6e8" />
                    <path
                      d="M783.641,691.024s.895,24.682-9.917,25.055c-10.738.373-8.2-26.919-11.111-31.244C759.63,680.435,783.641,691.024,783.641,691.024Z"
                      transform="translate(-437.273 -186.258)" fill="#939297" />
                    <path d="M645.141,682.5s3.132,21.923-10.216,23.489c0,0-7.457,2.088-8.724-22.594Z"
                      transform="translate(-402.647 -185.937)" fill="#939297" />
                    <path
                      d="M739.334,355.8s6.86,11.036,6.636,35.793-10.216,65.173-6.636,82.324c3.579,17.225,15.809,34.152,16.107,53.689.224,19.537-10.141,71.138-10.141,71.138H720.542s-13.05-40.938-16.927-69.05c0,0-1.79-11.707-11.484-28.634-9.619-16.927-26.1-68.3-26.1-68.3s-6.487-2.088-6.786,10.141c-.224,12.229-10.44,97.461-19.313,146.453,0,0-16.181,3.132-24.533-.224,0,0-.224-8.575-3.654-28.411-3.355-19.835,2.834-40.64-3.132-47.2s-17.225-96.939-4.176-156.37C617.563,297.711,709.953,285.556,739.334,355.8Z"
                      transform="translate(-395.576 -90.623)" fill="#231f20" />
                    <path
                      d="M760.633,722.219s4.922.671,9.918-4.7c5.071-5.369,7.83,32.288,7.83,32.288s-13.348,15.958-38.552,23.116-26.621-5.593-25.875-7.457c.671-1.939,2.61-14.392,16.48-25.2,13.87-10.738,22.371-21.923,24.011-22.445S755.189,722.219,760.633,722.219Z"
                      transform="translate(-424.929 -194.71)" fill="#3f3f41" />
                    <path
                      d="M615.934,705.144s-12.6,14.243-18.195,18.046-25.875,20.879-21.177,33.854,35.271,0,42.728-3.654,20.879-15.809,20.879-15.809-5.444-33.705-7.531-33.183-1.939,4.7-9.619,5.369C615.262,710.513,619.364,705.144,615.934,705.144Z"
                      transform="translate(-389.845 -191.504)" fill="#3f3f41" />
                    <g transform="translate(235.675 331.51)">
                      <path
                        d="M670.079,470.137a1.1,1.1,0,0,0,.3-2.162c-9.694-3.132-26.323-6.786-26.546-6.786a1.112,1.112,0,0,0-.522,2.163c.149,0,16.7,3.654,26.323,6.711A2.642,2.642,0,0,1,670.079,470.137Z"
                        transform="translate(-642.456 -461.156)" fill="#939297" />
                    </g>
                    <g transform="translate(255.245 291.425)">
                      <path
                        d="M684.434,460.866a1,1,0,0,0,.969-.6,1.077,1.077,0,0,0-.522-1.491c-3.132-1.566-5.816-7.606-7.755-12.08-.447-1.044-.895-2.013-1.268-2.834-1.641-3.43-4.1-26.7-4.922-35.42a1.121,1.121,0,0,0-2.237.149c.3,3.206,3.057,31.766,5.145,36.166.373.82.82,1.715,1.268,2.759,2.088,4.772,4.921,11.26,8.874,13.2C684.061,460.866,684.285,460.866,684.434,460.866Z"
                        transform="translate(-668.7 -407.4)" fill="#939297" />
                    </g>
                    <g transform="translate(267.772 304.027)">
                      <path
                        d="M687.439,460.764h.3a1.112,1.112,0,0,0,.82-1.342,38.291,38.291,0,0,1-.82-8.053c0-4.772.895-25.652.895-25.875a1.121,1.121,0,1,0-2.237-.149c0,.895-.895,21.1-.895,25.95a40.728,40.728,0,0,0,.895,8.575A1.175,1.175,0,0,0,687.439,460.764Z"
                        transform="translate(-685.5 -424.3)" fill="#939297" />
                    </g>
                    <path
                      d="M674.949,99.975s22.743,1.641,42.952,6.264,35.42,5.742,37.881,21.327-4.549,56.97-11.111,67.41c-6.637,10.44-19.984,42.579-20.656,53.167s.671,30.051,10.962,54.733c0,0-48.1,13.572-80.758,11.111s-54.062-9.694-54.062-9.694,4.847-28.634,7.681-37.732,1.939-23.489-2.759-37.732-17-55.4-19.313-62.414-13.572-52.422,9.4-56.821C617.978,105.344,665.926,99.155,674.949,99.975Z"
                      transform="translate(-391.144 -37.774)" fill="#3f3f41" />
                    <g transform="translate(209.134 71.843)">
                      <g transform="translate(53.385)">
                        <path
                          d="M684.827,302.312h0a1.512,1.512,0,0,0,1.491-1.566c0-.224-.895-24.086-.671-34.525.075-2.983-.522-7.681-1.268-13.646-1.939-15.659-5.071-41.833-.969-70.691,5.667-39.3,12.751-66.813,12.826-67.112a1.465,1.465,0,0,0-2.834-.746,679.671,679.671,0,0,0-12.9,67.41c-4.176,29.231-.969,55.7.895,71.511a104.824,104.824,0,0,1,1.193,13.2c-.149,10.514.671,34.451.671,34.674A1.558,1.558,0,0,0,684.827,302.312Z"
                          transform="translate(-678.455 -112.93)" fill="#b7b8ba" />
                      </g>
                      <g transform="translate(0 176.946)">
                        <path
                          d="M664.383,362.51a294.073,294.073,0,0,0,72.108-9.4,1.465,1.465,0,0,0-.746-2.834c-40.64,10.365-73,10.216-92.913,8.352C621.208,356.544,609.053,352,608.9,352a1.51,1.51,0,1,0-1.044,2.834c.522.224,12.6,4.7,34.525,6.86C648.574,362.062,655.957,362.51,664.383,362.51Z"
                          transform="translate(-606.863 -350.223)" fill="#b7b8ba" />
                      </g>
                    </g>
                    <g transform="translate(262.562 61.37)">
                      <path
                        d="M692.6,132.676c7.084,0,16.107-11.334,19.313-15.436l.224-.3c2.983-3.728,11.707-14.914,11.782-14.988a.746.746,0,1,0-1.193-.895c-.075.075-8.8,11.26-11.782,14.988l-.224.3c-9.1,11.484-15.809,16.405-19.984,14.466-.746-.969-5.443-7.457-7.308-14.243q-.783-2.908-1.566-5.145c-1.417-4.4-2.312-7.308-1.566-11.633a.76.76,0,1,0-1.491-.3c-.82,4.7.224,7.9,1.641,12.378.447,1.491.969,3.132,1.491,5.071,2.088,7.681,7.457,14.615,7.68,14.914l.075.149.149.075A7.672,7.672,0,0,0,692.6,132.676Z"
                        transform="translate(-678.512 -98.885)" fill="#efe266" />
                    </g>
                    <g transform="translate(266.132 97.323)">
                      <path
                        d="M684.195,162.088l6.86.447a1.045,1.045,0,0,0,1.044-.895l.895-13.05a1.046,1.046,0,0,0-.895-1.044l-6.86-.447a1.045,1.045,0,0,0-1.044.895l-.895,13.05A.948.948,0,0,0,684.195,162.088Z"
                        transform="translate(-683.3 -147.1)" fill="#fc0c10" />
                      <rect width="7.084" height="2.983" transform="matrix(-0.998, -0.066, 0.066, -0.998, 7.95, 13.951)"
                        fill="#9e0b0f" />
                    </g>
                    <g transform="translate(270.701 92.536)">
                      <path
                        d="M690.153,147.257h.074a.874.874,0,0,0,.671-.82,3.718,3.718,0,0,1,2.386-4.325.733.733,0,0,0-.373-1.417,5.2,5.2,0,0,0-3.43,5.891C689.407,147.033,689.78,147.257,690.153,147.257Z"
                        transform="translate(-689.428 -140.681)" fill="#efe266" />
                    </g>
                    <path
                      d="M542.65,121.3A14.362,14.362,0,0,0,525.5,109.443a82.663,82.663,0,0,0-19.239,6.711c-15.361,7.755-54.883,12.751-67.559,8.575s-14.392-5.22-28.858-6.786c-14.392-1.566-43.1.149-50.408-4.847-7.308-5.071-8.575-7.233-16.927-10.514S327.3,96.17,326.476,97.139c-.746.895,4.4,5.444,15.361,9.023,0,0-.224,3.654-1.566,4.922-1.268,1.268-1.566,3.281.895,5.369s5.742,5.369,9.545,5.444c3.8.149,8.5-1.417,9.619-.373a123.367,123.367,0,0,0,14.988,11.484c7.531,4.847,58.014,35.2,65.024,35.942s19.164-2.088,22.669-1.79c3.5.224,9.768-6.637,26.472-7.159s29.827.746,33.183,11.782c0,0,19.239-.671,20.656-29.082A101.9,101.9,0,0,0,542.65,121.3Z"
                      transform="translate(-326.405 -37.026)" fill="#3f3f41" />
                    <path
                      d="M342.629,102.682c8.352,3.281,9.619,5.444,16.927,10.514,2.088,1.417,5.816,2.312,10.514,2.908a15.31,15.31,0,0,1,.895,7.755,7.562,7.562,0,0,1-2.61,4.176c-4.1-3.132-7.009-5.742-7.83-6.413-1.193-1.044-5.891.522-9.619.373s-7.009-3.356-9.545-5.444c-2.461-2.088-2.237-4.027-.895-5.369s1.566-4.922,1.566-4.922c-10.962-3.5-16.181-8.053-15.361-9.023C327.417,96.27,334.277,99.4,342.629,102.682Z"
                      transform="translate(-326.452 -37.051)" fill="#efb097" />
                    <g transform="translate(191.311 69.577)">
                      <path
                        d="M585.973,173.579h.149a1.4,1.4,0,0,0,1.268-1.641c-5.816-47.276,7.83-59.282,7.979-59.431a1.48,1.48,0,1,0-1.939-2.237c-.6.522-14.988,13.2-9.023,62.041A1.66,1.66,0,0,0,585.973,173.579Z"
                        transform="translate(-582.962 -109.891)" fill="#b7b8ba" />
                    </g>
                    <path
                      d="M782.355,136.949s7.606-15.958,18.418-13.348,24.831,36.762,26.4,40.565S839.922,194.74,845.44,201.3s5.742,18.269,8.277,24.459c2.461,6.189-8.053,38.1-40.938,38.552,0,0,3.057,17.225-9.023,20.059S792.5,279,797.269,269.607a3.732,3.732,0,0,0-4.25.075c-2.162,1.566-2.237-3.057-.149-4.176s6.115.075,8.948-2.535,20.134-32.437,18.568-38.328c0,0-18.642-23.489-22.669-31.319C797.716,193.4,763.414,181.691,782.355,136.949Z"
                      transform="translate(-440.909 -43.728)" fill="#3f3f41" />
                    <path
                      d="M802.281,315.225a3.732,3.732,0,0,0-4.25.075c-2.163,1.566-2.237-3.057-.149-4.176s6.115.075,8.948-2.535a38.434,38.434,0,0,0,4.027-5.742c4.25-.522,10.813-.075,13.049,6.637a38.975,38.975,0,0,1-6.04.447s3.057,17.225-9.023,20.059C796.688,332.9,797.509,324.621,802.281,315.225Z"
                      transform="translate(-445.921 -89.346)" fill="#efb097" />
                    <g transform="translate(333.561 77.06)">
                      <path
                        d="M796.226,195.072a1.529,1.529,0,0,0,1.417-.969,1.44,1.44,0,0,0-.895-1.864h0a32.971,32.971,0,0,1-17.076-16.554c-4.7-10.589-3.8-23.936,2.61-39.67.074-.075,7.233-15.287,16.7-12.9a1.437,1.437,0,0,0,1.79-1.044,1.534,1.534,0,0,0-1.044-1.79C787.875,117.3,779.9,134.149,779.6,134.82c-6.86,16.629-7.681,30.722-2.61,42.131a35.407,35.407,0,0,0,18.791,18.12Z"
                        transform="translate(-773.726 -119.926)" fill="#b7b8ba" />
                    </g>
                    <path
                      d="M714.316,99.217s4.1-1.566,4.027,1.417-6.04,16.405-20.506,11.633-10.514-16.181-6.115-13.05C696.047,102.274,714.316,99.217,714.316,99.217Z"
                      transform="translate(-418.283 -37.463)" fill="#b7bcb8" />
                    <path
                      d="M683.282,34.359s-5.145,4.922-5.444,9.023c-.373,4.1.522,4.176-.522,7.531s-2.013,4.922-.969,15.361,4.4,8.053,6.935,9.1,5.742,1.119,6.189,4.549c.447,3.356-1.492,2.834-1.492,2.834S686.787,92.3,698.568,92.9s13.8-9.545,13.8-9.545-7.531-4.176-6.86-10.887C706.249,65.9,726.01,31.973,683.282,34.359Z"
                      transform="translate(-415.286 -21.075)" fill="#efb097" />
                    <path
                      d="M708.029,70.435s8.948-7.084,10.738-11.334,10.738-11.185,3.356-19.015c0,0,4.25-14.168-7.382-18.12-11.633-4.027-17,1.641-22.147-4.027s-8.575,8.053-6.935,11.931c1.641,3.8,8.575,5.22,10.887,4.922s5.518-1.491,6.637,1.268,1.193,3.878-.746,3.878c-2.013,0-.373,3.281,1.193,4.922s2.834,5.22.969,7.457c0,0,2.312.671,4.25-1.939,0,0,2.461,0,1.491,4.027s-3.579,4.027-3.579,4.027S705.271,67.75,708.029,70.435Z"
                      transform="translate(-417.663 -16.585)" fill="#202022" />
                    <path
                      d="M708.786,72.9a1.357,1.357,0,0,0-.969.82,2.925,2.925,0,0,0-.3,1.342,14.787,14.787,0,0,1-.224,2.61,3.859,3.859,0,0,1-1.417,2.013,7.866,7.866,0,0,1-2.237,1.119c-1.641.6-3.356,1.044-5,1.491-1.715.447-3.43.895-5.145,1.268,1.79-.224,3.5-.522,5.22-.895a44.061,44.061,0,0,0,5.145-1.417,8.211,8.211,0,0,0,2.386-1.268,4.057,4.057,0,0,0,1.491-2.312,10.8,10.8,0,0,0,.075-2.684,3.489,3.489,0,0,1,.149-1.268A1.034,1.034,0,0,1,708.786,72.9Z"
                      transform="translate(-419.762 -30.907)" fill="#c38469" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <!-- ** END: CARD__COACH ** -->
        </div>
      </div>
      <!-- ** END: CARD_GROUP_COACH ** -->
    </div>
</template>

<script>
export default {
    name: 'Coachs',
    props: {
      height: String,
      links: Array,
      programmes: Boolean
    },
    methods: {
      toCoachSenior() {
        this.$emit("close")
        this.$router.push({name: this.links[1]})
      },
      toCoachJeunes() {
        this.$emit("close")
        this.$router.push({name: this.links[0]})
      }
    }
}
</script>

<style scoped>
  @import "~@/assets/css/global.scss";


  @media (max-width: 575.98px) {
    .b-card-group__item {
      height: 130px !important;
    }

    .b-card-group__item svg {
      height: 130px !important;
    }

  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .b-card-group__item {
      height: 150px !important;
    }

    .b-card-group__item svg {
      height: 150px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .b-card-group__item {
      height: 160px !important;
    }

    .b-card-group__item svg {
      height: 160px !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
   
  }

  @media (min-width: 1200px) {}


  .b-card-group__item {
    height: 280px;
    background: #232323;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .b-card-group__item:hover {
    background-color: red;
    margin-right: 12px;
  }


  .b-card-group__item__title {
    font-family: Montserrat-bold, sans-serif;
    line-height: 1.2;
    font-size: 1.4rem;
    color: white;
    text-align: start;
  }

  .b-card-group__item__title.left-top {
    position: absolute;
    left: 20px;
    top: 40px;
  }

  .b-card-group__item__title.left-top.es {
    padding-right: 10rem;
    top: 25px;
  }

  .b-card-group__item__title.left-top.last {
    top: 40px;
  }

  .b-card-group__item svg {
    height: 280px;
    width: auto;
    float: right;
    border-radius: 7px;
  }

</style>