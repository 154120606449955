<template>
  <div id="footer" class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-6 col-sm-8 col-8">
        <div class="row">
          <div class="col-4">
            <a href="https://www.youtube.com/channel/UCgIjvN1Yg9pEjM9vgUGpUTA" target="_blank" v-if="lang == 'fr'">
              <img
                class="img-fluid"
                src="@/assets/Images/Footer/Icon-youtube.png"
                alt="Y-icon"
              />
            </a>
          </div>
          <div class="col-4">
            <a :href="locale[lang].FOOTER.INSTAGRAM" target="_blank">
              <img
                class="img-fluid"
                src="@/assets/Images/Footer/Icon-instagram.png"
                alt="I-icon"
              />
            </a>
          </div>
          <div class="col-4">
            <a :href="locale[lang].FOOTER.FACEBOOK" target="_blank">
              <img
                class="img-fluid"
                src="@/assets/Images/Footer/Icon-facebook-f.png"
                alt="F-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 borderR links">
            <a href="javascript:;" @click="goTo(link1.link)"> {{ link1.text }} </a>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 borderR links">
            <a href="javascript:;" @click="goTo('APropos')"> {{locale[lang].FOOTER.LINKS[1]}} </a>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12 col-12 links">
            <a href="javascript:;" @click="goTo(link3.link)"> {{ link3.text }} </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12 footer-bottom">
        <p v-html="end">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    var w = window.innerWidth;
    this.link1.text = this.locale[this.lang].FOOTER.LINKS[0]
    this.link3.text = this.locale[this.lang].FOOTER.LINKS[2]
    this.end = this.locale[this.lang].FOOTER.END
    if (w <= 825) {
      this.link1 = {
        text: this.locale[this.lang].FOOTER.LINKS[2],
        link: "Contacto",
      };
      this.link3 = {
        text: this.locale[this.lang].FOOTER.LINKS[0],
        link: "Mentions",
      };
      this.end = this.locale[this.lang].FOOTER.END_MOBILE
    }
  },
  methods: {
    goTo(link) {
      this.$router.push({name: link})
    }
  },
  data() {
    return {
      link1: {
        text: "",
        link: "Mentions",
      },
      link3: {
        text: "",
        link: "Contacto",
      },
      end: ``,
    };
  },
};
</script>

<style scoped>
#footer {
  position: absolute;
  top: 50%;
}

.row.justify-content-center {
  margin-top: 25px;
}

.borderR {
  border-right: 1px solid #ffffff;
}

.links a,
.footer-bottom p {
  text-align: center;
  font: normal normal 600 18px/32px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 10px;
}

@media (max-width: 1690px) {
  #footer {
    top: 40% !important;
  }
}

@media (max-width: 1024px) {
  #footer {
    top: 30% !important;
  }
}

@media (max-width: 825px) {
  #footer {
    top: 20% !important;
  }

  .borderR {
    border-right: none;
  }

  .links {
    font: normal normal 600 18px/32px Montserrat;
    margin-bottom: 15px;
  }

  .footer-bottom {
    position: relative;
  }

  .footer-bottom::before {
    content: "";
    border-bottom: 2px solid #b9b9b9;
    width: 93.5%;
    left: 22px;
    position: absolute;
    top: -45%;
  }

  .footer-bottom p {
    text-align: center;
    font: normal normal 600 13px/22px Montserrat;
    color: #b9b9b9;
  }

  .footer-bottom p span {
    color: #ffffff;
  }
}

@media (max-width: 425px) {
  #footer {
    top: 10% !important;
  }

  .footer-bottom::before {
    width: 88%;
  }
}
</style>
